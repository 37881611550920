// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { pascalize } from 'humps';
import { map, omit, isFunction } from 'lodash';
import { parse, stringify } from 'query-string';

// dm
import * as modalComponents from '../Modals';
import { closeModal } from '../../../store/actions/modal';

class ModalProviderComponent extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  render() {
    const { modals, handleClose, dispatch, location } = this.props;
    return (
      <div>
        {map(modals.toJS(), (modalProps = {}, modalName = '') => {
          if (!modalName.length) return null;
          const ModalComponent = modalComponents[`${modalName}Modal`];
          return (
            <ModalComponent
              {...modalProps}
              key={modalName}
              contentLabel={modalName}
              isOpen
              close={handleClose(modalName)}
              dispatch={dispatch}
              location={location}
            />
          );
        })}
      </div>
    );
  }
}

export default connect(
  state => ({
    modals: state.get('modal'),
  }),
  (dispatch, { location }) => ({
    handleClose: modalName => onClose => {
      const { modal = '', ...search } = parse(location.search);
      if (pascalize(modal) === modalName) {
        dispatch(
          push({ ...location, search: stringify(omit(search, 'modalProps')) }),
        );
      } else {
        dispatch(closeModal(modalName));
      }
      if (isFunction(onClose)) {
        onClose();
      }
    },
    dispatch,
  }),
)(ModalProviderComponent);

// vendor
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
// dm
import { CALL_API } from '../../constants/api';
import { ROLES } from '../../constants/user';
import { createConstants } from '../../utils/core';
import {
  getIdFromCookie,
  deleteUserFromCookie,
  setAuthData,
  resetAuthData,
} from '../../utils/jwt';
import { getIsKycCompleted } from '../../utils/kyc';

/* action types */
export const types = createConstants(
  'SIGNUP_USER_REQUEST',
  'SIGNUP_USER_SUCCESS',
  'SIGNUP_USER_FAILURE',

  'ACTIVATE_ACCOUNT_REQUEST',
  'ACTIVATE_ACCOUNT_SUCCESS',
  'ACTIVATE_ACCOUNT_FAILURE',

  'SIGNIN_USER_REQUEST',
  'SIGNIN_USER_SUCCESS',
  'SIGNIN_USER_FAILURE',

  'AUTHORIZE_USER_SUCCESS',

  'LOGOUT_USER',
  'LOGOUT_USER_REQUEST',
  'LOGOUT_USER__SUCCESS',
  'LOGOUT_USER_FAILURE',

  'READ_USER_REQUEST',
  'READ_USER_SUCCESS',
  'READ_USER_FAILURE',

  'READ_PROFILE_REQUEST',
  'READ_PROFILE_SUCCESS',
  'READ_PROFILE_FAILURE',

  'CREATE_PROFILE_REQUEST',
  'CREATE_PROFILE_SUCCESS',
  'CREATE_PROFILE_FAILURE',

  'FORGOT_PASSWORD_REQUEST',
  'FORGOT_PASSWORD_SUCCESS',
  'FORGOT_PASSWORD_FAILURE',

  'CREATE_PASSWORD_REQUEST',
  'CREATE_PASSWORD_SUCCESS',
  'CREATE_PASSWORD_FAILURE',

  'SET_PASSWORD_REQUEST',
  'SET_PASSWORD_SUCCESS',
  'SET_PASSWORD_FAILURE',

  'CHANGE_PASSWORD_REQUEST',
  'CHANGE_PASSWORD_SUCCESS',
  'CHANGE_PASSWORD_FAILURE',

  'CREATE_AVATAR_REQUEST',
  'CREATE_AVATAR_SUCCESS',
  'CREATE_AVATAR_FAILURE',

  'READ_UNREAD_FEED_AMOUNT_REQUEST',
  'READ_UNREAD_FEED_AMOUNT_SUCCESS',
  'READ_UNREAD_FEED_AMOUNT_FAILURE',
  'SET_USER_FROM_COOKIE',

  'POST_NEWSLETTER_SUBSCRIPTION_REQUEST',
  'POST_NEWSLETTER_SUBSCRIPTION_SUCCESS',
  'POST_NEWSLETTER_SUBSCRIPTION_FAILURE',

  'NOTIFICATION_TOGGLE',
  'MERGE_USER',

  'USER_CONFIRM_UNSUBSCRIBE_REQUEST',
  'USER_CONFIRM_UNSUBSCRIBE_SUCCESS',
  'USER_CONFIRM_UNSUBSCRIBE_FAILURE',

  'UPDATE_PROFILE_PROGRESS_BAR',

  'CONNECT_TO_INSTITUTIONAL_SERVICES_REQUEST',
  'CONNECT_TO_INSTITUTIONAL_SERVICES_SUCCESS',
  'CONNECT_TO_INSTITUTIONAL_SERVICES_FAILURE',
);

/* notification -> toggle */
export const notificationToggle = data => ({
  type: types.NOTIFICATION_TOGGLE,
  payload: data,
});

/* user -> merge / update */
export const mergeUser = data => ({
  type: types.MERGE_USER,
  payload: data,
});

export const confirmUser = token => ({
  [CALL_API]: {
    types: [
      types.ACTIVATE_ACCOUNT_REQUEST,
      types.ACTIVATE_ACCOUNT_SUCCESS,
      types.ACTIVATE_ACCOUNT_FAILURE,
    ],
    url: 'auth/activate',
    data: { token },
    withProgressBar: true,
  },
});

/* request -> user - register */
export const registerUser = data => ({
  [CALL_API]: {
    data,
    method: 'post',
    url: 'user/auth',
    types: [
      types.SIGNUP_USER_REQUEST,
      types.SIGNUP_USER_SUCCESS,
      types.SIGNUP_USER_FAILURE,
    ],
    withProgressBar: true,
    isAuth: false,
  },
});

/* request -> user - login */
export const loginUser = data => ({
  [CALL_API]: {
    data,
    method: 'post',
    url: 'auth/login?expand=kyc_status,suitability_status',
    types: [
      types.SIGNIN_USER_REQUEST,
      types.SIGNIN_USER_SUCCESS,
      types.SIGNIN_USER_FAILURE,
    ],
    withProgressBar: true,
    isAuth: false,
  },
});

export const establishCurrentUser = user => dispatch => {
  if (user) {
    return dispatch({ type: types.SET_USER_FROM_COOKIE, payload: user });
  }

  const userId = getIdFromCookie();
  if (userId) {
    dispatch(readUser(userId)).then(({ data }) =>
      dispatch(authorizeUserSuccess(data)),
    );
  }
};

export const authorizeUserSuccess = data => dispatch => {
  const { rolesList, avatarUrl, accessToken, refreshToken, ...userData } = data;
  const { firstName, lastName, kycStatus } = userData;
  const user = {
    ...userData,
    access: rolesList
      .filter(role => role !== 'admin')
      .reduce((access, role) => access | ROLES[role.c()], 0),
    avatar: { url: avatarUrl },
    fullName: `${firstName} ${lastName}`,
    isKycCompleted: getIsKycCompleted(kycStatus),
    isAuthorized: true,
  };
  setAuthData({ accessToken, refreshToken });
  Cookies.set('user', user, { expires: 365 });

  dispatch({ type: types.AUTHORIZE_USER_SUCCESS, payload: user });

  const redirectUrl = Cookies.get('redirectUrl');

  if (redirectUrl) {
    Cookies.remove('redirectUrl');
    return dispatch(push(redirectUrl));
  }
  if (user.access === ROLES.INVESTOR) {
    return dispatch(push('/inv-dashboard/tabs/activity-feeds'));
  } else if (user.access === ROLES.ASSET_MANAGER) {
    return dispatch(push('/am-dashboard'));
  }
};

export const forgotPassword = ({ email, returnUrl }) => ({
  [CALL_API]: {
    types: [
      types.FORGOT_PASSWORD_REQUEST,
      types.FORGOT_PASSWORD_SUCCESS,
      types.FORGOT_PASSWORD_FAILURE,
    ],
    method: 'get',
    url: `auth/password?email=${email}&return_url=${returnUrl}`,
    withProgressBar: true,
  },
});

export const resetPassword = data => ({
  [CALL_API]: {
    types: [
      types.SET_PASSWORD_REQUEST,
      types.SET_PASSWORD_SUCCESS,
      types.SET_PASSWORD_FAILURE,
    ],
    method: 'post',
    url: 'auth/password',
    data,
    withProgressBar: true,
  },
});

export const logout = next => {
  setTimeout(() => {
    deleteUserFromCookie();
    resetAuthData();
  }, 500);
  if (next) {
    next(push('/log-in'));
    return { type: types.LOGOUT_USER };
  }

  return dispatch => {
    dispatch(logoutRequest());
    dispatch(push('/log-in'));
    dispatch({ type: types.LOGOUT_USER });
  };
};

const logoutRequest = () => ({
  [CALL_API]: {
    types: [
      types.LOGOUT_USER_REQUEST,
      types.LOGOUT_USER__SUCCESS,
      types.LOGOUT_USER_FAILURE,
    ],
    method: 'post',
    url: 'auth/logout',
    withProgressBar: false,
  },
});

export const readUser = id => ({
  [CALL_API]: {
    types: [
      types.READ_USER_REQUEST,
      types.READ_USER_SUCCESS,
      types.READ_USER_FAILURE,
    ],
    method: 'get',
    url: `users/${id}?expand=kyc_status,suitability_status`,
    withProgressBar: true,
  },
});

export const readUserProfile = id => ({
  [CALL_API]: {
    types: [
      types.READ_PROFILE_REQUEST,
      types.READ_PROFILE_SUCCESS,
      types.READ_PROFILE_FAILURE,
    ],
    method: 'get',
    url: `users/${id}/profile`,
    form: 'profile',
    withProgressBar: true,
  },
});

export const loadUserProfile = id => dispatch =>
  dispatch(readUser(id)).then(() => dispatch(readUserProfile(id)));

export const updateUserProfile = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_PROFILE_REQUEST,
      types.CREATE_PROFILE_SUCCESS,
      types.CREATE_PROFILE_FAILURE,
    ],
    method: 'put',
    url: `users/${id}/profile`,
    data,
    withProgressBar: true,
  },
});

export const updateUserPassword = data => ({
  [CALL_API]: {
    types: [
      types.CREATE_PASSWORD_REQUEST,
      types.CREATE_PASSWORD_SUCCESS,
      types.CREATE_PASSWORD_FAILURE,
    ],
    method: 'put',
    url: 'auth/password',
    data,
    form: 'changePassword',
    withProgressBar: true,
  },
});

export const updateUserAvatar = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_AVATAR_REQUEST,
      types.CREATE_AVATAR_SUCCESS,
      types.CREATE_AVATAR_FAILURE,
    ],
    url: `users/${id}/profile/change-avatar`,
    data: { file: data },
    withProgressBar: true,
  },
});

export const readUnreadFeedsAmount = () => ({
  [CALL_API]: {
    types: [
      types.READ_UNREAD_FEED_AMOUNT_REQUEST,
      types.READ_UNREAD_FEED_AMOUNT_SUCCESS,
      types.READ_UNREAD_FEED_AMOUNT_FAILURE,
    ],
    method: 'get',
    url: 'activity-feed/unread-feed-amount',
    data: {},
  },
});

/* request -> user - subscribe to mailchimp's list */
export const newsletterSubscribe = data => ({
  [CALL_API]: {
    data,
    method: 'post',
    url: 'email/subscribe',
    types: [
      types.POST_NEWSLETTER_SUBSCRIPTION_REQUEST,
      types.POST_NEWSLETTER_SUBSCRIPTION_SUCCESS,
      types.POST_NEWSLETTER_SUBSCRIPTION_FAILURE,
    ],
  },
});

/* request -> user - confirm unsubscribe */
export const userConfirmUnsubscribe = data => ({
  [CALL_API]: {
    method: 'POST',
    authenticated: false,
    endpoint: `email/${data.token}/unsubscribe`,
    types: [
      types.USER_CONFIRM_UNSUBSCRIBE_REQUEST,
      types.USER_CONFIRM_UNSUBSCRIBE_SUCCESS,
      types.USER_CONFIRM_UNSUBSCRIBE_FAILURE,
    ],
  },
});

export const updateProfileProgressBar = (fields = [], errors = {}) => {
  const fieldsAmount = fields.length;
  const errorsAmount = Object.keys(errors).length;
  // debugger
  const progressPercentage = Math.round(
    (100 / fieldsAmount) * (fieldsAmount - errorsAmount),
  );
  return {
    type: types.UPDATE_PROFILE_PROGRESS_BAR,
    payload: { progressPercentage },
  };
};

export const connectToInstitutionalServices = data => ({
  [CALL_API]: {
    data,
    method: 'post',
    url: 'institutional-services',
    types: [
      types.CONNECT_TO_INSTITUTIONAL_SERVICES_REQUEST,
      types.CONNECT_TO_INSTITUTIONAL_SERVICES_SUCCESS,
      types.CONNECT_TO_INSTITUTIONAL_SERVICES_FAILURE,
    ],
  },
});

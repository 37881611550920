// vendor
import React from 'react';
import classNames from 'classnames';

// dm
import Link from '../../atoms/Link';

// own
import './HeaderNavLink.scss';

export default ({ className, tagNew, ...props }) => (
  <Link
    {...props}
    className={classNames(className, 'header-nav-link', {
      'header-nav-link--has-amount': props['data-amount'],
      'header-nav-link--new': tagNew,
    })}
    activeClassName="header-nav-link--active"
  />
);

// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'CREATE_MEDIA_REQUEST',
  'CREATE_MEDIA_SUCCESS',
  'CREATE_MEDIA_FAILURE',
);

export const createMedia = (
  { requestType, successType, ...data },
  fieldName,
) => ({
  [CALL_API]: {
    types: [
      requestType || types.CREATE_MEDIA_REQUEST,
      successType || types.CREATE_MEDIA_SUCCESS,
      types.CREATE_MEDIA_FAILURE,
    ],
    url: 'media',
    data,
    extraData: { fieldName },
    withProgressBar: true,
  },
});

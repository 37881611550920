// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

// own
import './Input.scss';
import FormControl from '../FormControl';

class InputComponent extends Component {
  static propTypes = {
    type: PropTypes.oneOf('text email password tel'.w()),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    type: 'text',
    meta: {},
    input: {},
  };

  state = {
    caretPosition: 0,
  };

  // TODO: fix carret position when paste and remove for selection area
  componentWillUpdate(nextProps) {
    const { props } = this;
    if (props.hasFormatting) {
      const value = props.input.value;
      const newValue = nextProps.input.value;
      const { formatRules } = props;
      if (value !== newValue) {
        let caretPosition = this.input.selectionStart;
        const diff = newValue.length - value.length;
        caretPosition += diff - Math.sign(diff);
        if (
          formatRules &&
          formatRules.suffix &&
          caretPosition >= newValue.length
        ) {
          caretPosition -= formatRules.suffix.length;
        }
        this.setState({ caretPosition });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.hasFormatting) {
      const { caretPosition } = this.state;
      this.input.setSelectionRange(caretPosition, caretPosition);
    }
  }

  setInput = inputNode => {
    this.input = inputNode;
  };

  controlCaret = () => {
    const {
      formatRules,
      input: { value },
    } = this.props;
    const input = this.input;
    let caretPosition = input.selectionStart;
    if (formatRules && formatRules.suffix && caretPosition >= value.length) {
      caretPosition = value.length - formatRules.suffix.length;
      input.setSelectionRange(caretPosition, caretPosition);
    }
  };

  render() {
    const {
      meta,
      size,
      icon,
      iconPos,
      input,
      type,
      disabled,
      prefix,
      intl,
      className,
      ...props
    } = this.props;
    const showFundFormErrors = props.showFundFormErrors || false;
    let placeholder =
      props.placeholder && intl.formatMessage({ id: props.placeholder });
    if (placeholder && prefix) {
      placeholder = `${prefix} ${placeholder}`;
    }
    // TODO: move Field to Input component
    let inputProps = { type, disabled, placeholder };
    if (this.props.hasFormatting) {
      inputProps = {
        ...inputProps,
        onClick: this.controlCaret,
        onKeyDown: this.controlCaret,
        onKeyUp: this.controlCaret,
      };
    }
    return (
      <FormControl
        kind="input"
        className={className}
        {...meta}
        {...{ size, icon, iconPos, disabled, showFundFormErrors }}
      >
        <input {...input} {...inputProps} ref={this.setInput} />
      </FormControl>
    );
  }
}

export default injectIntl(InputComponent);

// vendor
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../atoms/Button';
import { declineFundPipeline } from '../../../store/actions/fund';

const AccessDeclineButton = props => (
  <Button
    size="sm"
    icon="label-error"
    iconPos="left"
    color="red-s"
    text="Decline"
    frozen
    {...props}
  />
);

export default connect(
  null,
  null,
  (s, { dispatch }, { actionProps, ...props }) => ({
    ...props,
    onClick: () => dispatch(declineFundPipeline(actionProps)),
  }),
)(AccessDeclineButton);

// vendor
import React from 'react';
import { connect } from 'react-redux';

// dm
import responsive from '../../../enhancers/responsive';

// own
import OpenModalButton from './OpenModalButton';

const InviteAcceptButton = ({
  actionProps,
  investorId,
  breakpoint,
  ...props
}) => (
  <OpenModalButton
    size="sm"
    icon={breakpoint !== 'mobile' ? 'checked-lg' : ''}
    iconPos="left"
    color="green"
    text="Accept"
    action="inviteAccept"
    actionProps={{ ...actionProps, investorId }}
    {...props}
  />
);

export default connect(state => ({
  investorId: state.get('user').get('id'),
  modal: 'PotentialInvestmentAmount',
}))(
  responsive({
    breakpoints: ['mobile', 'tablet'],
  })(InviteAcceptButton),
);

/* eslint react/no-danger: 0 */
// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// own
import './FundShowRisks.scss';

export default ({ risks }) => {
  /** parse md links */
  const pattern = /\((.+?)\)\[(.*?)\]/gi;
  const text = risks.replace(pattern, match => {
    const linkarr = match.split(')[');
    const childs = linkarr[0].replace('(', '');
    const href = linkarr[1].replace(']', '');
    return `<a href="${href}" target="_blank">${childs}</a>`;
  });

  return (
    <div className="fund-show-risks">
      <div className="fund-show-risks__title">
        <FormattedMessage id="Risks" />
      </div>
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import SlickCarousel from 'react-slick';
import classNames from 'classnames';
import 'slick-carousel/slick/slick.css';

// own
import './Carousel.scss';
import CarouselArrow from './CarouselArrow';

export default class Carousel extends React.Component {
  static propTypes = {
    dots: PropTypes.bool,
  };

  static defaultProps = {
    dots: false,
    centerPadding: 0,
    prevArrow: <CarouselArrow />,
    nextArrow: <CarouselArrow />,
  };

  render() {
    const { props } = this;
    const className = classNames('carousel', props.className);
    return <SlickCarousel {...props} className={className} />;
  }
}

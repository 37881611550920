// vendor
import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

// dm
import responsive from '../../../enhancers/responsive';

// own
import './Dropdown.scss';
import DropdownContent from './DropdownContent';

class DropdownComponent extends Component {
  static propTypes = {
    breakpoint: PropTypes.string.isRequired,
    opened: PropTypes.bool,
    event: PropTypes.oneOf('click hover'.w()),
    divProps: PropTypes.shape({
      ref: PropTypes.func,
    }),
  };

  static defaultProps = {
    opened: false,
    event: 'hover',
    divProps: {},
  };

  state = {
    opened: false,
  };

  getEventProps(handler, opened) {
    const { breakpoint } = this.props;
    let { event } = this.props;
    let eventProps = {};
    if (breakpoint === 'tablet') {
      event = 'click';
    }
    if (event === 'hover') {
      eventProps = {
        onMouseEnter: this.openContent,
        onMouseLeave: this.closeContent,
      };
    } else {
      const handlerEventProps = {
        click: {
          onClick: this.toggleContent(!opened),
        },
      }[event];
      handler = cloneElement(handler, handlerEventProps);
    }
    return {
      handler,
      ...eventProps,
    };
  }

  toggleContent(opened) {
    return () => {
      if (this.state.opened !== opened) {
        this.setState({ opened });
      }
    };
  }

  openContent = this.toggleContent(true);

  closeContent = this.toggleContent(false);

  handleClickOutside = this.closeContent;

  render() {
    const { className, divProps, ...props } = this.props;
    const childrens = Children.toArray(props.children);
    const children = childrens.slice(1);
    const { opened } = this.state;
    const fullClassName = classNames(className, 'dropdown', {
      'dropdown--opened': opened,
    });
    const { handler, ...eventProps } = this.getEventProps(childrens[0], opened);
    return (
      <div {...eventProps} {...divProps} className={fullClassName}>
        {handler}
        <DropdownContent {...props} closeContent={this.closeContent}>
          {!!children.length && children}
        </DropdownContent>
      </div>
    );
  }
}

export default responsive({
  breakpoints: ['tablet', 'desktop'],
})(onClickOutside(DropdownComponent));

// vendor
import React from 'react';
import { connect } from 'react-redux';

import OpenModalButton from './OpenModalButton';
import {
  createInvestment,
  readInvestmentHistoryByFundId,
  updateInvestmentsFund,
} from '../../../store/actions/investments';
import {
  readFundsForInvestments,
  updateFundPipeline,
} from '../../../store/actions/fund';
import { closeModal } from '../../../store/actions/modal';

const ReadyToInvestButton = ({ handleClick, text, disabled, size }) => (
  <OpenModalButton
    size={size}
    text={text}
    disabled={disabled}
    modal="upload-sub-document"
    handleClick={handleClick}
  />
);

const mapDispatchToProps = (dispatch, { actionProps, userName }) => ({
  handleClick: openModal => {
    dispatch(closeModal('ReadyInvestAmount'));
    dispatch({
      type: 'GTM_EVENT',
      payload: {
        event: 'fund',
        eventCategory: 'readytoinvest',
        eventLabel: `${userName}_readytoinvest_${actionProps.fundName}`,
        eventValue: `${actionProps.amountInput}`,
      },
    });
    if (actionProps.selectPotentialInvest === '1') {
      dispatch(
        updateFundPipeline({
          id: actionProps.fundId,
          amount: actionProps.amountInput,
        }),
      );
    }
    dispatch(createInvestment(actionProps.fundId)).then(({ data }) => {
      dispatch(readInvestmentHistoryByFundId(actionProps.fundId)).then(
        ({ status, data: investments }) => {
          if (status === 'success') {
            const funds = investments.map(investment => investment.fundId);
            dispatch(readFundsForInvestments(funds)).then(
              ({ status: result, data: dataFunds }) => {
                if (result === 'success') {
                  const newFunds = {};
                  dataFunds.forEach(fund => {
                    newFunds[fund.id] = {
                      ...fund,
                    };
                  });
                  const newInvestments = investments.map(investment => {
                    investment.fund = newFunds[investment.fundId];
                    return investment;
                  });
                  dispatch(updateInvestmentsFund(newInvestments));
                }
              },
            );
          }
        },
      );
      openModal({ investmentId: data.id, ...actionProps });
    });
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(ReadyToInvestButton);

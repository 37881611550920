import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import jsCookies from 'js-cookie';

import { toJS } from '../utils/core';
import { INVESTOR } from '../constants/user';

const InvestorRoute = ({ component: Component, ...rest }) => {
  const {
    user: { isAuthorized, access, isProfileCompleted },
    location,
  } = rest;
  const isFullAccess =
    isAuthorized && access === INVESTOR && isProfileCompleted;
  const redirectTo = isAuthorized ? '/profile' : '/log-in';
  if (!isAuthorized && location) {
    const redirectUrl = location.pathname + location.search;
    jsCookies.set('redirectUrl', redirectUrl);
  }

  return (
    <Route
      {...rest}
      render={props =>
        isFullAccess ? <Component {...props} /> : <Redirect to={redirectTo} />
      }
    />
  );
};

const mapStateToProps = state => ({
  user: toJS(state.get('user')),
});

export default connect(mapStateToProps)(InvestorRoute);

// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Tag from '../../../../atoms/Tag/Tag';
import Icon from '../../../../atoms/Icons/Icon';
import { listParam } from '../../../../../utils/collection';

export default class InvestorInformationHD extends React.Component {
  render() {
    const {
      lists,
      firmAum,
      averageInvestment,
      industryFocuses,
      preferredAssetTypes,
      hedgeFundStrategies,
      ventureCapitalStageFocuses,
      tagSize,
      className,
    } = this.props;
    const fullClassName = classNames('investor-card__info', className);
    return (
      <div className={fullClassName}>
        <div className="investor-card__info__col">
          <div className="investor-card__info__col__item">
            <div>
              <FormattedMessage id="Average Investment" />:
            </div>
            <div>
              <Icon name="denomination" />
              {listParam(averageInvestment, lists.averageInvestment)}
            </div>
          </div>
          {!!preferredAssetTypes.length && (
            <div className="investor-card__info__col__item">
              <div>
                <FormattedMessage id="Preferred Asset Types" />
                &nbsp; (<strong>{preferredAssetTypes.length}</strong>)
              </div>
              <div>
                <Icon name="frame-flat" />
                {preferredAssetTypes.map(id => (
                  <Tag size={tagSize} key={id}>
                    {listParam(id, lists.preferredAssetType)}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          {!!hedgeFundStrategies.length && (
            <div className="investor-card__info__col__item">
              <div>
                <FormattedMessage id="Preferred Hedge Fund Strategies" />
                &nbsp; (<strong>{hedgeFundStrategies.length}</strong>)
              </div>
              <div>
                <Icon name="frame" />
                {hedgeFundStrategies.map(id => (
                  <Tag size={tagSize} key={id}>
                    {listParam(id, lists.hedgeFundStrategy)}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="investor-card__info__col">
          <div className="investor-card__info__col__item investor-card__info__col__item--green">
            {firmAum && (
              <div>
                <FormattedMessage id="Assets Under Management" />
              </div>
            )}
            {firmAum && (
              <div>
                <Icon name="purse" />
                {listParam(firmAum, lists.firmAum)}
              </div>
            )}
          </div>
          {!!ventureCapitalStageFocuses.length && (
            <div className="investor-card__info__col__item">
              <div>
                <FormattedMessage id="Preferred Venture Capital Stage Focus" />
                &nbsp; (<strong>{ventureCapitalStageFocuses.length}</strong>)
              </div>
              <div>
                <Icon name="frame" />
                {ventureCapitalStageFocuses.map(id => (
                  <Tag key={id} size={tagSize}>
                    {listParam(id, lists.ventureCapitalStageFocus)}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          {!!industryFocuses.length && (
            <div className="investor-card__info__col__item">
              <div>
                <FormattedMessage id="Industry Focus" />
                &nbsp; (<strong>{industryFocuses.length}</strong>)
              </div>
              <div>
                <Icon name="frame" />
                {industryFocuses.map(id => (
                  <Tag size={tagSize} key={id}>
                    {listParam(id, lists.industryFocus)}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'redux-form/immutable';

export default class FieldArrayComponent extends Component {
  static propTypes = {
    fieldComponent: PropTypes.any.isRequired,
    fieldProps: PropTypes.object,
  };

  static defaultProps = {
    fieldProps: {},
  };

  renderFields({ className, children, fields, fieldComponent, fieldProps }) {
    return (
      <div className={className}>
        {children}
        {fields.map((field, index) => (
          <Field
            {...fieldProps}
            key={index}
            name={field}
            component={fieldComponent}
          />
        ))}
      </div>
    );
  }

  render() {
    return <FieldArray {...this.props} component={this.renderFields} />;
  }
}

// vendor
import {
  formValueSelector,
  getFormValues,
  hasSubmitFailed,
} from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { kycProofTypes, userTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'kycProof';

/* initial state */
const initialState = fromJS({
  values: {
    proofOfIdentityMedia: {},
    mediaType: 'passport',
  },
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);
export const submitFailedSelector = hasSubmitFailed(form);
export const syncErrorsSelector = state =>
  state
    .get('form')
    .get(form)
    .get('syncErrors');

/* reducer */
export default createReducer(initialState, {
  [userTypes.READ_USER_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), response.data),
  [userTypes.READ_PROFILE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), response.data),
  [kycProofTypes.READ_KYC_PROOF_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), response.data),
  [kycProofTypes.CREATE_KYC_PROOF_SUCCESS]: state => state,
  [kycProofTypes.CREATE_KYC_PROOF_MEDIA_REQUEST]: (state, { progress }) =>
    state.setIn('values.progress'.p(), progress),
  [kycProofTypes.CREATE_KYC_PROOF_MEDIA_SUCCESS]: (state, { response }) =>
    state.setIn('values.proofOfIdentityMedia'.p(), response.data),
  [kycProofTypes.CLEAR_KYC_PROOF_MEDIA]: state =>
    state.setIn('values.proofOfIdentityMedia'.p(), {}),
});

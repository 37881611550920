import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'SHOW_NOTIFICATION',
  'SHOW_TOASTR_INFO',
  'SHOW_TOASTR_ERROR',
  'SHOW_TOASTR_SUCCESS',
  'FETCHING_DATA',
  'FETCHING_DATA_SUCCESS',
  'FETCHING_DATA_FAILURE',
  'SET_TOKEN',
  'OPEN_ASIDE_MENU',
);

export const startFetchingData = () => ({
  type: types.FETCHING_DATA,
});

export const fetchingSuccess = response => ({
  type: types.FETCHING_DATA_SUCCESS,
  payload: { response },
});

export const fetchingFailure = response => ({
  type: types.FETCHING_DATA_FAILURE,
  payload: { response },
});

export const showNotification = payload => ({
  type: types.SHOW_NOTIFICATION,
  payload,
});

export const showToastrError = payload => ({
  type: types.SHOW_TOASTR_ERROR,
  payload,
});

export const showToastrSuccess = payload => ({
  type: types.SHOW_TOASTR_SUCCESS,
  payload,
});

export const showToastrInfo = payload => ({
  type: types.SHOW_TOASTR_INFO,
  payload,
});

export const openAsideMenu = isOpen => ({
  type: types.OPEN_ASIDE_MENU,
  payload: { isOpen },
});

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
// dm
import Button from '../../../../../atoms/Button';
import Tooltip from '../../../../../molecules/Tooltip';
import Modal from '../../../../Modal/Modal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalFooter from '../../../../Modal/ModalFooter';
import ModalHeader from '../../../../Modal/ModalHeader';
import ModalControls from '../../../../Modal/ModalControls';

import { allowPipelineDocuments } from '../../../../../../store/actions/fund';
import { TABLET, MOBILE } from '../../../../../../constants/breakpoints';
// own
import './CoolingOffPeriodModal.scss';

class CoolingOffPeriodModal extends Component {
  render() {
    const { days, close, allowDocuments } = this.props;
    const TEN = 10;
    return (
      <Modal className="cooling-off-period-modal" {...this.props}>
        <ModalHeader title="modal.cooling_off.title" />
        <ModalBody>
          <FormattedMessage id="modal.cooling_off.message1" />
          <MediaQuery minWidth={TABLET.left}>
            <Tooltip responsive>
              <FormattedMessage id="modal.cooling_off.tooltip" />
            </Tooltip>
          </MediaQuery>
          <MediaQuery maxWidth={MOBILE.right}>
            <Tooltip responsive position="bottom-center">
              <FormattedMessage id="modal.cooling_off.tooltip" />
            </Tooltip>
          </MediaQuery>
          <div className="cooling-off-period-modal__days">
            <div className="cooling-off-period-modal__days__number">
              {Math.floor(days / TEN)}
            </div>
            <div className="cooling-off-period-modal__days__number">
              {days % TEN}
            </div>
            <FormattedMessage id="modal.cooling_off.message2" />
          </div>
        </ModalBody>
        <ModalFooter className="cooling-off-period-modal__footer">
          <FormattedMessage tagName="p" id="modal.cooling_off.message3" />
          <ModalControls>
            <Button
              color="red-s"
              frozen
              icon="label-error"
              text="Cancel"
              type="button"
              onClick={close}
            />
            <Button
              color="green"
              frozen
              icon="checked-lg"
              text="Confirm"
              type="button"
              onClick={allowDocuments}
            />
          </ModalControls>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  null,
  (dispatch, { id, investorId, documents, close }) => ({
    allowDocuments: () =>
      dispatch(allowPipelineDocuments({ id, investorId, documents })).then(
        close,
      ),
  }),
)(CoolingOffPeriodModal);

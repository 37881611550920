// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';

// dm
import Icon from '../../atoms/Icons/Icon';
import Link from '../../atoms/Link';
import { selector } from '../../../store/reducers/form/profile';

class LinkedinLinkComponent extends Component {
  render() {
    const { linkedinUrl, fullName, ...props } = this.props;
    let linkedinProps = {};
    if (linkedinUrl && linkedinUrl !== 'not_public') {
      linkedinProps = {
        href: linkedinUrl,
        target: '_blank',
      };
    }
    return (
      <Link
        className="has-icons"
        {...linkedinProps}
        {...omit(props, 'dispatch')}
      >
        {fullName}
        <Icon name="linkedin-sm" style={{ marginLeft: '10px' }} />
      </Link>
    );
  }
}

export default connect(state => ({
  fullName: state.get('user').get('fullName'),
  linkedinUrl: selector(state, 'linkedinUrl'),
}))(LinkedinLinkComponent);

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// dm
import Icon from '../../atoms/Icons/Icon';

// own
import './Tag.scss';

export default class TagComponent extends Component {
  static propTypes = {
    border: PropTypes.oneOf('solid dashed dotted none'.w()),
    color: PropTypes.oneOf(
      'blue blue-g cyan cyan-p green green-d green-h green-l headings orange red violet darkviolet white green-light'.w(),
    ),
    flat: PropTypes.bool,
    icon: PropTypes.string,
    iconPos: PropTypes.oneOf('left center right'.w()),
    iconClick: PropTypes.func,
    size: PropTypes.oneOf('xs sm md lg'.w()),
  };

  static defaultProps = {
    color: 'blue-g',
    iconPos: 'left',
    size: 'md',
  };

  render() {
    const {
      className,
      border,
      color,
      flat,
      icon,
      iconPos,
      size,
      iconClick,
      ...props
    } = this.props;
    const fullClassName = classNames(className, 'tag', {
      [`tag--${size}`]: size,
      [`tag--${color}`]: !flat,
      [`tag--flat tag--${color}--flat`]: flat,
      [`tag--icon tag--icon--${iconPos}`]: icon,
      'tag--icon--clickable': iconClick,
    });
    return (
      <div {...props} className={fullClassName} style={{ borderStyle: border }}>
        {props.children}
        {icon && <Icon name={icon} onClick={iconClick} />}
      </div>
    );
  }
}

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './DropdownContent.scss';
import DropdownList from './DropdownList';
import DropdownTriangle from './DropdownTriangle';

export default class DropdownContentComponent extends Component {
  static propTypes = {
    position: PropTypes.oneOf([
      'top-left',
      'top-center',
      'top-right',
      'left-top',
      'right-top',

      'left-center',
      'right-center',

      'left-bottom',
      'right-bottom',
      'bottom-left',
      'bottom-center',
      'bottom-right',
    ]).isRequired,
    triangle: PropTypes.bool,
  };

  static defaultProps = {
    position: 'bottom-center',
    triangle: true,
  };

  render() {
    const {
      className,
      children,
      position,
      triangle,
      styleContent,
      styleTriangle,
      ...props
    } = this.props;
    const fullClassName = classNames(
      className,
      'dropdown-content',
      `dropdown-content--${position}`,
    );
    return (
      <div className={fullClassName} style={styleContent}>
        {triangle && <DropdownTriangle style={styleTriangle} />}
        <div className="dropdown-content__body">
          {children || <DropdownList {...props} />}
        </div>
      </div>
    );
  }
}

// vendor
import { pascalize } from 'humps';
import { push } from 'connected-react-router';
import { parse } from 'query-string';
// dm
import { createConstants } from '../../utils/core';
import { getPath } from '../../utils/route';

/* action types */
export const types = createConstants(
  'OPEN_MODAL',
  'CLOSE_MODAL',
  'CLOSE_MODALS',
  'OPEN_KYC_MODAL',
);

export const openModal = (modalName = '', modalProps) => ({
  type: types.OPEN_MODAL,
  payload: {
    modalName: pascalize(modalName),
    modalProps,
  },
});

export const closeModal = modalName => ({
  type: types.CLOSE_MODAL,
  payload: {
    modalName,
  },
});

export const closeModals = () => ({
  type: types.CLOSE_MODALS,
});

export const openKycModal = (kycModal, location) => dispatch => {
  if (location && location.search) {
    const entry = parse(location.search);
    dispatch(push(entry));
  } else {
    dispatch(push(getPath('profile')));
  }
  dispatch(openModal(kycModal));
};

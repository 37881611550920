// vendor
import { fromJS } from 'immutable';

// dm
import { investmentTypes } from '../actions';
import { createReducer } from '../../utils/core';

const initialState = fromJS({
  data: [],
});

export default createReducer(initialState, {
  [investmentTypes.READ_INVESTMENTS_SUCCESS]: (state, { response }) =>
    state.setIn('data'.p(), response.data),
  [investmentTypes.READ_INVESTMENTS_BY_FUND_ID_SUCCESS]: (
    state,
    { response },
  ) => state.setIn('data'.p(), response.data),
  [investmentTypes.READ_INVESTMENTS_PIPELINE_SUCCESS]: (state, { response }) =>
    state.setIn('data'.p(), response.data),
  [investmentTypes.UPDATE_INVESTMENTS_FUND]: (state, investments) =>
    state.mergeDeepIn('data'.p(), investments),
  [investmentTypes.UPDATE_INVESTMENT_FUND]: (state, payload) =>
    state.update('data', investments =>
      investments.map(investment => {
        if (investment.id === payload.id) {
          return payload;
        }
        return investment;
      }),
    ),
});

// vendor
import React from 'react';

// dm
import AvatarImage from '../../atoms/Images/AvatarImage';

export default ({ avatar, firstName, lastName }) => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  return (
    <AvatarImage
      className="header-nav__account__avatar"
      src={avatar}
      alt={initials}
      size="xs"
    />
  );
};

// vendor
import React from 'react';
import { connect } from 'react-redux';
// dm
import Button from '../../atoms/Button';
import { readRequestFundDocuments } from '../../../store/actions/fund';

const DocumentsRequestButton = props => <Button frozen {...props} />;

export default connect(
  null,
  null,
  (s, { dispatch }, { actionProps, userName, fundName, ...props }) => {
    const { isDocumentsRequested, id } = actionProps;
    if (isDocumentsRequested) {
      props.icon = 'dots';
      props.text = 'Pending approval';
      props.disabled = true;
    } else {
      props.text = 'Request Access';
      props.onClick = () => {
        dispatch({
          type: 'GTM_EVENT',
          payload: {
            event: 'DOCUMENT_REQUEST_ACCESS',
            eventCategory: 'DOCUMENT_REQUEST_ACCESS',
            eventAction: userName,
            eventLabel: fundName,
          },
        });
        dispatch(readRequestFundDocuments({ id }));
      };
    }
    return props;
  },
)(DocumentsRequestButton);

import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'TOGGLE_FILTERS_PANEL',
  'APPLY_FILTERS_PANEL_FILTERS',
  'REMOVE_FILTERS_PANEL_FILTER',
  'CLEAR_FILTERS_PANEL',
);

export const openFiltersPanel = () => ({
  type: types.TOGGLE_FILTERS_PANEL,
  payload: true,
});

export const closeFiltersPanel = () => ({
  type: types.TOGGLE_FILTERS_PANEL,
  payload: false,
});

export const applyFiltersPanelFilters = filters => ({
  type: types.APPLY_FILTERS_PANEL_FILTERS,
  payload: filters,
});

export const removeFiltersPanelFilter = filterKey => ({
  type: types.REMOVE_FILTERS_PANEL_FILTER,
  payload: filterKey,
});

export const clearFiltersPanel = () => ({
  type: types.CLEAR_FILTERS_PANEL,
});

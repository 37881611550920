// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// dm
import Button from '../../../atoms/Button';

// own
import './ScrollButton.scss';

export default class ScrollButtonComponent extends Component {
  static propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
  };

  static defaultProps = {
    icon: 'arrow-to-top',
    text: 'Go to top',
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ scrollTop: window.pageYOffset });
  };

  handleClick = () => {
    let timeOut;
    const _self = this;
    const scrollToTop = () => {
      if (this.state.scrollTop) {
        window.scrollBy(0, -50);
        timeOut = setTimeout(scrollToTop.bind(_self), 10);
      } else {
        clearTimeout(timeOut);
      }
    };
    scrollToTop();
  };

  render() {
    const { icon, text } = this.props;
    return (
      <div className="scroll-button">
        <Button
          {...{ icon, text }}
          size="sm"
          onClick={this.handleClick}
          border="none"
          flat
        />
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/* local (Counter) styles */
import './counter.styles.scss';

const Counter = ({ counter, hide, className, ...props }) => {
  const typeCounter = typeof counter == 'number' ? 'numeric' : 'event';
  const classNames = cx('counter', typeCounter, hide && 'hide', className);
  return (
    <div className={classNames} {...props}>
      {counter}
    </div>
  );
};

Counter.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
};

export default Counter;

// vendor
import moment from 'moment';
import { Iterable, fromJS } from 'immutable';
import { memoize, omitBy, isNil } from 'lodash';

export const isBrowser = () => !global.process;

export const createConstants = (...constants) =>
  constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});

export const createReducer = (initialState, reducerMap) => (
  state = initialState,
  action,
) => {
  const reducer = reducerMap[action.type];
  return reducer ? reducer(state, action.payload) : state;
};

// safe convert to mutable data
export const toJS = value => {
  // TODO: optimize, check deeply isIterable, dont use fromJS
  if (!Iterable.isIterable(value)) {
    value = fromJS(value);
  }
  return Iterable.isIterable(value) ? value.toJS() : value;
};

export const getCurrentYear = memoize(() => moment().format('YYYY'));

// remove from object empty values (null, undefined)
export const omitEmptyPrimitives = obj =>
  omitBy(obj, value => isNil(value) || value === '');

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
// own
import DatePickerInput from './DatePickerInput';

const MINUTE = 60;

const DatePickerComponent = props => {
  const { input, meta, size, icon, iconPos, dateFormat, ...ownProps } = props;
  const inputProps = { input, meta, size, icon, iconPos };
  return (
    <DatePicker
      customInput={<DatePickerInput {...inputProps} />}
      selected={input.value ? moment.unix(input.value) : null}
      onChange={value => {
        value = value.unix() + value.utcOffset() * MINUTE;
        return input.onChange(value);
      }}
      utcOffset={0}
      dateFormat={dateFormat}
      placeholderText={dateFormat}
      {...ownProps}
    />
  );
};

DatePickerComponent.propTypes = {
  dateFormat: PropTypes.string,
};

DatePickerComponent.defaultProps = {
  dateFormat: 'MM/DD/YYYY',
};

export default DatePickerComponent;

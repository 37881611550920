// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form/immutable';
import { FormattedPlural } from 'react-intl';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

// dm
import DeletePipelineButton from '../../../molecules/ActionButtons/DeletePipelineButton';
import Button from '../../../atoms/Button';
import Panel from '../Panel';
import { TABLET } from '../../../../constants/breakpoints';
import { toJS } from '../../../../utils/core';

// own
import './DeletePanel.scss';

class DeletePanelComponent extends Component {
  static propTypes = {
    form: PropTypes.string,
    field: PropTypes.string,
    reverse: PropTypes.bool,
    description: PropTypes.func,
    handleDelete: PropTypes.func,
  };

  render() {
    const {
      className,
      reverse,
      one,
      other,
      description,
      collection,
      indexes,
      handleDelete,
      handleCancel,
    } = this.props;
    const fullClassName = classNames(className, 'delete-panel', {
      'delete-panel--reverse': reverse,
    });
    const length = collection.length;
    const opened = !!length;
    return (
      <Panel className={fullClassName} opened={opened}>
        <DeletePipelineButton
          actionProps={{
            handleDelete,
            collection,
            investors: description(collection),
          }}
        />
        <Button
          size="sm"
          color="blue-g"
          border="none"
          flat
          frozen
          text="Cancel"
          onClick={handleCancel(collection, indexes)}
        />
        <div className="delete-panel__text">
          {`(${length}) `}
          <FormattedPlural value={length} {...{ one, other }} />
          <MediaQuery minWidth={TABLET.left}>
            {description && (
              <span className="delete-panel__description">
                &nbsp;
                {description(collection)}
              </span>
            )}
          </MediaQuery>
        </div>
      </Panel>
    );
  }
}

export default connect(
  (state, { form, field }) => {
    const indexes = [];
    const collection = toJS(
      formValueSelector(form)(state, field).filter(
        (item, index) => item.get('checked') && indexes.push(index),
      ),
    );
    return { collection, indexes };
  },
  (dispatch, { form, field }) => ({
    handleCancel: (collection, indexes) => () =>
      indexes.map(fieldIndex =>
        dispatch(change(form, `${field}[${fieldIndex}].checked`, false)),
      ),
  }),
)(DeletePanelComponent);

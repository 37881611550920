// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isString } from 'lodash';

// dm
import Tooltip from '../Tooltip';
// own
import './FormGroup.scss';

export default class FormGroupComponent extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(
      'related, col, col-center, col-right, row-top, row-start'.w(),
    ),
    required: PropTypes.bool,
    translate: PropTypes.bool,
    dots: PropTypes.bool,
    hidden: PropTypes.bool,
    hint: PropTypes.string,
  };

  static defaultProps = {
    translate: true,
    hidden: false,
    hint: '',
  };

  renderLabel(label, required, translate, dots, hint) {
    if (isString(label) && /:$/.test(label)) {
      label = label.slice(0, -1);
      dots = true;
    }
    return (
      <label>
        {translate ? <FormattedMessage id={label} /> : label}
        {required && <span className="form-group__asterisk" />}
        {dots && ':'}
        {hint && (
          <Tooltip responsive>
            <FormattedMessage id={hint} />
          </Tooltip>
        )}
      </label>
    );
  }

  render() {
    const {
      children,
      className,
      type,
      label,
      required,
      translate,
      dots,
      hidden,
      hint,
    } = this.props;
    const fullClassName = classNames(className, 'form-group', {
      [`form-group--${type}`]: type,
      'form-group--hidden': hidden,
    });

    return (
      <div className={fullClassName}>
        {label && this.renderLabel(label, required, translate, dots, hint)}
        {children}
      </div>
    );
  }
}

// vendor
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { stringify } from 'query-string';

// dm
import Button from '../../atoms/Button';
import { getPath } from '../../../utils/route';

export default connect(
  state => ({
    location: state
      .get('router')
      .get('location')
      .toJS(),
  }),
  null,
  ({ location }, { dispatch }, { to, params = {}, withEntry, ...props }) => ({
    ...props,
    onClick: () => {
      if (withEntry) {
        params.entry = stringify(location);
      }
      dispatch(push(getPath(to, params)));
    },
  }),
)(props => <Button {...props} />);

// TODO: remove this file

import { toNumber, isNaN } from 'lodash';

export const toFixed = (value, digits) => {
  const number = toNumber(value);
  return isNaN(number) ? value || 0 : number.toFixed(digits);
};

export const toValueWithComma = value => {
  const number = toNumber(value);
  return isNaN(number) ? value || 0 : `$ ${number.toLocaleString('en-US')}`;
};

export const toValueWithIndent = value => {
  let transValue = +value;
  if (transValue && typeof transValue === 'number' && !isNaN(transValue)) {
    if (transValue < 1000000) {
      transValue = `${toValueWithComma(transValue)}`;
    } else if (transValue >= 1000000 && transValue < 1000000000) {
      transValue = `$${parseInt(transValue / 1000000, 10)} MM`;
    } else if (transValue >= 1000000000) {
      transValue = `$${parseInt(transValue / 1000000000, 10)} Bn`;
    }
  }
  return transValue;
};

export const sliceTextWithDefaultLength = (value, count) => {
  if (value) {
    let slicedText = value.slice(0, count);
    if (slicedText.length === count) {
      if (value[count] && value[count - 1]) {
        slicedText = slicedText.split(' ');
        slicedText.pop();
        slicedText = slicedText.join(' ');
      }
      return `${slicedText} ...`;
    }
    return slicedText;
  }
  return value;
};

export const replaceValueToNotApplicable = value => {
  if (typeof value !== 'number') return 'N/A';
  return `${toFixed(value, 2)} %`;
};

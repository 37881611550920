import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import cx from 'classnames';

import './DMLogo.scss';

class DMLogo extends Component {
  static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    withAside: PropTypes.bool,
    classNames: PropTypes.string,
    colorOverride: PropTypes.string,
    position: PropTypes.oneOf(['absolute', 'fixed']),
    breakpoint: PropTypes.oneOf(['mobile', 'tablet', 'desktop']),
  };

  static defaultProps = {
    breakpoint: 'desktop',
    position: 'fixed',
    withAside: false,
  };

  render() {
    const {
      width,
      height,
      position,
      withAside,
      breakpoint,
      classNames,
      colorOverride,
    } = this.props;

    // svg fill color
    let fillColor = withAside
      ? {
          mobile: '#1b3c5d',
          tablet: '#1b3c5d',
          desktop: '#ffffff',
        }[breakpoint]
      : {
          absolute: '#ffffff',
          fixed: '#1b3c5d',
        }[position];

    // color override option
    if (colorOverride) {
      fillColor = colorOverride;
    }

    // styles attr
    const svgStyle =
      width && height
        ? { width, height }
        : {
            mobile: { width: '104px', height: '32px' },
            tablet: { width: '140px', height: '43px' },
            desktop: { width: '140px', height: '43px' },
          }[breakpoint];

    // class attr
    const className = cx(
      'dm-logo',
      {
        'dm-logo--with-aside': withAside,
      },
      classNames,
    );

    return (
      <div className={className} onClick={this.props.goHome}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 337.76 102.59"
          style={svgStyle}
        >
          <g>
            <path
              fill={fillColor}
              d="M147.41,43.83V22.27h6q5.36,0,8.17,2.81t2.81,8q0,5.36-2.94,8.07a11.11,11.11,0,0,1-7.85,2.71Zm1.95-1.8h4.19q4.22,0,6.46-2.2T162.27,33q0-4.56-2.49-6.77t-7.29-2.21h-3.12Z"
            />
            <path
              fill={fillColor}
              d="M187.91,43.83l8.67-21.56h2.29l8.67,21.56h-2.12L203.1,38H192.22l-2.3,5.84Zm5-7.65h9.48L197.7,24Z"
            />
            <path
              fill={fillColor}
              d="M232,43.83V22.27h7.13a8.56,8.56,0,0,1,5.44,1.45,5.1,5.1,0,0,1,1.8,4.25,4.13,4.13,0,0,1-1.19,3,6.08,6.08,0,0,1-3.11,1.67A4.22,4.22,0,0,1,244.76,34a7,7,0,0,1,1.13,3.73l.26,2.84a6.81,6.81,0,0,0,.88,3.28h-2.29a9.23,9.23,0,0,1-.62-2.83l-.29-3.19q-.26-2.72-1.14-3.46a4.56,4.56,0,0,0-2.94-.75H234V43.83Zm1.95-12h4.51a8.14,8.14,0,0,0,4.45-.92,3.3,3.3,0,0,0,1.36-2.94,3.46,3.46,0,0,0-1.22-2.9,5.67,5.67,0,0,0-3.59-1H234Z"
            />
            <path
              fill={fillColor}
              d="M286.84,38.66h2.1a6,6,0,0,1-2.23,3.85,7.62,7.62,0,0,1-5.21,1.77,8.51,8.51,0,0,1-6.94-3q-2.48-3-2.48-8.22a12.54,12.54,0,0,1,2.49-8.2,8.5,8.5,0,0,1,6.93-3,7.91,7.91,0,0,1,5.16,1.57,5.3,5.3,0,0,1,2.05,4.06h-2.1a3.93,3.93,0,0,0-1.42-2.7,5.41,5.41,0,0,0-3.68-1.13A6.3,6.3,0,0,0,276,26.22,11.46,11.46,0,0,0,274.17,33,11.54,11.54,0,0,0,276,39.87a6.24,6.24,0,0,0,5.42,2.61,5.74,5.74,0,0,0,3.77-1.16A4.26,4.26,0,0,0,286.84,38.66Z"
            />
            <path
              fill={fillColor}
              d="M147.63,60.47h3.11l8.16,18.69,8.35-18.69h3V82h-1.95V62L159.89,82h-1.95l-8.36-20V82h-1.95Z"
            />
            <path
              fill={fillColor}
              d="M188.26,82l8.67-21.56h2.29L207.89,82h-2.12l-2.31-5.84H192.57L190.27,82Zm5-7.65h9.48L198,62.24Z"
            />
            <path
              fill={fillColor}
              d="M223.46,60.47h16v1.8h-7V82h-1.95V62.27h-7Z"
            />
            <path
              fill={fillColor}
              d="M255.14,60.47h16v1.8h-7V82h-1.95V62.27h-7Z"
            />
            <path
              fill={fillColor}
              d="M289.4,82V60.47h13v1.8h-11V70h10.59v1.8H291.34v8.42h11.47V82Z"
            />
            <path
              fill={fillColor}
              d="M322.74,82V60.47h7.13a8.55,8.55,0,0,1,5.43,1.45,5.11,5.11,0,0,1,1.8,4.25,4.13,4.13,0,0,1-1.19,3,6.08,6.08,0,0,1-3.11,1.67,4.22,4.22,0,0,1,2.67,1.36,7,7,0,0,1,1.13,3.73l.26,2.84a6.81,6.81,0,0,0,.88,3.28h-2.29a9.23,9.23,0,0,1-.62-2.83L334.57,76q-.26-2.72-1.14-3.46a4.56,4.56,0,0,0-2.95-.75h-5.79V82Zm1.95-12h4.51a8.14,8.14,0,0,0,4.45-.92A3.3,3.3,0,0,0,335,66.14a3.46,3.46,0,0,0-1.21-2.9,5.68,5.68,0,0,0-3.59-1h-5.52Z"
            />
            <rect
              fill={fillColor}
              x="112.41"
              y="0.14"
              width="13.49"
              height="102.45"
            />
            <rect fill={fillColor} y="0.14" width="13.49" height="102.45" />
            <polygon
              fill={fillColor}
              points="13.49 0.14 0 0.14 55.19 102.59 68.68 102.59 13.49 0.14"
            />
            <polygon
              fill={fillColor}
              points="112.41 0.14 125.9 0.14 70.71 102.59 57.22 102.59 112.41 0.14"
            />
            <path
              fill={fillColor}
              d="M67,8.19C56.25,2.52,44.76,0,23.17,0,15,0,7.09.06,0,.13V102.59c2.56,0,5,0,7.25,0l47.94,0c21.35,0,3.89-3.35,14.33-10.13C84,82.86,91.64,66.31,91.64,48.2,91.64,30.87,81.73,15.91,67,8.19ZM62.8,85.37C55,91.68,43.16,95.46,27.42,95.46c-12,0-13.62-1.42-13.62-5.67l.35-82.07c1.77-.47,5.84-.63,10.79-.63,16.28,0,28,3.62,35.91,9.77C72,25.67,76.78,35.29,76.78,52.93,76.78,66,71.12,78.6,62.8,85.37Z"
            />
            <path
              fill={fillColor}
              d="M329.18,17.46a8.58,8.58,0,1,1,8.58-8.58A8.59,8.59,0,0,1,329.18,17.46Zm0-15.43A6.84,6.84,0,1,0,336,8.88,6.85,6.85,0,0,0,329.18,2Z"
            />
            <path
              fill={fillColor}
              d="M328.7,8.18a1.57,1.57,0,0,0,1-.28,1.1,1.1,0,0,0,.32-.89,1.11,1.11,0,0,0-.32-.88,1.63,1.63,0,0,0-1-.26h-1V8.18h1m-1,1.59v3.4h-2.32v-9h3.54a4.44,4.44,0,0,1,2.6.6,2.14,2.14,0,0,1,.83,1.88,2.37,2.37,0,0,1-.43,1.46,2.47,2.47,0,0,1-1.3.84,1.79,1.79,0,0,1,.85.5,4.29,4.29,0,0,1,.76,1.16l1.26,2.54h-2.47L330,10.95a2.4,2.4,0,0,0-.67-.92,1.51,1.51,0,0,0-.91-.25h-.65"
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, { isAuthorized, isAM, isInvestor }) => ({
  goHome: () => {
    if (!isAuthorized) {
      return dispatch(push('/'));
    }
    if (isAM) {
      return dispatch(push('/am-dashboard'));
    }
    if (isInvestor) {
      return dispatch(push('/inv-dashboard'));
    }
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(DMLogo);

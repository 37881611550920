// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './Icon.scss';

export default class IconComponent extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    rotate: PropTypes.number,
  };

  render() {
    const { props } = this;
    const classes = [];
    props.name.split('--').reduce((prev, cur) => {
      const c = `${prev}--${cur}`;
      classes.push(c);
      return c;
    }, 'icon');
    const className = classNames(props.className, 'icon', {
      [classes.join(' ')]: props.name,
    });
    return (
      <div
        {...props}
        className={className}
        style={{ transform: `rotateZ(${props.rotate}deg)`, ...props.style }}
      />
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/* local (Button) styles */
import './button.styles.scss';

const ButtonDefault = ({
  className,
  size,
  flat,
  accept,
  secondary,
  children,
  ...props
}) => {
  const classNames = cx(
    'button',
    size,
    flat && 'flat',
    accept && 'accept',
    secondary && 'secondary',
    className,
  );
  return (
    <button className={classNames} {...props}>
      {typeof children === 'string' ? (
        <FormattedMessage id={children} />
      ) : (
        <span>{children}</span>
      )}
    </button>
  );
};

const ButtonLink = ({
  href,
  className,
  size,
  flat,
  accept,
  secondary,
  children,
  ...props
}) => {
  const classNames = cx(
    'button_link',
    size,
    flat && 'flat',
    accept && 'accept',
    secondary && 'secondary',
    className,
  );
  return (
    <Link className={classNames} to={href} {...props}>
      {typeof children === 'string' ? (
        <FormattedMessage id={children} />
      ) : (
        children
      )}
    </Link>
  );
};

const Button = props => {
  return props.href ? <ButtonLink {...props} /> : <ButtonDefault {...props} />;
};

Button.propTypes = {
  href: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  flat: PropTypes.bool,
  accept: PropTypes.bool,
  secondary: PropTypes.bool,
};

Button.defaultProps = {
  size: 'm',
  flat: false,
  accept: false,
  secondary: false,
};

export default Button;

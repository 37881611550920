import {
  // forOwn, snakeCase, cloneDeepWith, isArray, isObject, join,
  isEmpty,
  isNil,
  omit,
  filter,
  values,
  dropRightWhile,
  concat,
  times,
  stubObject,
  take,
} from 'lodash';

// export const toFormData = data => {
//   const formData = new FormData();
//   forOwn(data, (value, key) => {
//     formData.append(snakeCase(key), value);
//   });
//   return formData;
// };

// export function toFormErrors(response = {}) {
//   return cloneDeepWith(response.errors, error => {
//     let errorLine;
//     if (isArray(error) && !isObject(error[0])) {
//       errorLine = join(error, '; ');
//     }
//     return errorLine;
//   });
// }

export const isEmptyErrors = response =>
  !!response.data && isEmpty(response.data.errors);

function isEmptyValues(object) {
  return isEmpty(filter(values(object), item => !(isNil(item) || item === '')));
}

export function transformArray(array = [], min = 0, max) {
  array = dropRightWhile(array, item => isEmptyValues(item));
  array = array.map(item => item || {});
  if (array.length < min) {
    array = concat(array, times(min - array.length, stubObject));
  }
  return max ? take(array, max) : array;
}

export const clearMeta = meta =>
  omit(meta, ['__altered', '__hash', '__ownerID', '_root', 'size', 'warning']);

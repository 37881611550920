// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import IconLg from '../../../atoms/Icons/IconLg';

// own
import './ActivityFeedsContentEmpty.scss';

export default () => (
  <div className="activity-feeds__empty-box">
    <IconLg name="bell" resizable />
    <FormattedMessage id="There is no recent activity to show" />
  </div>
);

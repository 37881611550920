// vendor
import { Set } from 'immutable';
import {
  isNumber,
  isEmpty,
  isString,
  includes,
  toLower,
  isArray,
  keys,
  filter,
  findIndex,
} from 'lodash';

// dm
import { toJS } from './core';
import { COUNTRY } from '../constants/lists';

export const isEmptyAndNotNumber = value =>
  !isNumber(value) && isEmpty(toJS(value));

function checkFilter(value, filterValue) {
  // Check for empty filter
  if (isEmptyAndNotNumber(filterValue)) {
    return true;
  }
  // Only immediately value and filter can be string
  if (isString(value)) {
    return includes(toLower(value), toLower(filterValue));
  }
  if (!isArray(value)) {
    value = [value];
  }
  if (!isArray(filterValue)) {
    filterValue = [filterValue];
  }
  return !!Set(value).intersect(filterValue).size;
}

function filterObject(object = {}, filters = {}) {
  let isFit = true;
  let i = 0;
  const filterKeys = keys(filters);
  while (isFit && i < filterKeys.length) {
    const key = filterKeys[i++];
    if (!checkFilter(object[key], filters[key])) {
      isFit = false;
    }
  }
  return isFit;
}

export const filterCollection = (collection = [], filters = {}) =>
  filter(toJS(collection), object => filterObject(object, filters));

export const USAFirstInCoutryList = (countries = []) => {
  const indexUSA = findIndex(
    countries,
    country => country.id === COUNTRY.UNITED_STATES,
  );
  if (indexUSA >= 0) {
    const USA = countries.splice(indexUSA, 1)[0];
    countries.splice(0, 0, USA);
  }
  return countries;
};

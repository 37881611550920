// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './Form.scss';

export default class FormComponent extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['horizontal']),
  };

  static defaultProps = {};

  render() {
    const { props } = this;
    const className = classNames(props.className, 'form', {
      [`form--${props.type}`]: props.type,
    });
    return <form {...props} className={className} />;
  }
}

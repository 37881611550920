import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/* local (Input) styles */
import './icon.styles.scss';

import { ICONS_LIST } from './icon.constants';

const iconPath = name => {
  const foundIcon = ICONS_LIST.find(icon => icon.name === name);
  return foundIcon ? foundIcon.paths.join(' ') : '';
};

const Icon = ({ name, size, color, className, style, pathStyle, pathSize }) => (
  <svg
    width={size}
    height={size}
    style={style}
    version={'1.1'}
    viewBox={'0 0 1024 1024'}
    xmlns={'http://www.w3.org/2000/svg'}
    className={cx('icon', color, className)}
  >
    <path
      width={pathSize}
      height={pathSize}
      style={pathStyle}
      d={iconPath(name)}
    />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.oneOf([
    'dark-gray',
    'blue',
    'blue-active',
    'green',
    'green-active',
    'red',
    'red-active',
    'white',
    'paragraph',
    'caption',
    'grey',
    'line',
    'hedge',
    'private',
    'venture',
  ]),
};

Icon.defaultProps = {
  size: '20',
};

export default Icon;

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { toJS } from '../utils/core';
import { ROLES } from '../constants/user';

const UnauthenticatedRoute = ({ component: Component, device, ...rest }) => {
  let redirectTo = '/';
  if (rest.access === ROLES.INVESTOR) {
    redirectTo = 'inv-dashboard';
  } else if (rest.access === ROLES.ASSET_MANAGER) {
    redirectTo = 'am-dashboard';
  }
  return (
    <Route
      {...rest}
      render={props =>
        !rest.isAuthorized ? (
          <Component {...props} device={device} />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  isAuthorized: toJS(state.get('user').get('isAuthorized')),
  access: toJS(state.get('user').get('access')),
});

export default connect(
  mapStateToProps,
  null,
)(UnauthenticatedRoute);

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

// dm
import Button from '../../../atoms/Button';
import LogoImage from '../../../atoms/Images/LogoImage';
import LinkButton from '../../ActionButtons/LinkButton';
import Tooltip from '../../Tooltip';
import { createFundFromPublished } from '../../../../store/actions/fund';
import {
  FUND_STATUS,
  FUND_TYPE_OF_OFFERING,
} from '../../../../constants/lists';
import { DESKTOP, TABLET, MOBILE } from '../../../../constants/breakpoints';
import { getPath } from '../../../../utils/route';

// own
import './FundCard.scss';

class FundCardComponent extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    status: PropTypes.number,
    isPublished: PropTypes.bool,
    typeOfOfferingId: PropTypes.number,
    unreadFeedsAmount: PropTypes.number,
  };

  static defaultProps = {
    isPublished: false,
    unreadFeedsAmount: 0,
  };

  renderFundCard({ buttonSize }) {
    const {
      id,
      parentId,
      status,
      isPublished,
      typeOfOfferingId,
      unreadFeedsAmount,
      logoUrl,
      name,
      handleUpdateFund,
    } = this.props;

    const stateText = isPublished ? 'Published' : 'Draft';
    const typeOfOffering = {
      [FUND_TYPE_OF_OFFERING.TYPE_506B]: 'Type: 506b',
      [FUND_TYPE_OF_OFFERING.TYPE_506C]: 'Type: 506c',
    }[typeOfOfferingId];
    const statusText = {
      [FUND_STATUS.UPDATE_PENDING_APPROVAL]: {
        text: 'Update Pending Approval',
        tooltip: (
          <FormattedMessage id="am-dashboard.cards.status_text.update_pending_approval.tooltip" />
        ),
      },
      [FUND_STATUS.PUBLISHED]: {
        text: 'Published',
      },
      [FUND_STATUS.DRAFT_UPDATE]: {
        text: 'Draft Update',
        tooltip: (
          <FormattedMessage id="am-dashboard.cards.status_text.draft_update.tooltip" />
        ),
      },
      [FUND_STATUS.UPDATE_REQUIRES_REVISION]: {
        text: 'Update Requires Revision',
        tooltip: (
          <FormattedMessage id="am-dashboard.cards.status_text.update_require_revision.tooltip" />
        ),
      },
      [FUND_STATUS.DRAFT]: {
        text: 'Draft',
      },
      [FUND_STATUS.DRAFT_PENDING_APPROVAL]: {
        text: 'Draft Pending Approval',
        tooltip: (
          <FormattedMessage id="am-dashboard.cards.status_text.draft_pending_approval.tooltip" />
        ),
      },
      [FUND_STATUS.PAYMENT_PENDING]: {
        text: 'Payment Pending',
        tooltip: (
          <FormattedMessage id="am-dashboard.cards.status_text.payment_pending.tooltip" />
        ),
      },
      [FUND_STATUS.DRAFT_REQUIRES_REVISION]: {
        text: 'Draft Requires Revision',
        tooltip: (
          <FormattedMessage id="am-dashboard.cards.status_text.draft_require_revision.tooltip" />
        ),
      },
      [FUND_STATUS.UNPABLISHED]: {
        text: 'Unpublished',
      },
    }[status];
    return (
      <div className="fund-card__content">
        <div className="fund-card__avatar">
          <LogoImage src={logoUrl} />
          {isPublished && status === FUND_STATUS.PUBLISHED && (
            <div className="fund-card__state">
              <div />
              <FormattedMessage id={stateText} />
            </div>
          )}
          {!isPublished && status !== FUND_STATUS.UNPABLISHED && (
            <div className="fund-card__controls__state">
              <div />
              <FormattedMessage id={stateText} />
            </div>
          )}
        </div>
        <div className="fund-card__info">
          <div className="fund-card__info__name">
            <div>{name}</div>
            {!!unreadFeedsAmount && <span>{unreadFeedsAmount}</span>}
          </div>
          <div className="fund-card__info__type">
            {typeOfOffering && <FormattedMessage id={typeOfOffering} />}
          </div>
          {status !== FUND_STATUS.DRAFT && status !== FUND_STATUS.PUBLISHED && (
            <div
              className={`fund-card__info__status fund-card__info__status--${status}`}
            >
              <div className="fund-card__info__status__icon" />
              {statusText && (
                <div className="fund-card__info__status__label">
                  <FormattedMessage id={statusText.text} />
                  {statusText.tooltip && (
                    <MediaQuery minWidth={TABLET.left}>
                      <Tooltip className="fund-card__info__status__tooltip">
                        {statusText.tooltip}
                      </Tooltip>
                    </MediaQuery>
                  )}
                  {statusText.tooltip && (
                    <MediaQuery maxWidth={MOBILE.right}>
                      <Tooltip
                        responsive
                        className="fund-card__info__status__tooltip"
                      >
                        {statusText.tooltip}
                      </Tooltip>
                    </MediaQuery>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="fund-card__controls">
            {isPublished && (
              <LinkButton
                size={buttonSize}
                icon="manage"
                text="Manage"
                to="funds.manage"
                params={{ id: parentId || id }}
              />
            )}
            {isPublished && status === FUND_STATUS.PUBLISHED && (
              <Button
                size={buttonSize}
                icon="edit-v2"
                text="Update"
                onClick={handleUpdateFund}
              />
            )}
            {isPublished &&
              (status === FUND_STATUS.DRAFT_UPDATE ||
                status === FUND_STATUS.UPDATE_REQUIRES_REVISION) && (
                <LinkButton
                  size={buttonSize}
                  icon="edit-v2"
                  text="Update"
                  to="funds.edit"
                  params={{ id }}
                />
              )}
            {!isPublished &&
              (status !== FUND_STATUS.UNPABLISHED &&
                status !== FUND_STATUS.DRAFT_PENDING_APPROVAL) && (
                <LinkButton
                  size={buttonSize}
                  flat
                  icon="edit-v2"
                  text="Edit"
                  to="funds.edit"
                  params={{ id }}
                />
              )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { className, isPublished } = this.props;
    const fullClassName = classNames(className, 'fund-card', {
      'fund-card--live': isPublished,
    });
    return (
      <div className={fullClassName}>
        <MediaQuery maxWidth={TABLET.right}>
          {this.renderFundCard({ buttonSize: 'xs' })}
        </MediaQuery>
        <MediaQuery minWidth={DESKTOP.left}>
          {this.renderFundCard({ buttonSize: 'sm' })}
        </MediaQuery>
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch, { id }) => ({
    handleUpdateFund: () =>
      dispatch(createFundFromPublished(id)).then(({ data }) =>
        dispatch(push(getPath('funds.edit', { id: data.id }))),
      ),
  }),
)(FundCardComponent);

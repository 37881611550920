import { KYC_STATUS, SUITABILITY_STATUS } from '../constants/lists';

export const getIsKycCompleted = kycStatus =>
  !!kycStatus &&
  [KYC_STATUS.APPROVED_AUTO, KYC_STATUS.APPROVED_MANUAL].includes(+kycStatus);

export const getKycModal = (kycStatus = KYC_STATUS.ERROR, suitabilityStatus) =>
  ({
    [KYC_STATUS.PENDING_MANUAL]: 'kyc-in-progress',
    [KYC_STATUS.NOT_ElIGIBLE_FOR_QUESTIONS]: 'kyc-declined',
    [KYC_STATUS.ERROR]: 'kyc-declined',
    [KYC_STATUS.DECLINED]: 'kyc-declined',
    [KYC_STATUS.APPROVED_AUTO]:
      suitabilityStatus === SUITABILITY_STATUS.APPROVED
        ? 'kyc-successful'
        : 'kyc-in-progress',
    [KYC_STATUS.APPROVED_MANUAL]:
      suitabilityStatus === SUITABILITY_STATUS.APPROVED
        ? 'kyc-successful'
        : 'kyc-in-progress',
  }[kycStatus]);

export const getRestrictedKycModal = kycStatus =>
  ({
    [KYC_STATUS.PENDING_MANUAL]: 'kyc-in-progress',
    [KYC_STATUS.APPROVED_AUTO]: 'kyc-in-progress',
    [KYC_STATUS.APPROVED_MANUAL]: 'kyc-in-progress',
  }[kycStatus] || 'kyc-uncompleted');

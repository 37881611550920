// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isObject } from 'lodash';
// dm
import { toJS } from '../../../utils/core';
// import config from 'config';
// own
import './Image.scss';

class Image extends Component {
  static propTypes = {
    src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    defaultSrc: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sms', 'sm', 'md', 'lg']),
    style: PropTypes.object,
  };

  static defaultProps = {
    alt: 'Image',
  };

  constructor(props) {
    super(props);
    this.state = {
      dimension: null,
      src: props.src,
    };
  }

  componentDidMount() {
    if (this.refs.image) {
      this.onLoad({ target: this.refs.image });
    }
  }

  componentWillReceiveProps({ src: nextSrc }) {
    const { src } = this.props;
    if (nextSrc && src !== nextSrc) {
      this.setState({ src: nextSrc });
    }
  }

  onLoad = event => {
    const img = event.target;
    const dimension = img.clientWidth > img.clientHeight ? 'height' : 'width';
    this.setState({ dimension });
  };

  onError = () => {
    const { defaultSrc } = this.props;
    if (defaultSrc) {
      this.setState({
        src: defaultSrc,
      });
    }
  };

  getSrc = () => {
    const { src } = this.state;
    return isObject(toJS(src)) ? src.url : src;
  };

  render() {
    const {
      className,
      defaultSrc,
      full,
      size,
      children,
      alt,
      ...props
    } = this.props;
    const { dimension } = this.state;
    const fullClassName = classNames(className, 'image', {
      [`image--dimension-${dimension}`]: dimension,
      [`image--${size}`]: size,
      'image--full': full,
    });
    const validSrc = this.getSrc() || defaultSrc;
    return (
      <div className={fullClassName}>
        {validSrc || props.srcSet ? (
          <img
            ref="image"
            {...props}
            src={validSrc}
            onLoad={this.onLoad}
            onError={this.onError}
            alt={alt}
          />
        ) : (
          children
        )}
      </div>
    );
  }
}

export default Image;

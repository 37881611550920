// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import { toValueWithComma } from '../../../../../../utils/crutchFormat';

// own
import './FundShowPreviousFunds.scss';

export default ({ previousFunds }) => (
  <div className="fund-show-previous-funds">
    <div className="fund-show-previous-funds__title">
      <FormattedMessage id="Previous Funds" />
    </div>
    <div className="fund-show-previous-funds__content">
      {previousFunds
        .filter(item => item.name)
        .map((item, index) => (
          <div className="fund-show-previous-funds__item" key={index}>
            <div className="fund-show-previous-funds__item__row">
              <div className="fund-show-previous-funds__item__row__label">
                <FormattedMessage id="Vintage Year" />
              </div>
              <div className="fund-show-previous-funds__item__row__value">
                {item.vintageYear}
              </div>
            </div>
            <div className="fund-show-previous-funds__item__row">
              <div className="fund-show-previous-funds__item__row__label">
                <FormattedMessage id="Fund Name" />
              </div>
              <div className="fund-show-previous-funds__item__row__value">
                {item.name}
              </div>
            </div>
            <div className="fund-show-previous-funds__item__row">
              <div className="fund-show-previous-funds__item__row__label">
                <FormattedMessage id="Fund Size" />
              </div>
              <div className="fund-show-previous-funds__item__row__value">
                {toValueWithComma(item.fundSize)}
              </div>
            </div>
            <div className="fund-show-previous-funds__item__row">
              <div className="fund-show-previous-funds__item__row__label">
                <FormattedMessage id="Since-Inception IRR" />
              </div>
              <div className="fund-show-previous-funds__item__row__value">
                {item.sinceInceptionIrr && `${item.sinceInceptionIrr}%`}
              </div>
            </div>
            <div className="fund-show-previous-funds__item__row">
              <div className="fund-show-previous-funds__item__row__label">
                <FormattedMessage id="Multiple" />
              </div>
              <div className="fund-show-previous-funds__item__row__value">
                {item.multiple}
                {item.multiple &&
                  item.multiple.length &&
                  item.multiple[item.multiple.length - 1] !== 'x' &&
                  'x'}
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

// vendor
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Button from '../../atoms/Button';
import { readCurrentInvestments } from '../../../store/actions/investments';
import { getPath } from '../../../utils/route';

const InvestButton = ({ handleClick }) => (
  <Button size="sm" color="green" text="Invest" onClick={handleClick} />
);

const mapDispatchToProps = (dispatch, { actionProps, fundName, userName }) => ({
  handleClick: () => {
    dispatch({
      type: 'GTM_EVENT',
      payload: {
        event: 'CLICK_BTN_INVEST',
        eventCategory: 'CLICK_BTN_INVEST',
        eventLabel: fundName,
        eventAction: userName,
      },
    });
    dispatch(readCurrentInvestments()).then(({ data }) => {
      const currentInvestment = data.find(
        investment => investment.fundId === actionProps.fundId,
      );
      dispatch(push(getPath('investments', { id: currentInvestment.id })));
    });
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(InvestButton);

import React, { Component, Children, cloneElement } from 'react';

import ModalTrigger from './ModalTrigger';
import ModalContent from './ModalContent';
import s from './modal.module.scss';

class Modal extends Component {
  state = { isOpen: false };

  onOpen = () => {
    this.setState({ isOpen: true });
    this.toggleScrollLock();
  };

  onClose = () => {
    this.setState({ isOpen: false });
    this.toggleScrollLock();
  };

  onKeyDown = ({ keyCode }) => keyCode === 27 && this.onClose();

  onClickAway = e => {
    if (this.modalNode && this.modalNode.contains(e.target)) return;
    this.onClose();
  };

  toggleScrollLock = () =>
    document.querySelector('html').classList.toggle(s.lock_scroll);

  render() {
    const { triggerProps, role, ariaLabel } = this.props;
    const { isOpen } = this.state;
    const children = Children.map(this.props.children, child => {
      return cloneElement(child, { onClose: this.onClose });
    });

    return (
      <>
        <ModalTrigger {...triggerProps} onOpen={this.onOpen} />
        {isOpen && (
          <ModalContent
            onClose={this.onClose}
            onKeyDown={this.onKeyDown}
            role={role}
            ariaLabel={ariaLabel}
            content={children}
            modalRef={n => (this.modalNode = n)}
            onClickAway={this.onClickAway}
          />
        )}
      </>
    );
  }
}

export default Modal;

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
// dm
import LogoImage from '../../../../atoms/Images/LogoImage';
import Link from '../../../../atoms/Link';
import AvatarImage from '../../../../atoms/Images/AvatarImage';
import ModalBody from '../../../Modal/ModalBody';
import { logout } from '../../../../../store/actions/user';
import { selector } from '../../../../../store/reducers/form/fund';
import { toJS } from '../../../../../utils/core';
import { checkAssetManager, checkInvestor } from '../../../../../utils/access';
import { includesRoutePath } from '../../../../../utils/route';

// own
import './UserNavModal.scss';
import NavModal from '../index';

class UserNavModal extends Component {
  handleLogout = () => {
    this.props.dispatch(logout());
  };

  renderInvestor() {
    return (
      <ModalBody className="nav-modal__body user-nav-modal__body">
        <div className="user-nav-modal__row">
          {this.renderNavHeader()}
          <div className="user-nav-modal__list">
            <div className="nav-modal__list__items user-nav-modal__list__items">
              <Link
                href="https://blog.darcmatter.com"
                target="_blank"
                text="Resource Center"
              />
              <Link to="browse" text="Browse Investments" />
              <Link to="invDashboard" text="Go to Dashboard" />
              <Link to="invDashboard.archivedDeals" text="Archived Deals" />
            </div>
          </div>
        </div>
      </ModalBody>
    );
  }

  renderAssetManager() {
    const { id, location } = this.props;
    return (
      <ModalBody className="nav-modal__body user-nav-modal__body">
        <div className="user-nav-modal__row">
          {this.renderNavHeader()}
          <div className="user-nav-modal__list">
            <div className="nav-modal__list__items user-nav-modal__list__items">
              <Link
                href="https://blog.darcmatter.com"
                target="_blank"
                text="Resource Center"
              />
              <Link to="amDashboard" text="Go to Dashboard" />
            </div>
          </div>
        </div>
        {includesRoutePath(location, {
          pathName: 'funds.manage',
          pathNameParams: { id },
        }) && this.renderFundMenu()}
      </ModalBody>
    );
  }

  renderFundMenu() {
    const { id, logo, name } = this.props;
    return (
      <div className="user-nav-modal__row user-nav-modal__row--fund">
        <div className="user-nav-modal__header">
          <div className="user-nav-modal__user">
            <LogoImage src={logo} size="xs" />
            <div className="user-nav-modal__user__name">{name}</div>
          </div>
        </div>
        <div className="user-nav-modal__list">
          <div className="nav-modal__list__items user-nav-modal__list__items">
            <Link to="funds.manage.overview" params={{ id }} text="Overview" />
            <Link
              to="funds.manage.pipelines"
              params={{ id }}
              text="Investor Pipeline"
            />
            {/* Temporary unused */}
            {/* <Link to='funds.manage.contacts' params={{ id }} text='Invite My Investors' /> */}
            <Link
              to="funds.manage.permissions"
              params={{ id }}
              text="User Permissions"
            />
          </div>
        </div>
      </div>
    );
  }

  renderNavHeader() {
    const { user } = this.props;
    return (
      <div className="user-nav-modal__header">
        <div className="user-nav-modal__user">
          <AvatarImage src={user.avatar} size="md" />
          <div className="user-nav-modal__user__name">{user.fullName}</div>
        </div>
        <div className="user-nav-modal__control-group">
          <Link to="profile" text="Profile & Settings" />
          <Link onClick={this.handleLogout} text="Log Out" />
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    return (
      <NavModal className="user-nav-modal" {...this.props}>
        {checkInvestor(user) && this.renderInvestor()}
        {checkAssetManager(user) && this.renderAssetManager()}
      </NavModal>
    );
  }
}

export default connect(state => ({
  ...toJS(selector(state, 'id', 'name', 'logo')),
  user: toJS(state.get('user')),
}))(UserNavModal);

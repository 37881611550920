// vendor
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';

// dm
import Checkable from '../../../atoms/Checkable';
import Dropdown from '../../../molecules/Dropdown/Dropdown';
import DropdownList from '../../../molecules/Dropdown/DropdownList';
import DropdownListItem from '../../../molecules/Dropdown/DropdownListItem';
import Icon from '../../../atoms/Icons/Icon';
import Button from '../../../atoms/Button';
import Tag from '../../../atoms/Tag/Tag';

// own
import './ActivityFeedsHeader.scss';

class ActivityFeedsHeaderComponent extends Component {
  render() {
    const {
      rowsHighlight,
      handlerFilter,
      filter,
      handlerEditReadeable,
      checkAllFeeds,
    } = this.props;
    const checkAllFeedsValue = checkAllFeeds || false;
    const disableCheck = !Object.keys(rowsHighlight).length;
    return (
      <div className="activity-feeds__header">
        <div className="activity-feeds__row-container activity-feeds__row-container--header">
          <div className="activity-feeds__row activity-feeds__row--header">
            <div className="activity-feeds__row__l-col">
              <Field
                name="checkAllFeeds"
                component={Checkable}
                value={checkAllFeedsValue}
              />
              <Dropdown position="bottom-center">
                <div className="activity-feeds__filter">
                  <Icon name="filter" />
                  <FormattedMessage id="Filter" />
                </div>
                <DropdownList>
                  <DropdownListItem onClick={handlerFilter.bind(null, 'read')}>
                    <FormattedMessage
                      id={'read'.charAt(0).toUpperCase() + 'read'.slice(1)}
                    />
                  </DropdownListItem>
                  <DropdownListItem
                    onClick={handlerFilter.bind(null, 'unread')}
                  >
                    <FormattedMessage id="Unread" />
                  </DropdownListItem>
                </DropdownList>
              </Dropdown>
              {filter && (
                <Tag
                  icon="select-value-icon"
                  iconPos="right"
                  iconClick={handlerFilter.bind(null, null)}
                >
                  <FormattedMessage
                    id={filter.charAt(0).toUpperCase() + filter.slice(1)}
                  />
                </Tag>
              )}
            </div>
            <div className="activity-feeds__row__r-col">
              <Button
                disabled={disableCheck}
                size="sm"
                flat
                className="activity-feeds__header__first-button"
                onClick={handlerEditReadeable.bind(
                  null,
                  'unreadArr',
                  'readArr',
                )}
                text="Mark as unread"
              />
              <Button
                disabled={disableCheck}
                size="sm"
                onClick={handlerEditReadeable.bind(
                  null,
                  'readArr',
                  'unreadArr',
                )}
                text="Mark as read"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityFeedsHeaderComponent;

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form/immutable';

// dm
import Form from '../../../../atoms/Form';
import Input from '../../../../atoms/Input';
import Button from '../../../../atoms/Button';
import { updateUserPassword } from '../../../../../store/actions/user';
import { toJS } from '../../../../../utils/core';

const form = 'changePassword';

class ChangePasswordModalForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="oldPassword"
          component={Input}
          type="password"
          icon="password"
          placeholder="Current Password"
        />
        <Field
          name="password"
          component={Input}
          type="password"
          icon="password"
          placeholder="New Password"
        />
        <Field
          name="confirmPassword"
          component={Input}
          type="password"
          icon="password"
          placeholder="Confirm Password"
        />
        <Button
          type="submit"
          size="md"
          frozen
          disabled={submitting}
          text={
            submitting
              ? 'Submitting ...'
              : 'modal.change_password.button.save_password'
          }
        />
      </Form>
    );
  }
}

const validate = values => {
  const errors = {};
  const PASS_MIN_LENGTH = 6;
  const PASS_MAX_LENGTH = 40;
  values = toJS(values);
  if (!values.oldPassword) errors.oldPassword = 'Old Password cannot be blank.';
  if (!values.password) errors.password = 'Please enter a valid password';
  else if (~values.password.indexOf(' '))
    errors.password = "Spaces are't available";
  else if (values.password.length < PASS_MIN_LENGTH)
    errors.password = 'Min 6 symbols ';
  else if (values.password.length > PASS_MAX_LENGTH)
    errors.password = 'Max 40 symbols ';
  if (!values.confirmPassword)
    errors.confirmPassword = 'Please confirm your password';
  else if (values.confirmPassword !== values.password)
    errors.confirmPassword = 'Passwords do not match';
  return errors;
};

export default connect(
  null,
  (dispatch, ownProps) => ({
    onSubmit: values =>
      dispatch(updateUserPassword(values.toJS())).then(ownProps.close),
  }),
)(
  reduxForm({
    form,
    validate,
  })(ChangePasswordModalForm),
);

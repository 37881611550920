import React from 'react';
import classNames from 'classnames';

const CarouselArrow = ({ className, ...props }) => (
  <div {...props} className={classNames('carousel__arrow', className)}>
    <div className="carousel__arrow__helper" />
  </div>
);

export default CarouselArrow;

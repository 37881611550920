/* eslint func-names: 0 */
// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';
import { createPath } from '../../utils/route';

/* action types */
export const types = createConstants(
  'READ_DEAL_ROOM_REQUEST',
  'READ_DEAL_ROOM_SUCCESS',
  'READ_DEAL_ROOM_FAILURE',

  'READ_DEAL_ROOMS_REQUEST',
  'READ_DEAL_ROOMS_SUCCESS',
  'READ_DEAL_ROOMS_FAILURE',

  'READ_INVITATION_DEAL_ROOMS_REQUEST',
  'READ_INVITATION_DEAL_ROOMS_SUCCESS',
  'READ_INVITATION_DEAL_ROOMS_FAILURE',

  'READ_PENDING_DEAL_ROOMS_REQUEST',
  'READ_PENDING_DEAL_ROOMS_SUCCESS',
  'READ_PENDING_DEAL_ROOMS_FAILURE',

  'READ_PENDING_DOCUMENTS_REQUEST',
  'READ_PENDING_DOCUMENTS_SUCCESS',
  'READ_PENDING_DOCUMENTS_FAILURE',

  'READ_ARCHIVED_DEALS_REQUEST',
  'READ_ARCHIVED_DEALS_SUCCESS',
  'READ_ARCHIVED_DEALS_FAILURE',

  'CLEAR_DEAL_ROOM',

  'MOVE_DEAL_ROOM_TO_ARCHIVED_REQUEST',
  'MOVE_DEAL_ROOM_TO_ARCHIVED_SUCCESS',
  'MOVE_DEAL_ROOM_TO_ARCHIVED_FAILURE',

  'RESTORE_DEAL_ROOM_FROM_ARCHIVED_REQUEST',
  'RESTORE_DEAL_ROOM_FROM_ARCHIVED_SUCCESS',
  'RESTORE_DEAL_ROOM_FROM_ARCHIVED_FAILURE',

  'SET_DEAL_ROOM_AF_COUNTER',

  'READ_INVESTMENTS_AMOUNT_REQUEST',
  'READ_INVESTMENTS_AMOUNT_SUCCESS',
  'READ_INVESTMENTS_AMOUNT_FAILURE',

  'UPDATE_INVESTMENT_DEAL_ROOM',

  'READ_FAVORITE_FUNDS_REQUEST',
  'READ_FAVORITE_FUNDS_SUCCESS',
  'READ_FAVORITE_FUNDS_FAILURE',
);

export const readDealRoom = id => ({
  [CALL_API]: {
    types: [
      types.READ_DEAL_ROOM_REQUEST,
      types.READ_DEAL_ROOM_SUCCESS,
      types.READ_DEAL_ROOM_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${id}`,
    withProgressBar: true,
  },
});

export const readDealRooms = () => ({
  [CALL_API]: {
    types: [
      types.READ_DEAL_ROOMS_REQUEST,
      types.READ_DEAL_ROOMS_SUCCESS,
      types.READ_DEAL_ROOMS_FAILURE,
    ],
    method: 'get',
    url: 'deal-room/funds?only=pipelined',
    withProgressBar: true,
  },
});

// TODO: check and remove
export const readInvitationDealRooms = () => ({
  [CALL_API]: {
    types: [
      types.READ_INVITATION_DEAL_ROOMS_REQUEST,
      types.READ_INVITATION_DEAL_ROOMS_SUCCESS,
      types.READ_INVITATION_DEAL_ROOMS_FAILURE,
    ],
    method: 'get',
    url: 'deal-room/funds?only=invitations',
    withProgressBar: true,
  },
});

export const readPendingDealRooms = () => ({
  [CALL_API]: {
    types: [
      types.READ_PENDING_DEAL_ROOMS_REQUEST,
      types.READ_PENDING_DEAL_ROOMS_SUCCESS,
      types.READ_PENDING_DEAL_ROOMS_FAILURE,
    ],
    method: 'get',
    url: 'deal-room/funds?only=requests',
    withProgressBar: true,
  },
});

export const readPendingDocuments = () => ({
  [CALL_API]: {
    types: [
      types.READ_PENDING_DOCUMENTS_REQUEST,
      types.READ_PENDING_DOCUMENTS_SUCCESS,
      types.READ_PENDING_DOCUMENTS_FAILURE,
    ],
    method: 'get',
    url: 'deal-room/funds?only=documents_pending',
    withProgressBar: true,
  },
});

export const readArchivedDeals = () => ({
  [CALL_API]: {
    types: [
      types.READ_ARCHIVED_DEALS_REQUEST,
      types.READ_ARCHIVED_DEALS_SUCCESS,
      types.READ_ARCHIVED_DEALS_FAILURE,
    ],
    method: 'get',
    url: 'funds/archived',
    withProgressBar: true,
  },
});

export const clearDealRoom = () => ({
  type: types.CLEAR_DEAL_ROOM,
});

export const moveDealRoomToArchived = data => ({
  [CALL_API]: {
    types: [
      types.MOVE_DEAL_ROOM_TO_ARCHIVED_REQUEST,
      types.MOVE_DEAL_ROOM_TO_ARCHIVED_SUCCESS,
      types.MOVE_DEAL_ROOM_TO_ARCHIVED_FAILURE,
    ],
    method: 'post',
    url: 'funds/archived',
    data,
  },
});

const archivedDealPath = createPath('funds/archived/:id');
export const restoreDealRoomFromArchived = data => ({
  [CALL_API]: {
    types: [
      types.RESTORE_DEAL_ROOM_FROM_ARCHIVED_REQUEST,
      types.RESTORE_DEAL_ROOM_FROM_ARCHIVED_SUCCESS,
      types.RESTORE_DEAL_ROOM_FROM_ARCHIVED_FAILURE,
    ],
    method: 'delete',
    url: archivedDealPath(data),
  },
});

export const changeCountAsideDealRoom = (indexDealRoom, unreadCounterReal) => ({
  type: types.SET_DEAL_ROOM_AF_COUNTER,
  payload: { indexDealRoom, unreadCounterReal },
});

export const readInvestmentAmount = () => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTMENTS_AMOUNT_REQUEST,
      types.READ_INVESTMENTS_AMOUNT_SUCCESS,
      types.READ_INVESTMENTS_AMOUNT_FAILURE,
    ],
    method: 'get',
    url: 'investments/total-investment-amount',
    withProgressBar: false,
  },
});

export const updateInvestmentDealRoom = dealRoom => ({
  type: types.UPDATE_INVESTMENT_DEAL_ROOM,
  payload: dealRoom,
});

export function loadInvestmentsDealRoom(dealRoom) {
  return function(dispatch, getState) {
    const currentState = getState()
      .get('form')
      .get('invDashboard')
      .get('values')
      .toJS();
    if (currentState.dealRoom.id !== dealRoom.id) return;
    dispatch(updateInvestmentDealRoom(dealRoom));
  };
}

export const readFavoriteFunds = () => ({
  [CALL_API]: {
    types: [
      types.READ_FAVORITE_FUNDS_REQUEST,
      types.READ_FAVORITE_FUNDS_SUCCESS,
      types.READ_FAVORITE_FUNDS_FAILURE,
    ],
    method: 'get',
    url: 'funds/published?filter={"is_favorite":true}&fields=id,logo,name',
    withProgressBar: true,
  },
});

// vendor
import React from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';

import Button from '../../atoms/Button';

const BackButton = ({ handleGoBack, ...props }) => (
  <Button
    size="sm"
    icon="arrow-left-sm"
    iconPos="left"
    color="blue-g"
    text="Back"
    border="none"
    flat
    frozen
    onClick={handleGoBack}
    {...props}
  />
);

export default connect(
  null,
  dispatch => ({
    handleGoBack: () => dispatch(goBack()),
  }),
)(BackButton);

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change, FieldArray, Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { fromJS } from 'immutable';
// dm
import NotesMessage from '../../molecules/Message/NotesMessage';
import Form from '../../atoms/Form';
import Button from '../../atoms/Button';
import Checkable from '../../atoms/Checkable';
import OverlaySpinner from '../../atoms/Spinners/OverlaySpinner';

import {
  createNoteMessage,
  deleteNoteMessage,
  updateNoteMessage,
  checkFundCollection,
} from '../.././../store/actions/fund';
import { form, selector } from '../.././../store/reducers/form/inbox';
import { toJS } from '../.././../utils/core';
import { MOBILE, TABLET } from '../.././../constants/breakpoints';
// own
import './Notes.scss';
import NotesEmpty from './NotesEmpty';
import NotesNewMessage from './NotesNewMessage';

class NotesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messenger: false,
      updater: null,
      sortingArr: null,
    };
  }

  componentWillMount() {
    this.initState(this.props.notes);
  }

  componentWillReceiveProps({ notes }) {
    if (this.props.notes !== notes) {
      this.initState(notes);
    }
  }

  initState(notes) {
    const { investorId } = this.props;
    const sortingForUserNotes = notes.filter(
      msg => +msg.investor.userId === +investorId && msg,
    );

    this.setState({
      sortingArr: sortingForUserNotes,
    });
  }

  changeMessangerText(text = '') {
    const { dispatch } = this.props;
    dispatch(change(form, 'messanger', text));
  }

  handleCheckAll(event) {
    const { dispatch } = this.props;
    dispatch(checkFundCollection('notes', event.target.checked));
  }

  toggleSendForm = () => {
    this.changeMessangerText();
    this.setState({
      messenger: !this.state.messenger,
      updater: null,
    });
  };

  sendMsg = id => {
    const { text, dispatch, fundId, investorId } = this.props;
    if (text) {
      dispatch(createNoteMessage({ fundId, text, investorId, id })).then(
        ({ status }) => {
          if (status === 'success') {
            this.toggleSendForm();
            dispatch(change(form, 'notesCheckedAll', false));
          }
        },
      );
    }
  };

  showUpdateForm = (id, text) => {
    this.changeMessangerText(text);
    this.setState({
      messenger: false,
      updater: id,
    });
  };

  updateMsg = id => {
    const { text, dispatch, fundId, investorId } = this.props;
    if (text) {
      dispatch(updateNoteMessage({ fundId, text, investorId, id })).then(
        ({ status }) => {
          if (status === 'success') {
            this.setState({ updater: null });
          }
        },
      );
    }
  };

  deleteMsg = deleteNotes => {
    const { dispatch, fundId } = this.props;
    dispatch(change(form, 'notesChecked', fromJS({})));
    dispatch(deleteNoteMessage({ fundId, deleteNotes }));
  };

  handleClickCheckAll = value => {
    const { dispatch } = this.props;
    dispatch(change(form, 'notesCheckedAll', value));
    this.state.sortingArr.map(elem =>
      dispatch(change(form, `notesChecked.${elem.id}`, value)),
    );
  };

  render() {
    const { user, notesCheckedAll, notesChecked, reading } = this.props;
    return (
      <Form className="notes">
        <OverlaySpinner active={reading} />
        {!this.state.sortingArr.length && !this.state.messenger && (
          <NotesEmpty toggleSendForm={this.toggleSendForm} />
        )}
        {(this.state.messenger ||
          this.state.updater ||
          !!this.state.sortingArr.length) && (
          <div className="notes__main">
            {!!this.state.sortingArr.length && (
              <div className="notes__controls">
                <Field
                  name="notesCheckedAll"
                  component={Checkable}
                  onClick={() => this.handleClickCheckAll(!notesCheckedAll)}
                />
                <MediaQuery maxWidth={MOBILE.right}>
                  <Button
                    size="xs"
                    icon="feather"
                    text="Add note"
                    onClick={this.toggleSendForm}
                  />
                </MediaQuery>
                <MediaQuery minWidth={TABLET.left}>
                  <Button
                    size="sm"
                    icon="feather"
                    text="Add note"
                    onClick={this.toggleSendForm}
                  />
                </MediaQuery>
                {notesChecked && !!notesChecked.filter(item => item).size && (
                  <div className="notes__controls__delete">
                    <MediaQuery maxWidth={MOBILE.right}>
                      <Button
                        size="xs"
                        color="red-s"
                        icon="delete-white"
                        text="Delete"
                        onClick={() =>
                          this.deleteMsg(
                            Object.keys(
                              toJS(notesChecked.filter(item => item)),
                            ),
                          )
                        }
                      />
                    </MediaQuery>
                    <MediaQuery minWidth={TABLET.left}>
                      <Button
                        size="sm"
                        color="red-s"
                        icon="delete-white"
                        text="Delete"
                        onClick={() =>
                          this.deleteMsg(
                            Object.keys(
                              toJS(notesChecked.filter(item => item)),
                            ),
                          )
                        }
                      />
                    </MediaQuery>
                    <span className="notes__controls__amount-selected">
                      {/* ({Object.keys(notesChecked).length}) */}(
                      {notesChecked.filter(item => item).size})
                    </span>
                    <FormattedMessage id="Notes" />
                  </div>
                )}
              </div>
            )}
            {this.state.messenger && (
              <NotesNewMessage
                user={user}
                approveFunc={this.sendMsg}
                cancelFunc={this.toggleSendForm}
                text="Add"
              />
            )}
            {!!this.state.sortingArr.length && (
              <FieldArray
                className="table__body"
                name="notesChecked"
                component={NotesMessage}
                fieldProps={{
                  deleteNoteMessage: this.deleteMsg,
                  showUpdateForm: this.showUpdateForm,
                  updateMsg: this.updateMsg,
                  notes: this.state.sortingArr,
                  user,
                  updater: this.state.updater,
                }}
              />
            )}
          </div>
        )}
      </Form>
    );
  }
}

export default connect(state => ({
  notes: toJS(selector(state, 'notes')),
  notesChecked: selector(state, 'notesChecked'),
  notesCheckedAll: toJS(selector(state, 'notesCheckedAll')),
  text: toJS(selector(state, 'messanger')),
  user: state.get('user').toJS(),
}))(reduxForm({ form, destroyOnUnmount: false })(NotesComponent));

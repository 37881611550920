// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastMessageAnimated, ToastContainer } from 'react-toastr';
import { injectIntl, intlShape } from 'react-intl';

// own
import './Toastr.scss';

class ToastrComponent extends Component {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    options: PropTypes.object,
    toastMessageFactory: PropTypes.func.isRequired,
    toastType: PropTypes.shape({
      error: PropTypes.string,
      info: PropTypes.string,
      success: PropTypes.string,
      warning: PropTypes.string,
    }).isRequired,
    intl: intlShape.isRequired,
    translate: PropTypes.bool,
  };

  static defaultProps = {
    toastType: {
      error: 'error',
      info: 'info',
      success: 'success',
      warning: 'warning',
    },
    toastMessageFactory: React.createFactory(ToastMessageAnimated),
    translate: true,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.showMessage(nextProps);
    }
  }

  showMessage({ type, title, message, options, intl, translate }) {
    title = translate ? intl.formatMessage({ id: title }) : title;
    message = translate ? intl.formatMessage({ id: message }) : message;
    this.refs.toastr[type](message, title, { timeOut: 5000, ...options });
  }

  render() {
    return (
      <ToastContainer
        ref="toastr"
        toastMessageFactory={this.props.toastMessageFactory}
        className="toast-top-center"
      />
    );
  }
}

export default injectIntl(ToastrComponent);

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// dm
import Icon from '../../../atoms/Icons/Icon';
import LinkButton from '../LinkButton';
import { KYC_STATUS } from '../../../../constants/lists';

// own
import './KycStatusButton.scss';

export default class KycStatusButtonComponent extends Component {
  static propTypes = {
    user: PropTypes.shape({
      isInvestor: PropTypes.bool.isRequired,
      kycStatus: PropTypes.any,
    }),
    buttonProps: PropTypes.shape({
      flat: PropTypes.bool,
      size: PropTypes.string,
    }),
    icon: PropTypes.string,
  };

  static defaultProps = {
    buttonProps: {
      flat: false,
      size: 'xs',
    },
    icon: 'verified-sm',
  };

  render() {
    const {
      className,
      icon,
      buttonProps: { flat, size },
      user: { isInvestor, kycStatus },
    } = this.props;
    const fullClassName = classNames('kyc-status-button', className);
    const isKycCompleted = kycStatus === 25 || kycStatus === 26;
    return (
      <div className={fullClassName}>
        {isKycCompleted && (
          <div className="kyc-status-button__label">
            <Icon name={icon} />
            <FormattedMessage id="KYC/AML VERIFIED" />
          </div>
        )}
        {!isKycCompleted && isInvestor && (
          <LinkButton
            flat={flat}
            size={size}
            to="kyc.party"
            withEntry
            text="Complete KYC/AML"
            disabled={kycStatus === KYC_STATUS.PENDING_MANUAL}
          />
        )}
      </div>
    );
  }
}

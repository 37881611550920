// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

// own
import './TextArea.scss';
import FormControl from '../FormControl';

class TextAreaComponent extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    disabled: PropTypes.bool,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    meta: {},
    input: {},
  };

  render() {
    const {
      meta,
      size,
      icon,
      iconPos,
      input,
      disabled,
      prefix,
      intl,
      onKeyDown,
      ...props
    } = this.props;
    const showFundFormErrors = props.showFundFormErrors || false;
    let placeholder =
      props.placeholder && intl.formatMessage({ id: props.placeholder });
    if (placeholder && prefix) {
      placeholder = `${prefix} ${placeholder}`;
    }
    return (
      <FormControl
        kind="text-area"
        {...meta}
        {...{ size, icon, iconPos, disabled, showFundFormErrors }}
      >
        <TextareaAutosize
          {...input}
          {...{ disabled, placeholder }}
          onKeyDown={onKeyDown}
        />
      </FormControl>
    );
  }
}

export default injectIntl(TextAreaComponent);

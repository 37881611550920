// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import DMLogo from '../../atoms/DMLogo';
import Button from '../../atoms/Button';
import Icon from '../../dm-public/ui-kit/icon';
import HeaderNavAuthorized from '../../molecules/HeaderNavAuthorized';
import HeaderNavGuest from '../../molecules/HeaderNavGuest';
import FundManageHeaderInfo from '../../../views/Funds/Manage/FundManageHeaderInfo';
import SearchBrowseView from '../../../views/InvDashboard/BrowseInvestments/Search';
import AssetManagerRoute from '../../../enhancers/AssetManagerRoute';
import InvestorRoute from '../../../enhancers/InvestorRoute';
import {
  isAuthorizedSelector,
  isAssetManagerSelector,
  isInvestorSelector,
} from '../../../store/reducers/user';
import { openAsideMenu } from '../../../store/actions/app';

// own
import './Header.scss';
import { toJS } from '../../../utils/core';
import { selector as fundSelector } from '../../../store/reducers/form/fund';

const propTypes = {
  position: PropTypes.oneOf(['absolute', 'fixed']),
  breakpoint: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

const defaultProps = {
  position: 'fixed',
};

const HeaderComponent = ({
  className,
  isAuthorized,
  info,
  fundInfo,
  asideMenu,
  onClickAsideMenu,
  isInvestor,
  isAM,
  ...props
}) => {
  const fullClassName = classNames(
    className,
    'header',
    'header--fixed',
    {
      'header--public': !isAuthorized,
    },
    {
      'header--with-aside': props.withAside,
    },
  );

  const logoStyle =
    isAuthorized && props.breakpoint === 'mobile' ? { display: 'none' } : {};

  const viewSearch =
    props.pathname === '/inv-dashboard/browse-investments' &&
    props.breakpoint === 'desktop';

  const MenuButton = () => (
    <Button
      className="header__menu"
      onClick={() => onClickAsideMenu(!asideMenu)}
    >
      <Icon name={asideMenu ? 'close' : 'menu'} color="white" size="30" />
    </Button>
  );

  return (
    <header className={fullClassName}>
      <InvestorRoute path="/inv-dashboard" component={MenuButton} />
      <AssetManagerRoute
        path="/am-dashboard/funds/:id/manage"
        component={MenuButton}
      />
      <div className="header__logo" style={logoStyle}>
        <DMLogo
          {...props}
          isAuthorized={isAuthorized}
          isInvestor={isInvestor}
          isAM={isAM}
        />
      </div>
      <div className="header__info">
        {info && props.breakpoint !== 'mobile' && (
          <FundManageHeaderInfo {...fundInfo} />
        )}
        {viewSearch && <SearchBrowseView />}
      </div>
      {isAuthorized ? (
        <HeaderNavAuthorized {...props} />
      ) : (
        <HeaderNavGuest {...props} />
      )}
    </header>
  );
};

HeaderComponent.propTypes = propTypes;
HeaderComponent.defaultProps = defaultProps;

export default connect(
  state => ({
    isAuthorized: isAuthorizedSelector(state),
    fundInfo: toJS(fundSelector(state, 'name', 'logo')),
    asideMenu: toJS(state.get('app').get('asideMenu')),
    isAM: isAssetManagerSelector(state),
    isInvestor: isInvestorSelector(state),
  }),
  dispatch => ({
    onClickAsideMenu: isOpen => dispatch(openAsideMenu(isOpen)),
  }),
)(HeaderComponent);

// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import Button from '../../../../../atoms/Button';
import Modal from '../../../../Modal/Modal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalControls from '../../../../Modal/ModalControls';

export default class DeletePipelineModal extends Component {
  confirmClick = () => {
    const { handleDelete, collection, close } = this.props;
    handleDelete(collection);
    close();
  };

  render() {
    const { investors, close } = this.props;
    return (
      <Modal className="modal--notification" {...this.props}>
        <ModalBody>
          <div>
            <FormattedMessage id="modal.pipeline.delete.text1" />
            <strong> {investors} </strong>
            <FormattedMessage id="modal.pipeline.delete.text2" />
          </div>
          <ModalControls>
            <Button
              color="green"
              frozen
              text="Yes"
              onClick={this.confirmClick}
            />
            <Button color="red-s" frozen text="No" onClick={close} />
          </ModalControls>
        </ModalBody>
      </Modal>
    );
  }
}

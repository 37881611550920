// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import AccessDeclineButton from '../../ActionButtons/AccessDeclineButton';
import AccessAcceptButton from '../../ActionButtons/AccessAcceptButton';
import IconLg from '../../../atoms/Icons/IconLg';

export default class RequestToFund extends React.Component {
  render() {
    const { fundId, userId, className } = this.props;
    const fullClassName = classNames('am-investor-card__contacts', className);
    return (
      <div className={fullClassName}>
        <div className="am-investor-card__contacts__title">
          <FormattedMessage id="Request to fund’s" />
          <FormattedMessage id="Deal Room" />:
        </div>
        <div className="am-investor-card__contacts__content">
          <IconLg name="notification" />
          <FormattedMessage id="fund.manage.investor-page.pending-pipeline.text" />
        </div>
        <div className="am-investor-card__contacts__controls">
          <AccessAcceptButton
            size="sm"
            actionProps={{ id: fundId, investorId: userId }}
          />
          <AccessDeclineButton
            size="sm"
            actionProps={{ id: fundId, investorId: userId }}
          />
        </div>
      </div>
    );
  }
}

// vendor
import React, { Component } from 'react';

// dm
import Input from '../../atoms/Input';

export default class DatePickerInputComponent extends Component {
  render() {
    const {
      input,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      value,
      ...props
    } = this.props;
    return (
      <Input
        input={{
          ...input,
          onBlur,
          onChange,
          onClick,
          onFocus,
          onKeyDown,
          value,
        }}
        {...props}
      />
    );
  }
}

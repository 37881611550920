// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

// dm
import readData from '../../../../enhancers/readData';
import Icon from '../../../atoms/Icons/Icon';
import AvatarImage from '../../../atoms/Images/AvatarImage';
import TagGroup from '../../../atoms/Tag/TagGroup';
import KycStatusButton from '../../ActionButtons/KycStatusButton/';
import {
  MOBILE,
  TABLET,
  DESKTOP,
  DESKTOP_LARGE,
} from '../../../../constants/breakpoints';
import { ELIGIBILITY_OPTIONS } from '../../../../constants/styles';
import { listParam } from '../../../../utils/collection';

// own
import './InvestorCard.scss';

class InvestorCardComponent extends React.Component {
  static propTypes = {
    isInvestor: PropTypes.bool,
  };

  static defaultProps = {
    isInvestor: false,
    avatar: {},
  };

  renderCardInvestor({ tagSize, deviceSize, avatarSize }) {
    const {
      lists,
      className,
      isInvestor,
      avatar,
      fullName,
      qualifications,
      investorType,
      kycStatus,
      totalInvestmentAmount,
    } = this.props;
    const investorTypeText = listParam(investorType, lists.investorType);
    const fullClassName = classNames(className, 'investor-card', {
      'investor-card--investor': isInvestor,
    });
    const transformAmount = totalInvestmentAmount
      ? totalInvestmentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '0';

    return (
      <div className={fullClassName}>
        <div className="investor-card__main">
          <div className="investor-card__header">
            <div className="investor-card__avatar">
              <AvatarImage src={avatar} size={avatarSize || 'lg'} />
              {deviceSize !== 'mobile' && (
                <KycStatusButton
                  user={{ isInvestor, kycStatus }}
                  buttonProps={{ size: 'sm' }}
                />
              )}
            </div>
            <div className="investor-card__main-info">
              <div className="investor-card__main-info__name">{fullName}</div>
              {deviceSize === 'mobile' && (
                <KycStatusButton user={{ isInvestor, kycStatus }} />
              )}
              <div className="investor-card__main-info__type">
                ({investorTypeText})
              </div>
              {deviceSize !== 'mobile' && (
                <div>
                  <TagGroup
                    values={qualifications}
                    list={lists.qualification}
                    styles={ELIGIBILITY_OPTIONS.ICON_MD}
                    size={tagSize}
                    diff
                  />
                  <div className="investor-card__total-investment">
                    <Icon name="denomination" />
                    <FormattedMessage id="inv-card.total-investment-amount" />
                    <div className="amount">${transformAmount}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {deviceSize === 'mobile' && (
            <div>
              <TagGroup
                values={qualifications}
                list={lists.qualification}
                styles={ELIGIBILITY_OPTIONS.ICON_MD}
                size={tagSize}
                diff
              />
              <div className="investor-card__total-investment">
                <FormattedMessage id="inv-card.total-investment-amount" />
                <div className="amount">${transformAmount}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <MediaQuery maxWidth={MOBILE.right}>
          {this.renderCardInvestor({
            avatarSize: 'sm',
            tagSize: 'xs',
            deviceSize: 'mobile',
          })}
        </MediaQuery>
        <MediaQuery minWidth={TABLET.left} maxWidth={TABLET.right}>
          {this.renderCardInvestor({ tagSize: 'xs', deviceSize: 'tablet' })}
        </MediaQuery>
        <MediaQuery minWidth={DESKTOP.left} maxWidth={DESKTOP.right}>
          {this.renderCardInvestor({ tagSize: 'xs', deviceSize: 'desktop' })}
        </MediaQuery>
        <MediaQuery minWidth={DESKTOP_LARGE.left}>
          {this.renderCardInvestor({ tagSize: 'sm', deviceSize: 'desktop-hd' })}
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  totalInvestmentAmount: state
    .get('form')
    .get('invDashboard')
    .get('values')
    .get('totalInvestmentAmount'),
});

export default connect(mapStateToProps)(
  readData({
    listNames: 'investorType qualification',
  })(InvestorCardComponent),
);

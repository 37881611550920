// vendor
import React from 'react';
import { connect } from 'react-redux';

// dm
import Button from '../../atoms/Button';
import { openInbox } from '../../../store/actions/inbox';

const OpenInboxButton = ({ handleOpenInbox, ...props }) => (
  <Button
    size="sm"
    icon="arrow-right-sm"
    iconPos="right"
    color="blue-g"
    text="Inbox"
    border="none"
    flat
    frozen
    onClick={handleOpenInbox}
    {...props}
  />
);

export default connect(
  null,
  dispatch => ({
    handleOpenInbox: () => dispatch(openInbox()),
  }),
)(OpenInboxButton);

// vendor
import { formValueSelector } from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { filtersPanelTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'filtersPanel';

/* initial state */
const initialState = fromJS({
  values: {
    opened: false,
    filters: {},
  },
});

/* selectors */
export const selector = formValueSelector(form);

/* reducer */
export default createReducer(initialState, {
  [filtersPanelTypes.TOGGLE_FILTERS_PANEL]: (state, opened) =>
    state.mergeDeep({ values: { opened } }),
  [filtersPanelTypes.APPLY_FILTERS_PANEL_FILTERS]: (state, filters) =>
    state.setIn('values.filters'.p(), fromJS(filters)),
  [filtersPanelTypes.REMOVE_FILTERS_PANEL_FILTER]: (state, filterKey) =>
    state
      .removeIn(`values.${filterKey}`.p())
      .removeIn(`values.filters.${filterKey}`.p()),
  [filtersPanelTypes.CLEAR_FILTERS_PANEL]: state => state.merge(initialState),
});

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedPlural } from 'react-intl';
import { keys, isEmpty, startCase } from 'lodash';

// dm
import Tag from '../../../atoms/Tag/Tag';
import { removeFiltersPanelFilter } from '../../../../store/actions/filters-panel';
import { selector } from '../../../../store/reducers/form/filters-panel';

// own
import './ActiveFilters.scss';

class ActiveFiltersComponent extends Component {
  render() {
    const { filters, handleRemoveFilter } = this.props;
    const filterKeys = keys(filters);
    return (
      !isEmpty(filterKeys) && (
        <div className="active-filters">
          <FormattedPlural
            value={filterKeys.length}
            one="Active Filter :"
            other="Active Filters :"
          />
          <div className="active-filters__tags">
            {filterKeys.map(filterKey => (
              <Tag
                key={filterKey}
                icon="select-value-icon"
                iconPos="right"
                iconClick={handleRemoveFilter(filterKey)}
              >
                {startCase(filterKey).replace(/Id$/, '')}
              </Tag>
            ))}
          </div>
        </div>
      )
    );
  }
}

export default connect(
  state => ({
    filters: selector(state, 'filters').toJS(),
  }),
  dispatch => ({
    handleRemoveFilter: filterKey => () => {
      dispatch(removeFiltersPanelFilter(filterKey));
    },
  }),
)(ActiveFiltersComponent);

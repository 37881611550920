// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import AssetManagersCarousel from '../../../../Carousels/AssetManagers';

// own
import './FundShowManagement.scss';

export default ({ teamMembers }) => (
  <div className="fund-show-management">
    <div className="fund-show-overview__title">
      <FormattedMessage id="Management" />
    </div>
    <AssetManagersCarousel teamMembers={teamMembers} />
  </div>
);

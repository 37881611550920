// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// dm
import Image from '../../atoms/Images/Image';
import Link from '../../atoms/Link';

export default class ImageLinkComponent extends Component {
  static propTypes = {
    alt: PropTypes.string,
    size: PropTypes.string,
    src: PropTypes.string.isRequired,
  };

  render() {
    const { src, size, alt, className, ...props } = this.props;
    const fullClassName = classNames('image-link', className);
    return (
      <Link className={fullClassName} {...props}>
        <Image src={src} size={size} alt={alt} />
      </Link>
    );
  }
}

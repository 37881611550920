// vendor
import React from 'react';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';

// dm
import DMLogo from '../../../atoms/DMLogo';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/ModalHeader';
import Link from '../../../atoms/Link';
import { MOBILE, TABLET } from '../../.././../constants/breakpoints';

// own
import './NavModal.scss';

export default ({ className, children, ...props }) => (
  <Modal {...props} className={classNames(className, 'nav-modal')}>
    <ModalHeader className="nav-modal__header">
      <Link className="nav-modal__header__logo" to="home">
        <MediaQuery maxWidth={MOBILE.right}>
          <DMLogo width="109px" height="32px" breakpoint="mobile" />
        </MediaQuery>
        <MediaQuery minWidth={TABLET.left}>
          <DMLogo width="144px" height="43px" breakpoint="tablet" />
        </MediaQuery>
      </Link>
    </ModalHeader>
    {children}
  </Modal>
);

import React, { Component } from 'react';

import ErrorPage from '../components/dm-public/error-page';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          error={{ message: 'Oops!', content: 'Something went wrong.' }}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

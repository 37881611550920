// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'READ_KYC_PARTY_REQUEST',
  'READ_KYC_PARTY_SUCCESS',
  'READ_KYC_PARTY_FAILURE',

  'CREATE_KYC_PARTY_REQUEST',
  'CREATE_KYC_PARTY_SUCCESS',
  'CREATE_KYC_PARTY_FAILURE',

  'CREATE_KYC_PARTY_MEDIA_REQUEST',
  'CREATE_KYC_PARTY_MEDIA_SUCCESS',
  'CLEAR_KYC_PARTY_MEDIA',
);

export const readKycParty = id => ({
  [CALL_API]: {
    types: [
      types.READ_KYC_PARTY_REQUEST,
      types.READ_KYC_PARTY_SUCCESS,
      types.READ_KYC_PARTY_FAILURE,
    ],
    method: 'get',
    url: `users/${id}?expand=kyc_status,suitability_status`,
    withProgressBar: true,
  },
});

export const createKycParty = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_KYC_PARTY_REQUEST,
      types.CREATE_KYC_PARTY_SUCCESS,
      types.CREATE_KYC_PARTY_FAILURE,
    ],
    method: 'put',
    url: `users/${id}/profile`,
    data,
    form: 'kycParty',
    withProgressBar: true,
  },
});

export const clearKycPartyMedia = () => ({
  type: types.CLEAR_KYC_PARTY_MEDIA,
});

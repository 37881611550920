import React from 'react';

import OpenModalButton from './OpenModalButton';

export default ({ id, isPreview, ...props }) => (
  <OpenModalButton
    text="View fund"
    modal="fund-show"
    actionProps={{ id, isPreview }}
    {...props}
  />
);

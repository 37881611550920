// vendor
import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
// dm
import readData from '../../../../enhancers/readData';
import TagGroup from '../../../atoms/Tag/TagGroup';
import Icon from '../../../atoms/Icons/Icon';
import AvatarImage from '../../../atoms/Images/AvatarImage';
import {
  MOBILE,
  TABLET,
  DESKTOP,
  DESKTOP_LARGE,
} from '../../../../constants/breakpoints';
import { ELIGIBILITY_OPTIONS } from '../../../../constants/styles';
import { listParam } from '../../../../utils/collection';
// own
import './AmInvestorCard.scss';
// import ContactInformation from './ContactInformation';
// import InviteToDealRoom from './InviteToDealRoom';
import RequestToFund from './RequestToFund';
import InvestorInformation from './InvestorInformation';
import WaitingConfirmation from './WaitingConfirmation';

class AmInvestorCardComponent extends React.Component {
  static propTypes = {
    industryFocuses: PropTypes.array,
    preferredAssetTypes: PropTypes.array,
    hedgeFundStrategies: PropTypes.array,
    ventureCapitalStageFocuses: PropTypes.array,
  };

  static defaultProps = {
    avatar: {},
    industryFocuses: [],
    preferredAssetTypes: [],
    hedgeFundStrategies: [],
    ventureCapitalStageFocuses: [],
  };

  renderCardInvestor({ tagSize, deviceSize, avatarSize }) {
    const {
      // email,
      // firmName,
      // isPipelined,
      // linkedIn,
      // phoneNumber,
      // fundName,
      avatar,
      averageInvestment,
      className,
      firmAum,
      fullName,
      fundId,
      hedgeFundStrategies,
      industryFocuses,
      investorType,
      isAccessRequested,
      isPendingPipelined,
      isPipeline,
      lists,
      preferredAssetTypes,
      qualifications,
      userId,
      ventureCapitalStageFocuses,
    } = this.props;
    const investorTypeText = listParam(investorType, lists.investorType);
    const fullClassName = classNames(className, 'am-investor-card');
    return (
      <div className={fullClassName}>
        <div className="am-investor-card__main">
          <div className="am-investor-card__header">
            <div className="am-investor-card__avatar">
              <AvatarImage src={avatar} size={avatarSize || 'sm'} />
            </div>
            <div className="am-investor-card__main-info">
              <div className="am-investor-card__main-info__name">
                {fullName}
                {isPipeline && (
                  <div className="am-investor-card__main-info__pipeline">
                    <Icon name="checked-arr" />
                    <FormattedMessage id="Pipeline" />
                  </div>
                )}
              </div>
              <div className="am-investor-card__main-info__type">
                ({investorTypeText})
              </div>
              {deviceSize !== 'mobile' && (
                <TagGroup
                  values={qualifications}
                  list={lists.qualification}
                  styles={ELIGIBILITY_OPTIONS.ICON_MD}
                  size={tagSize}
                  diff
                />
              )}
            </div>
          </div>
          {deviceSize === 'mobile' && (
            <TagGroup
              values={qualifications}
              list={lists.qualification}
              styles={ELIGIBILITY_OPTIONS.ICON_MD}
              size={tagSize}
              diff
            />
          )}
          <InvestorInformation
            {...{
              tagSize,
              deviceSize,
              lists,
              firmAum,
              averageInvestment,
              industryFocuses,
              preferredAssetTypes,
              hedgeFundStrategies,
              ventureCapitalStageFocuses,
            }}
          />
        </div>
        {/*
        {!isPipeline && !isAccessRequested &&
          <InviteToDealRoom
            {...{ fundId, userId, fullName, fundName }}
          />
        }
        {isPipelined &&
          <ContactInformation
            {...{
              email,
              fullName,
              firmName,
              phoneNumber,
              linkedIn,
              investorType,
            }}
          />
        }
        */}
        {isPendingPipelined && !isAccessRequested && <WaitingConfirmation />}
        {isPendingPipelined && isAccessRequested && (
          <RequestToFund {...{ fundId, userId }} />
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <MediaQuery maxWidth={MOBILE.right}>
          {this.renderCardInvestor({
            avatarSize: 'sm',
            tagSize: 'xs',
            deviceSize: 'mobile',
          })}
        </MediaQuery>
        <MediaQuery minWidth={TABLET.left} maxWidth={TABLET.right}>
          {this.renderCardInvestor({ tagSize: 'xs', deviceSize: 'tablet' })}
        </MediaQuery>
        <MediaQuery minWidth={DESKTOP.left} maxWidth={DESKTOP.right}>
          {this.renderCardInvestor({ tagSize: 'xs', deviceSize: 'desktop' })}
        </MediaQuery>
        <MediaQuery minWidth={DESKTOP_LARGE.left}>
          {this.renderCardInvestor({ tagSize: 'sm', deviceSize: 'desktop-hd' })}
        </MediaQuery>
      </div>
    );
  }
}

export default readData({
  listNames: [
    'preferredAssetType',
    'industryFocus',
    'hedgeFundStrategy',
    'ventureCapitalStageFocus',
    'investorType',
    'firmAum',
    'averageInvestment',
    'qualification',
    'waterMark',
    'pipelineStage',
  ].join(' '),
})(AmInvestorCardComponent);

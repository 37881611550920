// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';
import moment from 'moment';

// dm
import Checkable from '../../atoms/Checkable';
import AvatarImage from '../../atoms/Images/AvatarImage';
import Button from '../../atoms/Button';

// own
import './Message.scss';

export default class Message extends Component {
  static propTypes = {
    name: PropTypes.string,
    avatarUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    createdAt: PropTypes.number,
    text: PropTypes.string,
    type: PropTypes.oneOf('inbox note'.w()),
  };

  render() {
    const {
      className,
      createdAt,
      children,
      type,
      deleteNoteMessage,
      showUpdateForm,
      id: messageId,
      isRead,
      user,
      author,
    } = this.props;

    const { avatar, role, name, firstName, lastName } = author;
    const authorName = name || `${firstName} ${lastName}`;
    const authorId = author.id || author.userId;
    const isNewMessage = author.id !== user.id && !isRead && type === 'inbox';
    const fullClassName = classNames(className, 'message', `message--${type}`, {
      'message--new': isNewMessage,
    });
    return (
      <div className={fullClassName}>
        <div className="message__container">
          {type === 'note' && (
            <Field name={`notesChecked.${messageId}`} component={Checkable} />
          )}
          <div className="message__content">
            <div className="message__header">
              <AvatarImage src={avatar} size="xs" />
              <div className="message__author-info">
                <div className="message__author-info__name">
                  {authorName}
                  {type === 'note' && role && (
                    <span className="message__author-info__type">({role})</span>
                  )}
                </div>
                <div className="message__meta">
                  <div className="message__date">
                    {createdAt &&
                      moment.unix(createdAt).format('MM/DD/YYYY hh:mm a[.]')}
                  </div>
                  {isNewMessage && (
                    <div className="message__new-message">
                      <FormattedMessage id="New message" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="message__text">{children}</div>
            {type === 'note' && authorId === user.id && (
              <div className="message__controls">
                <Button
                  size="sm"
                  icon="edit-v3"
                  square
                  flat
                  border="none"
                  onClick={showUpdateForm.bind(null, messageId, children)}
                />
                <Button
                  size="sm"
                  icon="delete"
                  square
                  flat
                  border="none"
                  onClick={deleteNoteMessage.bind(null, [`${messageId}`])}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { createPortal } from 'react-dom';

import s from './modal.module.scss';

const ModalContent = ({
  onClose,
  role = 'dialog',
  ariaLabel,
  content,
  onKeyDown,
  modalRef,
  onClickAway,
}) => {
  return createPortal(
    <aside
      aria-modal="true"
      className={s.modal_cover}
      tabIndex="-1"
      aria-label={ariaLabel}
      role={role}
      onKeyDown={onKeyDown}
      onClick={onClickAway}
    >
      <div className={s.modal} ref={modalRef}>
        <button
          className={s.modal__close}
          aria-label="Close Modal"
          onClick={onClose}
          aria-labelledby="close-modal"
        >
          <span id="close-modal" className={s.hide_visually}>
            Close Modal
          </span>
          <svg className={s.modal__close_icon} viewBox="0 0 40 40">
            <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
          </svg>
        </button>
        <div className="c-modal__body">{content}</div>
      </div>
    </aside>,
    document.body,
  );
};

export default ModalContent;

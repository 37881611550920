import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

/* ui components */
import Icon from '../icon';

/* local (Input) styles */
import './input.styles.scss';

const Input = ({
  className,
  icon,
  danger,
  success,
  message,
  handleIconClick,
  intl,
  ...props
}) => {
  const getClass = cx(
    'ui-kit-input',
    className && className,
    danger && 'danger',
    success && 'success',
  );
  const placeholder =
    props.placeholder && intl.formatMessage({ id: props.placeholder });
  return (
    <div className={getClass}>
      <input {...props} placeholder={placeholder} />
      {icon && (
        <span className="input-icon" onClick={handleIconClick}>
          <Icon name={icon} size="24" />
        </span>
      )}
      {(danger || success) && message && (
        <span
          className="message"
          children={intl.formatMessage({ id: message })}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  icon: PropTypes.string,
  danger: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  handleIconClick: PropTypes.func,
  intl: intlShape.isRequired,
};

Input.defaultProps = {
  danger: false,
  success: false,
};

export default injectIntl(Input);

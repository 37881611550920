// vendor
import React from 'react';

// dm
import ModalBody from '../../../Modal/ModalBody';
import HeaderNavLink from '../../../../molecules/HeaderNavLink';
import { Button } from '../../../../dm-public/ui-kit';

// own
import './GuestNavModal.scss';
import NavModal from '../index';

export default props => (
  <NavModal className="guest-nav-modal" {...props}>
    <ModalBody className="nav-modal__body guest-nav-modal__body">
      <div className="nav-modal__control-group">
        <Button href="/log-in" children="login" size="s" secondary />
        <Button href="/registration" children="register" size="s" />
      </div>
      <nav className="nav-modal__list guest-nav-modal__list">
        <HeaderNavLink
          to="institutionalServices"
          text="Institutional Services"
          tagNew
        />
        <HeaderNavLink
          href="https://blog.darcmatter.com"
          target="_blank"
          text="Resource Center"
        />
        <HeaderNavLink to="browseInvestments" text="browse_investments" />
        <HeaderNavLink to="forInvestors" text="for_investors" />
        <HeaderNavLink to="forAssetManager" text="for_asset_managers" />
      </nav>
    </ModalBody>
  </NavModal>
);

// vendor
import React, { Component } from 'react';
import classNames from 'classnames';

// dm
import DMLogo from '../../atoms/DMLogo';

// own
import './Preloader.scss';

export default class PreloaderView extends Component {
  render() {
    const { className, receivedTranslations } = this.props;
    const fullClassName = classNames('preloader-view', className, {
      'preloader-view--hide': receivedTranslations,
    });
    return (
      <div className={fullClassName}>
        <DMLogo colorOverride="#ffffff" height="59px" width="200px" />
        <div className="preloader-view__line" />
      </div>
    );
  }
}

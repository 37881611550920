// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// dm
import Button from '../../../../../atoms/Button';
import Modal from '../../../../Modal/Modal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalControls from '../../../../Modal/ModalControls';
import { deleteFundAdmin } from '../../../../../../store/actions/fund';

class ManagePermissionsConfirmDeletionModal extends Component {
  render() {
    const { submitting, confirmClick, ...props } = this.props;
    return (
      <Modal className="modal--notification" {...this.props}>
        <ModalBody>
          <div>
            <FormattedMessage id="modal.manage_confirm_deletion.message_1" />
            <strong> {props.name} </strong>
            <FormattedMessage id="modal.manage_confirm_deletion.message_2" />
            <strong>
              {' '}
              <FormattedMessage id="modal.manage_confirm_deletion.message_3" />
            </strong>
          </div>
          <ModalControls>
            <Button
              color="green"
              frozen
              onClick={confirmClick(props.fundId, props.userId)}
              disabled={submitting}
              text={submitting ? 'Submitting ...' : 'Yes'}
            />
            <Button color="red-s" frozen onClick={props.close} text="No" />
          </ModalControls>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(
  null,
  (dispatch, { close }) => ({
    confirmClick: (fundId, userId) => () => {
      dispatch(deleteFundAdmin(fundId, userId));
      close();
    },
  }),
)(ManagePermissionsConfirmDeletionModal);

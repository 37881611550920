export default {
  INVITE_REQUESTED: 1,
  INVITE_ACCEPTED: 2,
  INVITE_DECLINED: 3,
  ACCESS_REQUESTED: 4,
  ACCESS_ACCEPTED: 5,
  ACCESS_DECLINED: 6,
  DOCUMENTS_REQUESTED: 20,
  DOCUMENTS_ACCEPTED: 21,
  SOME_DOCUMENTS_ACCEPTED: 100,
  DOCUMENTS_DECLINED: 22,
};

import React from 'react';
import cx from 'classnames';

// import './tabs.styles'

const TabContent = ({ className, children }) => (
  <div className={cx('tabContent', className)}>{children}</div>
);

export default TabContent;

// vendor
import React from 'react';
import { connect } from 'react-redux';
// dm
import Button from '../../atoms/Button';
import { declineFundPipeline } from '../../../store/actions/fund';
import responsive from '../../../enhancers/responsive';

const InviteDeclineButton = ({ breakpoint, ...props }) => (
  <Button
    size="sm"
    icon={breakpoint !== 'mobile' ? 'label-error' : ''}
    iconPos="left"
    color="red-s"
    text="Decline"
    {...props}
  />
);

export default connect(
  state => ({
    investorId: state.get('user').get('id'),
  }),
  null,
  ({ investorId }, { dispatch }, { actionProps, ...props }) => ({
    ...props,
    onClick: () =>
      dispatch(declineFundPipeline({ ...actionProps, investorId })),
  }),
)(
  responsive({
    breakpoints: ['mobile', 'tablet'],
  })(InviteDeclineButton),
);

// vendor
import { formValueSelector } from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { fundTypes, inboxTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'inbox';

/* initial state */
const initialState = fromJS({
  values: {
    messages: [],
    notes: [],
    isInboxOpen: false,
  },
});

/* selectors */
export const selector = formValueSelector(form);

/* reducer */
export default createReducer(initialState, {
  [inboxTypes.READ_MESSAGES_SUCCESS]: (state, { response }) =>
    state.setIn('values.messages'.p(), fromJS(response.data)),
  [inboxTypes.CLEAR_MESSAGES]: state =>
    state.setIn('values.messages'.p(), fromJS([])),
  [inboxTypes.OPEN_INBOX]: state => state.setIn('values.isInboxOpen'.p(), true),
  [inboxTypes.CLOSE_INBOX]: state =>
    state.setIn('values.isInboxOpen'.p(), false),
  [inboxTypes.SEND_MESSAGE_REQUEST]: (state, { extraData }) =>
    state.updateIn('values.messages'.p(), messages =>
      messages.push(fromJS({ ...extraData, isRead: true })),
    ),
  [inboxTypes.SEND_MESSAGE_SUCCESS]: (state, { extraData, response }) =>
    state.updateIn('values.messages'.p(), messages =>
      messages.mergeIn(
        [messages.findKey(message => message.get('id') === extraData.id)],
        fromJS(response.data),
      ),
    ),
  [inboxTypes.MARK_MESSAGE_AS_READ_SUCCESS]: (state, { config }) =>
    state.updateIn('values.messages'.p(), messages =>
      messages.mergeIn(
        [
          messages.findKey(
            message => message.get('id') === config.url.match(/[^/]+$/)[0],
          ),
        ],
        fromJS({ isRead: true }),
      ),
    ),
  [fundTypes.READ_ALL_NOTES_SUCCESS]: (state, { response }) =>
    state.setIn('values.notes'.p(), fromJS(response.data)),
  [fundTypes.CREATE_NOTE_SUCCESS]: (state, { response }) =>
    state.updateIn('values.notes'.p(), messages =>
      messages.push(fromJS(response.data)),
    ),
  [fundTypes.UPDATE_NOTES_SUCCESS]: (state, { response }) =>
    state.updateIn('values.notes'.p(), notes =>
      notes.mergeIn(
        [notes.findKey(note => note.get('id') === response.data.id)],
        response.data,
      ),
    ),
  [fundTypes.DELETE_NOTE_SUCCESS]: (state, { extraData }) =>
    state.updateIn('values.notes'.p(), notes =>
      notes.filter(note => extraData.indexOf(`${note.get('id')}`) < 0),
    ),
});

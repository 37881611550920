// vendor
import React, { Component } from 'react';
import classNames from 'classnames';

// dm
import Header from '../Header';

// own
import './StickyHeader.scss';

export default class StickyHeader extends Component {
  state = {
    position: 'absolute',
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    if (event.target.body) {
      const SCROLL_TOP_FOR_FIXED = 16;
      const scrollTop =
        event.target.body.scrollTop || event.target.documentElement.scrollTop;
      if (scrollTop >= SCROLL_TOP_FOR_FIXED) {
        this.setState({ position: 'fixed' });
      } else {
        this.setState({ position: 'absolute' });
      }
    }
  };

  render() {
    const { className, ...props } = this.props;
    const { position } = this.state;
    const fullClassName = classNames(className, 'sticky-header');
    return <Header className={fullClassName} position={position} {...props} />;
  }
}

// vendor
import React, { Component } from 'react';
import classNames from 'classnames';

// own
import './AvatarImage.scss';
import Image from './Image';
import avatar from '../../../images/svg/avatar_default.svg';

export default class AvatarImage extends Component {
  render() {
    const { className, ...props } = this.props;
    const fullClassName = classNames(className, 'avatar-image');
    return <Image {...props} className={fullClassName} defaultSrc={avatar} />;
  }
}

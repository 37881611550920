// vendor
import { formValueSelector, getFormValues } from 'redux-form/immutable';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { find, filter, some, isEmpty } from 'lodash';
// dm
import { fundTypes } from '../../actions';
import { currentUserIdSelector } from '../user';
import { createReducer } from '../../../utils/core';
import { filterCollection } from '../../../utils/filter';
import { ADMIN_ROLES } from '../../../constants/fund';

/* form name */
export const form = 'fundManage';

/* initial state */
const initialState = fromJS({
  values: {
    viewCount: {}, // overview
    investorsReal: [], // browse
    investors: [], // browse
    investor: {}, // browse
    pipelinesReal: [], // pipeline
    pipelines: [], // pipeline
    documentsAllowed: [], // pipeline
    documentsUnallowed: [], // pipeline
    owner: {}, // permissions
    approvedAdmins: [], // permissions
    pendingAdmins: [], // permissions
    maybeAdmin: null, // permissions
    // contacts
    // contactsReal: [],
    // contacts: [],
    // newContact: null,
    // search: {},
  },
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);
export const ownerSelector = state => selector(state, 'owner').toJS();
export const isCurrentUserOwnerSelector = createSelector(
  currentUserIdSelector,
  ownerSelector,
  (currentUserId, owner) => !isEmpty(owner) && currentUserId === owner.user.id,
);

const MAX_ROWS = 300; // ???

/* reducer */
export default createReducer(initialState, {
  [fundTypes.CLEAR_FUND_INVESTOR]: state =>
    state.setIn('values.investor'.p(), fromJS({})),
  [fundTypes.READ_FUND_INVESTOR_SUCCESS]: (state, { response: { data } }) =>
    state.setIn(
      'values.investor'.p(),
      fromJS({
        ...data,
        isPipeline: data.isPendingPipelined || data.isPipelined,
      }),
    ),
  [fundTypes.READ_FUND_INVESTOR_DOCUMENTS_SUCCESS]: (
    state,
    { response: { data } },
  ) =>
    state
      .setIn(
        'values.documentsAllowed'.p(),
        fromJS(filter(data, { allowed: true })),
      )
      .setIn(
        'values.documentsUnallowed'.p(),
        fromJS(filter(data, { allowed: false })),
      ),
  [fundTypes.CREATE_FUND_ADMIN_SUCCESS]: (state, { response }) =>
    state.updateIn('values.pendingAdmins'.p(), pendingAdmins => {
      const userIsPending = pendingAdmins.find(
        user => user.userId === response.data.userId,
      );
      if (userIsPending) return pendingAdmins;
      return pendingAdmins.concat(response.data);
    }),
  [fundTypes.READ_FUND_ADMINS_SUCCESS]: (state, { response: { data } }) =>
    state
      .setIn(
        'values.owner'.p(),
        fromJS(find(data, { role: ADMIN_ROLES.OWNER })),
      )
      .setIn(
        'values.approvedAdmins'.p(),
        filter(data, { role: ADMIN_ROLES.ADMINISTRATOR }),
      )
      .setIn(
        'values.pendingAdmins'.p(),
        filter(data, { role: ADMIN_ROLES.PENDING }),
      ),
  [fundTypes.DELETE_FUND_ADMIN_SUCCESS]: (state, { config }) =>
    state
      .updateIn('values.approvedAdmins'.p(), approvedAdmins =>
        filter(
          approvedAdmins,
          approvedAdmin =>
            approvedAdmin.userId !== Number(config.url.match(/[^/]+$/)[0]),
        ),
      )
      .updateIn('values.pendingAdmins'.p(), pendingAdmins =>
        filter(
          pendingAdmins,
          pendingAdmin =>
            pendingAdmin.userId !== Number(config.url.match(/[^/]+$/)[0]),
        ),
      ),
  [fundTypes.CHECK_FUND_COLLECTION]: (state, { name, checked }) =>
    state.updateIn(`values.${name}`.p(), collection =>
      collection.map(item => item.set('checked', checked)),
    ),
  [fundTypes.FILTER_FUND_COLLECTION]: (state, { name, filters }) =>
    state.setIn(
      `values.${name}`.p(),
      fromJS(
        filterCollection(state.getIn(`values.${name}Real`.p()), filters).slice(
          0,
          MAX_ROWS,
        ),
      ),
    ),
  [fundTypes.READ_FUND_VIEW_COUNT_SUCCESS]: (state, { response }) =>
    state.setIn('values.viewCount'.p(), fromJS(response.data)),
  [fundTypes.CLEAR_FUND_VIEW_COUNT]: state =>
    state.setIn('values.viewCount'.p(), fromJS({})),
  [fundTypes.CLEAR_MAYBE_ADMIN]: state =>
    state.setIn('values.maybeAdmin'.p(), fromJS(null)),
  [fundTypes.READ_FUND_INVESTORS_SUCCESS]: (state, { response }) => {
    const investors = response.data.map(investor => ({
      ...investor,
      isPipeline: investor.isPendingPipelined || investor.isPipelined,
    }));
    return state
      .setIn('values.investorsReal'.p(), fromJS(investors))
      .setIn('values.investors'.p(), fromJS(investors.slice(0, MAX_ROWS)));
  },
  [fundTypes.FIND_FUND_MAYBE_ADMIN_REQUEST]: state =>
    state.setIn('values.maybeAdmin'.p(), fromJS({ value: 'spinner' })),
  [fundTypes.FIND_FUND_MAYBE_ADMIN_SUCCESS]: (state, { response: { data } }) =>
    state.setIn(
      'values.maybeAdmin'.p(),
      fromJS({
        value: data.id,
        label: `${data.firstName} ${data.lastName}`,
        avatarUrl: data.avatarUrl,
      }),
    ),
  [fundTypes.FIND_FUND_MAYBE_ADMIN_FAILURE]: state =>
    state.setIn('values.maybeAdmin'.p(), fromJS({ value: 'not-found' })),
  [fundTypes.CREATE_FUND_PIPELINE_SUCCESS]: (state, { extraData }) =>
    state
      .updateIn('values.investors'.p(), investors =>
        investors.mergeIn(
          [
            investors.findKey(
              investor => investor.get('userId') === extraData.investorId,
            ),
          ],
          { isPendingPipelined: true, isPipeline: true },
        ),
      )
      .mergeIn('values.investor'.p(), {
        isPendingPipelined: true,
        isPipeline: true,
      }),
  [fundTypes.DECLINE_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values.investor'.p(), {
      stageId: response.data.statusId,
      isPendingPipelined: false,
      isPipelined: false,
      isPipeline: false,
    }),
  [fundTypes.DELETE_FUND_PIPELINE_SUCCESS]: (state, { extraData }) =>
    state.updateIn('values.pipelinesReal'.p(), pipelines =>
      pipelines.filterNot(
        pipeline => extraData.userId === pipeline.get('userId'),
      ),
    ),
  [fundTypes.READ_FUND_PIPELINES_SUCCESS]: (state, { response }) =>
    state
      .setIn('values.pipelinesReal'.p(), fromJS(response.data))
      .setIn('values.pipelines'.p(), fromJS(response.data.slice(0, MAX_ROWS))),
  [fundTypes.ACCEPT_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values.investor'.p(), {
      stageId: response.data.statusId,
      isPendingPipelined: false,
      isPipelined: true,
    }),
  [fundTypes.ALLOW_PIPELINE_DOCUMENTS_SUCCESS]: (state, { requestData }) => {
    let allowedDocuments = fromJS([]);
    return state
      .updateIn('values.documentsUnallowed'.p(), documents =>
        documents.filterNot(document => {
          const isAllowed = some(
            requestData.documents,
            id => id === document.get('id'),
          );
          if (isAllowed)
            allowedDocuments = allowedDocuments.push(
              document.merge({ allowed: true, checked: false }),
            );
          return isAllowed;
        }),
      )
      .updateIn('values.documentsAllowed'.p(), documents =>
        documents.concat(allowedDocuments),
      );
  },
  // [fundTypes.DELETE_FUND_ADMIN_SUCCESS]: (state, { xhrConfig }) => state.updateIn('values.approvedAdmins'.p(), approvedAdmins => filter(approvedAdmins, approvedAdmin => approvedAdmin.userId !== xhrConfig.url.match(/[^/]+$/)[0])),
  // [fundTypes.READ_FUND_CONTACTS_SUCCESS]: (state, { response }) => state.setIn('values.contactsReal'.p(), fromJS(response.data)).setIn('values.contacts'.p(), fromJS(response.data.slice(0, MAX_ROWS))),
  // [fundTypes.DELETE_FUND_CONTACTS_SUCCESS]: (state, { extraData }) => state.updateIn('values.contactsReal'.p(), contacts => contacts.filterNot(contact => extraData.contactIds.includes(contact.get('id')))),
  // [fundTypes.SET_FUND_NEW_CONTACT]: (state, { value }) => state.setIn('values.newContact'.p(), fromJS(value)),
  // [fundTypes.UPLOAD_FUND_CONTACTS_CSV_SUCCESS]: (state, { response }) => state.updateIn('values.contactsReal'.p(), contacts => fromJS(response.data).concat(contacts)),
  // [fundTypes.CREATE_FUND_CONTACT_SUCCESS]: (state, { response }) => state.updateIn('values.contactsReal'.p(), contacts => contacts.unshift(fromJS(response.data))),
});

// vendor
import { fromJS } from 'immutable';
// dm
import { fundTypes } from '../actions';
import { createReducer } from '../../utils/core';

/* initial state */
const initialState = fromJS({
  isRequesting: null,
  isRequested: null,
  data: [],
  errors: null,
  firstLoadingFunds: null,
});

/* reducer */
export default createReducer(initialState, {
  [fundTypes.READ_FUNDS_REQUEST]: state => state.merge({ isRequesting: true }),
  [fundTypes.READ_FUNDS_SUCCESS]: (state, { response }) =>
    state.merge({ data: response.data }),
  [fundTypes.READ_FUNDS_FAILURE]: (state, error) => state.merge({ error }),
  [fundTypes.READ_FUNDS_PUBLISHED_REQUEST]: state =>
    state.merge({ isRequesting: true }),
  [fundTypes.READ_FUNDS_PUBLISHED_SUCCESS]: (state, { response }) => {
    const imageSize = 'size=130x130';
    response.data.forEach(fund => {
      fund.logo.url = `${fund.logo.url}?${imageSize}`;
    });
    return state.merge({
      isRequested: true,
      data: response.data,
      firstLoadingFunds: true,
    });
  },
  [fundTypes.READ_FUNDS_PUBLISHED_FAILURE]: (state, error) =>
    state.merge({ error }),
  [fundTypes.CREATE_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.update('data', funds => {
      const { fundId, fund: currentFund } = response.data;
      const index = funds.findKey(fund => fund.get('id') === fundId);
      return funds.mergeIn([index], currentFund);
    }),
  [fundTypes.ACCEPT_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.update('data', funds => {
      const { fundId, fund: currentFund } = response.data;
      const index = funds.findKey(fund => fund.get('id') === fundId);
      return funds.mergeIn([index], currentFund);
    }),
  [fundTypes.DECLINE_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.update('data', funds => {
      const { fundId, fund: currentFund } = response.data;
      const index = funds.findKey(fund => fund.get('id') === fundId);
      return funds.mergeIn([index], currentFund);
    }),
  [fundTypes.SET_FAVORITE_FUND_SUCCESS]: (state, { response }) =>
    state.update('data', funds => {
      const { id, isFavorite } = response.data;
      const index = funds.findKey(fund => fund.get('id') === id);
      return funds.mergeIn([index], { isFavorite });
    }),
});

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { join, keys } from 'lodash';

// dm
import LogoImage from '../../../atoms/Images/LogoImage';
import Button from '../../../atoms/Button';
import Dropzone from '../Dropzone';
import { IMAGE } from '../../../../constants/files';

// own
import './Logo.scss';

export default class LogoComponent extends Component {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
    onLoad: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'Logo',
  };

  constructor(props) {
    super(props);
    this.state = {
      src: props.input.value ? props.input.value.toJS().url : '',
    };
  }

  componentWillReceiveProps({ input: { value } }) {
    if (value && !value.equals(this.props.input.value)) {
      this.setState({ src: value.toJS().url });
    }
  }

  handleOpen = () => {
    this.refs.dropzone.open();
  };

  handleLoad = (onLoad, name) => logo => {
    this.setState({ src: logo.preview });
    return onLoad(logo, name);
  };

  render() {
    const {
      props: {
        className,
        input: { name },
        onLoad,
        placeholder,
        meta,
      },
      state: { src },
    } = this;
    const { error, touched } = meta || false;
    const showFundFormErrors = this.props.showFundFormErrors || false;
    const fullClassName = classNames(className, 'logo', {
      'logo--error': error && (touched || showFundFormErrors),
    });
    return (
      <Dropzone
        ref="dropzone"
        className={fullClassName}
        activeClassName="logo--active"
        types={IMAGE.TYPES}
        maxSize={IMAGE.SIZE_MAX}
        disableClick
        onLoad={this.handleLoad(onLoad, name)}
        {...{ meta }}
      >
        <LogoImage src={src}>
          <div className="logo__placeholder">
            <FormattedMessage id={placeholder} />
          </div>
        </LogoImage>
        <div className="logo__controls">
          <Button flat onClick={this.handleOpen}>
            <FormattedMessage id={src ? 'Change Logo' : 'Upload Logo'} />
          </Button>
          <div className="logo__extensions">
            <FormattedMessage id="Supported file types:" />
            {` ${join(keys(IMAGE.TYPES), ', ')}`}
          </div>
        </div>
      </Dropzone>
    );
  }
}

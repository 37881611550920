// vendor
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
// own
import app from './app';
import list from './list';
import intl from './intl';
import modal from './modal';
import form from './form';
import user from './user';
import activityFeeds from './activity-feeds';
import funds from './funds';
import investments from './investments';

/* reducer */
const rootReducer = history => (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = state.remove('list');
    state = state.remove('modal');
    state = state.remove('form');
    state = state.remove('user');
    state = state.remove('activityFeeds');
    state = state.remove('funds');
    state = state.remove('investments');
  }
  return allReducers(history)(state, action);
};

const allReducers = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    list,
    intl,
    modal,
    form,
    user,
    activityFeeds,
    funds,
    investments,
  });

export default rootReducer;

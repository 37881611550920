// vendor
import React, { Component } from 'react';

// dm
import NotesNewMessage from '../../organisms/Notes/NotesNewMessage';

// own
import './NotesMessage.scss';
import Message from './Message';

export default class NotesMessageComponent extends Component {
  render() {
    const {
      notes,
      deleteNoteMessage,
      showUpdateForm,
      updateMsg,
      user,
      updater,
    } = this.props.fieldProps;
    return (
      <div className="notes__message-items">
        {notes.map(message => {
          if (updater === message.id) {
            return (
              <NotesNewMessage
                user={user}
                role={message.author.role}
                approveFunc={updateMsg.bind(null, message.id)}
                cancelFunc={showUpdateForm.bind(null, null)}
                text="Edit"
                key={message.id}
              />
            );
          }
          return (
            <Message
              type="note"
              {...message}
              {...{ user, showUpdateForm, deleteNoteMessage }}
              key={message.id}
            >
              {message.text}
            </Message>
          );
        })}
      </div>
    );
  }
}

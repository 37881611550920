// vendor
import React from 'react';
import Loadable from 'react-loadable';
import classNames from 'classnames';

// dm
import BaseTemplate from '../Base';
import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';
import InvestorRoute from '../../../enhancers/InvestorRoute';
import AssetManagerRoute from '../../../enhancers/AssetManagerRoute';

// own
import './RegularTemplate.scss';

const getCSSClassName = pathname => {
  if (pathname === '/profile') {
    return 'profile-view regular-template regular-template--old-container';
  } else if (pathname.includes('know-your-customer')) {
    return 'kyc regular-template regular-template--old-container';
  } else if (pathname.includes('inv-dashboard')) {
    return 'inv-dashboard';
  } else if (pathname.includes('/investments/')) {
    return 'investments';
  } else if (pathname === '/am-dashboard') {
    return 'am-dashboard-view regular-template regular-template--old-container';
  } else if (
    pathname.includes('/am-dashboard') &&
    (pathname.includes('/edit') || pathname.includes('/new'))
  ) {
    return 'regular-template regular-template--old-container';
  } else if (pathname.includes('/manage')) {
    return 'fund-manage';
  }
  return '';
};

const InvDashboardAside = Loadable({
  loader: () =>
    import(/* webpackChunkName: "InvDashboardAside" */ '../../../views/InvDashboard/InvDashboardAside'),
  loading: () => null,
  modules: ['InvDashboardAside'],
});
const InvDashboardDealRoomInbox = Loadable({
  loader: () =>
    import(/* webpackChunkName: "InvDashboardDealRoomInbox" */ '../../../views/InvDashboard/DealRooms/InvDashboardDealRoomInbox'),
  loading: () => null,
  modules: ['InvDashboardDealRoomInbox'],
});
const FundManageAside = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundManageAside" */ '../../../views/Funds/Manage/FundManageAside'),
  loading: () => null,
  modules: ['FundManageAside'],
});
const FundManageInbox = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundManageInbox" */ '../../../views/Funds/Manage/FundManageInbox'),
  loading: () => null,
  modules: ['FundManageInbox'],
});

const RegularTemplate = props => {
  const {
    location: { pathname },
    children,
    breakpoint,
  } = props;
  const isInvDashboard = pathname.includes('inv-dashboard');
  const isFundManage = pathname.includes('/manage');
  const fullClassName = classNames(
    getCSSClassName(pathname),
    isInvDashboard || isFundManage ? 'dashboard-template' : '',
  );
  return (
    <BaseTemplate className={fullClassName}>
      <Header
        pathname={pathname}
        breakpoint={breakpoint}
        withAside={isInvDashboard || isFundManage}
        info={isFundManage}
      />
      <InvestorRoute path="/inv-dashboard" component={InvDashboardAside} />
      <AssetManagerRoute
        path="/am-dashboard/funds/:id/manage"
        component={FundManageAside}
      />
      <main>{children}</main>
      <InvestorRoute
        path="/inv-dashboard/deal-rooms/:id"
        component={InvDashboardDealRoomInbox}
      />
      <AssetManagerRoute
        path="/am-dashboard/funds/:id/manage/pipelines/:userId"
        component={FundManageInbox}
      />
      {!isInvDashboard && !isFundManage && <Footer />}
    </BaseTemplate>
  );
};

export default RegularTemplate;

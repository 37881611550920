// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// dm
import Button from '../../../../../atoms/Button';
import Modal from '../../../../Modal/Modal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalControls from '../../../../Modal/ModalControls';
import { changeFundOwner } from '../../../../../../store/actions/fund';

class ManagePermissionsChangeOwnerModal extends Component {
  render() {
    const { submitting, handleChangeFundOwner, ...props } = this.props;
    return (
      <Modal className="modal--notification" {...this.props}>
        <ModalBody>
          <FormattedMessage id="modal.manage.permissions.message" />
          <ModalControls>
            <Button
              color="green"
              icon="checked-lg"
              frozen
              onClick={handleChangeFundOwner(props.fundId, props.userId)}
              disabled={submitting}
              text={submitting ? 'Submitting ...' : 'Confirm'}
            />
            <Button
              color="red-s"
              frozen
              icon="label-error"
              onClick={props.close}
              text="Cancel"
            />
          </ModalControls>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(
  null,
  (dispatch, { close }) => ({
    handleChangeFundOwner: (fundId, userId) => () => {
      dispatch(changeFundOwner(fundId, { userId })).then(close);
    },
  }),
)(ManagePermissionsChangeOwnerModal);

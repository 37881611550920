import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';

import ReadyToInvestButton from '../../../molecules/ActionButtons/ReadyToInvestButton';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/ModalHeader';
import ModalBody from '../../Modal/ModalBody';
import ModalFooter from '../../Modal/ModalFooter';
import Form from '../../../atoms/Form';
import Input from '../../../atoms/Input';
import CheckableGroup from '../../../molecules/CheckableGroup';
import FormGroup from '../../../molecules/FormGroup';

import { toJS } from '../../../../utils/core';
import { formatInteger } from '../../../../utils/form/format';
import { parseInteger } from '../../../../utils/form/parse';

import './ReadyInvestAmountModal.scss';

const form = 'readyInvestAmountModal';
const selector = formValueSelector(form);

const ReadyInvestAmountModal = props => {
  const {
    invalid,
    submitting,
    selectPotentialInvest,
    pipelinedAmount,
    userName,
    fundId,
    fundName,
    amountInput,
  } = props;
  return (
    <Modal className="ready-invest-amount-modal" {...props}>
      <Form>
        <ModalHeader title="modal.potential_investment_amount.title" />
        <ModalBody>
          <CheckableGroup
            name="selectPotentialInvest"
            value={Number(selectPotentialInvest)}
            options={[
              {
                value: 0,
                intlValues: { amount: <strong>${pipelinedAmount}</strong> },
                label: 'modal.ready_invest_amount.option-1',
              },
              { value: 1, label: 'modal.ready_invest_amount.option-2' },
            ]}
            multi={false}
            dots
            required
          />
          <div className="ready-invest-amount-modal__container">
            <FormGroup type="col" required>
              <Field
                name="amountInput"
                component={Input}
                format={formatInteger}
                parse={parseInteger}
                disabled={selectPotentialInvest === '0'}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <ReadyToInvestButton
            size="md"
            text="Access"
            disabled={invalid || submitting}
            userName={userName}
            actionProps={{
              fundId,
              fundName,
              amountInput,
              selectPotentialInvest,
            }}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = values => {
  values = toJS(values);
  const errors = {};
  if (values.selectPotentialInvest === '1' && !values.amountInput) {
    errors.amountInput = 'Please indicate potential investment amount.';
  }
  return errors;
};

export default connect(state => ({
  selectPotentialInvest: selector(state, 'selectPotentialInvest'),
  amountInput: selector(state, 'amountInput'),
}))(
  reduxForm({
    form,
    validate,
    initialValues: {
      selectPotentialInvest: '0',
    },
  })(ReadyInvestAmountModal),
);

// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

// dm
import AvatarImage from '../../../atoms/Images/AvatarImage';
import { TABLET } from '../../../../constants/breakpoints';

// own
import './AssetManagers.scss';
import Carousel from '../Carousel';

const TestimonialsCarouselComponent = ({ teamMembers, className }) => {
  const renderBoxes = teamMembers => {
    return teamMembers.map((member, index) => {
      const src = member.photo ? member.photo.url : '';
      const title = member.photo ? member.photo.title : '';
      return (
        <div className="asset-managers" key={index}>
          <div className="asset-managers__person">
            <AvatarImage src={src} size="md" title={title} />
            <div className="asset-managers__info">
              <div className="asset-managers__name">{member.name}</div>
              <div className="asset-managers__position">{member.position}</div>
            </div>
          </div>
          <div className="asset-managers__text">
            <p className="asset-managers__text__title">
              <FormattedMessage id="Bio" />:
            </p>
            <span>{member.bio}</span>
          </div>
        </div>
      );
    });
  };

  const slides = teamMembers.length;
  const hasMultiple = slides >= 2;
  const slidesToShow = slides < 3 ? 1 : 3;
  const classNames = cx('asset-managers-carousel', className);

  const assetsSettings = {
    autoplay: false,
    autoplaySpeed: 6000,
    className: 'asset-managers-carousel__slick',
    arrows: hasMultiple,
    slidesToShow,
    centerMode: true,
    dots: hasMultiple,
    infinite: hasMultiple,
    speed: 500,
    responsive: [
      {
        breakpoint: TABLET.right,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={classNames}>
      <Carousel {...assetsSettings}>{renderBoxes(teamMembers)}</Carousel>
    </div>
  );
};

export default TestimonialsCarouselComponent;

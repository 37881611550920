// vendor
import { formValueSelector, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { kycVerifyTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'kycVerify';

/* initial state */
const initialState = fromJS({
  values: {
    questions: [],
  },
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);

/* reducer */
export default createReducer(initialState, {
  [kycVerifyTypes.GET_KYC_QUESTIONS_SUCCESS]: (state, { response }) => {
    if (response.data && response.data.length === 0) {
      return state;
    }
    return state.mergeIn('values'.p(), {
      idNumber: response.data.idNumber,
      questions: response.data.questions.map(question => ({
        legend: question.prompt,
        key: question.type,
        options: question.answer.map(label => ({ label, value: label })),
      })),
    });
  },
});

import React, { cloneElement, Children, Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// import './tabs.styles'

class Tabs extends Component {
  static propTypes = {
    active: PropTypes.number,
    secondary: PropTypes.bool,
  };

  static defaultProps = {
    active: 0,
    secondary: false,
  };

  state = {
    active: this.props.active,
  };

  changeActiveTab = activeTab => {
    this.setState({
      active: activeTab,
    });
  };

  render() {
    const { secondary, className, ...props } = this.props;
    const { active } = this.state;
    const childrens = Children.toArray(props.children);
    const classNames = cx('tabs', secondary && 'tabs--secondary', className);
    return (
      <div className={classNames}>
        {childrens.map(children =>
          cloneElement(children, {
            active,
            changeActiveTab: this.changeActiveTab,
          }),
        )}
      </div>
    );
  }
}

export default Tabs;

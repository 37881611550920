export default {
  UPDATE_PENDING_APPROVAL: 9,
  PUBLISHED: 10,
  DRAFT_UPDATE: 11,
  UPDATE_REQUIRES_REVISION: 12,
  DRAFT: 15,
  DRAFT_PENDING_APPROVAL: 16,
  PAYMENT_PENDING: 17,
  DRAFT_REQUIRES_REVISION: 18,
  UNPABLISHED: 19,
};

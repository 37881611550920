// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import moment from 'moment';

// dm
import Select from '../../../../atoms/Select';
import Form from '../../../../atoms/Form';
import Button from '../../../../atoms/Button';
import FormGroup from '../../../../molecules/FormGroup';
import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody';
import ModalHeader from '../../../Modal/ModalHeader';
import ModalFooter from '../../../Modal/ModalFooter';
import { fundSubmitSignature } from '../../../../../store/actions/fund';
import { SIGNATURE_FONTS } from '../../../../../constants/lists';

// own
import './FundWizardTermsModal.scss';

const form = 'fundWizardTermsModal';
const selector = formValueSelector(form);

class FundWizardTermsModal extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {
    showSelect: false,
  };

  onInputChangeSignature = inputValue => {
    if (!inputValue) return inputValue;
    const { signature, change } = this.props;
    signature.name = inputValue;
    change('signature', fromJS(signature));
  };

  scrollDown = () => {
    this.scrollBox.scrollTop = this.scrollBox.scrollHeight;
  };

  toggleSelect = () => {
    this.setState({
      showSelect: !this.state.showSelect,
    });
  };

  renderOptionSignature = ({ value, label }) => {
    return <span style={{ fontFamily: value, fontSize: 20 }}>{label}</span>;
  };

  render() {
    const {
      props: { signature, onSubmit, handleSubmit, submitting },
      state: { showSelect },
    } = this;
    const signatures = signature.name
      ? SIGNATURE_FONTS.map(signatureFont => ({
          value: signatureFont,
          label: signature.name,
        }))
      : [];
    return (
      <Modal className="fund-wizard-modal" {...this.props}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            title="TERMS OF USE"
            className="fund-wizard-modal__title"
          />
          <ModalBody className="fund-wizard-modal__body">
            <div className="fund-wizard-modal__terms">
              <div
                className="terms__text"
                ref={node => (this.scrollBox = node)}
              >
                <FormattedMessage tagName="p" id="terms.subtext1" />
                <FormattedMessage tagName="p" id="terms.subtext2" />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.ownership_copyright" />
                </p>
                <FormattedMessage
                  tagName="p"
                  id="terms.ownership_copyright.text"
                />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.unauthorized_access" />
                </p>
                <FormattedMessage
                  tagName="p"
                  id="terms.unauthorized_access.text"
                />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.passwords_security" />
                </p>
                <FormattedMessage
                  tagName="p"
                  id="terms.passwords_security.text"
                />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.links" />
                </p>
                <FormattedMessage tagName="p" id="terms.links.text" />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.privacy_policy" />
                </p>
                <FormattedMessage tagName="p" id="terms.privacy_policy.text" />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.assistance" />
                </p>
                <FormattedMessage tagName="p" id="terms.assistance.text" />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.no_warranty_or_reliance" />
                </p>
                <FormattedMessage
                  tagName="p"
                  id="terms.no_warranty_or_reliance.text"
                />
                <p className="terms__text__title">
                  <FormattedMessage id="terms.limitation_of_liability" />
                </p>
                <FormattedMessage
                  tagName="p"
                  id="terms.limitation_of_liability.text"
                />
              </div>
              <FormGroup>
                {!showSelect && !signature.name && (
                  <Button
                    frozen
                    icon="edit"
                    iconPos="left"
                    text="Enter Signature"
                    onClick={this.toggleSelect}
                  />
                )}
                {!showSelect && signature.name && (
                  <div className="fund-wizard-modal__terms__change-signature">
                    <span
                      className="fund-wizard-modal__terms__change-signature__name"
                      style={{ fontFamily: signature.font, fontSize: 34 }}
                    >
                      {signature.name}
                    </span>
                    <Button
                      color="blue-g"
                      frozen
                      icon="edit-v3"
                      iconPos="left"
                      text="Change Signature"
                      flat
                      border="none"
                      onClick={this.toggleSelect}
                    />
                  </div>
                )}
                {showSelect && (
                  <div className="fund-wizard-modal__terms__signature">
                    <Field
                      name="signature.font"
                      component={Select}
                      searchable
                      placeholder="Type your signature here"
                      noResultsText="Type your signature here"
                      options={signatures}
                      valueRenderer={this.renderOptionSignature}
                      optionRenderer={this.renderOptionSignature}
                      onOpen={this.scrollDown}
                      onInputChange={this.onInputChangeSignature}
                      filterOptions={options => options}
                      onChangeField={this.toggleSelect}
                    />
                  </div>
                )}
                <span className="fund-wizard-modal__terms__date">
                  <FormattedMessage id="DATE" />
                  :&nbsp;
                  {moment().format('MM/DD/YY')}
                </span>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter className="fund-wizard-modal__footer">
            <Button
              type="submit"
              size="md"
              frozen
              text="Accept"
              disabled={submitting || !signature.font}
            />
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

FundWizardTermsModal = connect(
  state => ({
    signature: (selector(state, 'signature') || fromJS({})).toJS(),
  }),
  (dispatch, ownProps) => ({
    onSubmit: values => {
      dispatch(fundSubmitSignature(values.toJS().signature));
      ownProps.close();
    },
  }),
)(FundWizardTermsModal);

export default reduxForm({ form })(FundWizardTermsModal);

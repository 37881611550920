/* eslint func-names: 0 */
// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';
import { readFundsForInvestments } from './fund';
import { loadInvestmentsDealRoom } from './inv-dashboard';

export const types = createConstants(
  'READ_INVESTOR_INVESTMENT_INTEREST_REQUEST',
  'READ_INVESTOR_INVESTMENT_INTEREST_SUCCESS',
  'READ_INVESTOR_INVESTMENT_INTEREST_FAILURE',

  'READ_INVESTMENTS_REQUEST',
  'READ_INVESTMENTS_SUCCESS',
  'READ_INVESTMENTS_FAILURE',

  'READ_INVESTMENTS_BY_FUND_ID_REQUEST',
  'READ_INVESTMENTS_BY_FUND_ID_SUCCESS',
  'READ_INVESTMENTS_BY_FUND_ID_FAILURE',

  'READ_INVESTMENTS_PIPELINE_REQUEST',
  'READ_INVESTMENTS_PIPELINE_SUCCESS',
  'READ_INVESTMENTS_PIPELINE_FAILURE',

  'READ_INVESTMENT_REQUEST',
  'READ_INVESTMENT_SUCCESS',
  'READ_INVESTMENT_FAILURE',

  'READ_INVESTMENT_DETAILS_REQUEST',
  'READ_INVESTMENT_DETAILS_SUCCESS',
  'READ_INVESTMENT_DETAILS_FAILURE',

  'READ_CURRENT_INVESTMENTS_REQUEST',
  'READ_CURRENT_INVESTMENTS_SUCCESS',
  'READ_CURRENT_INVESTMENTS_FAILURE',

  'CREATE_INVESTMENTS_REQUEST',
  'CREATE_INVESTMENTS_SUCCESS',
  'CREATE_INVESTMENTS_FAILURE',

  'UPDATE_INVESTMENT_REQUEST',
  'UPDATE_INVESTMENT_SUCCESS',
  'UPDATE_INVESTMENT_FAILURE',

  'DELETE_INVESTMENT_REQUEST',
  'DELETE_INVESTMENT_SUCCESS',
  'DELETE_INVESTMENT_FAILURE',

  'UPDATE_INVESTMENTS_FUND',
  'UPDATE_INVESTMENT_FUND',

  'CREATE_INVESTMENT_MEDIA_REQUEST',
  'CREATE_INVESTMENT_MEDIA_SUCCESS',
  'CREATE_INVESTMENT_MEDIA_FAILURE',

  'CREATE_EXTERNAL_ACCOUNT_REQUEST',
  'CREATE_EXTERNAL_ACCOUNT_SUCCESS',
  'CREATE_EXTERNAL_ACCOUNT_FAILURE',
);

export const readInvestorInvestmentInterest = (fundId, investorId) => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTOR_INVESTMENT_INTEREST_REQUEST,
      types.READ_INVESTOR_INVESTMENT_INTEREST_SUCCESS,
      types.READ_INVESTOR_INVESTMENT_INTEREST_FAILURE,
    ],
    method: 'get',
    url: `investments?filter=[{"fund_id":${fundId},"investor_id":${investorId},"investment_status":[55,48,47]}]`,
    withProgressBar: false,
  },
});

export const readInvestmentHistory = () => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTMENTS_REQUEST,
      types.READ_INVESTMENTS_SUCCESS,
      types.READ_INVESTMENTS_FAILURE,
    ],
    method: 'get',
    url: 'investments',
    withProgressBar: false,
  },
});

// TODO: check and remove
export const readInvestmentHistoryByFundId = fundId => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTMENTS_BY_FUND_ID_REQUEST,
      types.READ_INVESTMENTS_BY_FUND_ID_SUCCESS,
      types.READ_INVESTMENTS_BY_FUND_ID_FAILURE,
    ],
    method: 'get',
    url: `investments?filter=[{"fund_id":${fundId}}]`,
    withProgressBar: false,
  },
});

// TODO: check and remove
export const readInvestmentHistoryPipeline = (fundId, userId) => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTMENTS_PIPELINE_REQUEST,
      types.READ_INVESTMENTS_PIPELINE_SUCCESS,
      types.READ_INVESTMENTS_PIPELINE_FAILURE,
    ],
    method: 'get',
    url: `investments?filter=[{"fund_id":${fundId},"investor_id":${userId}}]`,
    withProgressBar: false,
  },
});

export const readInvestment = id => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTMENT_REQUEST,
      types.READ_INVESTMENT_SUCCESS,
      types.READ_INVESTMENT_FAILURE,
    ],
    method: 'get',
    url: `investments/${id}`,
    withProgressBar: false,
  },
});

export const readInvestmentDetails = id => ({
  [CALL_API]: {
    types: [
      types.READ_INVESTMENT_DETAILS_REQUEST,
      types.READ_INVESTMENT_DETAILS_SUCCESS,
      types.READ_INVESTMENT_DETAILS_FAILURE,
    ],
    method: 'get',
    url: `investments/${id}/transaction-details`,
    withProgressBar: false,
  },
});

export const readCurrentInvestments = () => ({
  [CALL_API]: {
    types: [
      types.READ_CURRENT_INVESTMENTS_REQUEST,
      types.READ_CURRENT_INVESTMENTS_SUCCESS,
      types.READ_CURRENT_INVESTMENTS_FAILURE,
    ],
    method: 'get',
    url: 'investments/current',
    withProgressBar: false,
  },
});

export const createInvestment = fundId => ({
  [CALL_API]: {
    types: [
      types.CREATE_INVESTMENTS_REQUEST,
      types.CREATE_INVESTMENTS_SUCCESS,
      types.CREATE_INVESTMENTS_FAILURE,
    ],
    method: 'put',
    url: 'investments',
    withProgressBar: false,
    data: {
      fundId,
    },
  },
});

export const updateInvestmentsFund = investments => ({
  type: types.UPDATE_INVESTMENTS_FUND,
  payload: investments,
});

export const updateInvestmentFund = investment => ({
  type: types.UPDATE_INVESTMENT_FUND,
  payload: investment,
});

export const updateInvestment = (investmentId, data) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_INVESTMENT_REQUEST,
      types.UPDATE_INVESTMENT_SUCCESS,
      types.UPDATE_INVESTMENT_FAILURE,
    ],
    method: 'put',
    url: `investments/${investmentId}`,
    withProgressBar: false,
    data,
  },
});

// TODO: check and remove
export const deleteInvestment = investmentId => ({
  [CALL_API]: {
    types: [
      types.DELETE_INVESTMENT_REQUEST,
      types.DELETE_INVESTMENT_SUCCESS,
      types.DELETE_INVESTMENT_FAILURE,
    ],
    method: 'delete',
    url: `investments/${investmentId}`,
    withProgressBar: false,
  },
});

export const createInvestmentMedia = (
  { requestType, successType, ...data },
  fieldName,
) => ({
  [CALL_API]: {
    types: [
      requestType || types.CREATE_INVESTMENT_MEDIA_REQUEST,
      successType || types.CREATE_INVESTMENT_MEDIA_SUCCESS,
      types.CREATE_INVESTMENT_MEDIA_FAILURE,
    ],
    url: 'media',
    data,
    extraData: { fieldName },
    withProgressBar: true,
  },
});

export const createExternalAccount = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_EXTERNAL_ACCOUNT_REQUEST,
      types.CREATE_EXTERNAL_ACCOUNT_SUCCESS,
      types.CREATE_EXTERNAL_ACCOUNT_FAILURE,
    ],
    method: 'post',
    url: `investments/${id}/create-external-account`,
    data,
    withProgressBar: true,
  },
});

// TODO: check and remove
export function loadInvestments() {
  return function(dispatch) {
    dispatch(readInvestmentHistory()).then(({ status, data: investments }) => {
      if (status !== 'success') return;
      const funds = investments.map(investment => investment.fundId);
      dispatch(readFundsForInvestments(funds)).then(
        ({ status: result, data: dataFunds }) => {
          if (result !== 'success') return;
          const newFunds = {};
          dataFunds.forEach(fund => {
            dispatch(loadInvestmentsDealRoom(fund));
            newFunds[fund.id] = { ...fund };
          });
          const newInvestments = investments.map(investment => {
            investment.fund = newFunds[investment.fundId];
            return investment;
          });
          dispatch(updateInvestmentsFund(newInvestments));
        },
      );
    });
  };
}

export const loadInvestment = id => dispatch => {
  dispatch(readInvestment(id)).then(({ status, data: investment }) => {
    if (status === 'success') {
      dispatch(readInvestmentDetails(id));
      dispatch(readFundsForInvestments([investment.fundId])).then(
        ({ status: result, data: dataFunds }) => {
          if (result === 'success') {
            const newFunds = {};
            dataFunds.forEach(fund => {
              dispatch(loadInvestmentsDealRoom(fund));
              newFunds[fund.id] = { ...fund };
            });
            investment.fund = newFunds[investment.fundId];
            dispatch(updateInvestmentFund(investment));
          }
        },
      );
    }
  });
};

export const updateCurrentInvestment = id => dispatch => {
  dispatch(readInvestment(id)).then(({ status, data: investment }) => {
    if (status === 'success') {
      dispatch(readFundsForInvestments([investment.fundId])).then(
        ({ status: result, data: dataFunds }) => {
          if (result === 'success') {
            const newFunds = {};
            dataFunds.forEach(fund => {
              dispatch(loadInvestmentsDealRoom(fund));
              newFunds[fund.id] = { ...fund };
            });
            investment.fund = newFunds[investment.fundId];
            dispatch(updateInvestmentFund(investment));
          }
        },
      );
    }
  });
};

// vendor
import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';
import { pascalize } from 'humps';
import { parse } from 'query-string';
// dm
import { modalTypes } from '../actions';
import { createReducer } from '../../utils/core';

/* initial state */
const initialState = fromJS({});

/* reducer */
export default createReducer(initialState, {
  [modalTypes.OPEN_MODAL]: (state, { modalName, modalProps }) =>
    state.set(modalName, modalProps),
  [modalTypes.CLOSE_MODAL]: (state, { modalName }) => state.delete(modalName),
  [modalTypes.CLOSE_MODALS]: () => initialState,
  // rewrite asap !
  [LOCATION_CHANGE]: (state, { location }) => {
    let newState = fromJS({});
    const { modal = '', modalProps } = parse(location.search);
    if (modal) newState = newState.set(pascalize(modal), parse(modalProps));
    return newState;
  },
});

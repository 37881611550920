// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';

// dm
import readData from '../../../../../../enhancers/readData';
import FormGroup from '../../../../../molecules/FormGroup';
import Button from '../../../../../atoms/Button';
import Form from '../../../../../atoms/Form';
import Input from '../../../../../atoms/Input';
import Select from '../../../../../atoms/Select';
import Modal from '../../../../Modal/Modal';
import ModalHeader from '../../../../Modal/ModalHeader';
import ModalBody from '../../../../Modal/ModalBody';
import ModalControls from '../../../../Modal/ModalControls';
import { moveDealRoomToArchived } from '../../../../../../store/actions/inv-dashboard';
import { NO_LONGER_INTERESTED_REASON } from '../../../../../../constants/lists';
import { toJS } from '../../../../../../utils/core';
import { getPath } from '../../../../../../utils/route';

// own
import './NoLongerInterestedModal.scss';

const form = 'noLongerInterestedModal';
const selector = formValueSelector(form);

class NoLongerInterestedModal extends Component {
  render() {
    const {
      noLongerInterestedReason,
      lists,
      handleSubmit,
      fundName,
      close,
    } = this.props;
    return (
      <Modal className="no-longer-modal" {...this.props}>
        <ModalHeader title="modal.deal_rooms.no_interested.title" />
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <p>
              <FormattedMessage id="modal.deal_rooms.no_interested.message1" />
              <strong> {fundName} </strong>
              <FormattedMessage id="modal.deal_rooms.no_interested.message2" />
              <strong> {fundName} </strong>
              <FormattedMessage id="modal.deal_rooms.no_interested.message3" />
              <strong> {fundName} </strong>
            </p>
            <div className="no-longer-modal__fields">
              <FormGroup type="col-center" required>
                <label>
                  <FormattedMessage id="modal.deal_rooms.no_interested.label.select" />
                  <strong> {fundName} </strong>?
                </label>
                <Field
                  name="noLongerInterestedReason"
                  component={Select}
                  options={lists.noLongerInterestedReason}
                />
              </FormGroup>
              {noLongerInterestedReason ===
                NO_LONGER_INTERESTED_REASON.OTHER && (
                <FormGroup type="col-center" required>
                  <label>
                    <FormattedMessage id="modal.deal_rooms.no_interested.label.input" />
                    :
                  </label>
                  <Field
                    name="noLongerInterestedOtherReason"
                    component={Input}
                  />
                </FormGroup>
              )}
              <p>
                <strong> {fundName} </strong>
                <FormattedMessage id="modal.deal_rooms.no_interested.message5" />
              </p>
            </div>
            <ModalControls mobile>
              <Button
                color="blue-g"
                flat
                border="none"
                text="Cancel"
                onClick={close}
              />
              <Button
                type="submit"
                text="modal.deal_rooms.no_interested.button.no_iterested"
              />
            </ModalControls>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

const validate = values => {
  const errors = {};
  values = toJS(values);
  if (!values.noLongerInterestedReason) {
    errors.noLongerInterestedReason = 'Please indicate the reason';
  }
  if (!values.noLongerInterestedOtherReason) {
    errors.noLongerInterestedOtherReason = 'Please indicate the reason';
  }
  return errors;
};

export default connect(
  state => ({
    noLongerInterestedReason: selector(state, 'noLongerInterestedReason'),
  }),
  (dispatch, { fundId, close }) => ({
    onSubmit: values => {
      values = values.toJS();
      const reason = {
        [true]: values.noLongerInterestedOtherReason,
        [false]: values.noLongerInterestedReason,
      }[values.noLongerInterestedReason === NO_LONGER_INTERESTED_REASON.OTHER];
      dispatch(moveDealRoomToArchived({ fundId, reason })).then(() =>
        dispatch(push(getPath('invDashboard.archivedDeals'))),
      );
      close();
    },
  }),
)(
  reduxForm({
    form,
    validate,
  })(
    readData({
      listNames: 'noLongerInterestedReason',
    })(NoLongerInterestedModal),
  ),
);

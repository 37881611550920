// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
import { FormattedHTMLMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
// dm
import Tooltip from '../../../molecules/Tooltip';
import Button from '../../../atoms/Button';
import Form from '../../../atoms/Form';
import Input from '../../../atoms/Input';
import Select from '../../../atoms/Select';
import FormGroup from '../../../molecules/FormGroup';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/ModalHeader';
import ModalBody from '../../Modal/ModalBody';
import ModalFooter from '../../Modal/ModalFooter';
import {
  createFundPipeline,
  acceptFundPipeline,
} from '../../../../store/actions/fund';
import { restoreDealRoomFromArchived } from '../../../../store/actions/inv-dashboard';
import { POTENTIAL_INVESTMENT_AMOUNT } from '../../../../constants/lists';
import { TABLET, MOBILE } from '../../../../constants/breakpoints';
import { toJS } from '../../../../utils/core';
import { getPath } from '../../../../utils/route';
import { parseInteger } from '../../../../utils/form/parse';
import { formatInteger } from '../../../../utils/form/format';
// own
import './PotentialInvestmentAmountModal.scss';

const form = 'potentialInvestmentAmountModal';
const selector = formValueSelector(form);

class PotentialInvestmentAmountModal extends Component {
  render() {
    const { name, amount, invalid, submitting, handleSubmit } = this.props;
    return (
      <Modal className="potential-investment-amount-modal" {...this.props}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader title="modal.potential_investment_amount.title" />
          <ModalBody className="potential-investment-amount-modal__body">
            <div className="potential-investment-amount-modal__body__text">
              <FormattedHTMLMessage
                id="modal.potential_investment_amount.text"
                values={{ name }}
              />
              <MediaQuery minWidth={TABLET.left}>
                <Tooltip responsive>
                  <FormattedHTMLMessage
                    id="modal.potential_investment_amount.tooltip"
                    values={{ name }}
                  />
                </Tooltip>
              </MediaQuery>
              <MediaQuery maxWidth={MOBILE.right}>
                <Tooltip responsive position="bottom-center">
                  <FormattedHTMLMessage
                    id="modal.potential_investment_amount.tooltip"
                    values={{ name }}
                  />
                </Tooltip>
              </MediaQuery>
            </div>
            <div className="potential-investment-amount-modal__body__container">
              <FormGroup
                type="col"
                label="modal.potential_investment_amount.label:"
                required
              >
                <Field
                  name="amount"
                  component={Select}
                  options={POTENTIAL_INVESTMENT_AMOUNT}
                />
              </FormGroup>
              {amount === Infinity && (
                <FormGroup type="col" required>
                  <Field
                    name="amountInput"
                    component={Input}
                    format={formatInteger}
                    parse={parseInteger}
                  />
                </FormGroup>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              size="md"
              text={submitting ? 'Submitting ...' : 'Enter deal room'}
              disabled={invalid || submitting}
            />
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

const validate = values => {
  values = values.toJS();
  const errors = {};
  if (!values.amount) {
    errors.amount = 'Please indicate potential investment amount.';
  }
  if (values.amount === Infinity && !values.amountInput) {
    errors.amountInput = 'Please indicate potential investment amount.';
  }
  return errors;
};

export default connect(
  state => ({
    amount: selector(state, 'amount'),
  }),
  (dispatch, { action, id, investorId, name, userName, fundTypeName }) => ({
    onSubmit: values => {
      values = toJS(values);
      const amount = values.amountInput || values.amount;
      action = {
        accessRequest: createFundPipeline({ id, amount }),
        inviteAccept: acceptFundPipeline({ id, investorId, amount }),
        restoreFromArchived: restoreDealRoomFromArchived({ id, amount }),
      }[action];
      return dispatch(action).then(() => {
        dispatch({
          type: 'GTM_EVENT',
          payload: {
            event: 'fund',
            eventCategory: 'access',
            eventAction: fundTypeName,
            eventLabel: `${userName}_access_${name}`,
            eventValue: `${amount}`,
          },
        });
        return dispatch(push(getPath('invDashboard.dealRooms.show', { id })));
      });
    },
  }),
)(
  reduxForm({
    form,
    validate,
  })(PotentialInvestmentAmountModal),
);

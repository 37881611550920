// vendor
import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import 'react-select/dist/react-select.css';

// own
import './ReactSelectCustom.scss';
import FormControl from '../FormControl';

export default class ReactSelectCustomComponent extends ReactSelect {
  render() {
    const {
      props: { multi, ...props },
      state,
    } = this;
    const valueArray = this.getValueArray(props.value);
    this._visibleOptions = this.filterOptions(multi ? valueArray : null);
    const options = this._visibleOptions;
    let isOpen = state.isOpen;
    if (multi && !options.length && valueArray.length && !state.inputValue) {
      isOpen = false;
    }
    const focusedOptionIndex = this.getFocusableOptionIndex(valueArray[0]);
    let focusedOption = null;
    if (focusedOptionIndex !== null) {
      this._focusedOption = this._visibleOptions[focusedOptionIndex];
    } else {
      this._focusedOption = null;
    }
    focusedOption = this._focusedOption;
    const className = classNames(props.className, {
      'Select--multi': multi,
      'Select--single': !multi,
      'is-disabled': props.disabled,
      'is-focused': state.isFocused,
      'is-loading': props.isLoading,
      'is-open': isOpen,
      'is-pseudo-focused': state.isPseudoFocused,
      'is-searchable': props.searchable,
      'has-value': valueArray.length,
    });
    let removeMessage = null;
    if (
      multi &&
      !props.disabled &&
      valueArray.length &&
      !state.inputValue &&
      state.isFocused &&
      props.backspaceRemoves
    ) {
      removeMessage = (
        <span
          id={`${this._instancePrefix}-backspace-remove-message`}
          className="Select-aria-only"
          aria-live="assertive"
        >
          {props.backspaceToRemoveMessage.replace(
            '{label}',
            valueArray[valueArray.length - 1][props.labelKey],
          )}
        </span>
      );
    }
    return (
      <FormControl
        {...props}
        kind="Select"
        className={className}
        style={props.wrapperStyle}
      >
        {this.renderHiddenField(valueArray)}
        <div
          className="Select-control"
          style={props.style}
          onKeyDown={this.handleKeyDown}
          onMouseDown={this.handleMouseDown}
          onTouchEnd={this.handleTouchEnd}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
        >
          <span
            className="Select-multi-value-wrapper"
            id={`${this._instancePrefix}-value`}
          >
            {this.renderValue(valueArray, isOpen)}
            {this.renderInput(valueArray, focusedOptionIndex)}
          </span>
          {removeMessage}
          {this.renderLoading()}
          {this.renderClear()}
          {this.renderArrow()}
        </div>
        {isOpen &&
          this.renderOuter(options, !multi ? valueArray : null, focusedOption)}
        {isOpen && (
          <div
            className="Select__shadow"
            style={{ bottom: this.getShadowBottom() }}
          />
        )}
      </FormControl>
    );
  }

  getShadowBottom() {
    // calculate new bottom for shadow
    const V_SIZE_MAX = 5;
    const OPTION_HEIGHT = 40;
    let vSize = this._visibleOptions.length || +!!this.props.noResultsText;
    vSize = Math.min(vSize, V_SIZE_MAX);
    return -OPTION_HEIGHT * vSize;
  }
}

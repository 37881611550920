// vendor
import React, { Component } from 'react';
import cx from 'classnames';
import { reduxForm, Field } from 'redux-form/immutable';

// dm
import Icon from '../../../../components/atoms/Icons/Icon';
import Form from '../../../../components/atoms/Form';

// own
import s from './SearchBrowse.module.scss';

const Input = ({ input, placeholder, closeIcon, onClose }) => (
  <div className={s.input}>
    <Icon name="magnifier" className={s.left_icon} />
    <input {...input} type="text" placeholder={placeholder} />
    {closeIcon && (
      <Icon name="close" className={s.right_icon} onClick={onClose} />
    )}
  </div>
);

class SearchBrowseView extends Component {
  handleSubmit = evt => evt.preventDefault();

  render() {
    const { mobile, onClose } = this.props;
    return (
      <Form
        className={cx(s.search_funds, mobile && s.mobile)}
        onSubmit={this.handleSubmit}
      >
        <div className={s.container}>
          <Field
            name="searchFunds"
            component={Input}
            placeholder="Search by Fund Name"
            closeIcon={mobile}
            onClose={onClose}
          />
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'searchInvestments',
})(SearchBrowseView);

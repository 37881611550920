// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

// dm
import Tag from '../../../../../atoms/Tag/Tag';
import { MOBILE, TABLET } from '../../../../../../constants/breakpoints';
import { FUND_TYPE } from '../../../../../../constants/lists';
import {
  toValueWithComma,
  replaceValueToNotApplicable,
} from '../../../../../../utils/crutchFormat';
import { listParam, getFundAumLabel } from '../../../../../../utils/collection';

// own
import './FundShowInfo.scss';

export default class FundShowInfo extends Component {
  renderInfo() {
    const {
      minimumInvestment,
      investmentTypeId,
      managementFree,
      fundAum,
      fundLife,
      carry,
    } = this.props.params;

    const fundAumLabel = getFundAumLabel(investmentTypeId);

    return (
      <div className="fund-show-info__item">
        {fundAum && (
          <div className="fund-show-info__fund-aum">
            <FormattedMessage id={fundAumLabel} />:
            <div className="fund-show-info__fund-aum__size">
              {toValueWithComma(fundAum)}
            </div>
          </div>
        )}
        <div className="fund-show-info__fund-other">
          {investmentTypeId !== FUND_TYPE.HEDGE_FUND && fundLife && (
            <div className="fund-show-info__fund-other__attr">
              <div className="fund-show-info__fund-other__attr__label">
                <FormattedMessage id="Fund Life" />:
              </div>
              <div className="fund-show-info__fund-other__attr__value">
                {fundLife}
              </div>
            </div>
          )}
          {minimumInvestment && (
            <div className="fund-show-info__fund-other__attr">
              <div className="fund-show-info__fund-other__attr__label">
                <FormattedMessage id="Minimum Investment" />:
              </div>
              <div className="fund-show-info__fund-other__attr__value">
                {toValueWithComma(minimumInvestment)}
              </div>
            </div>
          )}
          <div className="fund-show-info__fund-other__attr">
            <div className="fund-show-info__fund-other__attr__label">
              <FormattedMessage id="Management Fee" />:
            </div>
            <div className="fund-show-info__fund-other__attr__value">
              {replaceValueToNotApplicable(managementFree)}
            </div>
          </div>
          <div className="fund-show-info__fund-other__attr">
            <div className="fund-show-info__fund-other__attr__label">
              <FormattedMessage id="Carry" />:
            </div>
            <div className="fund-show-info__fund-other__attr__value">
              {replaceValueToNotApplicable(carry)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInfoMobile() {
    const {
      investmentTypeId,
      minimumInvestment,
      fundAum,
      fundLife,
      carry,
      managementFree,
    } = this.props.params;
    const fundAumLabel = getFundAumLabel(investmentTypeId);
    return (
      <div className="fund-show-info__item fund-show-info__item--mobile">
        <div className="fund-show-info__l-col">
          {fundAum && (
            <div className="fund-show-info__fund-aum">
              <FormattedMessage id={fundAumLabel} />:
              <div className="fund-show-info__fund-aum__size">
                {toValueWithComma(fundAum)}
              </div>
            </div>
          )}
          <div className="fund-show-info__fund-other">
            <div className="fund-show-info__fund-other__attr">
              <div className="fund-show-info__fund-other__attr__label">
                <FormattedMessage id="Carry" />:
              </div>
              <div className="fund-show-info__fund-other__attr__value">
                {replaceValueToNotApplicable(carry)}
              </div>
            </div>
            {investmentTypeId !== FUND_TYPE.HEDGE_FUND && fundLife && (
              <div className="fund-show-info__fund-other__attr">
                <div className="fund-show-info__fund-other__attr__label">
                  <FormattedMessage id="Fund Life" />:
                </div>
                <div className="fund-show-info__fund-other__attr__value">
                  {fundLife}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fund-show-info__r-col">
          {minimumInvestment && (
            <div className="fund-show-info__fund-other__attr">
              <div className="fund-show-info__fund-other__attr__label">
                <FormattedMessage id="Minimum Investment" />:
              </div>
              <div className="fund-show-info__fund-other__attr__value">
                {toValueWithComma(minimumInvestment)}
              </div>
            </div>
          )}
          <div className="fund-show-info__fund-other__attr">
            <div className="fund-show-info__fund-other__attr__label">
              <FormattedMessage id="Management Fee" />:
            </div>
            <div className="fund-show-info__fund-other__attr__value">
              {replaceValueToNotApplicable(managementFree)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      investmentTypeId,
      redemptionPeriodId,
      fundStrategies,
      waterMarkId,
      industryFocuses,
      preferredVentureCapitalStageFocuses,
      creditFocuses,
    } = this.props.params;
    const {
      redemptionPeriod,
      hedgeFundStrategy,
      cryptoassetFundStrategy,
      industryFocus,
      ventureCapitalStageFocus,
      waterMark,
      privateDebtCreditFocus,
    } = this.props.lists;
    const className = classNames(this.props.className, 'fund-show-info', {
      'fund-show-info--column': investmentTypeId !== FUND_TYPE.HEDGE_FUND,
    });
    let fundStrategyLabels = [];
    let industryFocusesLabels = [];
    let stageFocusesLabels = [];
    let creditFocusesLabels = [];

    if (
      investmentTypeId === FUND_TYPE.HEDGE_FUND &&
      fundStrategies &&
      hedgeFundStrategy.length
    ) {
      fundStrategyLabels = fundStrategies.map((tag, index) => (
        <Tag key={index}>
          {hedgeFundStrategy.find(item => item.id === tag).label}
        </Tag>
      ));
    }

    if (
      investmentTypeId === FUND_TYPE.CRYPTO_ASSET &&
      fundStrategies &&
      cryptoassetFundStrategy.length
    ) {
      fundStrategyLabels = fundStrategies.map((tag, index) => (
        <Tag key={index}>
          {cryptoassetFundStrategy.find(item => item.id === tag).label}
        </Tag>
      ));
    }

    if (industryFocuses && industryFocus.length) {
      industryFocusesLabels = industryFocuses.map(tag => (
        <Tag key={tag}>{industryFocus.find(item => item.id === tag).label}</Tag>
      ));
    }

    if (
      preferredVentureCapitalStageFocuses &&
      ventureCapitalStageFocus.length
    ) {
      stageFocusesLabels = preferredVentureCapitalStageFocuses.map(
        (tag, index) => (
          <Tag key={index}>
            {ventureCapitalStageFocus.find(item => item.id === tag).label}
          </Tag>
        ),
      );
    }
    if (
      investmentTypeId === FUND_TYPE.PRIVATE_DEBT &&
      creditFocuses &&
      privateDebtCreditFocus.length
    ) {
      creditFocusesLabels = creditFocuses.map((tag, i) => (
        <Tag key={`creditFocuse${i}`}>
          {privateDebtCreditFocus.find(item => item.id === tag).label}
        </Tag>
      ));
    }

    return (
      <div className={className}>
        <div className="fund-show-info__item">
          {investmentTypeId !== FUND_TYPE.HEDGE_FUND &&
            investmentTypeId !== FUND_TYPE.PRIVATE_DEBT &&
            industryFocusesLabels[0] && (
              <div className="fund-show-info__row">
                <div className="fund-show-info__row__label">
                  <FormattedMessage id="Industry Focus" />:
                </div>
                <div className="fund-show-info__row__tags">
                  {industryFocusesLabels}
                </div>
              </div>
            )}
          {investmentTypeId !== FUND_TYPE.HEDGE_FUND &&
            investmentTypeId !== FUND_TYPE.PRIVATE_DEBT &&
            stageFocusesLabels[0] && (
              <div className="fund-show-info__row">
                <div className="fund-show-info__row__label">
                  <FormattedMessage id="Stage Focus" />:
                </div>
                <div className="fund-show-info__row__tags">
                  {stageFocusesLabels}
                </div>
              </div>
            )}
          {fundStrategyLabels[0] && (
            <div className="fund-show-info__row fund-show-info__row--fund-strategy">
              <div className="fund-show-info__row__label">
                <FormattedMessage id="Fund Strategy" />:
              </div>
              <div className="fund-show-info__row__tags">
                {fundStrategyLabels}
              </div>
            </div>
          )}
          {investmentTypeId === FUND_TYPE.PRIVATE_DEBT &&
            creditFocusesLabels[0] && (
              <div className="fund-show-info__row">
                <div className="fund-show-info__row__label">
                  <FormattedMessage id="Credit Focus" />:
                </div>
                <div className="fund-show-info__row__tags">
                  {creditFocusesLabels}
                </div>
              </div>
            )}
          {redemptionPeriodId && redemptionPeriod[0] && (
            <div className="fund-show-info__row fund-show-info__row--redeption-period">
              <div className="fund-show-info__row__label">
                <FormattedMessage id="Redemption Period" />:
              </div>
              <div className="fund-show-info__row__tags">
                <Tag> {redemptionPeriod[redemptionPeriodId - 1].label}</Tag>
              </div>
            </div>
          )}
          {investmentTypeId === FUND_TYPE.HEDGE_FUND && waterMarkId && (
            <div className="fund-show-info__row">
              <div className="fund-show-info__row__label">
                <FormattedMessage id="High Water Mark" />:
              </div>
              <div className="fund-show-info__row__tags">
                <Tag>{listParam(waterMarkId, waterMark)}</Tag>
              </div>
            </div>
          )}
        </div>
        <MediaQuery minWidth={TABLET.left}>{this.renderInfo()}</MediaQuery>
        <MediaQuery maxWidth={MOBILE.right}>
          {this.renderInfoMobile()}
        </MediaQuery>
      </div>
    );
  }
}

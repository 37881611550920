export const ELIGIBILITY_OPTIONS = {
  DEFAULT: [
    {
      color: 'green-d',
      flat: true,
    },
    {
      color: 'green-h',
      flat: true,
    },
    {
      color: 'green-l',
      flat: true,
    },
  ],
  ICON_SM: [
    {
      color: 'green-d',
      icon: 'lock-green-d-sm',
      flat: true,
    },
    {
      color: 'green-h',
      icon: 'lock-green-h-sm',
      flat: true,
    },
    {
      color: 'green-l',
      icon: 'lock-green-l-sm',
      flat: true,
    },
  ],
  ICON_MD: [
    {
      color: 'green-d',
      icon: 'lock-green-d-md',
      flat: true,
    },
    {
      color: 'green-h',
      icon: 'lock-green-h-md',
      flat: true,
    },
    {
      color: 'green-l',
      icon: 'lock-green-l-md',
      flat: true,
    },
  ],
};

export const { DEFAULT, ICON_SM, ICON_MD } = ELIGIBILITY_OPTIONS;

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { camelizeKeys } from 'humps';
import jwtDecode from 'jwt-decode';
import url from 'url';

import config from '../../config';
import {
  mergeUser,
  notificationToggle,
  authorizeUserSuccess,
} from '../../store/actions/user';
import { getAccessToken } from '../../utils/jwt';
import { showToastrInfo } from '../../store/actions/app';
import { toJS } from '../../utils/core';
import { openAuthWindow } from '../../utils/window';

const mapStateToProps = state => ({
  currentUserEmail: toJS(state.get('user').get('email')),
});

export default ComposedComponent =>
  connect(mapStateToProps)(
    class extends Component {
      requestCredentialsViaPostMessage(args) {
        if (args.linkedinWindow.closed) {
          window.removeEventListener('message', args.messageHandler, false);
        } else {
          args.linkedinWindow.postMessage('requestCredentials', '*');
          setTimeout(() => {
            this.requestCredentialsViaPostMessage(args);
          }, 1000);
        }
      }

      /* handle message from subwindow */
      handlePostMessage = (resolve, reject) => ({ data: response }) => {
        const { status, data } = response;
        if (status === 'success') {
          const linkedinUser = camelizeKeys(data);
          resolve(linkedinUser);
        } else if (status === 'error') {
          reject(response);
        }
      };

      /* read user from subwindow (promise) */
      readLinkedinUser({ query = {} } = {}) {
        return new Promise((resolve, reject) => {
          const messageHandler = this.handlePostMessage(resolve, reject).bind(
            this,
          );
          const linkedinWindow = openAuthWindow({
            width: 400,
            height: 566,
            url: url.format({
              pathname: `https://${config.apiHost}/${
                config.apiVersion
              }/auth/oauth`,
              query: { ...query, authclient: 'linkedin' },
            }),
          });
          window.addEventListener('message', messageHandler, false);
          this.requestCredentialsViaPostMessage({
            linkedinWindow,
            messageHandler,
          });
        });
      }

      /* sign in jwt */
      signInWithJwt(linkedinUser) {
        const { jwt, ...user } = linkedinUser;
        try {
          jwtDecode(jwt);
          user.accessToken = jwt;
          user.refreshToken = '';
          if (!linkedinUser.rolesList) return;
          this.props.dispatch(authorizeUserSuccess(user));
        } catch (e) {
          console.error('Login in with JWT error', e);
        }
      }

      /* sign up with linkedIn */
      signUpWithLinkedin = () => {
        this.readLinkedinUser().then(
          linkedinUser => {
            if (linkedinUser.jwt) {
              this.signInWithJwt(linkedinUser);
            } else if (linkedinUser.id) {
              this.props.dispatch(mergeUser(linkedinUser));
            }
          },
          error => {
            const { code, message } = error;
            if (code === 406) {
              this.props.dispatch(
                showToastrInfo({ message, translate: false }),
              );
              console.error('Register with LinkedIn error 406', error);
            } else {
              console.error('Register with LinkedIn error', error);
            }
          },
        );
      };

      /* sign in with linkedIn */
      signInWithLinkedin = () => {
        this.readLinkedinUser().then(
          linkedinUser => {
            if (linkedinUser.jwt) {
              this.signInWithJwt(linkedinUser);
            } else {
              this.props.dispatch(
                notificationToggle({ isMissingOnApiSide: true }),
              );
            }
          },
          error => {
            const { code } = error;
            if (code === 406) {
              this.props.dispatch(
                notificationToggle({ isMissingOnApiSide: true }),
              );
            } else if (code === 458) {
              this.props.dispatch(notificationToggle({ isNotActivated: true }));
            }
          },
        );
      };

      /* connect linked in */
      connectLinkedin = () => {
        this.readLinkedinUser({ query: { jwt: getAccessToken() } }).then(
          linkedinUser => {
            if (linkedinUser.email !== this.props.currentUserEmail) {
              console.error('Connect with LinkedIn, error: email not matches');
            } else {
              this.props.dispatch(mergeUser(linkedinUser));
            }
          },
          error => {
            const { code, message } = error;
            if (code === 406) {
              this.props.dispatch(
                showToastrInfo({ message, translate: false }),
              );
              console.error('Connect with LinkedIn error 406', error);
            } else {
              this.props.dispatch(
                showToastrInfo({
                  message: 'Connect with LinkedIn error',
                  translate: false,
                }),
              );
              console.error('Connect with LinkedIn error', error);
            }
          },
        );
      };

      render() {
        return (
          <ComposedComponent
            {...this.props}
            signUpWithLinkedin={this.signUpWithLinkedin}
            signInWithLinkedin={this.signInWithLinkedin}
            connectLinkedin={this.connectLinkedin}
          />
        );
      }
    },
  );

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { keys } from 'lodash';

// dm
import Link from '../../../atoms/Link';
import IconLg from '../../../atoms/Icons/IconLg';
import { toJS } from '../../../../utils/core';
import { DOCUMENT } from '../../../../constants/files';
import { TABLET, DESKTOP } from '../../../../constants/breakpoints';

// own
import './Document.scss';

export default class DocumentComponent extends Component {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: ImmutablePropTypes.mapContains({
        extension: PropTypes.oneOf(keys(DOCUMENT.TYPES)).isRequired,
        title: PropTypes.string,
        url: PropTypes.string,
        uploading: PropTypes.bool,
      }),
    }),
  };

  render() {
    const {
      children,
      className,
      input: { value },
    } = this.props;
    const { extension, title, url, uploading } = toJS(value);
    const fullClassName = classNames(className, 'document', {
      'document--uploading': uploading,
    });
    return (
      <div className={fullClassName}>
        <MediaQuery minWidth={DESKTOP.left}>
          <IconLg
            className="document__extension"
            name={`document-${extension}`}
          />
        </MediaQuery>
        <MediaQuery maxWidth={TABLET.right}>
          <IconLg
            className="document__extension"
            name={`document-${extension}-sm`}
          />
        </MediaQuery>
        <div className="document__file">
          {uploading ? (
            title
          ) : (
            <Link href={url} download={title}>
              {title}
            </Link>
          )}
        </div>
        {children}
      </div>
    );
  }
}

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './TableCell.scss';

export default class TableCellComponent extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf('chackable sorting user controls'.w()),
    hidden: PropTypes.bool,
    hide: PropTypes.bool,
    withoutPadding: PropTypes.bool,
  };

  render() {
    const {
      className,
      type,
      hidden,
      hide,
      withoutPadding,
      ...props
    } = this.props;
    const fullClassName = classNames(className, 'table-cell', {
      [`table-cell--${type}`]: type,
      'table-cell--hidden': hidden,
      'table-cell--hide': hide,
      'table-cell--without-padding': withoutPadding,
    });
    return <div {...props} className={fullClassName} />;
  }
}

// vendor
import { fromJS } from 'immutable';
import { uniqueId } from 'lodash';
// dm
import { appTypes } from '../actions';
import { createReducer } from '../../utils/core';

/* initial state */
const initialState = fromJS({
  isFetchingFailure: null,
  isFetchingSuccess: null,
  toastr: {
    id: null,
    type: 'info',
    title: '',
    message: '',
    options: { closeButton: true },
  },
  asideMenu: false,
});

/* reducer */
export default createReducer(initialState, {
  [appTypes.FETCHING_DATA]: state =>
    state.merge({
      isFetchingFailure: false,
      isFetchingSuccess: false,
      response: null,
    }),
  [appTypes.FETCHING_DATA_SUCCESS]: (state, payload) =>
    state.merge({ isFetchingSuccess: true, response: payload.response }),
  [appTypes.FETCHING_DATA_FAILURE]: (state, payload) =>
    state.merge({ isFetchingFailure: true, response: payload.response }),
  [appTypes.SHOW_NOTIFICATION]: (state, payload) =>
    state.merge({
      toastr: {
        ...payload,
        id: uniqueId('notification'),
        type: payload.type || 'info',
        title: payload.title || 'Information',
      },
    }),
  [appTypes.SHOW_TOASTR_SUCCESS]: (state, payload) =>
    state.merge({
      toastr: {
        ...payload,
        id: uniqueId('notification'),
        type: 'success',
        title: payload.title || 'Notification',
      },
    }),
  [appTypes.SHOW_TOASTR_INFO]: (state, payload) =>
    state.merge({
      toastr: {
        ...payload,
        id: uniqueId('notification'),
        type: 'info',
        title: payload.title || 'Information',
      },
    }),
  [appTypes.SHOW_TOASTR_ERROR]: (state, payload) =>
    state.merge({
      toastr: {
        ...payload,
        id: uniqueId('notification'),
        type: 'error',
        title: payload.title || 'Error',
      },
    }),
  [appTypes.OPEN_ASIDE_MENU]: (state, { isOpen }) =>
    state.merge({ asideMenu: isOpen }),
});

import { getIsKycCompleted } from '../../utils/kyc';

const gtmMiddleware = store => next => action => {
  const state = store.getState();
  const user = state.get('user').toJS();

  // "kyc completed"
  if (
    action.type === 'FETCHING_DATA_SUCCESS' &&
    user.isKycCompleted === false &&
    action.payload.response.data &&
    action.payload.response.data.kycStatus &&
    getIsKycCompleted(action.payload.response.data.kycStatus) === true
  ) {
    store.dispatch({
      type: 'GTM_EVENT',
      payload: {
        event: 'COMPLETED_KYC',
        eventCategory: 'COMPLETED_KYC',
        eventAction: user.fullName,
      },
    });
  }

  // "profile completed"
  if (
    action.type === 'FETCHING_DATA_SUCCESS' &&
    user.isProfileCompleted === false &&
    action.payload.response.data &&
    action.payload.response.data.progressPercentage &&
    action.payload.response.data.progressPercentage >= 100
  ) {
    store.dispatch({
      type: 'GTM_EVENT',
      payload: {
        event: 'COMPLETED_PROFILE',
        eventCategory: 'COMPLETED_PROFILE',
        eventAction: user.fullName,
      },
    });
  }

  // push event
  if (action.type === 'GTM_EVENT' && window.dataLayer) {
    const data = { ...action.payload };

    const gtmLayer = window.dataLayer || [];
    gtmLayer.push(data);
  }

  return next(action);
};

export default gtmMiddleware;

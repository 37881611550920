// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, reduxForm, Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isEmpty, uniqueId } from 'lodash';

// dm
import InboxMessage from '../../molecules/Message/InboxMessage';
import Button from '../../atoms/Button';
import TextArea from '../../atoms/TextArea';
import LogoImage from '../../atoms/Images/LogoImage';
import Form from '../../atoms/Form';
import FormGroup from '../../molecules/FormGroup';
import { closeInbox } from '../../../store/actions/inbox';
import { form, selector } from '../../../store/reducers/form/inbox';
import { selector as invDashboardSelector } from '../../../store/reducers/form/inv-dashboard';
import { selector as fundManageSelector } from '../../../store/reducers/form/fund-manage';
import { toJS } from '../../../utils/core';
import { checkInvestor } from '../../../utils/access';

// own
import './Inbox.scss';
import InboxEmpty from './InboxEmpty';

class InboxComponent extends Component {
  state = {
    displayedMessages: 20,
    showMoreMessages: 20,
    listener: null,
    scrollTop: 0,
  };

  componentDidMount() {
    const inboxContainer = this.inboxContainerRef;
    if (inboxContainer) {
      inboxContainer.scrollTop = inboxContainer.scrollHeight;
    }
  }

  componentWillReceiveProps(nextProps) {
    const _self = this;
    if (
      !!nextProps.messages.length &&
      nextProps.messages.length !== this.props.messages.length
    ) {
      if (!this.state.listener) {
        this.setState({ listener: true }, () => {
          _self.handlerScrollToBottomInit();
        });
      } else {
        _self.handlerScrollToBottomInit();
        // this.setState({ scrollTop: this.state.scrollTop++ }, () => {
        //   _self.handlerScrollToBottomInit();
        // });
      }
    }
  }

  componentWillUnmount() {
    const inboxContainer = this.inboxContainerRef;
    if (inboxContainer) {
      inboxContainer.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    this.setState({
      scrollTop: this.inboxContainerRef.scrollTop,
    });
  };

  handlerScrollToBottomInit() {
    const inboxContainer = this.inboxContainerRef;
    if (inboxContainer) {
      inboxContainer.scrollTop = inboxContainer.scrollHeight;
    }
  }

  /* TODO add slow scroll if will need */
  // handlerScrollToBottom() {
  //   let timeOut;
  //   const inboxContainer = this.inboxContainerRef;
  //   const scrollToBottom = () => {
  //     if (inboxContainer.scrollHeight - this.state.scrollTop > inboxContainer.offsetHeight) {
  //       inboxContainer.scrollTop += 50;
  //       timeOut = setTimeout(scrollToBottom.bind(this), 10);
  //     } else {
  //       clearTimeout(timeOut);
  //     }
  //   };
  //   scrollToBottom();
  // }

  closeInbox = () => {
    const { dispatch } = this.props;
    dispatch(closeInbox());
  };

  showMore = () => {
    const prevScrollHeight = this.inboxContainerRef.scrollHeight;
    const prevScrollTop = this.state.scrollTop;

    this.setState(
      {
        displayedMessages:
          this.state.displayedMessages + this.state.showMoreMessages,
      },
      () => {
        const inboxContainer = this.inboxContainerRef;
        inboxContainer.scrollTop =
          inboxContainer.scrollHeight - prevScrollHeight + prevScrollTop;
      },
    );
  };

  handlerSubmitEnter = event => {
    const ENTER_KEY = 13;
    if (event.keyCode === ENTER_KEY && event.shiftKey === false) {
      event.preventDefault();
      this.props.handleSubmit();
    }
  };

  render() {
    const {
      className,
      dispatch,
      match: {
        params: { id: fundId },
      },
      handleSubmit,
      dealRoom: { name, logo = {} },
      isInboxOpen,
      investor,
      invalid,
      messages,
      reading,
      submiting,
      user,
    } = this.props;
    const { displayedMessages } = this.state;
    const showTextArea = checkInvestor(user) || investor.isPipelined;
    const isInvestor = checkInvestor(user);
    const fullClassName = classNames(className, 'inbox', {
      'inbox--opened': isInboxOpen,
    });
    return (
      <Form onSubmit={handleSubmit} className={fullClassName}>
        <div className="inbox__header">
          <Button
            className="inbox__back-btn"
            size="sm"
            icon="arrow-left-sm"
            iconPos="left"
            color="blue-g"
            text="Back"
            border="none"
            flat
            frozen
            onClick={this.closeInbox}
          />
          {isInvestor && (
            <LogoImage className="inbox__avatar" src={logo.url} size="xs" />
          )}
          <div className="inbox__title">{name || investor.name}</div>
        </div>
        {!isEmpty(messages) && (
          <div
            className="inbox--scrollable"
            ref={el => (this.inboxContainerRef = el)}
          >
            <div className="inbox__messge-items">
              {messages.slice(-displayedMessages).map((message, index) => (
                <InboxMessage
                  key={index}
                  {...message}
                  {...{ dispatch, fundId, user }}
                >
                  {user}
                </InboxMessage>
              ))}
            </div>
          </div>
        )}
        {!isEmpty(messages) && displayedMessages < messages.length && (
          <Button
            type="button"
            text="Show more"
            color="white"
            border="none"
            size="sm"
            onClick={this.showMore}
          />
        )}
        {isEmpty(messages) && <InboxEmpty {...{ showTextArea, reading }} />}
        {showTextArea && (
          <div className="inbox__new-message">
            <FormGroup className="inbox-new-message__form">
              <Field
                name="newMessage"
                component={TextArea}
                onKeyDown={this.handlerSubmitEnter}
              />
            </FormGroup>
            <div className="inbox__new-message__form__controls">
              <label className="inbox__new-message__label">
                <FormattedMessage id="Press «Enter» to send Message" />
              </label>
              <Button
                className="inbox__send-btn"
                type="submit"
                text="Send"
                size="md"
                disabled={invalid || submiting}
              />
            </div>
          </div>
        )}
      </Form>
    );
  }
}

const validate = values => {
  const errors = {};
  const MAX_MESSAGE_LENGTH = 2048;
  values = toJS(values);
  if (!values.newMessage) {
    errors._error = 'This field cannot be blank';
  }
  if (values.newMessage && values.newMessage.length > MAX_MESSAGE_LENGTH) {
    errors.newMessage = 'Please enter less than 2,048 characters.';
  }
  return errors;
};

export default connect(
  state => ({
    isInboxOpen: toJS(selector(state, 'isInboxOpen')),
    messages: toJS(selector(state, 'messages')),
    user: toJS(state.get('user')),
    investor: toJS(fundManageSelector(state, 'investor')),
    dealRoom: toJS(invDashboardSelector(state, 'dealRoom')),
  }),
  null,
  (stateProps, { dispatch }, ownProps) => ({
    ...stateProps,
    ...ownProps,
    onSubmit: values => {
      const message = {
        id: uniqueId('inbox-message'),
        userId: ownProps.match.params.userId,
        fundId: ownProps.match.params.id,
        text: values.get('newMessage'),
        author: stateProps.user,
      };
      dispatch(change(form, 'newMessage', ''));
      dispatch(ownProps.createMessage(message));
      dispatch({
        type: 'GTM_EVENT',
        payload: {
          event: 'CLICK_BTN_CHAT_MSG_SEND',
          eventCategory: 'CLICK_BTN_CHAT_MSG_SEND',
          eventAction: stateProps.user.fullName,
          eventLabel: stateProps.dealRoom.name,
        },
      });
    },
  }),
)(reduxForm({ form, validate })(InboxComponent));

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';

// dm
import Checkable from '../../../atoms/Checkable';
import { MOBILE, TABLET } from '../../../../constants/breakpoints';

// own
import './ActivityFeed.scss';

class ActivityFeedsRowComponent extends Component {
  static propTypes = {
    dotMatch: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
  };

  render() {
    const { time, index, highlight, dotMatch, msg } = this.props;

    const className = classNames('activity-feeds__row', {
      'activity-feeds__row--select': highlight,
    });

    return (
      <div className={className}>
        <div className="activity-feeds__row__l-col">
          <Field
            name={`check.${[index]}`}
            component={Checkable}
            value={!!highlight}
          />
          {dotMatch && <mark className="activity-feeds__row__dot">.</mark>}
          <MediaQuery minWidth={TABLET.left}>
            <p className="activity-feeds__row__msg-content">{msg}</p>
          </MediaQuery>
          <MediaQuery maxWidth={MOBILE.right}>
            <div className="activity-feeds__row__msg-content">
              <div className="activity-feeds__row__msg">{time}</div>
              <p>{msg}</p>
            </div>
          </MediaQuery>
        </div>
        <MediaQuery minWidth={TABLET.left}>
          <div className="activity-feeds__row__r-col">
            <span className="activity-feeds__row__msg">{time}</span>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default ActivityFeedsRowComponent;

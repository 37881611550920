// vendor
import React from 'react';
import classNames from 'classnames';

export default ({ className, mobile, ...props }) => (
  <div
    {...props}
    className={classNames(className, 'modal__controls', {
      'modal__controls--mobile': mobile,
    })}
  />
);

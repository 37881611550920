// vendor
import React from 'react';

// own
import OpenModalButton from './OpenModalButton';

export default props => (
  <OpenModalButton
    color="red-s"
    frozen
    icon="label-error"
    modal="delete-pipeline"
    size="sm"
    text="Delete"
    {...props}
  />
);

// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export default ({ className, title, children, ...props }) => (
  <div {...props} className={classNames(className, 'modal__header')}>
    {title && (
      <h4 className="modal__header__title">
        <FormattedMessage id={title} />
      </h4>
    )}
    {children}
  </div>
);

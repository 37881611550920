// vendor
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { toJS } from '../../../utils/core';
// own
import './Aside.scss';

const Aside = ({ className, isOpen, children }) => (
  <aside className={classNames(className, 'aside', isOpen && 'open')}>
    {children}
  </aside>
);

export default connect(state => ({
  isOpen: toJS(state.get('app').get('asideMenu')),
}))(Aside);

// vendor
import React, { Component } from 'react';
import InputRange from 'react-input-range';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';

// dm
import FormGroup from '../../molecules/FormGroup';
import { toValueWithIndent } from '../../../utils/crutchFormat';

// own
import './Range.scss';

export default class RangeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        min: 0,
        max: 0,
      },
    };
  }

  componentWillMount() {
    const {
      range,
      input: { value },
    } = this.props;

    if (value) {
      this.setState({
        values: {
          min: range.indexOf(value.min),
          max: range.indexOf(value.max),
        },
      });
    } else {
      this.setState({
        values: {
          min: 0,
          max: range.length - 1,
        },
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      range,
      input: { value },
    } = this.props;

    const equalProps = isEqual(value, nextProps.input.value);
    if (!nextProps.input.value && !equalProps) {
      this.handleValuesChange(null, { min: 0, max: range.length - 1 });
    }

    return true;
  }

  handleValuesChange = values => {
    const { range, input } = this.props;

    if (values) {
      input.onChange({
        min: range[values.min],
        max: range[values.max],
      });
      this.setState({
        values,
      });
    } else {
      this.setState({
        values: {
          min: 0,
          max: range.length - 1,
        },
      });
    }
  };

  refactorLabel = labelValue => {
    const { range } = this.props;
    let realLabel = +labelValue;

    if (range instanceof Array && !isNaN(realLabel)) {
      if (realLabel === this.state.values.min) {
        if (!realLabel && !isFinite(range[0])) {
          realLabel = `Less than ${toValueWithIndent(range[1])}`;
        } else {
          realLabel = `from ${toValueWithIndent(range[realLabel])}`;
        }
      } else if (
        realLabel === range.length - 1 &&
        !isFinite(range[range.length - 1])
      ) {
        realLabel = `More than ${toValueWithIndent(range[range.length - 2])}`;
      } else {
        realLabel = `to ${toValueWithIndent(range[realLabel])}`;
      }
    }

    return realLabel;
  };

  render() {
    const { legend, range } = this.props;
    const { values } = this.state;
    return (
      <FormGroup type="col">
        <label>
          <FormattedMessage id={legend} />
        </label>
        <InputRange
          maxValue={range.length - 1}
          minValue={0}
          value={values}
          onChange={this.handleValuesChange}
          step={1}
        />
        <span className="input-range__label--min">
          {this.refactorLabel(values.min)}
        </span>
        <span className="input-range__label--max">
          {this.refactorLabel(values.max)}
        </span>
      </FormGroup>
    );
  }
}

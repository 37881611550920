import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { SubmissionError } from 'redux-form';

/* actions */
import { newsletterSubscribe } from '../../../store/actions/user';

/* ui-kit components */
import { Button, Caption, Input } from '../ui-kit';
import Form from '../../atoms/Form';

/* local styles */
import './subscribe-form.styles.scss';

const validate = values => {
  values = values.toJS();
  const errors = {};
  if (!values.email) {
    errors.email = 'enter_email';
  }
  return errors;
};

function submit(values, dispatch) {
  values = values.toJS();
  return dispatch(newsletterSubscribe(values)).then(response => {
    const { _status, title, detail } = response;
    if (_status === 400) {
      if (title === 'Member Exists') {
        throw new SubmissionError({ email: 'already_subscribe' });
      } else {
        throw new SubmissionError({ email: detail });
      }
    }
  });
}

const renderInput = ({ input, className, label, type, meta }) => {
  const { touched, error, active, invalid } = meta;

  return (
    <Input
      {...input}
      className={className}
      placeholder={label}
      type={type}
      message={error}
      danger={!active && touched && invalid}
      icon="markunread"
    />
  );
};

/* init component as function */
const SubscribeForm = ({ handleSubmit, submitting, submitSucceeded }) => {
  return submitSucceeded ? (
    <div className="subscribed_success">
      <Caption text="subscribe_success" />
    </div>
  ) : (
    <Form className="subscribe-form" onSubmit={handleSubmit(submit)}>
      <Field
        className="field"
        name="email"
        component={renderInput}
        label="your_email"
        type="text"
      />
      <div className="controls">
        <Button type={'submit'} disabled={submitting} children="subscribe" />
      </div>
    </Form>
  );
};

export default reduxForm({
  form: 'subscribe',
  validate,
})(SubscribeForm);

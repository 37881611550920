import React from 'react';

const getViewBox = name => {
  switch (name) {
    case 'windows':
      return '0 0 12 12';
    default:
      return '0 0 12 12';
  }
};

const getPath = (name, props) => {
  switch (name) {
    case 'windows':
      return (
        <>
          <path d="M4 0H12V8H4V0ZM5 1V7H11V1H5Z" {...props} />
          <path d="M0 4H3V5H1V11H7V9H8V12H0V4Z" {...props} />
        </>
      );
    default:
      return <path />;
  }
};

const SVGIcon = ({
  name = '',
  style = {},
  fill = '#000',
  viewBox = '',
  size = '20',
  className = '',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox={viewBox || getViewBox(name)}
    width={size}
    height={size}
    className={`${className} icon`}
    style={style}
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVGIcon;

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { reduxForm, Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
// dm
import Button from '../../../atoms/Button';
import Link from '../../../atoms/Link';
import Form from '../../../atoms/Form';
import IconLg from '../../../atoms/Icons/IconLg';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalControls from '../../Modal/ModalControls';
import Dropzone from '../../../molecules/Dropzone/Dropzone';
import { SUB_DOCUMENT } from '../../../../constants/files';
import { TABLET, DESKTOP } from '../../../../constants/breakpoints';
import {
  updateInvestment,
  createInvestmentMedia,
  readInvestmentHistoryPipeline,
  readInvestmentHistoryByFundId,
} from '../../../../store/actions/investments';
import { openModal } from '../../../../store/actions/modal';
import { form, selector } from '../../../../store/reducers/form/investment';
import { toJS } from '../../../../utils/core';
// own
import './UploadSubDocumentModal.scss';

class CounterSignatureDocumentModal extends Component {
  state = { uploading: false };

  componentWillReceiveProps() {
    this.setState({
      uploading: false,
    });
  }

  openDropzone = () => {
    this.setState({
      uploading: true,
    });
  };

  render() {
    const { progress, handleUpload, handleSubmit, fundName } = this.props;
    const investorDocument = toJS(this.props.investorDocument) || {};
    const amDocument = toJS(this.props.amDocument) || {};
    const renderControls =
      Object.keys(amDocument).length === 0 ? (
        <div>
          <div className="sub-doc-modal__text">
            <FormattedMessage id="modal.am.sub-documents.text_1" />
            &nbsp;
            <Link href={investorDocument.url} download={investorDocument.title}>
              <FormattedMessage id="modal.sub-documents.link-text" />
            </Link>
            &nbsp;
            <FormattedMessage id="for" />
            &nbsp;
            {fundName}
            .&nbsp;
            <FormattedMessage id="modal.am.sub-documents.text_2" />
          </div>
          {/* <div className='sub-doc-modal__text'>
          <FormattedMessage id='modal.sub-documents.text_3' />
        </div> */}
          <ModalControls>
            <Button text="Upload File" onClick={this.openDropzone} />
            <div className="sub-doc-modal__file-types">
              <FormattedMessage id="only .pdf file" />
            </div>
          </ModalControls>
        </div>
      ) : (
        <div className="sub-doc-modal__file">
          <MediaQuery minWidth={DESKTOP.left}>
            <IconLg
              className="document__extension"
              name={`document-${amDocument.extension}`}
            />
          </MediaQuery>
          <MediaQuery maxWidth={TABLET.right}>
            <IconLg
              className="document__extension"
              name={`document-${amDocument.extension}-sm`}
            />
          </MediaQuery>
          <div className="sub-doc-modal__file-name">{amDocument.title}</div>
          <Button
            className="sub-doc-modal__change-btn"
            text="Change file"
            flat
            onClick={this.openDropzone}
          />
        </div>
      );

    return (
      <Modal className="sub-doc-modal" {...this.props}>
        <ModalHeader title="modal.sub-documents.title" />
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Field
              name="dropzoneDocument"
              component={Dropzone}
              ref="dropzone"
              className="sub-doc-modal__dropzone"
              types={{ pdf: 'application/pdf' }}
              maxSize={SUB_DOCUMENT.SIZE_MAX}
              disableClick
              onLoad={handleUpload}
              start={this.state.uploading}
            >
              {progress && progress < 100 ? (
                <div className="sub-doc-modal__progress">
                  <div style={{ width: `${progress}%` }} />
                </div>
              ) : (
                renderControls
              )}
            </Field>
            {Object.keys(amDocument).length > 0 && (
              <ModalControls className="submit">
                <Button type="submit" text="Complete Upload" />
              </ModalControls>
            )}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  amDocument: selector(state, 'amDocument'),
  investorDocument: selector(state, 'investorDocument'),
  progress: selector(state, 'progress'),
  fundName: state
    .get('form')
    .get('fund')
    .get('values')
    .get('name'),
});

const mapDispatchToProps = (
  dispatch,
  { investmentId, action, actionProps, close },
) => ({
  handleUpload: file =>
    dispatch(
      createInvestmentMedia(
        {
          type: 'sub_document',
          title: file.name,
          file,
        },
        'amDocument',
      ),
    ),
  onSubmit: values => {
    dispatch(
      updateInvestment(investmentId, {
        amDocument: values.get('amDocument').toJS(),
      }),
    ).then(() => {
      const updateInvestments =
        action === 'pipeline'
          ? readInvestmentHistoryPipeline(
              actionProps.fundId,
              actionProps.userId,
            )
          : readInvestmentHistoryByFundId(actionProps.fundId);
      dispatch(updateInvestments).then(() => {
        close();
        dispatch(openModal('AmUploadSuccess'));
      });
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form,
  })(CounterSignatureDocumentModal),
);

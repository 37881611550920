const BREAKPOINTS = {
  MOBILE: { left: null, right: 767 },
  TABLET: { left: 768, right: 1199 },
  DESKTOP: { left: 1200, right: 1439 },
  DESKTOP_LARGE: { left: 1440, right: 1919 },
  DESKTOP_HD: { left: 1920, right: null },
};

export const {
  MOBILE,
  TABLET,
  DESKTOP,
  DESKTOP_LARGE,
  DESKTOP_HD,
} = BREAKPOINTS;

export default BREAKPOINTS;

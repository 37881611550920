// vendor
import { formValueSelector, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'browseInvestments';

/* initial state */
const initialState = fromJS({
  values: {
    weightOrder: true,
  },
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);

/* reducer */
export default createReducer(initialState, {}); // ???

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// dm
import { markInboxMessageAsRead } from '../../../store/actions/inbox';

// own
import './InboxMessage.scss';
import Message from './Message';

export default class InboxMessageComponent extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.markMessageAsRead();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.markMessageAsRead();
    }
  }

  markMessageAsRead() {
    const MESSAGE_READING_TIMEOUT = 1000;
    const {
      dispatch,
      id: messageId,
      author,
      fundId,
      isRead,
      user,
    } = this.props;
    if (author && user && user.id !== author.id && !isRead) {
      setTimeout(() => {
        dispatch(markInboxMessageAsRead(messageId, fundId));
      }, MESSAGE_READING_TIMEOUT);
    }
  }

  render() {
    const { createdAt, author, isRead, text, user } = this.props;
    // console.error('INBOX MESSAGE', author);
    return !author ? null : (
      <Message
        type="inbox"
        author={author}
        {...{ createdAt, author, isRead, user }}
      >
        {text}
      </Message>
    );
  }
}

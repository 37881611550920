// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './IconLg.scss';

export default class IconLgComponent extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    resizable: PropTypes.bool,
  };

  render() {
    const { className, name, resizable } = this.props;
    const fullClassName = classNames(className, 'icon-lg', {
      [`icon-lg--${name}`]: name,
      'icon-lg--resizable': resizable,
    });
    return <div className={fullClassName} />;
  }
}

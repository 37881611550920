// vendor
import { hasIn } from 'lodash';
// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'READ_LIST_REQUEST',
  'READ_LIST_REQUEST_SUCCESS',
  'READ_LIST_REQUEST_FAILURE',
);

/* list name to list url */
export const AVAILABLE_LISTS = {
  accountTypes: 'account-types',
  accreditation: 'accreditation',
  availableToInvestors: 'fund/available-to-investors',
  averageInvestment: 'average-investment',
  assetsUnderManagement: 'assets-under-management',
  citizenship: 'citizenship',
  country: 'countries',
  eligibleInvestors: 'eligible-investor',
  entityTypes: 'entity-types',
  firmAum: 'firm-aum',
  fundAum: 'fund-aum',
  fundType: 'fund-type',
  hedgeFundStrategy: 'hedge-fund-strategy',
  cryptoassetFundStrategy: 'crypto-asset-strategy',
  industryFocus: 'industry-focus',
  investorType: 'investor-type',
  noLongerInterestedReason: 'fund/no-interested-reasons',
  pipelineStage: 'fund/pipeline-statuses',
  preferredAssetType: 'preferred-asset-type',
  qualification: 'qualification',
  redemptionPeriod: 'fund/redemption-period',
  state: 'us-state',
  typeOfOffering: 'fund/type-of-offering',
  ventureCapitalStageFocus: 'venture-capital-stage-focus',
  waterMark: 'fund/water-mark',
  privateDebtCreditFocus: 'private-debt-credit-focus',
};

export const getList = list => {
  if (hasIn(AVAILABLE_LISTS, list)) {
    return {
      [CALL_API]: {
        types: [
          types.READ_LIST_REQUEST,
          types.READ_LIST_REQUEST_SUCCESS,
          types.READ_LIST_REQUEST_FAILURE,
        ],
        method: 'get',
        url: `lists/${AVAILABLE_LISTS[list]}`,
        name: list,
        withProgressBar: true,
      },
    };
  }
  throw new Error(`List "${list}" - Not Available`);
};

export default [
  {
    label: 'ACH',
    value: 1,
  },
  {
    label: 'WIRE',
    value: 2,
  },
  {
    label: 'CHECK',
    value: 3,
  },
];

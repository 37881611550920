// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Tag from '../../../../atoms/Tag/Tag';
import Accordion from '../../../Accordion/Accordion';
import AccordionTitle from '../../../Accordion/AccordionTitle';
import AccordionContent from '../../../Accordion/AccordionContent';
import { listParam } from '../../../../../utils/collection';

export default class InvestorInformationDesktop extends React.Component {
  render() {
    const {
      lists,
      firmAum,
      averageInvestment,
      industryFocuses,
      preferredAssetTypes,
      hedgeFundStrategies,
      ventureCapitalStageFocuses,
      tagSize,
      className,
    } = this.props;
    const fullClassName = classNames('am-investor-card__info', className);
    return (
      <div className={fullClassName}>
        <div className="am-investor-card__info__row">
          <div className="am-investor-card__info__row__item">
            <div>
              <FormattedMessage id="Average Investment" />:
            </div>
            <div>{listParam(averageInvestment, lists.averageInvestment)}</div>
          </div>
          {firmAum && (
            <div className="am-investor-card__info__row__item am-investor-card__info__row__item--green">
              <div>
                <FormattedMessage id="Assets Under Management" />:
              </div>
              <div>{listParam(firmAum, lists.firmAum)}</div>
            </div>
          )}
        </div>
        <div className="am-investor-card__info__row">
          {!!preferredAssetTypes.length && (
            <Accordion className="am-investor-card__info__row__item">
              <AccordionTitle iconOpen="minus" iconHide="plus">
                <span>
                  <FormattedMessage id="Preferred Asset Types" />
                  &nbsp; (<strong>{preferredAssetTypes.length}</strong>)
                </span>
              </AccordionTitle>
              <AccordionContent>
                {preferredAssetTypes.map(id => (
                  <Tag size={tagSize} key={id}>
                    {listParam(id, lists.preferredAssetType)}
                  </Tag>
                ))}
              </AccordionContent>
            </Accordion>
          )}
          {!!hedgeFundStrategies.length && (
            <Accordion className="am-investor-card__info__row__item">
              <AccordionTitle iconOpen="minus" iconHide="plus">
                <span>
                  <FormattedMessage id="Preferred Hedge Fund Strategies" />
                  &nbsp; (<strong>{hedgeFundStrategies.length}</strong>)
                </span>
              </AccordionTitle>
              <AccordionContent>
                {hedgeFundStrategies.map(id => (
                  <Tag size={tagSize} key={id}>
                    {listParam(id, lists.hedgeFundStrategy)}
                  </Tag>
                ))}
              </AccordionContent>
            </Accordion>
          )}
          {!!ventureCapitalStageFocuses.length && (
            <Accordion className="am-investor-card__info__row__item">
              <AccordionTitle iconOpen="minus" iconHide="plus">
                <span>
                  <FormattedMessage id="Preferred Venture Capital Stage Focus" />
                  &nbsp; (<strong>{ventureCapitalStageFocuses.length}</strong>)
                </span>
              </AccordionTitle>
              <AccordionContent>
                {ventureCapitalStageFocuses.map(id => (
                  <Tag key={id} size={tagSize}>
                    {listParam(id, lists.ventureCapitalStageFocus)}
                  </Tag>
                ))}
              </AccordionContent>
            </Accordion>
          )}
          {!!industryFocuses.length && (
            <Accordion className="am-investor-card__info__row__item">
              <AccordionTitle iconOpen="minus" iconHide="plus">
                <span>
                  <FormattedMessage id="Industry Focus" />
                  &nbsp; (<strong>{industryFocuses.length}</strong>)
                </span>
              </AccordionTitle>
              <AccordionContent>
                {industryFocuses.map(id => (
                  <Tag size={tagSize} key={id}>
                    {listParam(id, lists.industryFocus)}
                  </Tag>
                ))}
              </AccordionContent>
            </Accordion>
          )}
        </div>
      </div>
    );
  }
}

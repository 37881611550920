// vendor
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../atoms/Button';
import { acceptFundPipeline } from '../../../store/actions/fund';

const AccessAcceptButton = props => (
  <Button
    size="sm"
    icon="checked-lg"
    iconPos="left"
    color="green"
    text="Accept"
    frozen
    {...props}
  />
);

export default connect(
  null,
  null,
  (s, { dispatch }, { actionProps, ...props }) => ({
    ...props,
    onClick: () => dispatch(acceptFundPipeline(actionProps)),
  }),
)(AccessAcceptButton);

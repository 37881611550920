// vendor
import React from 'react';

// own
import InvestorInformationHD from './InvestorInformation/InvestorInformationHD';
import InvestorInformationDesktop from './InvestorInformation/InvestorInformationDesktop';

export default ({ deviceSize, ...props }) =>
  deviceSize === 'desktop-hd' ? (
    <InvestorInformationHD {...props} />
  ) : (
    <InvestorInformationDesktop {...props} />
  );

// Action key that carries API call info interpreted by this Redux middleware.
import 'core-js';

export const CALL_API = Symbol('Call API');

export const TYPES_LENGTH = 3;

export const METHODS = ['get', 'delete', 'head', 'post', 'put', 'patch'];

export const REQUEST_TIMEOUT = 60000;

const KB = 1024;
const MB = KB * KB;
const MB_MAX = 30;
export const SIZE_MAX = MB_MAX * MB;

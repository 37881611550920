// vendor
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { destroy } from 'redux-form/immutable';
import Helmet from 'react-helmet';
import Loadable from 'react-loadable';

// dm
import readData from '../../enhancers/readData';
import {
  readDealRooms,
  readInvestmentAmount,
} from '../../store/actions/inv-dashboard';
import { form } from '../../store/reducers/form/inv-dashboard';

// own
import './InvDashboard.scss';

const InvDashboardIndex = Loadable({
  loader: () =>
    import(/* webpackChunkName: "InvDashboardIndex" */ './Index/index'),
  loading: () => null,
  modules: ['InvDashboardIndex'],
});
const FavoriteFunds = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FavoriteFunds" */ './FavoriteFunds'),
  loading: () => null,
  modules: ['FavoriteFunds'],
});
const ArchivedDeals = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ArchivedDeals" */ './ArchivedDeals'),
  loading: () => null,
  modules: ['ArchivedDeals'],
});

const DealRooms = Loadable({
  loader: () => import(/* webpackChunkName: "DealRooms" */ './DealRooms'),
  loading: () => null,
  modules: ['DealRooms'],
});

const BrowseInvestments = Loadable({
  loader: () =>
    import(/* webpackChunkName: "BrowseInvestments" */ './BrowseInvestments'),
  loading: () => null,
  modules: ['BrowseInvestments'],
});

const InvDashboardView = () => (
  <div>
    <Helmet title="Investor Dashboard | DarcMatter" />
    <Switch>
      <Route path="/inv-dashboard/tabs" component={InvDashboardIndex} />
      <Route
        exact
        path="/inv-dashboard/favorite-funds"
        component={FavoriteFunds}
      />
      <Route path="/inv-dashboard/deal-rooms/:id" component={DealRooms} />} />
      <Route
        exact
        path="/inv-dashboard/archived-deals"
        component={ArchivedDeals}
      />
      <Route
        exact
        path="/inv-dashboard/browse-investments"
        component={BrowseInvestments}
      />
      <Redirect to="/inv-dashboard/tabs" />
    </Switch>
  </div>
);

export default readData({
  listNames: [
    'fundType',
    'hedgeFundStrategy',
    'redemptionPeriod',
    'eligibleInvestors',
    'waterMark',
  ].join(' '),
  read: dispatch =>
    dispatch(readDealRooms()).then(() => dispatch(readInvestmentAmount())),
  clear: dispatch => dispatch(destroy(form)),
})(InvDashboardView);

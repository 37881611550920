// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// dm
import Button from '../../../atoms/Button';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import ModalControls from '../../Modal/ModalControls';

class InvestRequestSuccessModal extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.context.store.dispatch({
      type: 'GTM_EVENT',
      payload: {
        event: 'DOCUMENT_SUBSCRIPTION_UPLOADED',
        eventCategory: 'DOCUMENT_SUBSCRIPTION_UPLOADED',
        eventLabel: 'investor',
        eventAction: this.context.store
          .getState()
          .get('user')
          .toJS().fullName,
      },
    });
  }

  render() {
    const { close, fundName } = this.props;
    return (
      <Modal {...this.props}>
        <ModalHeader title="modal.invest-request-success.title" />
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage id="modal.invest-request-success.text1" />
            &nbsp;
            {fundName}
            &nbsp;
            <FormattedMessage id="modal.invest-request-success.text2" />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalControls>
            <Button text="Ok" onClick={close} />
          </ModalControls>
        </ModalFooter>
      </Modal>
    );
  }
}

export default InvestRequestSuccessModal;

// vendor
import { formValueSelector, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
// dm
import { createReducer } from '../../../utils/core';
import { userTypes, formTypes } from '../../actions';

/* form name */
export const form = 'profile';

/* initial state */
const initialState = fromJS({});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);
const getFormState = state => state.form;
const getNamedFormState = formName =>
  createSelector(
    [getFormState],
    (formState = {}) => formState[formName],
  );
export const registeredFieldsSelector = formName =>
  createSelector(
    [getNamedFormState(formName)],
    (namedFormState = {}) => namedFormState.registeredFields,
  );

/* reducer */
export default createReducer(initialState, {
  [userTypes.READ_PROFILE_SUCCESS]: (state, { response }) =>
    state.mergeDeep({ values: response.data }),
  [userTypes.CREATE_PROFILE_SUCCESS]: (state, { response }) =>
    state.mergeDeep({ values: response.data }),
  [formTypes.MARK_FIRST_EMPTY_REQUIRED]: (state, payload) =>
    state.mergeDeep(payload),
  [userTypes.UPDATE_PROFILE_PROGRESS_BAR]: (state, payload) =>
    state.mergeDeep({ values: payload }),
});

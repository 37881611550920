// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import classNames from 'classnames';
import { isEmpty, omit, omitBy } from 'lodash';

// dm
import Form from '../../../atoms/Form';
import Button from '../../../atoms/Button';
import Panel from '../Panel';
import {
  closeFiltersPanel,
  applyFiltersPanelFilters,
} from '../../../../store/actions/filters-panel';
import { form, selector } from '../../../../store/reducers/form/filters-panel';

// own
import './FiltersPanel.scss';

class FiltersPanelComponent extends Component {
  render() {
    const {
      className,
      children,
      initialize,
      handleSubmit,
      hideFiltersPanel,
      resetFilters,
      ...props
    } = this.props;
    const fullClassName = classNames(className, 'filters-panel');
    return (
      <Panel {...props} className={fullClassName} withOverlay>
        <Form onSubmit={handleSubmit}>
          <div className="filters-panel__fields">{children}</div>
          <div className="filters-panel__controls">
            <div className="filters-panel__controls__center">
              <Button
                size="sm"
                icon="arrow-bold-sm"
                color="blue-g"
                border="none"
                flat
                frozen
                text="Close Filters"
                onClick={hideFiltersPanel(initialize)}
              />
            </div>
            <Button type="submit" size="sm" frozen text="Apply" />
            <Button
              className="filters-panel__controls__reset"
              size="sm"
              color="blue-g"
              border="none"
              flat
              frozen
              text="Reset All"
              onClick={resetFilters(initialize)}
            />
          </div>
        </Form>
      </Panel>
    );
  }
}

export default connect(
  state => ({
    opened: selector(state, 'opened'),
    filters: selector(state, 'filters').toJS(),
  }),
  null,
  (stateProps, { dispatch }, ownProps) => ({
    ...stateProps,
    ...ownProps,
    hideFiltersPanel: initialize => () => {
      const { opened, filters } = stateProps;
      initialize({ ...filters, opened, filters });
      dispatch(closeFiltersPanel());
    },
    resetFilters: initialize => () => {
      initialize({ opened: false, filters: {} });
    },
    onSubmit: values => {
      let filters = omit(values.toJS(), 'opened filters'.w());
      filters = omitBy(filters, isEmpty);
      dispatch(applyFiltersPanelFilters(filters));
      dispatch(closeFiltersPanel());
    },
  }),
)(reduxForm({ form })(FiltersPanelComponent));

// vendor
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';

// dm
import Input from './index';

export default class InputFieldComponent extends Component {
  // TODO: develop functionality for formatBuilder
  // formatBuilder({...}) takes different parameters
  // and configures according to them format function
  // The final formatBuilder must looks like this:
  // format = formatBuilder({
  //   prefix: '$',
  //   suffix: '%',
  //   allowDecimal: true,
  //   allowNegative: true,
  //   thousandSeparator: ',',
  // })
  // see example: https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js
  render() {
    const { props } = this;
    return (
      <Field {...props} component={Input} hasFormatting={!!props.format} />
    );
  }
}

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink as ReactLink } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

// dm
import { getPath } from '../../../utils/route';

// own
import './Link.scss';

class LinkComponent extends Component {
  static propTypes = {
    to: PropTypes.string,
    text: PropTypes.string,
    params: PropTypes.object,
    underline: PropTypes.bool,
    target: PropTypes.string,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    underline: false,
  };

  render() {
    const {
      className,
      to,
      params,
      underline,
      text,
      intl,
      activeClassName,
      ...props
    } = this.props;
    const fullClassName = classNames(className, 'link', {
      'link--underline': underline,
    });
    const children =
      props.children || (text && intl.formatMessage({ id: text }));

    return to ? (
      <ReactLink
        {...props}
        to={getPath(to, params)}
        className={fullClassName}
        activeClassName={activeClassName}
      >
        {children}
      </ReactLink>
    ) : (
      <a {...props} className={fullClassName}>
        {children}
      </a>
    );
  }
}

export default injectIntl(LinkComponent);

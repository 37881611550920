export default {
  NEW: 24,
  PENDING_MANUAL: 31,
  NOT_ElIGIBLE_FOR_QUESTIONS: 29,
  ERROR: 28,
  DECLINED: 27,
  NO_ACCOUNT_AUTO: 41, // 32,
  NO_ACCOUNT_MANUAL: 40, // 33,
  APPROVED_AUTO: 25,
  APPROVED_MANUAL: 26,
};

// vendor
import React from 'react';
import { connect } from 'react-redux';
// dm
import OpenModalButton from './OpenModalButton';
import {
  readPipelineDocumentsDays,
  allowPipelineDocuments,
} from '../../../store/actions/fund';

const DocumentsAcceptButton = props => (
  <OpenModalButton
    size="sm"
    text="Grant Access"
    frozen
    disabled={!props.actionProps.isPipelined}
    modal="cooling-off-period"
    {...props}
  />
);

export default connect(
  null,
  (dispatch, { actionProps }) => ({
    handleClick: openModal => {
      const { isDocumentsAccepted, id, investorId, documents } = actionProps;
      const allowDocuments = () => {
        dispatch(allowPipelineDocuments({ id, investorId, documents }));
      };
      if (isDocumentsAccepted) {
        allowDocuments();
      } else {
        dispatch(readPipelineDocumentsDays({ id, investorId })).then(
          ({ data: days }) => {
            if (days) {
              openModal({ days });
            } else {
              allowDocuments();
            }
          },
        );
      }
    },
  }),
)(DocumentsAcceptButton);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import Caption from '../caption';
import Icon from '../icon';

/* local (Checkbox) styles */
import './checkbox.styles.scss';

const Checkbox = ({
  label,
  pos,
  message,
  danger,
  className,
  disabled,
  checked,
  callBack,
  value,
  onClick,
}) => {
  const classNames = cx(
    'checkbox',
    !checked && danger && 'error',
    checked && 'checked',
    pos === 'col' && 'posCol',
    className,
  );
  return (
    <div
      className={classNames}
      disabled={disabled}
      onClick={callBack ? evt => callBack(evt, value) : onClick}
    >
      <div className="switch">
        {checked && <Icon name="checkmark" color="white" size="11" />}
      </div>
      {label && (
        <FormattedMessage id={label}>
          {txt => <label className="label">{txt}</label>}
        </FormattedMessage>
      )}
      {!checked && message && (
        <Caption className="errorMessage" error text={message} />
      )}
    </div>
  );
};

Checkbox.propTypes = {
  //callBack: PropTypes.func,
  label: PropTypes.string,
  message: PropTypes.string,
  checked: PropTypes.bool,
  danger: PropTypes.bool,
  pos: PropTypes.oneOf(['row', 'col']),
};

Checkbox.defaultProps = {
  checked: false,
  danger: false,
  pos: 'row',
  message: '',
};

export default Checkbox;

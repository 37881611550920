// vendor
import React, { Component } from 'react';
import moment from 'moment';

// dm
import Button from '../../../atoms/Button';
import { checkInvestor, checkAdmin } from '../../../../utils/access';

// own
import './ActivityFeedsContent.scss';
import ActivityFeed from './ActivityFeed';
import EmptyRow from './ActivityFeedsContentEmpty';

class ActivityFeedsContentComponent extends Component {
  render() {
    const {
      index,
      scrollContent,
      activityFeeds,
      showElms,
      filterDate,
      user,
      heightContainer,
      feedCheck,
      rowsHighlight,
      filter,
      behavior,
      showMore,
      breakpoint,
    } = this.props;
    const arrContentRows = [];
    let countValidElms = 0;

    if (feedCheck) {
      activityFeeds.forEach((elem, i) => {
        let highlight = null;
        const timeDate = moment.unix(elem.createdAt);
        const year = timeDate.format('YYYY');
        const month =
          breakpoint !== 'desktop-large'
            ? timeDate.format('MMM')
            : timeDate.format('MMMM');
        const filterItemsDate =
          !filterDate ||
          (filterDate.month === month && filterDate.year === year);
        const matchFiltersItemsReadeable =
          !filter ||
          behavior[elem.fundId][`${filter}Arr`].indexOf(+elem.id) >= 0;

        if (filterItemsDate && matchFiltersItemsReadeable) {
          countValidElms++;
          if (arrContentRows.length < showElms) {
            const dotMatch = behavior
              ? behavior[elem.fundId].readArr.indexOf(elem.id) < 0
              : false;
            const time = timeDate.format('MM/DD/YYYY [at] h:mm A');
            let text = 'assetManager';
            if (checkInvestor(user)) {
              text = 'investor';
            } else if (checkAdmin(user)) {
              text = 'admin';
            }
            if (
              rowsHighlight[elem.fundId] &&
              rowsHighlight[elem.fundId].indexOf(+elem.id) >= 0
            ) {
              highlight = true;
            }
            arrContentRows.push(
              <ActivityFeed
                key={i}
                index={i}
                msg={elem.message}
                text={elem[`${text}StatusText`]}
                {...{ dotMatch, highlight, time, i }}
              />,
            );
          }
        }
      });
    } else {
      arrContentRows.push(<EmptyRow key={0} />);
    }

    return (
      <div className="activity-feeds__main">
        <div
          className="activity-feeds__row-container"
          style={{ height: heightContainer }}
          onScroll={scrollContent.bind(null, index)}
        >
          {arrContentRows}
        </div>
        {feedCheck && showElms < countValidElms && (
          <Button
            type="button"
            color="white"
            border="none"
            size="sm"
            onClick={showMore}
          >
            Show more ({`${countValidElms - showElms}`})
          </Button>
        )}
      </div>
    );
  }
}

export default ActivityFeedsContentComponent;

import { toString } from 'lodash';

export const formatInteger = value => {
  value = toString(value);
  if (value) {
    value = value.replace(/\D/g, '');
    if (value === 0) {
      return '0';
    }
    value = value.replace(/^0+/g, '');
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

export const formatNumbers = value => {
  value = toString(value);
  if (value) {
    return value.replace(/[^\d]/g, '');
  }
  return value;
};

export const formatDecimal = value => {
  value = toString(value);
  if (value) {
    value = value.replace(/[^(?:\d*.)?\d]/g, '');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const dotIndex = value.indexOf('.');
    if (dotIndex !== -1) {
      value = value.slice(0, dotIndex + 3);
    }
  }
  return value;
};

export const formatPercentage = value => {
  value = toString(value);
  if (value === '-') {
    return value;
  }
  if (value) {
    const DECIMALS = 2;
    const isNegative = value[0] === '-';
    const indexOfLastDecimal = value.lastIndexOf('.');
    const hasDecimal = !!~indexOfLastDecimal;
    const decimal = hasDecimal ? '.' : '';
    let integer = '';
    let fraction = '';
    if (hasDecimal) {
      const startIndex = isNegative ? 1 : 0;
      integer = value.slice(startIndex, indexOfLastDecimal);
      fraction = value.slice(indexOfLastDecimal + 1, value.length);
    } else {
      integer = value;
    }
    if (fraction.length > DECIMALS) {
      fraction = fraction.slice(0, DECIMALS);
    }
    integer = integer.replace(/[^\d]/g, '');
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    fraction = fraction.replace(/[^\d]/g, '');
    value = integer + decimal + fraction;
    value += '%';
    if (isNegative) {
      value = `-${value}`;
    }
  }
  return value;
};

export const formatPercentagePositive = value => {
  value = toString(value);
  if (value) {
    const DECIMALS = 2;
    const indexOfLastDecimal = value.lastIndexOf('.');
    const hasDecimal = !!~indexOfLastDecimal;
    const decimal = hasDecimal ? '.' : '';
    let integer = '';
    let fraction = '';
    if (hasDecimal) {
      integer = value.slice(0, indexOfLastDecimal);
      fraction = value.slice(indexOfLastDecimal + 1, value.length);
    } else {
      integer = value;
    }
    if (fraction.length > DECIMALS) {
      fraction = fraction.slice(0, DECIMALS);
    }
    integer = integer.replace(/[^\d]/g, '');
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    fraction = fraction.replace(/[^\d]/g, '');
    value = integer + decimal + fraction;
    value += '%';
  }
  return value;
};

export const formatMultiple = value => {
  value = toString(value);
  if (value) {
    const hasX = !!~value.indexOf('x');
    value = value.replace(/[^(?:\d*.)?\d]/g, '');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const dotIndex = value.indexOf('.');
    if (dotIndex !== -1) {
      value = value.slice(0, dotIndex + 3);
    }
    if (hasX) {
      value += 'x';
    }
  }
  return value;
};

export const formatBankCard = value => {
  if (!value) {
    return value;
  }
  return value
    .replace(/[^\d]/g, '')
    .toString()
    .split('')
    .join(' ');
};

export const formatOfThousands = value => {
  if (!value) {
    return value;
  }
  return value
    .toString()
    .replace(/[^\d]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatTaxId = type => value => {
  if (!value) {
    return '';
  }
  value = value.toString();
  const MAX_LENGTH = 9;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (type === 'EIN') {
    if (onlyNums.length <= 2) {
      return `${onlyNums}`;
    }
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, MAX_LENGTH)}`;
  }
  if (onlyNums.length <= 3) {
    return `${onlyNums}`;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    MAX_LENGTH,
  )}`;
};

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

// own
import './OverlaySpinner.scss';
import DotsSpinner from './DotsSpinner';

export default class OverlaySpinnerComponent extends Component {
  static propTypes = {
    active: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
  };

  componentDidUpdate() {
    if (this.props.active) {
      findDOMNode(this).parentNode.style.position = 'relative';
    }
  }

  render() {
    const { className, active } = this.props;
    const fullClassName = classNames(className, 'overlay-spinner');
    return (
      active && (
        <div className={fullClassName}>
          <DotsSpinner />
        </div>
      )
    );
  }
}

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './FormControlStatic.scss';

const FormControlStaticComponent = props => {
  const className = classNames(props.className, 'form-control-static', {
    [`form-control-static--${props.size}`]: props.size,
  });
  return <div {...props} className={className} />;
};

FormControlStaticComponent.propTypes = {
  size: PropTypes.oneOf('md'.w()),
};

export default FormControlStaticComponent;

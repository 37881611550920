// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './DropdownList.scss';
import DropdownListItem from './DropdownListItem';

export default class DropdownListComponent extends Component {
  static propTypes = {
    list: PropTypes.array,
  };

  static defaultProps = {
    list: [],
  };

  render() {
    const { className, children, list, closeContent } = this.props;
    const fullClassName = classNames(className, 'dropdown-list');
    return (
      <div className={fullClassName}>
        {children ||
          list.map((item, index) => (
            <DropdownListItem
              key={index}
              {...item}
              closeContent={closeContent}
            />
          ))}
      </div>
    );
  }
}

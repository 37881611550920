// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import Button from '../../atoms/Button';
import IconLg from '../../atoms/Icons/IconLg';

// own
import './NotesEmpty.scss';

export default ({ toggleSendForm }) => (
  <div className="notes__empty">
    <div className="notes__empty__container">
      <IconLg name="msg-blocked" resizable />
      <FormattedMessage id="There are no notes to show." />
      <div className="notes__header">
        <Button
          size="sm"
          icon="feather"
          text="Add note"
          onClick={toggleSendForm}
        />
      </div>
    </div>
  </div>
);

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { isNaN, toNumber, slice, includes } from 'lodash';

// dm
import Tooltip from '../Tooltip';
import Checkable from '../../atoms/Checkable';
import { toJS } from '../../../utils/core';

// own
import './CheckableGroup.scss';

export default class CheckableGroupComponent extends Component {
  static propTypes = {
    dots: PropTypes.bool,
    legend: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    multi: PropTypes.bool,
    disabled: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    required: PropTypes.bool,
    tooltips: PropTypes.object,
    translate: PropTypes.bool,
    optionComponent: PropTypes.any,
    optionProps: PropTypes.object,
    hint: PropTypes.string,
    LegendIconsComponent: PropTypes.any,
  };

  static defaultProps = {
    value: fromJS([]),
    multi: true,
    disabled: [],
    tooltips: {},
    translate: true,
    optionProps: {},
    hint: '',
    LegendIconsComponent: null,
  };

  isChecked(optionValue) {
    const { multi } = this.props;
    let { value } = this.props;
    value = toJS(value);
    return multi ? value.includes(optionValue) : value === optionValue;
  }

  handleChange = (event, inputOnChange) => {
    const { name, value, multi, onChange } = this.props;
    let newValue = event.target.value;
    if (multi) {
      let checkValue = toNumber(newValue);
      if (isNaN(checkValue)) {
        checkValue = newValue;
      }
      newValue = slice(toJS(value));
      if (includes(newValue, checkValue)) {
        newValue = newValue.filter(optionValue => optionValue !== checkValue);
      } else {
        newValue.push(checkValue);
      }
    }
    newValue = fromJS(newValue);
    if (onChange && newValue !== value) {
      return onChange({ newValue, inputOnChange, name });
    }
    return inputOnChange(newValue);
  };

  render() {
    const {
      legend,
      name,
      options,
      multi,
      disabled,
      required,
      translate,
      dots,
      tooltips,
      optionProps,
      optionComponent,
      hint,
      LegendIconsComponent,
      legendIcons,
      ...props
    } = this.props;
    const className = classNames(props.className, 'checkable-group');
    const type = props.type || (multi ? 'checkbox' : 'radio');
    return (
      <fieldset {...props} className={className}>
        {legend && (
          <legend>
            {LegendIconsComponent && (
              <LegendIconsComponent legendIcons={legendIcons} legend={legend} />
            )}
            {translate ? (
              <FormattedMessage id={legend} />
            ) : (
              <span>{legend}</span>
            )}
            {required && <span className="checkable-group__asterisk">*</span>}
            {dots && ':'}
            {hint && (
              <Tooltip responsive>
                <FormattedMessage id={hint} />
              </Tooltip>
            )}
          </legend>
        )}
        <div className="checkable-group__options">
          {options.map(({ value: optionValue, ...checkableProps }) => (
            <Field
              key={optionValue}
              {...checkableProps}
              {...optionProps}
              component={Checkable}
              type={type}
              name={name}
              props={{
                value: optionValue,
                onChange: this.handleChange,
              }}
              OptionComponent={optionComponent}
              tooltip={tooltips[optionValue]}
              checked={this.isChecked(optionValue)}
              disabled={includes(disabled, optionValue)}
              translate={translate}
            />
          ))}
        </div>
      </fieldset>
    );
  }
}

// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// dm
import IconLg from '../../atoms/Icons/IconLg';
import OverlaySpinner from '../../atoms/Spinners/OverlaySpinner';

// own
import './InboxEmpty.scss';

export default ({ showTextArea, reading }) => {
  const fullClassName = classNames('inbox__empty', {
    'inbox__empty--offset': showTextArea,
  });
  return (
    <div className={fullClassName}>
      <OverlaySpinner
        className="inv-dashboard-aside__spinner"
        active={reading}
      />
      <IconLg name="msg-blocked" resizable />
      <FormattedMessage id="There is no recent message to show" />
    </div>
  );
};

// vendor
import React from 'react';
import MediaQuery from 'react-responsive';
import { snakeCase } from 'lodash';

// dm
import BREAKPOINTS from '../constants/breakpoints';

export default ({
  style = { display: 'inline-flex' },
  fromZero = true,
  breakpoints,
  toInfinity = true,
}) => ComposedComponent => props => (
  <div style={style}>
    {breakpoints.reduce((queries, breakpoint, index) => {
      const breakpointProps = {};
      const BREAKPOINT = BREAKPOINTS[snakeCase(breakpoint).toUpperCase()];
      const first = !index;
      const last = index === breakpoints.length - 1;
      if (!fromZero || !first) {
        breakpointProps.minWidth = BREAKPOINT.left;
      }
      if (!toInfinity || !last) {
        breakpointProps.maxWidth = BREAKPOINT.right;
      }
      queries.push(
        <MediaQuery key={index} {...breakpointProps}>
          <ComposedComponent {...props} breakpoint={breakpoint} />
        </MediaQuery>,
      );
      return queries;
    }, [])}
  </div>
);

// vendor
import React from 'react';
import classNames from 'classnames';

// own
import './Tabs.scss';

export default ({ className, ...props }) => (
  <div {...props} className={classNames(className, 'tabs')} />
);

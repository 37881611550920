// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

// own
import './Button.scss';
import FormControl from '../FormControl';

class ButtonComponent extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    color: PropTypes.oneOf([
      'blue',
      'blue-dark2',
      'blue-g',
      'green',
      'red-s',
      'white',
      'cyan-p',
      'gray',
      'linkedin',
    ]),
    text: PropTypes.string,
    border: PropTypes.oneOf(['dashed', 'dotted', 'none']),
    disabled: PropTypes.bool,
    flat: PropTypes.bool,
    frozen: PropTypes.bool,
    square: PropTypes.bool,
    mobileTextWrap: PropTypes.bool,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    type: 'button',
    color: 'blue',
    iconPos: 'left',
  };

  render() {
    const {
      size,
      icon,
      iconPos,
      disabled,
      className,
      color,
      border,
      flat,
      square,
      frozen,
      children,
      intl,
      text,
      mobileTextWrap,
      ...restProps
    } = this.props;
    const field = {
      size,
      icon,
      iconPos,
      disabled,
      className: classNames(className, {
        [`btn--${color}`]: !flat,
        [`btn--flat btn--${color}--flat`]: flat,
        'btn--square': square,
        'btn--frozen': frozen,
        'btn--mobile-text-wrap': mobileTextWrap,
      }),
      style: { borderStyle: border },
    };
    return (
      <FormControl kind="btn" {...field}>
        <button {...restProps} disabled={disabled}>
          {children || (text && intl.formatMessage({ id: text }))}
        </button>
      </FormControl>
    );
  }
}

export default injectIntl(ButtonComponent);

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames';
import { omit, join, values } from 'lodash';

// own
import './Dropzone.scss';

export default class DropzoneComponent extends React.Component {
  static propTypes = {
    types: PropTypes.object,
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    onLoad: PropTypes.func.isRequired,
  };

  static defaultProps = {
    multiple: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.start) {
      this.open();
    }
  }

  onLoad = files => {
    const { maxSize, multiple, onLoad } = this.props;
    const normalFiles = files.filter(({ size }) => size <= maxSize);
    if (normalFiles.length) {
      onLoad(multiple ? normalFiles : normalFiles[0]);
    }
  };

  open = () => {
    this.refs.reactDropzone.open();
  };

  render() {
    const { types, showFundFormErrors } = this.props;
    const { error, touched } = this.props.meta || false;
    const props = omit(
      this.props,
      'types maxSize error input meta start showFundFormErrors onLoad'.w(),
    );
    const className = classNames(props.className, 'dropzone', {
      'dropzone--error': error && (touched || showFundFormErrors),
    });
    const accept = join(values(types), ',');
    return (
      <div className="dropzone__container">
        <ReactDropzone
          {...props}
          ref="reactDropzone"
          className={className}
          accept={accept}
          onDrop={this.onLoad}
        />
        {error && (touched || showFundFormErrors) && (
          <div className="dropzone__helper">{error}</div>
        )}
      </div>
    );
  }
}

// vendor
import React from 'react';

// dm
import OpenModalButton from '../ActionButtons/OpenModalButton';

export default ({ position, modal }) => (
  <OpenModalButton
    className="header-nav__burger"
    modal={modal}
    icon={position === 'fixed' ? 'nav-burger-blue' : 'nav-burger-white'}
    border="none"
    flat
    frozen
  />
);

// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'READ_MESSAGES_REQUEST',
  'READ_MESSAGES_SUCCESS',
  'READ_MESSAGES_FAILURE',

  'SEND_MESSAGE_REQUEST',
  'SEND_MESSAGE_SUCCESS',
  'SEND_MESSAGE_FAILURE',

  'GET_UNREAD_MESSAGES_AMOUNT_REQUEST',
  'GET_UNREAD_MESSAGES_AMOUNT_SUCCESS',
  'GET_UNREAD_MESSAGES_AMOUNT_FAILURE',

  'MARK_MESSAGE_AS_READ_REQUEST',
  'MARK_MESSAGE_AS_READ_SUCCESS',
  'MARK_MESSAGE_AS_READ_FAILURE',

  'CLEAR_MESSAGES',
  'OPEN_INBOX',
  'CLOSE_INBOX',
);

export const clearInboxMessages = () => ({
  type: types.CLEAR_MESSAGES,
});

export const openInbox = () => ({
  type: types.OPEN_INBOX,
});

export const closeInbox = () => ({
  type: types.CLOSE_INBOX,
});

export const readInboxMessagesAll = id => ({
  [CALL_API]: {
    types: [
      types.READ_MESSAGES_REQUEST,
      types.READ_MESSAGES_SUCCESS,
      types.READ_MESSAGES_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${id}/messages`,
    withProgressBar: false,
  },
});

export const readInboxMessages = (id, userId) => ({
  [CALL_API]: {
    types: [
      types.READ_MESSAGES_REQUEST,
      types.READ_MESSAGES_SUCCESS,
      types.READ_MESSAGES_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${id}/messages?user_id=${userId}`,
    withProgressBar: false,
  },
});

export const sendInboxMessage = ({ id, fundId, userId, text, author }) => ({
  [CALL_API]: {
    types: [
      types.SEND_MESSAGE_REQUEST,
      types.SEND_MESSAGE_SUCCESS,
      types.SEND_MESSAGE_FAILURE,
    ],
    method: 'post',
    url: `funds/published/${fundId}/messages`,
    data: { userId, text },
    extraData: { id, author, text },
    withProgressBar: true,
  },
});

export const sendInboxMessageAll = ({ id, fundId, text, author }) => ({
  [CALL_API]: {
    types: [
      types.SEND_MESSAGE_REQUEST,
      types.SEND_MESSAGE_SUCCESS,
      types.SEND_MESSAGE_FAILURE,
    ],
    method: 'post',
    data: { text },
    extraData: { id, author, text },
    url: `funds/published/${fundId}/messages/to-fund`,
    withProgressBar: true,
  },
});

export const getUnreadMessagesAmount = () => ({
  [CALL_API]: {
    types: [
      types.GET_UNREAD_MESSAGES_AMOUNT_REQUEST,
      types.GET_UNREAD_MESSAGES_AMOUNT_SUCCESS,
      types.GET_UNREAD_MESSAGES_AMOUNT_FAILURE,
    ],
    method: 'get',
    url: 'messages/unread-amount',
    withProgressBar: false,
  },
});

export const markInboxMessageAsRead = (messageId, fundId) => ({
  [CALL_API]: {
    types: [
      types.MARK_MESSAGE_AS_READ_REQUEST,
      types.MARK_MESSAGE_AS_READ_SUCCESS,
      types.MARK_MESSAGE_AS_READ_FAILURE,
    ],
    method: 'put',
    url: `funds/published/${fundId}/messages/${messageId}`,
  },
});

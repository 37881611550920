// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';

// own
import './Modal.scss';

// function getRelativeCoords(event) {
//   return {
//     x: event.offsetX || event.layerX,
//     y: event.offsetY || event.layerY,
//   };
// }

export default class ModalComponent extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    scrollable: PropTypes.bool,
  };

  static defaultProps = {
    scrollable: false,
  };

  render() {
    const { className, scrollable, close, children, ...props } = this.props;
    const overlayClassName = classNames(
      props.overlayClassName,
      'modal-overlay',
      {
        'modal-overlay--scrollable': scrollable,
      },
    );
    const fullClassName = classNames(className, 'modal');
    return (
      <ReactModal
        {...props}
        portalClassName="modal-portal"
        overlayClassName={overlayClassName}
        className={fullClassName}
        onRequestClose={ev => {
          if (window.innerWidth - ev.pageX > 18) {
            close();
          }
        }}
      >
        <div className="modal__close" onClick={close} />
        <div className="modal__container">{children}</div>
      </ReactModal>
    );
  }
}

// vendor
import React, { Component } from 'react';
import classNames from 'classnames';
// own
import './HeaderNav.scss';

export default class HeaderNavComponent extends Component {
  render() {
    const { className, children } = this.props;
    const fullClassName = classNames(className, 'header-nav');
    return <nav className={fullClassName}>{children}</nav>;
  }
}

// vendor
import { omit, cloneDeep, reduce, split, isObject } from 'lodash';
import { stringify } from 'query-string';

// dm
import ROUTES from '../constants/routes';
import { isBrowser } from './core';

export const createPath = pathTemplate => (params = {}) => {
  const path = pathTemplate.replace(/\/:(\w+)/g, (match, paramKey) => {
    const paramValue = params[paramKey];
    params = omit(params, paramKey);
    return `/${paramValue}`;
  });
  const search = stringify(params);
  return path + (search ? `?${search}` : '');
};

export function getPath(pathName, params) {
  let pathNode = cloneDeep(ROUTES);
  const pathTemplate = reduce(
    split(pathName, '.'),
    (path, pathNodeName) => {
      pathNode = pathNode[pathNodeName];
      return `${path}/${pathNode.index || pathNode}`;
    },
    '',
  );
  return createPath(pathTemplate)(params);
}

export const getFullPath = (...args) =>
  `https://darcmatter.com${getPath(...args)}`;

export function isRoutePath(location, pathName) {
  if (isObject(pathName)) {
    const { pathName: route, pathNameParams } = pathName;
    return location.pathname === getPath(route, pathNameParams);
  }
  return location.pathname === getPath(pathName);
}

export function includesRoutePath(location, pathName) {
  if (isObject(pathName)) {
    const { pathName: route, pathNameParams } = pathName;
    return !!~location.pathname.indexOf(getPath(route, pathNameParams));
  }
  return !!~location.pathname.indexOf(getPath(pathName));
}

export function getQueryParam(name) {
  let param;
  if (isBrowser()) {
    const regExp = new RegExp(`[?&]${name}=([^&]+)`);
    const matches = window.location.search.match(regExp);
    param = matches && matches[1];
  }
  return param;
}

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// dm
import Icon from '../../atoms/Icons/Icon';
import Dropdown from '../Dropdown/Dropdown';
import { MOBILE } from '../../../constants/breakpoints';

// own
import './Tooltip.scss';

export default class Tooltip extends Component {
  static propTypes = {
    position: PropTypes.string,
    icon: PropTypes.bool,
  };

  static defaultProps = {
    position: 'top-center',
    icon: true,
  };

  state = {
    styleContent: {},
    styleTriangle: {},
  };

  componentDidMount() {
    if (this.props.responsive) {
      this.calculateContentPosition();
      window.addEventListener('resize', this.calculateContentPosition);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateContentPosition);
  }

  // Need to remove same handler on unmount
  calculateContentPosition = () => {
    if (this.props.responsive) {
      const tooltip = this.node;
      const MARGIN = 10;
      const windowWidth = window.innerWidth;
      const CONTENT_WIDTH = windowWidth > MOBILE.right ? 490 / 2 : 340 / 2;
      const tooltipPositionLeft = tooltip.getBoundingClientRect().left;
      const tooltipPositionRight = windowWidth - tooltipPositionLeft;
      const isContentOverflowsLeft = tooltipPositionLeft < CONTENT_WIDTH;
      const isContentOverflowsRight =
        windowWidth - tooltipPositionLeft < CONTENT_WIDTH;
      let contentPositionLeft = CONTENT_WIDTH - MARGIN;
      if (isContentOverflowsLeft) {
        contentPositionLeft = tooltipPositionLeft - MARGIN;
      }
      if (isContentOverflowsRight) {
        contentPositionLeft =
          CONTENT_WIDTH + (CONTENT_WIDTH - tooltipPositionRight) + MARGIN;
      }
      this.setState({
        styleContent: {
          left: -contentPositionLeft,
        },
        styleTriangle: {
          left: contentPositionLeft + tooltip.offsetWidth / 2,
        },
      });
    }
  };

  render() {
    const { className, children, responsive, icon, ...props } = this.props;
    const { styleContent, styleTriangle } = this.state;
    const fullClassName = classNames(className, 'tooltip', {
      'tooltip--responsive': responsive,
    });
    return (
      <Dropdown
        {...{ styleContent, styleTriangle, ...props }}
        className={fullClassName}
        divProps={{
          ref: node => {
            this.node = node;
          },
        }}
      >
        {icon && <Icon name="tooltip" />}
        {children}
      </Dropdown>
    );
  }
}

// vendor
import React from 'react';

// dm
import Image from '../../../components/atoms/Images/Image';

// own
import './FundManageHeaderInfo.scss';

const FundManageHeaderInfo = ({ name, logo }) => (
  <div className="fund-manage-header-info">
    <Image className="fund-manage-header-info__fund-logo" src={logo} />
    <div className="fund-manage-header-info__fund-name">{name}</div>
  </div>
);

export default FundManageHeaderInfo;

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import { omit } from 'lodash';

// dm
import Button from '../../atoms/Button';
import { openModal } from '../../../store/actions/modal';
import { omitEmptyPrimitives } from '../../../utils/core';

const OpenModalButton = props => (
  <Button
    {...omit(
      props,
      'dispatch handleClick shouldQueryChange modal action actionProps'.w(),
    )}
  />
);

OpenModalButton.propTypes = {
  shouldQueryChange: PropTypes.bool,
};

OpenModalButton.defaultProps = {
  shouldQueryChange: false,
};

export default connect(
  null,
  (
    dispatch,
    {
      handleClick = handleOpenModal => handleOpenModal(),
      shouldQueryChange,
      modal,
      action,
      actionProps,
    },
  ) =>
    bindActionCreators(
      {
        onClick: () => (d, getState) =>
          handleClick((extraProps = {}) => {
            const location = getState()
              .get('router')
              .get('location')
              .toJS();
            const modalProps = { action, ...actionProps, ...extraProps };
            if (shouldQueryChange) {
              const search = stringify(
                omitEmptyPrimitives({
                  modal,
                  modalProps: stringify(modalProps),
                }),
              );
              dispatch(push({ ...location, search }));
            } else {
              dispatch(openModal(modal, modalProps));
            }
          }),
      },
      dispatch,
    ),
)(OpenModalButton);

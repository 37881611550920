// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './Table.scss';

export default class TableComponent extends React.Component {
  static propTypes = {
    name: PropTypes.string,
  };

  render() {
    const { className, children } = this.props;
    const fullClassName = classNames(className, 'table');
    return <div className={fullClassName}>{children}</div>;
  }
}

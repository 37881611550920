// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// dm
import Button from '../../../../../atoms/Button';
import Modal from '../../../../Modal/Modal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalControls from '../../../../Modal/ModalControls';
import { createFundPipeline } from '../../../../../../store/actions/fund';

export default class BrowseInvestorsInvitationModal extends Component {
  confirmClick = () => {
    const { dispatch, fundId, investorId, close } = this.props;
    dispatch(createFundPipeline({ id: fundId, investorId })).then(close);
  };

  render() {
    const { fundName, close } = this.props;
    return (
      <Modal className="modal--notification" {...this.props}>
        <ModalBody>
          <div>
            <FormattedMessage id="modal.browse.text_1" />
            <strong> {fundName} </strong>
            <FormattedMessage id="modal.browse.text_2" />
          </div>
          <ModalControls>
            <Button
              size="md"
              color="green"
              frozen
              text="Confirm"
              onClick={this.confirmClick}
            />
            <Button
              size="md"
              color="red-s"
              frozen
              text="Cancel"
              onClick={close}
            />
          </ModalControls>
        </ModalBody>
      </Modal>
    );
  }
}

// vendor
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

// dm
import '../utils/extends/String';
import responsive from '../enhancers/responsive';
import Routes from '../views/routes';
import RegularTemplate from './templates/Regular/';
import ErrorBoundary from '../enhancers/ErrorBoundary';
import { ModalProvider, Toastr } from './index';
import { currentUserIdSelector } from '../store/reducers/user';
import { establishCurrentUser } from '../store/actions/user';
import { toJS } from '../utils/core';
import { isServer } from '../store';

import 'toastr/build/toastr.css';
import '../styles/vendor/animate.css';
import './Application.scss';
import './main.styles.scss';

class Application extends Component {
  componentWillMount() {
    if (!isServer && !this.props.userId) {
      const user = Cookies.getJSON('user');
      this.props.dispatch(establishCurrentUser(user));
    }
  }

  componentDidMount() {
    const { userId, dispatch } = this.props;
    if (userId) {
      dispatch({ type: 'GTM_EVENT', payload: { userId } });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userId, dispatch } = this.props;
    if (userId !== prevProps.userId) {
      if (userId) {
        dispatch({ type: 'GTM_EVENT', payload: { userId } });
      }
    }
  }

  render() {
    const { intl, location, toastr, breakpoint } = this.props;

    return (
      <IntlProvider {...intl}>
        <ErrorBoundary>
          <div>
            <RegularTemplate location={location} breakpoint={breakpoint}>
              <Routes breakpoint={breakpoint} />
            </RegularTemplate>
            <Toastr {...toastr} />
            <ModalProvider location={location} />
          </div>
        </ErrorBoundary>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  userId: currentUserIdSelector(state),
  intl: toJS(state.get('intl')),
  toastr: toJS(state.get('app').get('toastr')),
});

export default withRouter(
  connect(mapStateToProps)(
    responsive({
      breakpoints: ['mobile', 'tablet', 'desktop'],
      style: {},
    })(Application),
  ),
);

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

// own
import './Progress.scss';

export default class ProgressComponent extends React.Component {
  static propTypes = {
    color: PropTypes.oneOf('green, blue'.w()),
    value: PropTypes.number.isRequired,
    title: PropTypes.string,
    titlePos: PropTypes.oneOf('top bottom right'.w()),
  };

  static defaultProps = {
    color: 'green',
    value: 0,
    title: '{value}%',
    titlePos: 'bottom',
  };

  componentDidMount() {
    const parent = findDOMNode(this).parentNode;
    parent.style.position = 'relative';
  }

  render() {
    const { color, value, title, titlePos, ...props } = this.props;
    const className = classNames(props.className, 'progress', {
      [`progress--${color}`]: color,
      [`progress--${titlePos}`]: titlePos,
    });
    const style = {
      flexBasis: `${value}%`,
    };
    return (
      <div className={className}>
        {title && (
          <div className="progress__title">
            <span className="progress__title__number">{value}%</span>
            <span className="progress__title__text">{title}</span>
          </div>
        )}
        <div className="progress__strip" style={style} />
      </div>
    );
  }
}

// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import LinkButton from '../../../molecules/ActionButtons/LinkButton';
import Button from '../../../atoms/Button';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalControls from '../../Modal/ModalControls';

export default class KycUncompletedModal extends Component {
  render() {
    const { close } = this.props;
    return (
      <Modal className="modal--notification" {...this.props}>
        <ModalHeader title="modal.kyc.uncompleted.title2" />
        <ModalBody>
          <FormattedMessage id="modal.kyc.uncompleted.text2" />
          <ModalControls>
            <LinkButton
              text="Verify Identity"
              color="cyan-p"
              to="kyc"
              withEntry
              frozen
            />
            <Button text="Cancel" color="red-s" onClick={close} />
          </ModalControls>
        </ModalBody>
      </Modal>
    );
  }
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import SubscribeForm from '../../dm-public/subscribe-form';

/* ui-kit components */
import { Caption, Icon } from '../../dm-public/ui-kit';
import Link from '../../atoms/Link';

/* local (Footer) styles */
import './Footer.scss';
import DMLogo from '../../../images/footer/dm-logo.svg';
import NortonSecured from '../../../images/footer/norton-secured.png';
import NortonSecured2x from '../../../images/footer/norton-secured@2x.png';

const curentYear = moment().year();

const SocialLink = ({ href, text, icon }) => (
  <Link className="social_link" href={href} target="_blank">
    <i children={<Icon name={icon} size={'18'} color={'blue'} />} />
    <span className="text" children={text} />
  </Link>
);

/* init component as function */
const Footer = () => (
  <div className="footer">
    <div className="footer-container">
      <div className="row">
        <div className="navigation">
          <nav>
            <h5>Darcmatter</h5>
            <ul className="nav_list">
              <li>
                <Link to="aboutUs" text="about_us" />
              </li>
              <li>
                <Link
                  href="https://blog.darcmatter.com"
                  text="resource_center"
                  target="_blank"
                />
              </li>
              <li>
                <Link to="press" text="about_us.press" />
              </li>
              <li>
                <Link to="contactUs" text="contact_us" />
              </li>
              <li>
                <Link to="privacy" text="privacy_policy" />
              </li>
              <li>
                <Link to="terms" text="terms_of_use" />
              </li>
            </ul>
          </nav>
          <nav>
            <FormattedMessage tagName="h5" id="learn_more" />
            <ul className="nav_list">
              <li>
                <Link
                  to="institutionalServices"
                  text="Institutional Services"
                />
              </li>
              <li>
                <Link to="forInvestors" text="for_investors" />
              </li>
              <li>
                <Link to="forAssetManager" text="for_asset_managers" />
              </li>
            </ul>
          </nav>
          <nav>
            <FormattedMessage tagName="h5" id="subscribe" />
            <ul className="nav_list">
              <li>
                <SocialLink
                  href="https://twitter.com/DarcMatterHQ"
                  text="Twitter"
                  icon="twitter"
                />
              </li>
              <li>
                <SocialLink
                  href="https://www.facebook.com/darcmatterHQ"
                  text="Facebook"
                  icon="facebook"
                />
              </li>
              <li>
                <SocialLink
                  href="https://www.linkedin.com/company/5382376"
                  text="LinkedIn"
                  icon="linkedin"
                />
              </li>
            </ul>
          </nav>
        </div>
        <div className="subscribe_form">
          <FormattedMessage tagName="h5" id="subscribe_newsletter" />
          <FormattedMessage tagName="p" id="subscribe_newsletter.description" />
          <SubscribeForm />
        </div>
      </div>
      <div className="information">
        <Caption text="footer.description_1" />
        <Caption text="footer.description_2" />
      </div>
    </div>
    <div className="bottom">
      <div className="footer-container">
        <Link className="logo" href={'/'}>
          <img src={DMLogo} alt="DarcMatter" />
        </Link>
        <Caption className="copyright">
          <FormattedMessage
            id="footer.copyright"
            values={{ year: `2014-${curentYear}` }}
          />
        </Caption>
        <div className="security">
          <img
            src={NortonSecured}
            srcSet={`${NortonSecured} 1x, ${NortonSecured2x} 2x`}
            alt="Norton secured"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;

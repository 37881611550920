// vendor
import { fromJS } from 'immutable';
import { omit, keys } from 'lodash';
// dm
import { types, AVAILABLE_LISTS } from '../actions/list';
import { createReducer } from '../../utils/core';
import { USAFirstInCoutryList } from '../../utils/filter';

/* initial state */
const initialState = fromJS(
  keys(AVAILABLE_LISTS).reduce((obj, name) => {
    obj[name] = [];
    return obj;
  }, {}),
);

/* reducer */
export default createReducer(initialState, {
  [types.READ_LIST_REQUEST_SUCCESS]: (state, { response }) => {
    if (response.name === 'country') {
      response.data = USAFirstInCoutryList(response.data);
    }
    return state.merge({
      [response.name]: response.data.map(item => ({
        ...omit(item, 'text alias'.w()),
        value: item.id,
        label: item.text || item.name,
      })),
    });
  },
});

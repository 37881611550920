export const ICONS_LIST = [
  {
    name: 'marker',
    paths: [
      'M256 0c-88.366 0-160 71.634-160 160 0 160 160 352 160 352s160-192 160-352c0-88.366-71.635-160-160-160zM256 256c-53.020 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96z',
    ],
  },
  {
    name: 'phone',
    paths: [
      'M211.5 345c46.5 90 121.5 165 211.5 211.5l70.5-70.5c9-9 22.5-12 33-7.5 36 12 75 18 114 18 18 0 31.5 13.5 31.5 31.5v112.5c0 18-13.5 31.5-31.5 31.5-300 0-544.5-244.5-544.5-544.5 0-18 13.5-31.5 31.5-31.5h112.5c18 0 31.5 13.5 31.5 31.5 0 40.5 6 78 18 114 3 10.5 1.5 24-7.5 33z',
    ],
  },
  {
    name: 'envelope',
    paths: [
      'M226.462 349.538l25.846-25.846 275.692 236.308 275.692-236.308 25.846 25.846-301.538 258.462-301.538-258.462zM192.115 256c-35.41 0-64.115 28.624-64.115 63.81v384.38c0 35.241 28.472 63.81 64.115 63.81h671.77c35.41 0 64.115-28.624 64.115-63.81v-384.38c0-35.241-28.472-63.81-64.115-63.81h-671.77z',
    ],
  },
  {
    name: 'close',
    paths: [
      'M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z',
    ],
  },
  {
    name: 'home',
    paths: [
      'M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z',
    ],
  },
  {
    name: 'chevron-thin-up',
    paths: [
      'M132.198 711.219c-13.926 13.722-36.301 13.722-50.125 0s-13.875-35.891 0-49.613l404.89-400.896c13.824-13.722 36.198-13.722 50.125 0l404.89 400.896c13.824 13.722 13.824 35.891 0 49.613-13.875 13.722-36.301 13.722-50.074 0l-379.904-365.619-379.802 365.619z',
    ],
  },
  {
    name: 'chevron-thin-down',
    paths: [
      'M891.802 312.781c13.926-13.722 36.301-13.722 50.125 0s13.875 35.891 0 49.613l-404.89 400.896c-13.824 13.722-36.198 13.722-50.125 0l-404.89-400.896c-13.824-13.722-13.824-35.891 0-49.613 13.875-13.722 36.301-13.722 50.125 0l379.853 365.619 379.802-365.619z',
    ],
  },
  {
    name: 'checkmark',
    paths: ['M864 128l-480 480-224-224-160 160 384 384 640-640z'],
  },
  {
    name: 'twitter',
    paths: [
      'M921.472 0h-818.944c-56.512 0-102.528 46.016-102.528 102.528v818.944c0 56.512 46.016 102.528 102.528 102.528h818.944c56.512 0 102.528-46.016 102.528-102.528v-818.944c0-56.512-46.016-102.528-102.528-102.528zM803.776 373.76c-5.056 235.52-153.536 399.232-378.752 409.472-92.288 5.248-158.784-25.472-220.288-61.248 66.496 10.24 153.792-15.488 199.744-56.512-66.496-4.992-107.456-40.704-128-97.216 20.544 5.248 41.024 0 56.256 0-61.504-20.48-102.208-56.256-107.456-138.24 15.488 10.24 35.712 15.488 56.192 15.488-45.952-25.728-76.736-123.008-40.704-184.512 66.496 71.744 148.224 133.248 281.472 143.488-35.712-143.488 158.784-220.224 235.52-123.008 35.712-4.992 61.504-20.48 86.976-30.464-10.24 35.712-30.72 56.256-56.256 76.736 25.536-5.248 51.264-10.24 71.744-20.48-5.248 25.472-30.72 46.016-56.448 66.496z',
    ],
  },
  {
    name: 'facebook',
    paths: [
      'M921.472 0h-818.944c-56.512 0-102.528 46.016-102.528 102.528v818.944c0 56.512 46.016 102.528 102.528 102.528h818.944c56.512 0 102.528-46.016 102.528-102.528v-818.944c0-56.512-46.016-102.528-102.528-102.528zM870.528 102.528v153.472h-102.528c-30.72 0-51.264 20.48-51.264 51.264v102.208h153.792v153.792h-153.792v358.208h-153.472v-358.208h-102.528v-153.792h102.528v-128c0-97.216 81.728-178.944 179.264-178.944h128z',
    ],
  },
  {
    name: 'linkedin',
    paths: [
      'M921.472 0h-818.944c-56.512 0-102.528 46.016-102.528 102.528v818.944c0 56.512 46.016 102.528 102.528 102.528h818.944c56.512 0 102.528-46.016 102.528-102.528v-818.944c0-56.512-46.016-102.528-102.528-102.528zM153.472 870.528v-461.056h153.792v461.056h-153.792zM230.528 322.496c-51.264 0-92.288-41.024-92.288-91.968 0-51.264 41.024-92.288 92.288-92.288 50.944 0 91.968 41.024 91.968 92.288 0 50.944-41.024 91.968-91.968 91.968zM870.528 870.528h-153.792v-271.552c0-40.96-35.712-76.736-76.736-76.736s-76.736 35.776-76.736 76.736v271.552h-153.792v-461.056h153.792v61.504c25.472-40.96 81.728-71.744 128-71.744 97.216 0 179.264 82.048 179.264 179.264v292.032z',
    ],
  },
  {
    name: 'pass-off',
    paths: [
      'M512 298.667c117.821 0 213.333 95.513 213.333 213.333-0.022 28.158-5.665 54.991-15.866 79.448l125.093 123.219c64.089-53.513 114.22-121.599 145.566-199.28-74.543-192.244-256.018-323.218-468.105-323.386-0.306-0.001-0.644-0.001-0.981-0.001-60.892 0-119.227 10.949-173.144 30.987l95.597 91.041c23.137-9.716 50.028-15.36 78.238-15.36 0.095 0 0.189 0 0.284 0zM85.333 182.187l97.28 97.28 19.627 19.2c-70.375 55.066-125.080 127.053-158.372 209.946 74.459 192.36 256.044 323.409 468.237 323.409 67.271 0 131.466-13.171 190.147-37.076l14.548 19.135 125.013 124.587 54.187-54.187-756.48-756.48zM321.28 418.133l66.133 66.133c-2.11 8.309-3.351 17.862-3.413 27.694-0 70.732 57.307 128.040 128 128.040 9.872-0.062 19.424-1.303 28.562-3.589l65.304 66.309c-26.965 13.225-58.685 20.958-92.212 20.958-117.821 0-213.333-95.513-213.333-213.333 0-33.526 7.734-65.247 21.515-93.474zM505.173 384l134.827 135.253v-7.253c0-70.692-57.308-128-128-128z',
    ],
  },
  {
    name: 'pass-on',
    paths: [
      'M512 162.909c-231.39 0.183-429.363 143.064-510.685 345.387 81.298 209.7 279.312 352.526 510.685 352.526s429.387-142.826 510.686-345.121c-81.324-209.728-279.296-352.609-510.664-352.792zM512 744.727c-128.532 0-232.727-104.196-232.727-232.727s104.196-232.727 232.727-232.727c128.532 0 232.727 104.196 232.727 232.727s-104.196 232.727-232.727 232.727zM512 372.364c-77.119 0-139.636 62.517-139.636 139.636s62.517 139.636 139.636 139.636c77.119 0 139.636-62.517 139.636-139.636s-62.517-139.636-139.636-139.636z',
    ],
  },
  {
    name: 'navigate-next',
    paths: ['M426 256l256 256-256 256-60-60 196-196-196-196z'],
  },
  {
    name: 'navigate-before',
    paths: ['M658 316l-196 196 196 196-60 60-256-256 256-256z'],
  },
  {
    name: 'markunread',
    paths: [
      'M854 342v-86l-342 214-342-214v86l342 212zM854 170c46 0 84 40 84 86v512c0 46-38 86-84 86h-684c-46 0-84-40-84-86v-512c0-46 38-86 84-86h684z',
    ],
  },
  {
    name: 'description',
    paths: [
      'M554 384h236l-236-234v234zM682 598v-86h-340v86h340zM682 768v-86h-340v86h340zM598 86l256 256v512c0 46-40 84-86 84h-512c-46 0-86-38-86-84l2-684c0-46 38-84 84-84h342z',
    ],
  },
  {
    name: 'star',
    paths: [
      'M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z',
    ],
  },
  {
    name: 'menu',
    paths: [
      'M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z',
    ],
  },
  {
    name: 'quote',
    paths: [
      'M1165.8 600.456c-26.067-38.271-70.26-60.584-116.942-59.043-14.879-0.478-29.748 1.21-44.129 5.011 11.98-83.010 48.079-160.809 103.924-223.973 55.029-62.996 122.633-114.1 198.581-150.114l-74.137-172.337c-53.491 19.34-105.171 43.252-154.452 71.462-68.181 38.371-130.262 86.456-184.239 142.706-62.936 65.355-114.991 140.14-154.232 221.576-44.491 93.036-66.616 194.963-64.649 297.831-1.261 72.646 22.030 143.635 66.194 201.75 44.129 59.116 109.661 88.674 196.596 88.674 82.963 0 148.495-23.821 196.596-71.462s72.151-119.031 72.151-214.169c1.323-49.118-13.119-97.391-41.261-137.913v0zM490.616 600.456c-26.067-38.271-70.261-60.584-116.943-59.043-14.879-0.478-29.749 1.21-44.129 5.011 11.917-82.986 47.938-160.782 103.704-223.973 55.030-62.996 122.634-114.1 198.583-150.114l-74.137-172.337c-53.491 19.34-105.171 43.252-154.453 71.462-68.104 38.389-130.109 86.473-184.020 142.706-63.014 65.339-115.145 140.125-154.453 221.576-44.458 92.97-66.582 194.818-64.65 297.614-1.261 72.646 22.031 143.635 66.194 201.75 45.453 59.261 111.648 88.892 198.583 88.892 82.963 0 148.496-23.821 196.597-71.462s72.152-119.031 72.152-214.169c0.807-49.31-14.259-97.599-43.026-137.913z',
    ],
  },
  {
    name: 'info',
    paths: [
      'M554 384v-86h-84v86h84zM554 726v-256h-84v256h84zM512 86c236 0 426 190 426 426s-190 426-426 426-426-190-426-426 190-426 426-426z',
    ],
  },
  {
    name: 'plus',
    paths: ['M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z'],
  },
  {
    name: 'help',
    paths: [
      'M512 256c94 0 170 76 170 170 0 106-128 118-128 214h-84c0-138 128-128 128-214 0-46-40-84-86-84s-86 38-86 84h-84c0-94 76-170 170-170zM512 854c188 0 342-154 342-342s-154-342-342-342-342 154-342 342 154 342 342 342zM512 86c236 0 426 190 426 426s-190 426-426 426-426-190-426-426 190-426 426-426zM470 768v-86h84v86h-84z',
    ],
  },
  {
    name: 'check',
    paths: ['M384 690l452-452 60 60-512 512-238-238 60-60z'],
  },
];

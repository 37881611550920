// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, reset, change } from 'redux-form/immutable';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import moment from 'moment';
// dm
import OverlaySpinner from '../../atoms/Spinners/OverlaySpinner';
import { changeCountAsideDealRoom } from '../../../store/actions/inv-dashboard';
import {
  setActivityFeedReadable,
  behaviorActivityFeeds,
} from '../../../store/actions/fund';
import { selector } from '../../../store/reducers/form/inv-dashboard';
import { responsive } from '../../../enhancers';
// own
import './ActivityFeeds.scss';
import Header from './ActivityFeedsHeader';
import SidebarColumnRow from './ActivityFeedsSidebar/ActivityFeedsSidebarRow';
import SidebarColumn from './ActivityFeedsSidebar';
import MainContent from './ActivityFeedsContent';

const form = 'ActivityFeeds';

class ActivityFeedsComponent extends Component {
  static propTypes = {
    breakpoint: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      filterDate: null,
      highlightIndex: null,
      showElms: 10,
      showMoreElems: 10,
      rowsHighlight: {},
      staticMinHeightContent: 170,
      staticHeight: 60,
      staticHeightSidebarRow: 25,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { ActFeedsForm, dispatch, activityFeeds, behavior } = this.props;
    const nextValues = nextProps.ActFeedsForm && nextProps.ActFeedsForm.values;
    let rowsHighlight = {};

    if (nextProps.activityFeeds && !behavior) {
      const arrIndexes = {};

      nextProps.activityFeeds.forEach(elem => {
        const isRead = elem.isRead ? 'readArr' : 'unreadArr';
        if (!arrIndexes[elem.fundId]) {
          arrIndexes[elem.fundId] = {
            readArr: [],
            unreadArr: [],
          };
        }
        arrIndexes[elem.fundId][isRead].push(elem.id);
      });

      dispatch(behaviorActivityFeeds(arrIndexes));
    }

    if (
      ActFeedsForm &&
      nextValues &&
      !isEqual(nextValues, ActFeedsForm.values)
    ) {
      if (nextValues.check) {
        Object.keys(nextValues.check).forEach(key => {
          if (nextValues.check[key]) {
            const curFeed = activityFeeds[key];
            if (!rowsHighlight[curFeed.fundId]) {
              rowsHighlight[curFeed.fundId] = [];
            }
            rowsHighlight[curFeed.fundId].push(+curFeed.id);
          }
        });
      }

      if (
        nextValues.checkAllFeeds &&
        (!ActFeedsForm.values || !ActFeedsForm.values.checkAllFeeds)
      ) {
        const { registeredFields } = nextProps.ActFeedsForm;
        Object.keys(registeredFields).forEach(elem => {
          if (
            registeredFields[elem].type === 'Field' &&
            registeredFields[elem].name !== 'checkAllFeeds'
          ) {
            dispatch(change(form, registeredFields[elem].name, true));
          }
        });
      }

      if (
        ActFeedsForm.values &&
        ActFeedsForm.values.checkAllFeeds &&
        !nextValues.checkAllFeeds
      ) {
        dispatch(reset(form));
        rowsHighlight = {};
      }
    }

    this.setState({
      rowsHighlight,
    });
  }

  showMore = () => {
    const { dispatch } = this.props;
    dispatch(reset(form));

    this.setState({
      rowsHighlight: {},
      showElms: this.state.showElms + this.state.showMoreElems,
    });
  };

  scrollForMonth = (date, index, hightlight) => {
    const { dispatch } = this.props;

    if (!hightlight) {
      dispatch(reset(form));

      this.setState({
        filterDate: date,
        highlightIndex: index,
        rowsHighlight: {},
        showElms: this.state.showMoreElems,
      });
    }
  };

  handlerEditReadeable = (flag, inverseFlag) => {
    const { dispatch, refreshAsideCounter, dealRooms, behavior } = this.props;
    const newState = behavior;
    const rowsHighlight = this.state.rowsHighlight;

    dispatch(reset(form));

    const fundIds = Object.keys(rowsHighlight);
    if (fundIds.length) {
      fundIds.forEach(fundId => {
        this.state.rowsHighlight[fundId].forEach(elem => {
          const indexElem = newState[fundId][inverseFlag].indexOf(elem);
          if (indexElem > -1) {
            newState[fundId][flag].push(elem);
            newState[fundId][inverseFlag].splice(indexElem, 1);
          }
        });

        dispatch(
          setActivityFeedReadable({
            fundId,
            flag: flag.substr(0, flag.length - 3),
            feedId: rowsHighlight[fundId],
          }),
        );

        if (refreshAsideCounter) {
          dealRooms.forEach((elem, indexDealRoom) => {
            if (elem.id === fundId) {
              const unreadCounterReal = newState[fundId].unreadArr.length;
              dispatch(
                changeCountAsideDealRoom(indexDealRoom, unreadCounterReal),
              );
            }
          });
        }
      });
      dispatch(behaviorActivityFeeds(newState));
    }
  };

  scrollContent = (sidebarIndexes, event) => {
    if (sidebarIndexes[1] && !this.state.filterDate) {
      const highlightIndexOfArray = sidebarIndexes.indexOf(
        this.state.highlightIndex,
      );
      if (
        sidebarIndexes[highlightIndexOfArray + 1] !== undefined &&
        event.target.scrollTop >
          sidebarIndexes[highlightIndexOfArray + 1] * this.state.staticHeight
      ) {
        this.setState({
          highlightIndex: sidebarIndexes[highlightIndexOfArray + 1],
        });
      }
      if (
        sidebarIndexes[highlightIndexOfArray - 1] !== undefined &&
        event.target.scrollTop <
          this.state.highlightIndex * this.state.staticHeight
      ) {
        this.setState({
          highlightIndex: sidebarIndexes[highlightIndexOfArray - 1],
        });
      }
    }
  };

  handlerFilter = flag => {
    const { dispatch } = this.props;
    dispatch(reset(form));

    this.setState({
      filter: flag,
    });
  };

  render() {
    const {
      user,
      ActFeedsForm,
      activityFeeds,
      behavior,
      reading,
      breakpoint,
    } = this.props;
    const feedCheck = activityFeeds && !!activityFeeds.length;

    const arrSidebarRows = [];
    const sidebarIndexes = [];
    const staticHeightScrollContent = 0;
    let fixedHeight = 0;

    if (feedCheck) {
      let sidebarDate = null;
      activityFeeds.forEach((elem, index) => {
        const timeDate = moment.unix(elem.createdAt);
        const year = timeDate.format('YYYY');
        const month =
          breakpoint !== 'desktop-large'
            ? timeDate.format('MMM')
            : timeDate.format('MMMM');

        let highlightIndex = false;
        if (
          !sidebarDate ||
          (sidebarDate.month !== month || sidebarDate.year !== year)
        ) {
          let yearVisible = false;
          sidebarIndexes.push(index);
          fixedHeight += this.state.staticHeightSidebarRow;
          if (!sidebarDate || sidebarDate.year !== year) {
            yearVisible = true;
            fixedHeight += this.state.staticHeightSidebarRow;
          }
          if (this.state.highlightIndex === index) {
            highlightIndex = 'active';
          }

          sidebarDate = {
            month,
            year,
            yearVisible,
          };
          arrSidebarRows.push(
            <SidebarColumnRow
              key={index}
              scrollForMonth={this.scrollForMonth}
              {...{ sidebarDate, index, highlightIndex }}
            />,
          );
        }
      });
    }

    if (fixedHeight > this.state.staticMinHeightContent) {
      const huinya = parseInt(fixedHeight / this.state.staticHeight, 10) + 1;
      fixedHeight = huinya * this.state.staticHeight - 10;
    } else {
      fixedHeight = this.state.staticMinHeightContent;
    }

    const className = classNames('activity-feeds', {
      'activity-feeds--empty': !feedCheck,
      'activity-feeds--small': breakpoint !== 'desktop-large',
    });

    return (
      <div className={className}>
        {feedCheck && (
          <Header
            checkAllFeeds={
              !!(ActFeedsForm && ActFeedsForm.values) &&
              ActFeedsForm.values.checkAllFeeds
            }
            filter={this.state.filter}
            handlerEditReadeable={this.handlerEditReadeable}
            handlerFilter={this.handlerFilter}
            rowsHighlight={this.state.rowsHighlight}
          />
        )}
        <div className="activity-feeds__container">
          <OverlaySpinner active={reading} />
          <SidebarColumn sidebarContent={arrSidebarRows} />
          <FieldArray
            component={MainContent}
            activityFeeds={activityFeeds}
            behavior={behavior}
            feedCheck={feedCheck}
            filter={this.state.filter}
            filterDate={this.state.filterDate}
            height={staticHeightScrollContent}
            index={sidebarIndexes}
            name="check"
            rowsHighlight={this.state.rowsHighlight}
            scrollContent={this.scrollContent}
            showElms={this.state.showElms}
            showMore={this.showMore}
            user={user}
            breakpoint={breakpoint}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.get('user').toJS(),
  ActFeedsForm: state.get('form').toJS().ActivityFeeds,
  activityFeeds: state.get('activityFeeds').toJS().data,
  behavior: state.get('activityFeeds').toJS().behavior,
  dealRooms: selector(state, 'dealRooms').toJS(),
}))(
  reduxForm({
    form,
  })(
    responsive({
      style: {
        display: 'inline-flex',
        flex: '1',
      },
      breakpoints: ['mobile', 'tablet', 'desktop', 'desktop-large'],
    })(ActivityFeedsComponent),
  ),
);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import './caption.styles.scss';

const Caption = ({ error, className, text, intl, ...props }) => {
  const children = props.children || (text && intl.formatMessage({ id: text }));
  return (
    <div className={cx('caption', error && 'error', className)}>{children}</div>
  );
};

Caption.propTypes = {
  error: PropTypes.bool,
  text: PropTypes.string,
  intl: intlShape.isRequired,
};

Caption.defaultProps = {
  error: false,
};

export default injectIntl(Caption);

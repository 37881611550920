// vendor
import React from 'react';

// dm
import HeaderNav from '../HeaderNav';
import HeaderNavLink from '../HeaderNavLink';
import HeaderNavBurger from '../HeaderNavBurger';
import { Button } from '../../dm-public/ui-kit';

export default ({ position, breakpoint }) => {
  const isDesktop = breakpoint === 'desktop';
  const isTablet = breakpoint === 'tablet';
  const isMobile = breakpoint === 'mobile';

  return (
    <HeaderNav>
      {isDesktop && (
        <>
          <HeaderNavLink
            to="institutionalServices"
            text="Institutional Services"
            tagNew
          />
          <HeaderNavLink
            href="https://blog.darcmatter.com"
            target="_blank"
            text="Resource Center"
          />
          <HeaderNavLink to="browseInvestments" text="browse_investments" />
          <HeaderNavLink to="forInvestors" text="for_investors" />
          <HeaderNavLink to="forAssetManager" text="for_asset_managers" />
          <Button href="/log-in" children="login" size="s" secondary />
          <Button href="/registration" children="register" size="s" />
        </>
      )}
      {isTablet && (
        <>
          <Button href="/log-in" children="login" size="s" secondary />
          <Button href="/registration" children="register" size="s" />
          <HeaderNavBurger modal="guest-nav" position={position} />
        </>
      )}
      {isMobile && (
        <>
          <Button href="/log-in" children="login" size="s" secondary />
          <HeaderNavBurger modal="guest-nav" position={position} />
        </>
      )}
    </HeaderNav>
  );
};

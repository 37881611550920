// vendor
import { formValueSelector, getFormValues } from 'redux-form/immutable';
import { getFormSyncErrors } from 'redux-form';
import { fromJS } from 'immutable';
import { times, stubObject, toArray, omitBy, isNil } from 'lodash';
// dm
import { fundTypes, mediaTypes } from '../../actions';
import {
  HIGHLIGHTS_MAX,
  METRICS_MAX,
  REP_INVESTMENTS_MAX,
} from '../../../constants/fund';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'fund';

/* initial state */
const initialState = fromJS({
  values: {
    logo: {},
    highlights: times(HIGHLIGHTS_MAX, stubObject),
    teamMembers: [{}],
    annualReturns: {},
    metricsAndPerformances: times(METRICS_MAX, stubObject),
    previousFunds: [],
    representativeInvestments: times(REP_INVESTMENTS_MAX, stubObject),
    documents: [],
    signature: {},
    subDocuments: [],
  },
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);
export const syncErrorsSelector = getFormSyncErrors(form);

/* reducer */
export default createReducer(initialState, {
  [fundTypes.CLEAR_FUND]: state => state.merge(initialState),
  [fundTypes.READ_FUND_PUBLISHED_SUCCESS]: (state, { response }) =>
    state.mergeDeep({ values: omitBy(response.data, isNil) }),
  [fundTypes.ADD_FUND_DOCUMENT]: (state, payload) =>
    state.updateIn('values.documents'.p(), documents =>
      documents.push(payload),
    ),
  [fundTypes.SET_FUND_SIGNATURE]: (state, signature) =>
    state.setIn('values.signature'.p(), fromJS(signature)),
  [fundTypes.READ_FUND_INVESTMENT_INTEREST_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), {
      totalInvestmentInterest: response.data.totalInvestmentInterest,
    }),
  [fundTypes.READ_FUND_SUCCESS]: (state, { response }) => {
    const { documents, subDocuments, ...data } = response.data;
    data.documents = toArray(documents);
    data.subDocuments = toArray(subDocuments);
    return state.mergeDeep({ values: omitBy(data, isNil) });
  },
  [fundTypes.CREATE_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), {
      isPendingPipelined: true,
      pipelinedStage: response.data.fund.pipelinedStage,
    }),
  [fundTypes.ACCEPT_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), {
      isPendingPipelined: false,
      isPipelined: true,
      pipelinedStage: response.data.fund.pipelinedStage,
    }),
  [fundTypes.DECLINE_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), {
      isPendingPipelined: false,
      isPipelined: false,
      pipelinedStage: response.data.fund.pipelinedStage,
    }),
  [mediaTypes.CREATE_MEDIA_REQUEST]: (state, { type, progress }) =>
    state.mergeIn('values.progress'.p(), type ? { [type]: progress } : {}),
  [mediaTypes.CREATE_MEDIA_SUCCESS]: (
    state,
    { response, extraData: { fieldName } },
  ) => {
    const media = fromJS(response.data);
    if (fieldName === 'documents') {
      return state.updateIn('values.documents'.p(), documents =>
        documents.push(media),
      );
    }
    if (fieldName === 'subDocuments') {
      return state.setIn('values.subDocuments'.p(), fromJS([response.data]));
    }
    return state.setIn(`values.${fieldName}`.p(), media);
  },
  [fundTypes.SET_FAVORITE_FUND_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), { isFavorite: response.data.isFavorite }),
});

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class AccordionContentComponent extends Component {
  static propTypes = {
    shouldAlwaysRender: PropTypes.bool,
  };

  static defaultProps = {
    shouldAlwaysRender: false,
  };

  render() {
    const { className, children, isOpen, shouldAlwaysRender } = this.props;
    const fullClassName = classNames(className, 'accordion__content');
    return (
      (shouldAlwaysRender || isOpen) && (
        <div className={fullClassName}>{children}</div>
      )
    );
  }
}

// vendor
import React, { Component } from 'react';
import { replace } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';

// dm
import Button from '../../../atoms/Button';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import ModalControls from '../../Modal/ModalControls';
import { getPath } from '../../../../utils/route';

export default class KycSuccessfulModal extends Component {
  // TODO: optimize onClose event
  onClose = () => {
    const {
      location: { query },
      dispatch,
    } = this.props;
    if (query.restrictedPath) {
      dispatch(replace(query.restrictedPath));
    }
  };

  render() {
    const { location, close } = this.props;
    const type = {
      [getPath('profile')]: 'basic',
      [getPath('browse')]: 'requestAccess',
      [getPath('invDashboard.tabs.activityFeeds')]: 'dealRoom',
    }[location.pathname];
    const handleClose = close.bind(null, this.onClose);
    return (
      <Modal
        {...this.props}
        className="modal--notification"
        close={handleClose}
      >
        <ModalHeader title="Verification Successful" />
        <ModalBody>
          <FormattedMessage id={`modal.kyc.kyc_successful.text.${type}`} />
        </ModalBody>
        <ModalFooter>
          <ModalControls>
            <Button text="Ok" onClick={handleClose} />
          </ModalControls>
        </ModalFooter>
      </Modal>
    );
  }
}

// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import LinkButton from '../../../molecules/ActionButtons/LinkButton';
import Link from '../../../atoms/Link';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalControls from '../../Modal/ModalControls';
// own
import './KycDeclinedModal.scss';

export default class KycDeclinedModal extends Component {
  render() {
    const email = 'ask@darcmatter.com';
    return (
      <Modal {...this.props} className="kyc-declined-modal modal--notification">
        <ModalHeader title="Verification Unsuccessful" />
        <ModalBody className="kyc-declined-modal__body">
          <div>
            <FormattedMessage id="modal.kyc.declined.text1" tagName="p" />
            <FormattedMessage id="modal.kyc.declined.text2" tagName="p" />
            <FormattedMessage
              id="modal.kyc.declined.text3"
              tagName="p"
              values={{
                email: <Link href={`mailto:${email}`}>{email}</Link>,
              }}
            />
          </div>
          <ModalControls className="kyc-declined-modal__controls">
            <LinkButton
              to="kyc"
              withEntry
              text="Try Again"
              border="none"
              flat
              frozen
            />
            <LinkButton
              to="kyc.proof"
              withEntry
              text="Upload Proof of Identity"
              frozen
            />
          </ModalControls>
        </ModalBody>
      </Modal>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

/* local (ErrorPage) styles */
import './error-page.styles.scss';
import magnifier from '../../../images/bg/notFound/magnifier.png';

const ErrorPage = ({ error }) => (
  <div className="error-box">
    <Helmet
      title={'Oops... Requested Information Not Found on DM...'}
      meta={[
        {
          name: 'description',
          content:
            'DarcMatter apologizes for the inconvenience, but the requested information is not currently available on the website. Please visit our homepage and try again.',
        },
      ]}
    />
    <h2>{error.message}</h2>
    <h3>{error.content}</h3>
    <img src={magnifier} alt="magnifier icon" />
  </div>
);

ErrorPage.propTypes = {
  error: PropTypes.object,
};

ErrorPage.defaultProps = {
  error: {
    message: 'Oops!',
    content: "Seems that we can't find page that you are looking for...",
  },
};

export default ErrorPage;

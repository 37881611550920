// vendor
import React from 'react';

// dm
import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../Modal/ModalHeader';
import ModalBody from '../../../Modal/ModalBody';

// own
import './ChangePasswordModal.scss';
import ChangePasswordModalForm from './ChangePasswordModalForm';

export default props => (
  <Modal className="change-password-modal" {...props}>
    <ModalHeader title="modal.change_password.title" />
    <ModalBody>
      <ChangePasswordModalForm close={props.close} />
    </ModalBody>
  </Modal>
);

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

// own
import './Panel.scss';

export default class PanelComponent extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    withOverlay: PropTypes.bool,
  };

  componentDidMount() {
    findDOMNode(this).parentNode.style.position = 'relative';
  }

  render() {
    const { className, opened, withOverlay, children } = this.props;
    const fullClassName = classNames(className, 'panel', {
      'panel--opened': opened,
      'panel--with-overlay': withOverlay,
    });
    return (
      <div className={fullClassName}>
        <div className="panel__content">{children}</div>
      </div>
    );
  }
}

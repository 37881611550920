// vendor
import {
  formValueSelector,
  getFormValues,
  hasSubmitFailed,
} from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { kycPartyTypes, userTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'kycParty';

/* initial state */
const initialState = fromJS({
  values: {
    proofOfIdentityMedia: {},
    mediaType: 'passport',
    investTo: false,
    domesticAccount: false,
    associatedAccount: false,
    associatedPerson: false,
  },
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);
export const submitFailedSelector = hasSubmitFailed(form);
export const syncErrorsSelector = state =>
  state
    .get('form')
    .get(form)
    .get('syncErrors');

/* reducer */
export default createReducer(initialState, {
  [userTypes.READ_USER_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), response.data),
  [userTypes.READ_PROFILE_SUCCESS]: (state, { response }) => {
    if (response.data.proofOfIdentityMedia == null) {
      return state.mergeIn('values'.p(), {
        ...response.data,
        proofOfIdentityMedia: {},
      });
    }
    return state.mergeIn('values'.p(), response.data);
  },
  [kycPartyTypes.READ_KYC_PARTY_SUCCESS]: (state, { response }) =>
    state.mergeIn('values'.p(), response.data),
  [kycPartyTypes.CREATE_KYC_PARTY_SUCCESS]: state => state,
  [kycPartyTypes.CREATE_KYC_PARTY_MEDIA_REQUEST]: (state, { progress }) =>
    state.setIn('values.progress'.p(), progress),
  [kycPartyTypes.CREATE_KYC_PARTY_MEDIA_SUCCESS]: (state, { response }) =>
    state.setIn('values.proofOfIdentityMedia'.p(), response.data),
  [kycPartyTypes.CLEAR_KYC_PARTY_MEDIA]: state =>
    state.setIn('values.proofOfIdentityMedia'.p(), {}),
});

import React from 'react';
import PropTypes from 'prop-types';

const ModalTrigger = ({ component: Component, onOpen, ...props }) => (
  <Component onOpen={onOpen} {...props} />
);

ModalTrigger.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default ModalTrigger;

// vendor
import { get, find } from 'lodash';

// dm
import { FUND_TYPE } from '../constants/lists';

export const listParam = (value, list, param = 'label') =>
  get(find(list, { value }), param);

export const investmentTypeColor = value => {
  const colors = { color: 'orange', colorCode: '#d6a76f' };
  switch (value) {
    case FUND_TYPE.HEDGE_FUND:
      return { color: 'violet', colorCode: '#7c82bc' };
    case FUND_TYPE.PRIVATE_EQUITY:
      return { color: 'cyan', colorCode: '#8eb7c1' };
    case FUND_TYPE.CRYPTO_ASSET:
      return { color: 'darkviolet', colorCode: '#9D6582' };
    case FUND_TYPE.PRIVATE_DEBT:
      return { color: 'green-light', colorCode: '#70A961' };
    case FUND_TYPE.VENTURE_CAPITAL:
      return colors;
    default:
      return colors;
  }
};

export const isCollectionChecked = collection =>
  !!collection.size && collection.every(item => item.get('checked'));

export const getFundAumLabel = investmentTypeId => {
  switch (investmentTypeId) {
    case FUND_TYPE.HEDGE_FUND:
      return 'Fund AUM';
    case FUND_TYPE.CRYPTO_ASSET:
      return 'Fund Size';
    default:
      return 'Maximum Target Raise';
  }
};

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// dm
import Icon from '../../atoms/Icons/Icon';

// own
import './DropdownListItem.scss';
import Dropdown from './Dropdown';

export default class DropdownListItemComponent extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    translate: PropTypes.bool,
    icon: PropTypes.string,
    iconPos: PropTypes.oneOf('left right'.w()),
    dropdown: PropTypes.object,
  };

  static defaultProps = {
    translate: false,
    iconPos: 'left',
  };

  handleClick = event => {
    const { onClick, dropdown, children, closeContent } = this.props;
    if (onClick) {
      onClick(event);
    }
    // TODO: finish closeContent for all dropdowns
    if (!(dropdown && children) && closeContent) {
      closeContent();
    }
  };

  render() {
    const {
      children,
      className,
      label,
      translate,
      icon,
      iconPos,
      dropdown,
    } = this.props;
    const fullClassName = classNames(
      className,
      'dropdown-list-item',
      'has-icons',
    );
    const listItemBody = children ? (
      <div className="dropdown-list-item__body">{children}</div>
    ) : (
      <div className="dropdown-list-item__body">
        {icon && (
          <Icon name={icon} className={`dropdown-list-item__icon-${iconPos}`} />
        )}
        <div className="dropdown-list-item__text">
          {translate ? <FormattedMessage id={label} /> : label}
        </div>
        {!!dropdown && (
          <Icon
            name="arrow-right"
            className="dropdown-list-item__arrow-right"
          />
        )}
      </div>
    );
    return (
      <div className={fullClassName} onClick={this.handleClick}>
        {dropdown ? (
          <Dropdown className="dropdown-list-item__content" {...dropdown}>
            {listItemBody}
          </Dropdown>
        ) : (
          <div className="dropdown-list-item__content">{listItemBody}</div>
        )}
      </div>
    );
  }
}

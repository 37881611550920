// vendor
import { reducer as formReducer } from 'redux-form/immutable';
// own
import profile from './profile';
import kycParty from './kyc-party';
import kycProof from './kyc-proof';
import kycVerify from './kyc-verify';
import invDashboard from './inv-dashboard';
import inbox from './inbox';
import fund from './fund';
import fundManage from './fund-manage';
import browseInvestments from './browse-investments';
import filtersPanel from './filters-panel';
import investment from './investment';

/* reducer */
export default formReducer.plugin({
  profile,
  kycParty,
  kycProof,
  kycVerify,
  invDashboard,
  inbox,
  fund,
  fundManage,
  browseInvestments,
  filtersPanel,
  investment,
});

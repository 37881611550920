// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// dm
import responsive from '../../../../enhancers/responsive';
import Icon from '../../../atoms/Icons/Icon';
import LogoImage from '../../../atoms/Images/LogoImage';
import Button from '../../../atoms/Button';
import ViewFundButton from '../../ActionButtons/ViewFundButton';
import LinkButton from '../../ActionButtons/LinkButton';
import InviteDeclineButton from '../../ActionButtons/InviteDeclineButton';
import InviteAcceptButton from '../../ActionButtons/InviteAcceptButton';
import { openModal } from '../../../../store/actions/modal';
import { setFavoriteFund } from '../../../../store/actions/fund';

// own
import './FundTabCard.scss';
import Tooltip from '../../Tooltip';

class FundTabCardComponent extends Component {
  static propTypes = {
    type: PropTypes.oneOf(
      'approved invitation pending-fund pending-documents'.w(),
    ),
  };

  static defaultProps = {
    type: 'approved',
    viewFaforite: false,
  };

  state = {
    viewFund: false,
  };

  removeFavoriteFundClick = () => {
    const {
      removeFavoriteFund,
      fund: { id },
    } = this.props;
    removeFavoriteFund(id, false);
  };

  renderApproved(params) {
    return (
      <div className="fund-tab-card__content">
        <LinkButton
          size="sm"
          icon="unlock-white"
          text="Enter deal room"
          frozen
          to="invDashboard.dealRooms.show"
          params={params}
        />
      </div>
    );
  }

  renderInvitation(actionProps) {
    return (
      <div className="fund-tab-card__content">
        <div className="fund-tab-card__description">
          <FormattedMessage id="You have been invited to this fund’s Deal Room" />
        </div>
        <div className="fund-tab-card__controls">
          <InviteAcceptButton
            className="fund-tab-card__btn-primary"
            frozen
            actionProps={actionProps}
          />
          <InviteDeclineButton
            className="fund-tab-card__btn-primary"
            frozen
            actionProps={actionProps}
          />
        </div>
      </div>
    );
  }

  renderPendingFund() {
    return (
      <div className="fund-tab-card__content">
        <Button disabled frozen icon="dots" size="sm" text="Pending approval" />
      </div>
    );
  }

  renderPendingDocuments() {
    return (
      <div className="fund-tab-card__content">
        <Button
          disabled
          frozen
          icon="paper-sheets"
          size="sm"
          text="Pending Access"
        />
      </div>
    );
  }

  render() {
    const {
      children,
      fund,
      isPreview,
      type,
      breakpoint,
      viewFaforite,
    } = this.props;
    const { id, name, logo, unreadFeedsAmount } = fund;
    const fullClassName = classNames(
      classNames,
      'fund-tab-card',
      `fund-tab-card--${type}`,
    );
    return (
      <div className={fullClassName}>
        {viewFaforite && (
          <div className="fund-tab-card__icon">
            <Tooltip icon={false}>
              <Icon name="close-blue" onClick={this.removeFavoriteFundClick} />
              <FormattedMessage id="Remove from My Favorites" />
            </Tooltip>
          </div>
        )}

        <div className="fund-tab-card__image">
          <LogoImage src={logo} size={breakpoint === 'tablet' ? 'sm' : 'md'} />
        </div>
        <div className="fund-tab-card__body">
          <div className="fund-tab-card__title">
            <div className="fund-tab-card__name" title={children}>
              <span>{children}</span>
            </div>
            {!viewFaforite && !!unreadFeedsAmount && type === 'approved' && (
              <div className="fund-tab-card__amount">{unreadFeedsAmount}</div>
            )}
          </div>
          {!viewFaforite ? (
            <div>
              {
                {
                  approved: this.renderApproved({ id }),
                  invitation: this.renderInvitation({ id, name }),
                  'pending-fund': this.renderPendingFund(),
                  'pending-documents': this.renderPendingDocuments(),
                }[type]
              }
              <div className="fund-tab-card__footer">
                <ViewFundButton
                  border="none"
                  className="fund-tab-card__btn-secondary"
                  flat
                  frozen
                  icon="t-lines-sm"
                  size="sm"
                  {...{ id, isPreview }}
                />
              </div>
            </div>
          ) : (
            <ViewFundButton
              className="fund-container__funds-btn fund-container__funds-btn--view"
              icon="t-lines"
              id={id}
              shouldQueryChange
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    handleOpenModal: () => {
      dispatch(openModal('FundShow'));
    },
    removeFavoriteFund: (fundId, isFavorite) =>
      dispatch(setFavoriteFund(fundId, isFavorite)),
  }),
)(
  responsive({
    breakpoints: ['mobile', 'tablet', 'desktop'],
  })(FundTabCardComponent),
);

import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { CONTACTS } from '../../../../constants/views';
import config from '../../../../config';

import './gmap.styles.scss';

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={CONTACTS.POSITION}
      options={{ disableDefaultUI: true }}
    >
      {props.isMarkerShown && <Marker position={CONTACTS.POSITION} />}
    </GoogleMap>
  )),
);

const GMap = () => (
  <div className="gmap">
    <Map
      isMarkerShown
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
        config.googleMapsId
      }&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div className="gmap_loader_container" />}
      containerElement={<div className="gmap_container" />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  </div>
);

export default GMap;

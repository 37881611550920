// vendor
import { Children, Component, cloneElement } from 'react';
import classNames from 'classnames';

// own
import './InfiniteContainer.scss';

export default class InfiniteContainerComponent extends Component {
  onScroll = event => {
    console.info(event);
  };

  render() {
    const { className, children } = this.props;
    const fullClassName = classNames(className, 'infinite-container');
    return cloneElement(Children.only(children), {
      className: fullClassName,
      onScroll: this.onScroll,
    });
  }
}

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// dm
import { getList } from '../store/actions/list';
import { toJS } from '../utils/core';

const readData = ({ listNames, read, clear }) => ComposedComponent =>
  connect(state => ({
    lists: toJS(state.get('list')),
  }))(
    class extends Component {
      static propTypes = {
        lists: PropTypes.object,
      };

      state = {
        isMounted: false,
        reading: false,
      };

      componentDidMount() {
        this.onMount();
        this.startReading();
        const listPromises = this.readLists();
        if (listPromises.length) {
          Promise.all(listPromises).then(this.readData);
        } else {
          this.readData();
        }
      }

      componentWillUnmount() {
        this.onUnmount();
        this.stopReading();
        this.clearData();
      }

      onMount() {
        this.setState({ isMounted: true });
      }

      onUnmount() {
        this.setState({ isMounted: false });
      }

      toggleReading(reading) {
        if (this.state.isMounted && this.state.reading !== reading) {
          this.setState({ reading });
        }
      }

      startReading() {
        this.toggleReading(true);
      }

      stopReading = () => {
        this.toggleReading(false);
      };

      readLists() {
        const listPromises = [];
        const { dispatch, lists } = this.props;
        if (listNames && lists) {
          listNames.w().forEach(listName => {
            const list = lists[listName];
            if (list && !list.length) {
              listPromises.push(dispatch(getList(listName)));
            }
          });
        }
        return listPromises;
      }

      readData = () => {
        let promise;
        if (read) {
          this.startReading();
          promise = read(this.props.dispatch, this.props);
        }
        if (promise instanceof Promise) {
          promise.then(this.stopReading, this.stopReading);
        } else {
          this.stopReading();
        }
      };

      clearData = () => {
        if (clear) {
          clear(this.props.dispatch, this.props);
        }
      };

      updateData = () => {
        this.clearData();
        this.readData();
      };

      render() {
        const { state, props, readData, clearData, updateData } = this;

        return (
          <ComposedComponent
            {...props}
            {...state}
            {...{ readData, clearData, updateData }}
          />
        );
      }
    },
  );

export default readData;

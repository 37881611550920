// vendor
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import MediaQuery from 'react-responsive';

// dm
import TextArea from '../../atoms/TextArea';
import Button from '../../atoms/Button';
import AvatarImage from '../../atoms/Images/AvatarImage';
import { MOBILE, TABLET } from '../../../constants/breakpoints';

export default class NotesNewMessage extends Component {
  render() {
    const { user, approveFunc, cancelFunc, text, role } = this.props;
    return (
      <div className="notes__new-message">
        <div className="notes__new-message__header">
          <AvatarImage src={user.avatar.url} size="xs" />
          <div className="notes__new-message__name">{user.fullName}</div>
          {role && <div className="notes__new-message__role">({role})</div>}
        </div>
        <div className="notes__new-message__main">
          <Field name="messanger" component={TextArea} ref="TextArea" />
          <MediaQuery maxWidth={MOBILE.right}>
            <div className="notes__new-message__controls">
              <Button size="xs" frozen text={text} onClick={approveFunc} />
              <Button
                flat
                frozen
                size="xs"
                color="blue-g"
                border="none"
                text="Cancel"
                onClick={cancelFunc}
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={TABLET.left}>
            <div className="notes__new-message__controls">
              <Button size="sm" frozen text={text} onClick={approveFunc} />
              <Button
                flat
                frozen
                size="sm"
                color="blue-g"
                border="none"
                text="Cancel"
                onClick={cancelFunc}
              />
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

// vendor
import React from 'react';
import classNames from 'classnames';

// own
import './Image.scss';

const ImageSvg = ({ className, style, src, alt = 'Image' }) => (
  <img
    className={classNames(className, 'image')}
    style={style}
    src={src}
    alt={alt}
  />
);

export default ImageSvg;

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { isEmpty } from 'lodash';

// dm
import readData from '../../../../../enhancers/readData';
import responsive from '../../../../../enhancers/responsive';
import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody';
import AccessRequestButton from '../../../../molecules/ActionButtons/AccessRequestButton';
import InviteAcceptButton from '../../../../molecules/ActionButtons/InviteAcceptButton';
import InviteDeclineButton from '../../../../molecules/ActionButtons/InviteDeclineButton';
import LinkButton from '../../../../molecules/ActionButtons/LinkButton';
import Button from '../../../../atoms/Button';
import LogoImage from '../../../../atoms/Images/LogoImage';
import Tag from '../../../../atoms/Tag/Tag';
import SVGIcon from '../../../../atoms/Icons/SVGIcon';
import TagGroup from '../../../../atoms/Tag/TagGroup';
import OverlaySpinner from '../../../../atoms/Spinners/OverlaySpinner';
import ImageSvg from '../../../../atoms/Images/ImageSvg';
import Tooltip from '../../../../molecules/Tooltip';
import {
  readFundPublished,
  readFund,
  clearFund,
  setFavoriteFund,
} from '../../../../../store/actions/fund';
import { valuesSelector } from '../../../../../store/reducers/form/fund';
import { MOBILE, TABLET } from '../../../../../constants/breakpoints';
import { FUND_TYPE, PIPELINE_STAGE } from '../../../../../constants/lists';
import { ELIGIBILITY_OPTIONS } from '../../../../../constants/styles';
import {
  listParam,
  investmentTypeColor,
} from '../../../../../utils/collection';
import { checkInvestor, checkAssetManager } from '../../../../../utils/access';
import { toJS } from '../../../../../utils/core';

// own
import './FundShowModal.scss';
import Overview from './Overview';
import Info from './Info';
import Disclaimer from './Disclaimer';
import Risks from './Risks';
import Management from './Management';
import PreviousFunds from './PreviousFunds';
import Investments from './Investments';
import Statistics from './Statistics';

import bookmarkRegular from '../../../../../images/svg/bookmark-regular.svg';
import bookmarkSolid from '../../../../../images/svg/bookmark-solid.svg';

class FundShowModal extends Component {
  state = { gtmDataUpdated: false };

  componentWillReceiveProps(nextProps) {
    const fundName = nextProps.fund.name;
    const fundTypeId = nextProps.fund.investmentTypeId;
    if (
      !this.state.gtmDataUpdated &&
      typeof fundName !== 'undefined' &&
      typeof fundTypeId !== 'undefined'
    ) {
      const fundTypeName = listParam(fundTypeId, nextProps.lists.fundType);
      this.props.dispatch({
        type: 'GTM_EVENT',
        payload: {
          event: 'fund',
          eventCategory: 'fund',
          eventAction: fundTypeName,
          eventLabel: fundName,
        },
      });
      this.setState({ gtmDataUpdated: true });
    }
  }

  onFavoriteClick = () => {
    const {
      onFavoriteClick,
      fund: { id, isFavorite },
    } = this.props;
    onFavoriteClick(id, !isFavorite);
  };

  renderButtons() {
    const { fund, id, isPreview, user, lists } = this.props;
    const {
      name,
      isPendingPipelined,
      isPipelined,
      pipelinedStage,
      investmentTypeId,
    } = fund;
    const isInviteRequested =
      pipelinedStage && pipelinedStage.id === PIPELINE_STAGE.INVITE_REQUESTED;
    const isAccessRequested =
      pipelinedStage && pipelinedStage.id === PIPELINE_STAGE.ACCESS_REQUESTED;
    const showAccessButton = !isPendingPipelined && !isPipelined && !isPreview;

    const fundTypeName = listParam(investmentTypeId, lists.fundType);
    return (
      <div className="fund-show__btns">
        {showAccessButton && this.renderBookbark()}
        {showAccessButton && (
          <AccessRequestButton
            actionProps={{ id, name, fundTypeName, userName: user.fullName }}
          />
        )}
        {isInviteRequested && (
          <div className="fund-show__btns-zone">
            <mark className="fund-show__mark-support fund-show__mark-support--deal">
              <FormattedMessage id="You have been invited to this fund’s Deal Room" />
            </mark>
            <div className="fund-show__btns-wrap">
              <InviteAcceptButton
                className="fund-show__funds-btn fund-show__funds-btn--custom"
                actionProps={{ id, name }}
              />
              <InviteDeclineButton
                className="fund-show__funds-btn fund-show__funds-btn--custom"
                actionProps={{ id }}
              />
            </div>
          </div>
        )}
        {isAccessRequested && (
          <Button icon="dots" text="Pending approval" disabled frozen />
        )}
        {isPipelined && !isPreview && (
          <LinkButton
            icon="unlock-white"
            text="Enter deal room"
            frozen
            to="invDashboard.dealRooms.show"
            params={{ id }}
          />
        )}
      </div>
    );
  }

  renderBookbark() {
    const isBookmark = this.props.fund.isFavorite
      ? bookmarkSolid
      : bookmarkRegular;
    return (
      <div className="bookmark-button" onClick={this.onFavoriteClick}>
        <Tooltip icon={false}>
          <ImageSvg
            src={isBookmark}
            style={{ width: '20px', marginRight: '0px' }}
          />
          <FormattedMessage id='Add/Remove "My Favorite Funds"' />
        </Tooltip>
      </div>
    );
  }

  render() {
    const { fund, lists, reading, breakpoint } = this.props;
    const {
      investmentTypeId,
      firmName,
      teamMembers,
      name,
      eligibleInvestors,
      disclaimer,
      risks,
      logo,
      representativeInvestments,
      summary,
      highlights,
      previousFunds,
      metricsAndPerformances,
      annualReturns,
    } = fund;
    const { color, colorCode } = investmentTypeColor(investmentTypeId);
    const title = `${name} | Alternative Investments | DarcMatter`;
    const imageSize = 'size=130x130';
    const newLogo = { ...logo, url: `${logo.url}?${imageSize}` };
    return (
      <Modal
        className="fund-show-modal"
        overlayClassName="fund-show-modal__overlay"
        {...this.props}
        scrollable
      >
        <Helmet
          title={title}
          meta={[
            {
              name: 'description',
              content:
                'Access fund opportunities on DarcMatter such as hedge funds, private equity funds, and venture capital funds.',
            },
          ]}
        />
        <ModalBody className="fund-show-modal__body">
          <OverlaySpinner active={reading} />
          <div className="fund-show">
            <div className="fund-show__header">
              <LogoImage
                src={newLogo}
                size={breakpoint === 'mobile' ? 'sm' : 'md'}
              />
              <div className="fund-show__header__info">
                <div className="fund-show__fund-name">{name}</div>
                <div className="fund-show__firm-name">{firmName}</div>
                <div className="fund-show__eligible-and-type">
                  <Tag
                    className="fund-show__type tag--icon tag--icon--left"
                    color={color}
                    border="solid"
                    flat
                  >
                    <SVGIcon name="windows" size="12" fill={colorCode} />
                    {listParam(investmentTypeId, lists.fundType)}
                  </Tag>
                  <MediaQuery minWidth={TABLET.left}>
                    <TagGroup
                      className="fund-show__eligible"
                      values={eligibleInvestors}
                      list={lists.eligibleInvestors}
                      styles={ELIGIBILITY_OPTIONS.ICON_MD}
                      diff
                    />
                  </MediaQuery>
                </div>
              </div>
              <MediaQuery maxWidth={MOBILE.right}>
                <TagGroup
                  className="fund-show__eligible"
                  values={eligibleInvestors}
                  list={lists.eligibleInvestors}
                  styles={ELIGIBILITY_OPTIONS.ICON_MD}
                  diff
                />
              </MediaQuery>
              <MediaQuery minWidth={TABLET.left}>
                {this.renderButtons()}
              </MediaQuery>
            </div>
            <Info params={fund} lists={lists} />
            <MediaQuery maxWidth={MOBILE.right}>
              {this.renderButtons()}
            </MediaQuery>
            {investmentTypeId === FUND_TYPE.HEDGE_FUND &&
              !(isEmpty(metricsAndPerformances) && isEmpty(annualReturns)) && (
                <Statistics
                  metricsAndPerformances={metricsAndPerformances}
                  annualReturns={annualReturns}
                />
              )}
            {investmentTypeId !== FUND_TYPE.HEDGE_FUND &&
              !isEmpty(representativeInvestments) && (
                <Investments
                  representativeInvestments={representativeInvestments}
                />
              )}
            {(summary || !isEmpty(highlights)) && (
              <Overview summary={summary} highlights={highlights} />
            )}
            {investmentTypeId !== FUND_TYPE.HEDGE_FUND &&
              !isEmpty(previousFunds) && (
                <PreviousFunds previousFunds={previousFunds} />
              )}
            {!isEmpty(teamMembers) && <Management teamMembers={teamMembers} />}
            {disclaimer && <Disclaimer disclaimer={disclaimer} />}
            {risks && <Risks risks={risks} />}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  fund: toJS(valuesSelector(state)),
  user: state.get('user').toJS(),
});

const mamDispatchToProps = dispatch => ({
  onFavoriteClick: (fundId, isFavorite) =>
    dispatch(setFavoriteFund(fundId, isFavorite)),
});

export default connect(
  mapStateToProps,
  mamDispatchToProps,
)(
  readData({
    read: (dispatch, { id, user }) => {
      if (checkInvestor(user)) {
        dispatch(clearFund());
        dispatch(readFundPublished(id));
      }
      if (checkAssetManager(user)) {
        dispatch(readFund(id));
      }
    },
  })(
    responsive({
      breakpoints: ['mobile', 'tablet'],
    })(FundShowModal),
  ),
);

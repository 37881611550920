// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';

// dm
import Accordion from '../../../../../molecules/Accordion/Accordion';
import AccordionContent from '../../../../../molecules/Accordion/AccordionContent';
import AccordionTitle from '../../../../../molecules/Accordion/AccordionTitle';
import { MOBILE, TABLET } from '../../../../../../constants/breakpoints';

// own
import './FundShowInvestments.scss';

export default ({ representativeInvestments }) => (
  <div className="fund-show-investments">
    <div className="fund-show-investments__head">
      <div className="fund-show-investments__head__item">
        <FormattedMessage id="Representative Investments" />
      </div>
      <div className="fund-show-investments__head__item">
        <FormattedMessage id="Description" />
      </div>
    </div>
    {representativeInvestments.map((elem, index) => {
      if (elem.description && elem.investment) {
        return (
          <div className="fund-show-investments__row" key={index}>
            <MediaQuery
              minWidth={TABLET.left}
              className="fund-show-investments__row__content"
            >
              <div className="fund-show-investments__row__investments">
                <span>{elem.investment}</span>
              </div>
              <div className="fund-show-investments__row__description">
                {elem.description}
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={MOBILE.right}>
              <Accordion>
                <AccordionTitle
                  iconOpen="arrow-bold-sm"
                  iconHide="arrow-bold-sm"
                >
                  <div className="fund-show-investments__row__investments">
                    <span>{elem.investment}</span>
                  </div>
                </AccordionTitle>
                <AccordionContent>
                  <div className="fund-show-investments__description-title">
                    <FormattedMessage id="Description" />
                  </div>
                  <div className="fund-show-investments__row__description">
                    {elem.description}
                  </div>
                </AccordionContent>
              </Accordion>
            </MediaQuery>
          </div>
        );
      }
      return null;
    })}
  </div>
);

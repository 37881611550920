// vendor
import React, { Component } from 'react';
import classNames from 'classnames';

// dm
import AccordionContent from '../Accordion/AccordionContent';
import Accordion from '../Accordion/Accordion';
import AccordionTitle from '../Accordion/AccordionTitle';

// own
import './ReadMore.scss';

export default class ReadMoreCompoment extends Component {
  render() {
    const { className, children } = this.props;
    const fullClassName = classNames(className, 'read-more');
    return (
      <Accordion className={fullClassName}>
        <AccordionContent className="read-more__content" shouldAlwaysRender>
          {children}
        </AccordionContent>
        <AccordionTitle
          className="read-more__title"
          hiddenText="Read more"
          visibleText="Read less"
        />
      </Accordion>
    );
  }
}

// vendor
import React from 'react';
import classNames from 'classnames';

// own
import './DropdownTriangle.scss';

export default ({ className, ...props }) => (
  <div className="dropdown-triangle">
    <div
      {...props}
      className={classNames(className, 'dropdown-triangle__square')}
    />
  </div>
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

/* local (Hint) styles */
import './hint.styles.scss';

class Hint extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    position: PropTypes.oneOf([
      'top-left',
      'top-center',
      'top-right',
      'bottom-left',
      'bottom-center',
      'bottom-right',
      'right-top',
      'right-center',
      'right-bottom',
      'left-top',
      'left-center',
      'left-bottom',
    ]),
  };

  static defaultProps = {
    text: '',
    position: 'top-left',
  };

  state = {
    isVisible: false,
  };

  render() {
    const { className, text, position, children } = this.props;
    const { isVisible } = this.state;

    const getClass = cx('hint', className && className);

    return (
      <div className={getClass}>
        {children}
        <div className={cx('message', position, isVisible && 'open')}>
          <FormattedMessage id={text}>
            {txt => <span className="text">{txt}</span>}
          </FormattedMessage>
          <div className="triangle" children={<span />} />
        </div>
      </div>
    );
  }
}

export default Hint;

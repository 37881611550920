// vendor
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { destroy } from 'redux-form/immutable';

// dm
import { readData } from '../../../enhancers';
import { readFund, clearFund, readFunds } from '../../../store/actions/fund';
import { form } from '../../../store/reducers/form/fund-manage';
import Loadable from 'react-loadable';

const FundManageOverview = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundManageOverview" */ './0_Overview'),
  loading: () => null,
  modules: ['FundManageOverview'],
});
const FundManagePipeline = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundManagePipeline" */ './2_Pipeline/Index/index'),
  loading: () => null,
  modules: ['FundManagePipeline'],
});
const FundManagePipelineShow = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundManagePipelineShow" */ './2_Pipeline/Show'),
  loading: () => null,
  modules: ['FundManagePipelineShow'],
});
const FundManagePermissions = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundManagePermissions" */ './4_Permissions'),
  loading: () => null,
  modules: ['FundManagePermissions'],
});

const FundManageView = () => (
  <Switch>
    <Route
      exact
      path="/am-dashboard/funds/:id/manage/overview"
      component={FundManageOverview}
    />
    <Route
      exact
      path="/am-dashboard/funds/:id/manage/pipelines"
      component={FundManagePipeline}
    />
    <Route
      path="/am-dashboard/funds/:id/manage/pipelines/:userId"
      component={FundManagePipelineShow}
    />
    <Route
      exact
      path="/am-dashboard/funds/:id/manage/permissions"
      component={FundManagePermissions}
    />
    <Redirect to="/am-dashboard/funds/:id/manage/overview" />
  </Switch>
);

export default readData({
  read: (
    dispatch,
    {
      match: {
        params: { id },
      },
    },
  ) =>
    Promise.all([
      dispatch(readFund(id)),
      // Need for generate another funds list
      dispatch(readFunds()),
    ]),
  clear: dispatch => {
    dispatch(clearFund());
    dispatch(destroy(form));
  },
})(FundManageView);

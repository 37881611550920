// vendor
import { fromJS } from 'immutable';
// dm
import { types } from '../actions/intl';
import { createReducer } from '../../utils/core';
import en_us from '../../assets/en-us.json';
// import ko_kr from '../../assets/ko-kr.json';

/* initial state */
const initialState = fromJS({ locale: 'en', key: 'en', messages: en_us });

/* reducer */
export default createReducer(initialState, {
  [types.READ_LOCALE_SUCCESS]: (state, translationsData) =>
    state.merge({ messages: fromJS(translationsData) }),
});

// vendor
import React, { Component } from 'react';
import classNames from 'classnames';

// own
import Image from './Image';

export default class LogoImage extends Component {
  render() {
    const { className, ...props } = this.props;
    const fullClassName = classNames(className, 'logo-image');
    return <Image {...props} full className={fullClassName} />;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../icon';

/* local (notification) styles */
import './notification.styles.scss';

const Notification = ({
  className,
  title,
  text,
  success,
  danger,
  handleClose,
}) => (
  <div
    className={cx(
      'notification',
      className && className,
      danger && 'danger',
      success && 'success',
    )}
  >
    <button className="close" onClick={() => handleClose()}>
      <Icon name={'close'} size={'24'} color={'grey'} />
    </button>
    <div className="row">
      {(success || danger) && (
        <div className="image">
          <Icon name={'info'} size={'28'} />
        </div>
      )}
      <div>
        <h5>{title}</h5>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  </div>
);

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  onClose: PropTypes.func,
};

Notification.defaultProps = {
  title: '',
  text: '',
  success: false,
  danger: false,
};

class NotificationWrapper extends Component {
  state = { active: false };

  handleClose = () => {
    if (this.props.onClose) this.props.onClose();
    this.setState({ active: false });
  };

  handleOpen = () => {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ active: true });
  };

  render() {
    return this.state.active || this.props.isOpen ? (
      <Notification {...this.props} handleClose={this.handleClose} />
    ) : null;
  }
}

NotificationWrapper.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

NotificationWrapper.defaultProps = {
  title: '',
  text: '',
  success: false,
  danger: false,
};

export default NotificationWrapper;

// dm
import { createConstants } from '../../utils/core';
import { CALL_API } from '../../constants/api';

/* action types */
export const types = createConstants(
  'READ_LOCALE_REQUEST',
  'READ_LOCALE_SUCCESS',
  'READ_LOCALE_FAILURE',

  'UPDATE_LOCALE_REQUEST',
  'UPDATE_LOCALE_SUCCESS',
  'UPDATE_LOCALE_FAILURE',
);

export const readLocale = () => ({
  [CALL_API]: {
    types: [
      types.READ_LOCALE_REQUEST,
      types.READ_LOCALE_SUCCESS,
      types.READ_LOCALE_FAILURE,
    ],
    method: 'get',
    url: 'translate?filter=[{"category":["common","public"]}]&per-page=99999',
  },
});

export const updateLocale = (messages = []) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_LOCALE_REQUEST,
      types.UPDATE_LOCALE_SUCCESS,
      types.UPDATE_LOCALE_FAILURE,
    ],
    method: 'post',
    url: 'translate',
    data: messages,
  },
});

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import NotFoundView from '../components/dm-public/error-page';
import KycView from './Kyc';
import InvDashboardView from './InvDashboard';
import FundManageView from './Funds/Manage';
import UnauthenticatedRoute from '../enhancers/UnauthenticatedRoute';
import AuthenticatedRoute from '../enhancers/AuthenticatedRoute';
import InvestorRoute from '../enhancers/InvestorRoute';
import AssetManagerRoute from '../enhancers/AssetManagerRoute';

const Profile = Loadable({
  loader: () => import(/* webpackChunkName: "Profile" */ './Profile/Profile'),
  loading: () => null,
  modules: ['Profile'],
});

// const BrowseInvestments = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "BrowseInvestments" */ './BrowseInvestments'),
//   loading: () => null,
//   modules: ['BrowseInvestments'],
// });

const FundInvestments = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundInvestments" */ './Investments'),
  loading: () => null,
  modules: ['FundInvestments'],
});

const AmDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "AmDashboard" */ './AmDashboard'),
  loading: () => null,
  modules: ['AmDashboard'],
});

const FundEdit = Loadable({
  loader: () => import(/* webpackChunkName: "FundEdit" */ './Funds/Edit'),
  loading: () => null,
  modules: ['FundEdit'],
});

const FundNew = Loadable({
  loader: () => import(/* webpackChunkName: "FundNew" */ './Funds/New'),
  loading: () => null,
  modules: ['FundNew'],
});

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  loading: () => null,
  modules: ['Home'],
});

const InstitutionalServices = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "InstitutionalServices" */ './static/InstitutionalServices'
    ),
  loading: () => null,
  modules: ['InstitutionalServices'],
});

const BrowseInvestmentsPublic = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BrowseInvestmentsPublic" */ './static/BrowseInvestments'
    ),
  loading: () => null,
  modules: ['BrowseInvestmentsPublic'],
});
const ForInvestors = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ForInvestors" */ './static/ForInvestors'),
  loading: () => null,
  modules: ['ForInvestors'],
});
const ForAssetManagers = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ForAssetManagers" */ './static/ForAssetManagers'
    ),
  loading: () => null,
  modules: ['ForAssetManagers'],
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ './Login'),
  loading: () => null,
  modules: ['Login'],
});
const Registration = Loadable({
  loader: () => import(/* webpackChunkName: "Registration" */ './Registration'),
  loading: () => null,
  modules: ['Registration'],
});
const ResetPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ResetPassword" */ './ResetPassword'),
  loading: () => null,
  modules: ['ResetPassword'],
});
const ConfirmPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ConfirmPassword" */ './ConfirmPassword'),
  loading: () => null,
  modules: ['ConfirmPassword'],
});
const ConfirmUnsubscribe = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ConfirmUnsubscribe" */ './ConfirmUnsubscribe'),
  loading: () => null,
  modules: ['ConfirmUnsubscribe'],
});
const AboutUs = Loadable({
  loader: () => import(/* webpackChunkName: "AboutUs" */ './static/AboutUs'),
  loading: () => null,
  modules: ['AboutUs'],
});
const ContactUs = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ContactUs" */ './static/ContactUs'),
  loading: () => null,
  modules: ['ContactUs'],
});
const Press = Loadable({
  loader: () => import(/* webpackChunkName: "Press" */ './static/Press'),
  loading: () => null,
  modules: ['Press'],
});
const Privacy = Loadable({
  loader: () => import(/* webpackChunkName: "Privacy" */ './static/Privacy'),
  loading: () => null,
  modules: ['Privacy'],
});
const Terms = Loadable({
  loader: () => import(/* webpackChunkName: "Terms" */ './static/Terms'),
  loading: () => null,
  modules: ['Terms'],
});

export default ({ breakpoint }) => (
  <Switch>
    <UnauthenticatedRoute exact path="/" component={Home} device={breakpoint} />
    <UnauthenticatedRoute
      exact
      path="/institutional-services"
      component={InstitutionalServices}
      device={breakpoint}
    />
    <UnauthenticatedRoute
      exact
      path="/browse-investments"
      component={BrowseInvestmentsPublic}
      device={breakpoint}
    />
    <UnauthenticatedRoute
      exact
      path="/for-investors"
      component={ForInvestors}
      device={breakpoint}
    />
    <UnauthenticatedRoute
      exact
      path="/for-asset-managers"
      component={ForAssetManagers}
      device={breakpoint}
    />
    <UnauthenticatedRoute exact path="/log-in" component={Login} />
    <UnauthenticatedRoute exact path="/registration" component={Registration} />
    <UnauthenticatedRoute
      exact
      path="/reset-password"
      component={ResetPassword}
    />
    <UnauthenticatedRoute
      exact
      path="/confirm-password"
      component={ConfirmPassword}
    />
    <UnauthenticatedRoute
      exact
      path="/confirm-unsubscribe"
      component={ConfirmUnsubscribe}
    />

    {/* Profile */}
    <AuthenticatedRoute exact path="/profile" component={Profile} />

    {/* Browse Investments */}
    {/*<InvestorRoute exact path="/browse" component={BrowseInvestments} />*/}

    {/* KYC */}
    <InvestorRoute path="/know-your-customer" component={KycView} />

    {/* Investor Dashboard */}
    <InvestorRoute path="/inv-dashboard" component={InvDashboardView} />

    {/* Investments */}
    <InvestorRoute exact path="/investments/:id" component={FundInvestments} />

    {/* Asset Manager Dashboard */}
    <AssetManagerRoute exact path="/am-dashboard" component={AmDashboard} />

    {/* Manage Fund */}
    <AssetManagerRoute
      path="/am-dashboard/funds/:id/manage"
      component={FundManageView}
    />
    <AssetManagerRoute
      exact
      path="/am-dashboard/funds/:id/edit"
      component={FundEdit}
    />
    <AssetManagerRoute
      exact
      path="/am-dashboard/funds/new"
      component={FundNew}
    />

    {/* Static */}
    <Route
      path="/About-Us"
      render={props => <AboutUs {...props} device={breakpoint} />}
    />
    <Route path="/Contact-Us" component={ContactUs} />
    <Route
      path="/press"
      render={props => <Press {...props} device={breakpoint} />}
    />
    <Route path="/privacy-policy" component={Privacy} />
    <Route path="/Terms-Of-Use" component={Terms} />

    {/* 404 */}
    <Route component={NotFoundView} />
  </Switch>
);

// vendor
import React from 'react';

// dm
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import { Button } from '../../../dm-public/ui-kit';

import './SuccessFormModal.scss';
import modalImg from '../../../../images/pages/institutioanal/success-modal.svg';

const SuccessFormModal = props => (
  <Modal className="success-form-modal" {...props}>
    <ModalBody>
      <div className="success-form-modal__content">
        <img src={modalImg} alt="" />
        <h3>
          Thank you for your interest in DarcMatter institutional services.
        </h3>
        <p>
          We have received your contact information. A DarcMatter team member
          will reach out to you within 48 hours.
        </p>
        <Button onClick={() => props.close()}>Close</Button>
      </div>
    </ModalBody>
  </Modal>
);

export default SuccessFormModal;

// dm
import { GUEST, ASSET_MANAGER, INVESTOR, ADMIN } from '../constants/user';

export function hasAccess(user, requiredAccess) {
  return !!(user && user.access & requiredAccess);
}

export const checkGuest = user => hasAccess(user, GUEST);
export const checkAuthorized = user =>
  hasAccess(user, ASSET_MANAGER | INVESTOR | ADMIN);
export const checkAssetManager = user => hasAccess(user, ASSET_MANAGER);
export const checkInvestor = user => hasAccess(user, INVESTOR);
export const checkAdmin = user => hasAccess(user, ADMIN);

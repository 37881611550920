// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import { isNil, isObject, isArray, map } from 'lodash';

// dm
import { toJS } from '../../../utils/core';
import { clearMeta } from '../../../utils/form/form';

// own
import ReactSelectCustom from './ReactSelectCustom';

class SelectComponent extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    // meta: {},
    // input: {},
    placeholder: 'Select',
    openOnFocus: true,
    scrollMenuIntoView: false,
  };

  getValue(item) {
    return isObject(item) ? item.value : item;
  }

  handleChange() {
    const {
      onChangeField,
      input: { onChange },
    } = this.props;
    return newValue => {
      if (isArray(newValue)) {
        newValue = map(newValue, this.getValue);
      } else {
        newValue = this.getValue(newValue);
      }
      if (onChangeField) {
        onChangeField(newValue);
      }
      return onChange(fromJS(newValue));
    };
  }

  render() {
    const {
      meta,
      input,
      multi,
      autosize,
      clearable,
      searchable,
      intl,
      ...props
    } = this.props;
    const placeholder = intl.formatMessage({ id: props.placeholder });
    let value = input.value;
    if (multi) {
      value = value ? toJS(value) : [];
    } else if (isNil(value)) {
      // Fixes for react-select with redux-form https://github.com/erikras/redux-form/issues/82
      value = '';
    }
    return (
      <ReactSelectCustom
        {...clearMeta(meta)}
        {...input}
        {...props}
        {...{ multi, placeholder, value }}
        autosize={autosize || !!multi}
        clearable={clearable || !!multi}
        searchable={searchable || !!multi}
        onBlur={() => input.onBlur(value)}
        onChange={this.handleChange()}
      />
    );
  }
}

export default injectIntl(SelectComponent);

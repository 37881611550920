// vendor
import React from 'react';
import classNames from 'classnames';

// own
import './BaseTemplate.scss';

export default ({ className, children }) => (
  <div className={classNames(className, 'base-template')}>{children}</div>
);

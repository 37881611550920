import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { toJS } from '../utils/core';

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuthorized ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

const mapStateToProps = state => ({
  isAuthorized: toJS(state.get('user').get('isAuthorized')),
});

export default connect(
  mapStateToProps,
  null,
)(AuthenticatedRoute);

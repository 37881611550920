// vendor
import axios from 'axios';
import { stringify } from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  find,
  isPlainObject,
  mapValues,
  isArray,
  map,
  isNull,
  findKey,
  omit,
  assign,
  round,
  isEmpty,
  forOwn,
  snakeCase,
  isObject,
  cloneDeepWith,
  join,
} from 'lodash';
import { SubmissionError } from 'redux-form';

// dm
import {
  isAccessTokenExpired,
  getRefreshToken,
  refreshTokens,
  getAccessToken,
} from '../../utils/jwt';
import {
  startFetchingData,
  fetchingSuccess,
  fetchingFailure,
  showToastrSuccess,
  showToastrError,
} from '../actions/app';
import { logout } from '../actions/user';
import {
  REQUEST_TIMEOUT,
  SIZE_MAX,
  CALL_API,
  TYPES_LENGTH,
} from '../../constants/api';
import configuration from '../../config';

function undefineNullValues(value) {
  const collection = find(
    [
      { checker: isPlainObject, iterator: mapValues },
      { checker: isArray, iterator: map },
    ],
    ({ checker }) => checker(value),
  );
  if (collection) {
    return collection.iterator(value, undefineNullValues);
  }
  return isNull(value) ? undefined : value;
}

const toFormData = data => {
  const formData = new FormData();
  forOwn(data, (value, key) => {
    formData.append(snakeCase(key), value);
  });
  return formData;
};

function toFormErrors(response = {}) {
  return cloneDeepWith(response.errors, error => {
    let errorLine;
    if (isArray(error) && !isObject(error[0])) {
      errorLine = join(error, '; ');
    }
    return errorLine;
  });
}

const apiInstance = axios.create({
  baseURL: `https://${configuration.apiHost}/${configuration.apiVersion}/`,
  headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  timeout: REQUEST_TIMEOUT,
  maxContentLength: SIZE_MAX,
  paramsSerializer: params =>
    stringify(decamelizeKeys(params), { arrayFormat: 'bracket' }),
  transformRequest: data => {
    const hasFile = findKey(data, value => value instanceof File);
    let reqData = {};
    try {
      reqData = hasFile
        ? toFormData(data)
        : JSON.stringify(decamelizeKeys(data));
    } catch (e) {
      console.error('api transformRequest Error', e);
    }
    return reqData;
  },
  transformResponse: (response, headers) => {
    let responseData = {};
    try {
      responseData = JSON.parse(response);
    } catch (e) {
      console.error(e);
    }

    return undefineNullValues(camelizeKeys(responseData));
  },
});

function callApi({ isAuth, url, method = 'post', data, next, actionProgress }) {
  const requestConfig = { url, method, data, isAuth, next };
  if (actionProgress && data && data.type) {
    requestConfig.onUploadProgress = ({ loaded, total }) => {
      const progress = round((loaded / total) * 100);
      const type = data.type;
      next({ ...actionProgress, payload: { type, progress } });
    };
  }
  return apiInstance.request(requestConfig);
}

apiInstance.interceptors.request.use(
  config => {
    const { isAuth, next, ...newConfig } = config;
    if (isAuth) {
      newConfig.headers.Authorization = `Bearer ${getAccessToken()}`;
      if (isAccessTokenExpired() && getRefreshToken()) {
        return refreshTokens()
          .then(data => {
            newConfig.headers.Authorization = `Bearer ${data.access_token}`;
            return config;
          })
          .catch(error => {
            next(logout(next));
            return Promise.reject(error);
          });
      }
      return newConfig;
    }
    return newConfig;
  },
  error => {
    console.error(error);
  },
);

const apiMiddleware = store => next => action => {
  if (!action) {
    return next();
  }

  // check call api action
  const callAPI = action[CALL_API];
  if (!callAPI) {
    return next(action);
  }

  // check types
  const { types } = callAPI;
  if (!Array.isArray(types) || types.length !== TYPES_LENGTH) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  // define variables
  const [requestType, successType, failureType] = types;

  const {
    isAuth = true,
    url,
    method,
    data,
    form,
    asyncValidation,
    name,
    extraData,
    withProgressBar,
  } = callAPI;

  const actionWith = rest => ({
    ...omit(action, ['CALL_API']),
    ...rest,
  });

  // start progress
  if (withProgressBar !== false) {
    next(startFetchingData());
  }

  // dispatch request
  next(
    actionWith({
      type: requestType,
      payload: {
        extraData,
      },
    }),
  );

  const actionProgress =
    withProgressBar &&
    actionWith({
      type: requestType,
      payload: { extraData },
    });

  return callApi({ isAuth, url, method, data, next, actionProgress }).then(
    ({ config, data: response, ...rest }) => {
      // stop progress
      if (withProgressBar !== false) {
        next(fetchingSuccess(response));
      }

      const payload = {
        config,
        response,
        ...rest,
      };

      if (name) {
        payload.response = assign({}, response, { name });
      }

      if (extraData) {
        payload.extraData = extraData;
      }

      if (config.data && !(config.data instanceof FormData)) {
        payload.requestData = undefineNullValues(
          camelizeKeys(JSON.parse(config.data)),
        );
      }
      // do success action
      next(actionWith({ type: successType, payload }));

      // check for message
      if (response.message) {
        next(showToastrSuccess({ ...response, translate: false }));
      }

      if (asyncValidation) {
        return Promise.resolve();
      }
      return Promise.resolve(response);
    },
    error => {
      if (error.response) {
        // 401 error - invalid / expired token
        if (error.response.status === 401) {
          // console.info('logging out:::', error.response.status);
          next(logout(next));
          // console.info('resolving error:::', error.response.status);
          return Promise.resolve(error);
        }

        // stop progress
        if (withProgressBar !== false) {
          next(fetchingFailure(error));
        }

        // 413 error
        if (error.response.status === 413) {
          next(
            showToastrError({
              message: 'notification.request_entity_too_large',
            }),
          );
        }

        // 422 error - invalid credentials
        if (
          error.response.status === 422 &&
          error.response.data &&
          error.response.data.errors &&
          (error.response.data.errors.password ||
            error.response.data.errors.email)
        ) {
          // error.response.data.errors.password = ' ';
          // error.response.data.errors.email = ' ';
          return Promise.resolve(error.response.data);
        }

        // toast error message
        if (error.response.data && error.response.data.message) {
          next(
            showToastrError({
              ...error.response.data,
              translate: false,
            }),
          );
        }

        // TODO: check if need form now, maybe enough check response.errors
        if (
          error.response.data &&
          error.response.data.errors &&
          !isEmpty(error.response.data.errors)
        ) {
          if (form) {
            throw new SubmissionError(toFormErrors(error.response.data));
          } else if (asyncValidation) {
            throw toFormErrors(error.response.data);
          }
        }
      } else {
        // log error
        console.error('Api middleware error:', error);
      }

      // do failure action
      next(
        actionWith({
          type: failureType,
          payload: error,
        }),
      );

      // reject promise
      return Promise.reject(error);
    },
  );
};

export default apiMiddleware;

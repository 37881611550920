// vendor
import { fromJS } from 'immutable';
// dm
import { fundTypes } from '../actions';
import { createReducer } from '../../utils/core';

/* initial state */
const initialState = fromJS({
  isRequesting: null,
  isRequested: null,
  data: null,
  behavior: null,
  errors: null,
});

/* reducer */
export default createReducer(initialState, {
  [fundTypes.READ_FUND_ACTIVITY_FEEDS_REQUEST]: state =>
    state.merge({ isRequesting: true }),
  [fundTypes.READ_FUND_ACTIVITY_FEEDS_SUCCESS]: (state, feed) =>
    state.merge({ isRequested: true, data: feed.response.data }),
  [fundTypes.READ_FUND_ACTIVITY_FEEDS_FAILURE]: (state, error) =>
    state.merge({ error }),
  [fundTypes.CLEAR_FUND_ACTIVITY_FEEDS]: state => state.merge(initialState),
  [fundTypes.UPDATE_BEHAVIOR_ACTIVITY_FEEDS]: (state, payload) =>
    state.merge({ behavior: payload }),
});

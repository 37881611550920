// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import Button from '../../../atoms/Button';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import ModalControls from '../../Modal/ModalControls';

class NoSignedDocsModal extends Component {
  render() {
    const { close, fundName } = this.props;
    return (
      <Modal {...this.props}>
        <ModalHeader title="modal.no-signed-docs-modal.title" />
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage id="modal.no-signed-docs-modal.text1" />
            &nbsp;
            {fundName}
            .&nbsp;
            <FormattedMessage id="modal.no-signed-docs-modal.text2" />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalControls>
            <Button text="Ok" onClick={close} />
          </ModalControls>
        </ModalFooter>
      </Modal>
    );
  }
}

export default NoSignedDocsModal;

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
// dm
import Icon from '../../atoms/Icons/Icon';

export default class AccordionTitleComponent extends Component {
  static propTypes = {
    hiddenText: PropTypes.string,
    visibleText: PropTypes.string,
    iconHide: PropTypes.string,
    iconOpen: PropTypes.string,
  };

  render() {
    const {
      className,
      children,
      isOpen,
      toggle,
      hiddenText,
      visibleText,
      iconHide,
      iconOpen,
    } = this.props;
    const fullClassName = classNames(className, 'accordion__title');
    return (
      <div className={fullClassName} onClick={toggle}>
        {iconOpen && <Icon name={isOpen ? iconOpen : iconHide} />}
        {children || (
          <FormattedMessage id={isOpen ? visibleText : hiddenText} />
        )}
      </div>
    );
  }
}

// vendor
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// dm
import Icon from '../../atoms/Icons/Icon';

// own
import './AsideLink.scss';

export default ({ className, id, text, icon, counter, active, ...props }) => (
  <NavLink
    {...props}
    className={classNames(
      className,
      'aside-link',
      'aside__list__item',
      'has-icons',
      active && 'has-icons--active',
    )}
    activeClassName="aside-link--active has-icons--active"
  >
    {icon && <Icon name={icon} className="aside-link__icon" />}
    <span className="aside-link__text">
      {text || (id && <FormattedMessage id={id} />)}
    </span>
    {counter && <span className="aside-link__counter">{counter}</span>}
  </NavLink>
);

// vendor
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// own
import './TabLink.scss';

export default ({ className, id, counter, activeTab, ...props }) => (
  <NavLink
    {...props}
    className={classNames(className, 'tab-link', {
      'tab-link--active': activeTab,
    })}
    activeClassName="tab-link--active"
  >
    <span className="tab-link__text">{id && <FormattedMessage id={id} />}</span>
    {typeof counter === 'number' && (
      <span className="tab-link__counter"> ({counter})</span>
    )}
  </NavLink>
);

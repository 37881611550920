// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { isEmpty, toArray } from 'lodash';
import ReactHighcharts from 'react-highcharts';

// own
import './FundShowStatistics.scss';

const config = {
  chart: {
    type: 'column',
    height: 280,
  },
  title: '',
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    title: '',
    labels: {
      formatter() {
        return `${this.value}%`;
      },
    },
  },
  tooltip: {
    valueSuffix: '%',
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: 'Return',
      data: [],
      showInLegend: false,
    },
  ],
  credits: {
    enabled: false,
  },
};

export default class FundShowStatistics extends Component {
  render() {
    let { metricsAndPerformances = [], annualReturns = [] } = this.props;
    const years = [];
    const sizes = [];
    const yearsNoData = [];

    metricsAndPerformances = metricsAndPerformances.filter(
      item => item.metric && item.performance,
    );

    if (!isEmpty(annualReturns)) {
      annualReturns = toArray(annualReturns).map(elem => {
        const { year, size } = elem;
        if (years.length > 0 && year !== years[years.length - 1] + 1) {
          let prevYear = years[years.length - 1] + 1;
          for (prevYear; prevYear < year; prevYear++) {
            yearsNoData.push(prevYear);
          }
        }
        years.push(year);
        sizes.push(size);
        return elem;
      });
      const currentYear = moment().year();
      if (years[years.length - 1] !== currentYear - 1) {
        let prevYear = years[years.length - 1] + 1;
        for (prevYear; prevYear < currentYear; prevYear++) {
          yearsNoData.push(prevYear);
        }
      }
      config.xAxis.categories = years;
      config.series[0].data = sizes;
    }

    return (
      <div className="fund-show-statictics">
        {!isEmpty(metricsAndPerformances) && (
          <div className="fund-show-statictics__performance">
            <div className="fund-show-statictics__performance__head">
              <div>
                <FormattedMessage id="Metric" />:
              </div>
              <div>
                <FormattedMessage id="Performance" />:
              </div>
            </div>
            {metricsAndPerformances.map((item, index) => (
              <div
                className="fund-show-statictics__performance__row"
                key={index}
              >
                <div className="fund-show-statictics__performance__row__label">
                  {`${index + 1}. ${item.metric}`}
                </div>
                <div className="fund-show-statictics__performance__row__value">
                  {item.performance}
                </div>
              </div>
            ))}
          </div>
        )}
        {!isEmpty(annualReturns) && (
          <div className="fund-show-statictics__chart">
            <div className="fund-show-statictics__chart__label">
              <FormattedMessage id="Annual Returns" />:
            </div>
            <ReactHighcharts config={config} />
            {!isEmpty(yearsNoData) && (
              <div className="fund-show-statictics__chart__label">
                <FormattedMessage
                  id="annualReturns.notAvailable"
                  defaultMessage="{years} - data for this years are not available"
                  values={{
                    years: yearsNoData.join(', '),
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

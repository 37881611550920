import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Modal from 'react-modal';
import queryString from 'query-string';
import jsCookies from 'js-cookie';

import createStore from './store';
import App from './components/Application';
import ScrollToTop from './enhancers/ScrollToTop';
import {
  mergeUser,
  confirmUser,
  authorizeUserSuccess,
} from './store/actions/user';

Modal.setAppElement('#root');

// Create a store and get back itself and its history object
const { store, history } = createStore();

const { redirect } = queryString.parse(window.location.search);
if (redirect) {
  const { view, token, action, redirectUrl } = JSON.parse(redirect);
  if (view) {
    // return to "dashboard"
    if (view === 'dashboard') {
      history.replace('/log-in');
    }

    // return to "log-in" - send activation request
    if (view === 'login') {
      // history.replace('/log-in');
      // activation
      if (token) {
        store.dispatch(confirmUser(token)).then(response => {
          store.dispatch(authorizeUserSuccess(response.data));
        });
      }

      // normal redirection while not logged in
      if (action && action === 'redirect' && redirectUrl) {
        jsCookies.set('redirectUrl', redirectUrl);
      }
    }

    // return to "confirm-password" - send configrmation request
    if (view === 'reset-password' && token) {
      store.dispatch(mergeUser({ token }));
    }
  }
}

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Frontload noServerRender={true}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Frontload>
    </ConnectedRouter>
  </Provider>
);

const root = document.querySelector('#root');

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}

// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// dm
import Icon from '../../atoms/Icons/Icon';
import HeaderNav from '../HeaderNav';
import HeaderNavAccountAvatar from '../HeaderNavAccountAvatar';
import HeaderNavItem from '../HeaderNavItem';
import Dropdown from '../Dropdown/Dropdown';
import { logout } from '../../../store/actions/user';
import { currentUserSelector } from '../../../store/reducers/user';
import { toJS } from '../../../utils/core';
import { getPath } from '../../../utils/route';
import SearchBrowseView from '../../../views/InvDashboard/BrowseInvestments/Search';

class HeaderNavAuthorized extends Component {
  state = { viewSearch: false };

  onSearchClick = () => this.setState({ viewSearch: !this.state.viewSearch });
  render() {
    const {
      breakpoint,
      user,
      goToProfile,
      handleLogout,
      pathname,
    } = this.props;
    const { viewSearch } = this.state;
    // const notificationsAmount =
    //   user.unreadFeedsAmount + user.unreadMessagesAmount;
    const isDesktop = breakpoint === 'desktop';
    const isTabletOrMobile = breakpoint === 'tablet' || breakpoint === 'mobile';
    return (
      <HeaderNav>
        <a
          href="https://blog.darcmatter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HeaderNavItem icon="header-question" text="Resource Center" />
        </a>

        {/*<HeaderNavItem*/}
        {/*icon="header-messages"*/}
        {/*text="Messages"*/}
        {/*notificationsAmount={user.unreadMessagesAmount}*/}
        {/*/>*/}
        {/*<HeaderNavItem*/}
        {/*icon="header-notification"*/}
        {/*text="Notifications"*/}
        {/*notificationsAmount={user.unreadFeedsAmount}*/}
        {/*/>*/}

        {pathname === '/inv-dashboard/browse-investments' && isTabletOrMobile && (
          <>
            <HeaderNavItem
              icon="magnifier-2"
              text="Search"
              onClick={this.onSearchClick}
            />
            {viewSearch && (
              <SearchBrowseView mobile onClose={this.onSearchClick} />
            )}
          </>
        )}
        <div className="header-nav__separator" />

        <Dropdown
          position="bottom-right"
          className="header-nav__account has-icons"
          list={[
            {
              label: 'Profile & Settings',
              translate: true,
              onClick: goToProfile,
            },
            {
              label: 'Log Out',
              translate: true,
              icon: 'exit',
              iconPos: 'right',
              onClick: handleLogout,
            },
          ]}
        >
          <div className="header-nav__account__handler">
            <HeaderNavAccountAvatar {...user} />
            {isDesktop && (
              <div className="header-nav__account__full-name">
                {user.fullName}
              </div>
            )}
            <Icon name="select-arrow" />
          </div>
        </Dropdown>
      </HeaderNav>
    );
  }
}

export default connect(
  state => ({
    user: toJS(currentUserSelector(state)),
  }),
  dispatch => ({
    goToProfile: () => dispatch(push(getPath('profile'))),
    handleLogout: () => dispatch(logout()),
  }),
)(HeaderNavAuthorized);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import Counter from '../counter';

import './tabs.styles.scss';

const Tab = ({
  text,
  className,
  id,
  counter,
  isActive,
  tabIndex,
  children,
  changeActiveTab,
  intl,
  ...props
}) => {
  const classNames = cx('tab', isActive && 'active', className);
  return (
    <div
      className={classNames}
      {...props}
      onClick={() => changeActiveTab(tabIndex)}
    >
      <div className="tabText">
        {intl.formatMessage({ id: text })}
        {children}
        {counter && <Counter counter={counter} hide={isActive} />}
      </div>
      {isActive && <div className="line" />}
    </div>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  tabIndex: PropTypes.number,
  text: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

Tab.defaultProps = {
  isActive: false,
};

export default injectIntl(Tab);

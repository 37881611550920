// Extending String prototype with new functions,
// if that functions is already defined in "String.prototype",
// then "Object.defineProperty" will throw an exception

import { words, toPath, toUpper, snakeCase } from 'lodash';

// eslint-disable-next-line
Object.defineProperty(String.prototype, 'w', {
  // Specify "enumerable" as "false" to prevent function enumeration
  enumerable: false,
  /**
   * Removes all occurence of specified item from array
   * @this String
   * @returns {Array} Words in string
   */
  value() {
    return words(this, /[^, ]+/g);
  },
});

// eslint-disable-next-line
Object.defineProperty(String.prototype, 'p', {
  // Specify "enumerable" as "false" to prevent function enumeration
  enumerable: false,
  /**
   * Removes all occurence of specified item from array
   * @this String
   * @returns {Array} Words in string
   */
  value() {
    return toPath(this);
  },
});

// eslint-disable-next-line
Object.defineProperty(String.prototype, 'c', {
  // Specify "enumerable" as "false" to prevent function enumeration
  enumerable: false,
  /**
   * Removes all occurence of specified item from array
   * @this String
   * @returns {Array} Words in string
   */
  value() {
    return toUpper(snakeCase(this));
  },
});

// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// own
import './DotsSpinner.scss';

export default class DotsSpinner extends Component {
  static propTypes = {
    type: PropTypes.oneOf('light dark'.w()),
  };

  static defaultProps = {
    type: 'light',
  };

  render() {
    return (
      <svg
        className="dots-spinner"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <circle stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    );
  }
}

import React, { Children } from 'react';

// import './tabs.styles.scss'

const TabContainer = ({ active, ...props }) => {
  const childrens = Children.toArray(props.children);
  return (
    <div className="tabContainer">
      {childrens.filter(
        (contentActiveTab, index) => active === index && contentActiveTab,
      )}
    </div>
  );
};

export default TabContainer;

// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'READ_KYC_PROOF_REQUEST',
  'READ_KYC_PROOF_SUCCESS',
  'READ_KYC_PROOF_FAILURE',

  'CREATE_KYC_PROOF_REQUEST',
  'CREATE_KYC_PROOF_SUCCESS',
  'CREATE_KYC_PROOF_FAILURE',

  'CREATE_KYC_PROOF_MEDIA_REQUEST',
  'CREATE_KYC_PROOF_MEDIA_SUCCESS',
  'CLEAR_KYC_PROOF_MEDIA',
);

export const createKycProof = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_KYC_PROOF_REQUEST,
      types.CREATE_KYC_PROOF_SUCCESS,
      types.CREATE_KYC_PROOF_FAILURE,
    ],
    method: 'put',
    url: `users/${id}/profile`,
    data,
    form: 'kycProof',
    withProgressBar: true,
  },
});

export const clearKycProofMedia = () => ({
  type: types.CLEAR_KYC_PROOF_MEDIA,
});

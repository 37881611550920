// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { values } from 'lodash';

// dm
import AvatarImage from '../../../atoms/Images/AvatarImage';
import FormGroup from '../../FormGroup';
import FormControlStatic from '../../../atoms/FormControlStatic';
import Icon from '../../../atoms/Icons/Icon';
import IconLg from '../../../atoms/Icons/IconLg';
import { ADMIN_ROLES } from '../../../../constants/fund';

// own
import './UserCard.scss';

const UserCardComponent = props => {
  const {
    className,
    name,
    role,
    firm,
    position,
    phoneNumber,
    linkedIn,
    border,
    user,
    isCurrentUserOwner,
    changeOwner,
    confirmDeletion,
  } = props;
  const isPendingCard = role === ADMIN_ROLES.PENDING;
  const isAdminCard = role === ADMIN_ROLES.ADMINISTRATOR;
  const hasFullPermissions = isCurrentUserOwner && isAdminCard;
  const fullClassName = classNames(className, 'user-card', {
    'user-card--pending': isPendingCard,
    'user-card--without-border': !border,
  });
  return (
    <div className={fullClassName}>
      <AvatarImage src={user.avatarUrl} size="sm" />
      <div className="user-card__info">
        <div className="user-card__name">
          {name}
          {isCurrentUserOwner && (
            <Icon name="closer-gray" onClick={confirmDeletion} />
          )}
        </div>
        {isPendingCard && (
          <div className="user-card__details">
            <FormGroup>
              <label>
                <FormattedMessage id="Email" />:
              </label>
              <FormControlStatic title={user.email}>
                {user.email || '-'}
              </FormControlStatic>
            </FormGroup>
          </div>
        )}
        {isPendingCard ? (
          <div className="user-card__pending">
            <IconLg name="clock" />
            <FormattedMessage id="Pending..." />
          </div>
        ) : (
          <div className="user-card__details">
            <FormGroup>
              <label>
                <FormattedMessage id="Email" />:
              </label>
              <FormControlStatic title={user.email}>
                {user.email || '-'}
              </FormControlStatic>
            </FormGroup>
            <FormGroup className="user-card__details__role">
              <label>
                <FormattedMessage id="Role" />:
              </label>
              <FormControlStatic title={role}>
                {role || '-'}
                {hasFullPermissions && (
                  <div
                    className="user-card__details__change-owner"
                    onClick={changeOwner}
                  >
                    (<FormattedMessage id="Change to Owner" />)
                  </div>
                )}
              </FormControlStatic>
            </FormGroup>
            <FormGroup>
              <label>
                <FormattedMessage id="Firm" />:
              </label>
              <FormControlStatic title={firm}>{firm || '-'}</FormControlStatic>
            </FormGroup>
            <FormGroup>
              <label>
                <FormattedMessage id="Position" />:
              </label>
              <FormControlStatic title={position}>
                {position || '-'}
              </FormControlStatic>
            </FormGroup>
            <FormGroup>
              <label>
                <FormattedMessage id="Phone" />:
              </label>
              <FormControlStatic title={phoneNumber}>
                {phoneNumber || '-'}
              </FormControlStatic>
            </FormGroup>
            {linkedIn && (
              <FormGroup className="user-card__details__linkedin">
                <label>
                  <FormattedMessage id="LinkedIn" />:
                </label>
                {/* TODO: use <LinkedinButton /> */}
                <FormControlStatic title={linkedIn}>
                  <Link target="_blank" to="https://www.linkedin.com">
                    {linkedIn}
                  </Link>
                  <Icon name="linkedin-sm" />
                </FormControlStatic>
              </FormGroup>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

UserCardComponent.propTypes = {
  name: PropTypes.string,
  role: PropTypes.oneOf(values(ADMIN_ROLES)),
  firm: PropTypes.string,
  position: PropTypes.string,
  phoneNumber: PropTypes.string,
  linkedin: PropTypes.string,
  border: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  isCurrentUserOwner: PropTypes.bool,
  changeOwner: PropTypes.func,
  confirmDeletion: PropTypes.func,
};

UserCardComponent.defaultProps = {
  border: true,
  user: {},
  isCurrentUserOwner: false,
};

export default UserCardComponent;

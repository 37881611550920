// vendor
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// dm
import Button from '../../../atoms/Button';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import ModalControls from '../../Modal/ModalControls';

export default class KycInProgressModal extends Component {
  render() {
    return (
      <Modal className="modal--notification" {...this.props}>
        <ModalHeader title="modal.kyc.kyc_manual_in_progress.title" />
        <ModalBody>
          <FormattedMessage id="modal.kyc.kyc_manual_in_progress.text" />
        </ModalBody>
        <ModalFooter>
          <ModalControls>
            <Button text="Complete" onClick={this.props.close} />
          </ModalControls>
        </ModalFooter>
      </Modal>
    );
  }
}

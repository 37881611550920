// vendor
import React from 'react';
import { connect } from 'react-redux';

// own
import OpenModalButton from './OpenModalButton';

const AccessRequestButton = props => (
  <OpenModalButton
    icon="lock-white"
    text="Access"
    frozen
    action="accessRequest"
    {...props}
  />
);

export default connect(() => ({
  modal: 'PotentialInvestmentAmount',
}))(AccessRequestButton);

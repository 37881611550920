// vendor
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

// own
import './Kyc.scss';

const PartyKyc = Loadable({
  loader: () => import(/* webpackChunkName: "PartyKyc" */ './Party'),
  loading: () => null,
  modules: ['PartyKyc'],
});

const ProofKyc = Loadable({
  loader: () => import(/* webpackChunkName: "ProofKyc" */ './Proof'),
  loading: () => null,
  modules: ['ProofKyc'],
});

const VerifyKyc = Loadable({
  loader: () => import(/* webpackChunkName: "VerifyKyc" */ './Verify'),
  loading: () => null,
  modules: ['VerifyKyc'],
});

const KycView = () => (
  <div className="background">
    <Switch>
      <Route exact path="/know-your-customer/party" component={PartyKyc} />
      <Route
        exact
        path="/know-your-customer/proof-of-identity"
        component={ProofKyc}
      />
      <Route
        exact
        path="/know-your-customer/verify-your-identity"
        component={VerifyKyc}
      />
      <Redirect to="/know-your-customer/party" />
    </Switch>
  </div>
);

export default KycView;

// vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// dm
import Icon from '../Icons/Icon';

// own
import './FormControl.scss';

export default class FormControlComponent extends React.Component {
  static propTypes = {
    kind: PropTypes.oneOf(['btn', 'input', 'Select', 'text-area']),
    size: PropTypes.oneOf([
      false,
      'xs',
      'sm',
      'md',
      'md-compact',
      'md-extend',
      'lg',
      'lgx',
      'xl',
      'xxl',
      'lgxx',
    ]),
    icon: PropTypes.string,
    iconPos: PropTypes.oneOf(['left', 'center', 'right', 'top']),
    disabled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
  };

  static defaultProps = {
    iconPos: 'right',
    asyncValidating: false,
  };

  render() {
    const {
      kind,
      size,
      iconPos,
      disabled,
      active,
      asyncValidating,
      error,
      touched,
      success,
      ...props
    } = this.props;
    const showFundFormErrors = props.showFundFormErrors || false;
    const icon = props.icon || (success && 'mark-success');
    const className = classNames(kind, 'form-control', props.className, {
      [`${kind}--${size}`]: size,
      [`${kind}--icon ${kind}--icon--${iconPos}`]: icon,
      [`${kind}--disabled`]: disabled,
      [`${kind}--focus`]: active,
      [`${kind}--async-validating`]: asyncValidating, // animated infinite pulse
      [`${kind}--error`]: (showFundFormErrors || touched) && error,
      [`${kind}--success`]: success,
    });
    return (
      <div className={className} style={props.style}>
        {icon && <Icon name={icon} />}
        {props.children}
        {error !== ' ' && <div className={`${kind}__helper`}>{error}</div>}
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './dropdown.styles.scss';

class Dropdown extends Component {
  static propTypes = {
    tabIndex: PropTypes.string,
    options: PropTypes.array.isRequired,
    event: PropTypes.oneOf(['click', 'hover']),
  };

  state = {
    isActive: false,
    isVisible: false,
    options: this.props.options,
    event: 'hover',
  };

  toggleDropdown = () => {
    const { isVisible } = this.state;
    // Toggle dropdown block visibility
    this.setState({ isVisible: !isVisible });
  };

  hideDropdown = () => {
    const { isActive } = this.state;

    // Hide dropdown block if it's not active
    if (!isActive) {
      this.setState({ isVisible: false });
    }
  };

  handleFocus = () => {
    // Make active on focus
    this.setState({ isActive: true });
  };

  handleBlur = () => {
    // Clean up everything on blur
    this.setState({
      isVisible: false,
      isActive: false,
    });
  };

  handleChange = evt => {
    // Do something on click inside the dropdown block...
    evt.stopPropagation();
    // console.log('evt', evt)
  };

  render() {
    const { children, tabIndex, options } = this.props;
    const { isVisible } = this.state;
    return (
      <div className="dropdown">
        <div
          className="wrapper"
          tabIndex={tabIndex}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onClick={this.toggleDropdown}
        >
          <span className="toggler">{children}</span>
          {isVisible && (
            <div className="list">
              {options.map((option, index) => (
                <div key={index} className="option" onClick={this.handleChange}>
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dropdown;

import React, { cloneElement, Children } from 'react';
import cx from 'classnames';

import './tabs.styles.scss';

const TabsPanel = ({ active, className, changeActiveTab, ...props }) => {
  const childrens = Children.toArray(props.children);
  const classNames = cx('tabsPanel', className);
  return (
    <div className={classNames} {...props}>
      {childrens.map((children, index) => {
        return cloneElement(children, {
          isActive: index === active,
          tabIndex: index,
          changeActiveTab: tabIndex => changeActiveTab(tabIndex),
        });
      })}
    </div>
  );
};

export default TabsPanel;

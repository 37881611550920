// vendor
import { isArray } from 'lodash';
// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';
import { createPath } from '../../utils/route';

/* action types */
export const types = createConstants(
  // common

  'READ_FUNDS_REQUEST',
  'READ_FUNDS_FAILURE',
  'READ_FUNDS_SUCCESS',

  'READ_FUNDS_PUBLISHED_REQUEST',
  'READ_FUNDS_PUBLISHED_FAILURE',
  'READ_FUNDS_PUBLISHED_SUCCESS',

  'READ_FUND_INVESTMENTS_REQUEST',
  'READ_FUND_INVESTMENTS_SUCCESS',
  'READ_FUND_INVESTMENTS_FAILURE',

  'CLEAR_FUND',
  'ADD_FUND_DOCUMENT',
  'SET_FUND_SIGNATURE',

  'SEND_FUND_TO_MODERATION_REQUEST',
  'SEND_FUND_TO_MODERATION_SUCCESS',
  'SEND_FUND_TO_MODERATION_FAILURE',

  'READ_FUND_INVESTMENT_INTEREST_REQUEST',
  'READ_FUND_INVESTMENT_INTEREST_SUCCESS',
  'READ_FUND_INVESTMENT_INTEREST_FAILURE',

  // wizard fund

  'CREATE_FUND_REQUEST',
  'CREATE_FUND_SUCCESS',
  'CREATE_FUND_FAILURE',

  'CREATE_FUND_FROM_PUBLISHED_REQUEST',
  'CREATE_FUND_FROM_PUBLISHED_SUCCESS',
  'CREATE_FUND_FROM_PUBLISHED_FAILURE',

  'READ_FUND_REQUEST',
  'READ_FUND_SUCCESS',
  'READ_FUND_FAILURE',

  'READ_FUND_PUBLISHED_REQUEST',
  'READ_FUND_PUBLISHED_SUCCESS',
  'READ_FUND_PUBLISHED_FAILURE',

  'UPDATE_FUND_REQUEST',
  'UPDATE_FUND_SUCCESS',
  'UPDATE_FUND_FAILURE',

  'SET_FAVORITE_FUND_REQUEST',
  'SET_FAVORITE_FUND_SUCCESS',
  'SET_FAVORITE_FUND_FAILURE',

  // fund document

  'READ_DEAL_ROOM_DOCUMENTS_REQUEST',
  'READ_DEAL_ROOM_DOCUMENTS_SUCCESS',
  'READ_DEAL_ROOM_DOCUMENTS_FAILURE',

  'READ_REQUEST_DEAL_ROOM_DOCUMENTS_REQUEST',
  'READ_REQUEST_DEAL_ROOM_DOCUMENTS_SUCCESS',
  'READ_REQUEST_DEAL_ROOM_DOCUMENTS_FAILURE',

  'READ_PIPELINE_DOCUMENTS_DAYS_REQUEST',
  'READ_PIPELINE_DOCUMENTS_DAYS_SUCCESS',
  'READ_PIPELINE_DOCUMENTS_DAYS_FAILURE',

  'ALLOW_PIPELINE_DOCUMENTS_REQUEST',
  'ALLOW_PIPELINE_DOCUMENTS_SUCCESS',
  'ALLOW_PIPELINE_DOCUMENTS_FAILURE',

  // fund pipelines

  'CREATE_FUND_PIPELINE_REQUEST',
  'CREATE_FUND_PIPELINE_SUCCESS',
  'CREATE_FUND_PIPELINE_FAILURE',

  'ACCEPT_FUND_PIPELINE_REQUEST',
  'ACCEPT_FUND_PIPELINE_SUCCESS',
  'ACCEPT_FUND_PIPELINE_FAILURE',

  'DECLINE_FUND_PIPELINE_REQUEST',
  'DECLINE_FUND_PIPELINE_SUCCESS',
  'DECLINE_FUND_PIPELINE_FAILURE',

  'UPDATE_FUND_PIPELINE_REQUEST',
  'UPDATE_FUND_PIPELINE_SUCCESS',
  'UPDATE_FUND_PIPELINE_FAILURE',

  'ADD_KYS_STATUS_PIPELINE_REQUEST',
  'ADD_KYS_STATUS_PIPELINE_SUCCESS',
  'ADD_KYS_STATUS_PIPELINE_FAILURE',

  // fund manage

  'READ_FUND_ACTIVITY_FEEDS_REQUEST',
  'READ_FUND_ACTIVITY_FEEDS_FAILURE',
  'READ_FUND_ACTIVITY_FEEDS_SUCCESS',

  'READ_FUND_ADMINS_REQUEST',
  'READ_FUND_ADMINS_SUCCESS',
  'READ_FUND_ADMINS_FAILURE',

  'READ_FUND_INVESTOR_DOCUMENTS_REQUEST',
  'READ_FUND_INVESTOR_DOCUMENTS_SUCCESS',
  'READ_FUND_INVESTOR_DOCUMENTS_FAILURE',

  'READ_FUND_INVESTORS_REQUEST',
  'READ_FUND_INVESTORS_SUCCESS',
  'READ_FUND_INVESTORS_FAILURE',

  'READ_FUND_INVESTOR_REQUEST',
  'READ_FUND_INVESTOR_SUCCESS',
  'READ_FUND_INVESTOR_FAILURE',

  'READ_FUND_PIPELINES_REQUEST',
  'READ_FUND_PIPELINES_SUCCESS',
  'READ_FUND_PIPELINES_FAILURE',

  'READ_FUND_VIEW_COUNT_REQUEST',
  'READ_FUND_VIEW_COUNT_SUCCESS',
  'READ_FUND_VIEW_COUNT_FAILURE',

  'CREATE_FUND_ADMIN_REQUEST',
  'CREATE_FUND_ADMIN_SUCCESS',
  'CREATE_FUND_ADMIN_FAILURE',

  'CREATE_FUND_PIPELINE_REQUEST',
  'CREATE_FUND_PIPELINE_SUCCESS',
  'CREATE_FUND_PIPELINE_FAILURE',

  'UPDATE_INVESTOR_VIEWED_REQUEST',
  'UPDATE_INVESTOR_VIEWED_SUCCESS',
  'UPDATE_INVESTOR_VIEWED_FAILURE',

  'DELETE_FUND_ADMIN_REQUEST',
  'DELETE_FUND_ADMIN_SUCCESS',
  'DELETE_FUND_ADMIN_FAILURE',

  'DELETE_FUND_PIPELINE_REQUEST',
  'DELETE_FUND_PIPELINE_SUCCESS',
  'DELETE_FUND_PIPELINE_FAILURE',

  'ACCEPT_AM_FUND_ADMIN_REQUEST',
  'ACCEPT_AM_FUND_ADMIN_SUCCESS',
  'ACCEPT_AM_FUND_ADMIN_FAILURE',

  'CHANGE_FUND_OWNER_REQUEST',
  'CHANGE_FUND_OWNER_SUCCESS',
  'CHANGE_FUND_OWNER_FAILURE',

  'CHECK_FUND_COLLECTION',

  'CLEAR_FUND_INVESTOR',

  'FILTER_FUND_COLLECTION',

  'FIND_FUND_MAYBE_ADMIN_REQUEST',
  'FIND_FUND_MAYBE_ADMIN_SUCCESS',
  'FIND_FUND_MAYBE_ADMIN_FAILURE',

  'SET_ACTIVITY_FEED_READABLE_REQUEST',
  'SET_ACTIVITY_FEED_READABLE_SUCCESS',
  'SET_ACTIVITY_FEED_READABLE_FAILURE',

  'READ_MESSAGES_REQUEST',
  'READ_MESSAGES_SUCCESS',
  'READ_MESSAGES_FAILURE',

  'SEND_MESSAGE_REQUEST',
  'SEND_MESSAGE_SUCCESS',
  'SEND_MESSAGE_FAILURE',

  'GET_UNREAD_MESSAGES_AMOUNT_REQUEST',
  'GET_UNREAD_MESSAGES_AMOUNT_SUCCESS',
  'GET_UNREAD_MESSAGES_AMOUNT_FAILURE',

  'MARK_MESSAGE_AS_READ_REQUEST',
  'MARK_MESSAGE_AS_READ_SUCCESS',
  'MARK_MESSAGE_AS_READ_FAILURE',

  'CREATE_NOTE_REQUEST',
  'CREATE_NOTE_SUCCESS',
  'CREATE_NOTE_FAILURE',

  'DELETE_NOTE_REQUEST',
  'DELETE_NOTE_SUCCESS',
  'DELETE_NOTE_FAILURE',

  'READ_ALL_NOTES_REQUEST',
  'READ_ALL_NOTES_SUCCESS',
  'READ_ALL_NOTES_FAILURE',

  'READ_ONE_NOTE_REQUEST',
  'READ_ONE_NOTE_SUCCESS',
  'READ_ONE_NOTE_FAILURE',

  'UPDATE_NOTES_REQUEST',
  'UPDATE_NOTES_SUCCESS',
  'UPDATE_NOTES_FAILURE',

  'CLEAR_MESSAGES',
  'CLEAR_FUND_ACTIVITY_FEEDS',
  'CLEAR_MAYBE_ADMIN',

  'UPDATE_BEHAVIOR_ACTIVITY_FEEDS',
);
export const createFund = data => ({
  [CALL_API]: {
    types: [
      types.CREATE_FUND_REQUEST,
      types.CREATE_FUND_SUCCESS,
      types.CREATE_FUND_FAILURE,
    ],
    method: 'post',
    url: 'funds',
    data,
    form: 'fund',
    withProgressBar: true,
  },
});
export const createFundFromPublished = id => ({
  [CALL_API]: {
    types: [
      types.CREATE_FUND_FROM_PUBLISHED_REQUEST,
      types.CREATE_FUND_FROM_PUBLISHED_SUCCESS,
      types.CREATE_FUND_FROM_PUBLISHED_FAILURE,
    ],
    method: 'post',
    url: `funds/${id}/draft`,
    withProgressBar: true,
  },
});
export const readFund = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_REQUEST,
      types.READ_FUND_SUCCESS,
      types.READ_FUND_FAILURE,
    ],
    method: 'get',
    url: `funds/${id}?expand=total_investment_interest`,
    form: 'fund',
    withProgressBar: true,
  },
});
export const readFundPublished = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_PUBLISHED_REQUEST,
      types.READ_FUND_PUBLISHED_SUCCESS,
      types.READ_FUND_PUBLISHED_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${id}`,
    progressBar: false,
  },
});
export const updateFund = (id, data) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_FUND_REQUEST,
      types.UPDATE_FUND_SUCCESS,
      types.UPDATE_FUND_FAILURE,
    ],
    method: 'put',
    url: `funds/${id}`,
    data,
    form: 'fund',
  },
});
export const setFavoriteFund = (id, isFavorite) => ({
  [CALL_API]: {
    types: [
      types.SET_FAVORITE_FUND_REQUEST,
      types.SET_FAVORITE_FUND_SUCCESS,
      types.SET_FAVORITE_FUND_FAILURE,
    ],
    method: isFavorite ? 'put' : 'delete',
    url: `funds/${id}/favorite`,
  },
});
/* common */
export const readFunds = () => ({
  [CALL_API]: {
    types: [
      types.READ_FUNDS_REQUEST,
      types.READ_FUNDS_SUCCESS,
      types.READ_FUNDS_FAILURE,
    ],
    method: 'get',
    url: 'funds',
    withProgressBar: true,
  },
});
export const readFundsPublished = (weightOrder, firstLoadingFunds) => {
  const limit = firstLoadingFunds ? '' : '&limit=10';
  return {
    [CALL_API]: {
      types: [
        types.READ_FUNDS_PUBLISHED_REQUEST,
        types.READ_FUNDS_PUBLISHED_SUCCESS,
        types.READ_FUNDS_PUBLISHED_FAILURE,
      ],
      method: 'get',
      url: `funds/published?weight_order=${weightOrder}&fields=${[
        'id',
        'logo',
        'name',
        'investment_type_id',
        'summary',
        'fund_strategies',
        'industry_focuses',
        'preferred_venture_capital_stage_focuses',
        'fund_aum',
        'fund_life',
        'minimum_investment',
        'management_free',
        'carry',
        'redemption_period_id',
        'water_mark_id',
        'eligible_investors',
        'is_pipelined',
        'is_pending_pipelined',
        'pipelined_stage',
        'is_favorite',
      ].join(',')}${limit}`,
      withProgressBar: true,
    },
  };
};
export const readTotalInvestmentInterest = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_INVESTMENT_INTEREST_REQUEST,
      types.READ_FUND_INVESTMENT_INTEREST_SUCCESS,
      types.READ_FUND_INVESTMENT_INTEREST_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${id}?fields=total_investment_interest&expand=total_investment_interest`,
    withProgressBar: false,
  },
});
export const readFundsForInvestments = funds => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_INVESTMENTS_REQUEST,
      types.READ_FUND_INVESTMENTS_SUCCESS,
      types.READ_FUND_INVESTMENTS_FAILURE,
    ],
    method: 'get',
    url: `funds/published?fields=id,logo,name,minimum_investment,able_to_invest,ready_to_invest&filter=[{"id":[${funds}]}]`,
    withProgressBar: false,
  },
});
export const fundSendToModeration = id => ({
  [CALL_API]: {
    types: [
      types.SEND_FUND_TO_MODERATION_REQUEST,
      types.SEND_FUND_TO_MODERATION_SUCCESS,
      types.SEND_FUND_TO_MODERATION_FAILURE,
    ],
    method: 'post',
    url: `funds/${id}/send-to-moderation`,
    withProgressBar: true,
  },
});
export const fundSubmitSignature = signature => ({
  type: types.SET_FUND_SIGNATURE,
  payload: signature,
});
export const fundDeclineSignature = () => ({
  type: types.SET_FUND_SIGNATURE,
  payload: {},
});
export const clearFund = () => ({ type: types.CLEAR_FUND });
// export const addFundDocument = document => ({
//   type: types.ADD_FUND_DOCUMENT,
//   payload: document,
// });

/* fund cocuments */
const fundDocumentsPath = createPath('funds/:id/documents');
export const readDealRoomDocuments = ({ id }) => ({
  [CALL_API]: {
    types: [
      types.READ_DEAL_ROOM_DOCUMENTS_REQUEST,
      types.READ_DEAL_ROOM_DOCUMENTS_SUCCESS,
      types.READ_DEAL_ROOM_DOCUMENTS_FAILURE,
    ],
    method: 'get',
    url: fundDocumentsPath({ id }),
    withProgressBar: true,
  },
});
export const readRequestFundDocuments = ({ id }) => ({
  [CALL_API]: {
    types: [
      types.READ_REQUEST_DEAL_ROOM_DOCUMENTS_REQUEST,
      types.READ_REQUEST_DEAL_ROOM_DOCUMENTS_SUCCESS,
      types.READ_REQUEST_DEAL_ROOM_DOCUMENTS_FAILURE,
    ],
    method: 'post',
    url: `${fundDocumentsPath({ id })}/request`,
  },
});
const pipelineDocumentsPath = createPath(
  'funds/:id/pipelines/:investorId/documents',
);
export const readPipelineDocumentsDays = ({ id, investorId }) => ({
  [CALL_API]: {
    types: [
      types.READ_PIPELINE_DOCUMENTS_DAYS_REQUEST,
      types.READ_PIPELINE_DOCUMENTS_DAYS_SUCCESS,
      types.READ_PIPELINE_DOCUMENTS_DAYS_FAILURE,
    ],
    method: 'get',
    url: `${pipelineDocumentsPath({ id, investorId })}/check`,
  },
});
export const allowPipelineDocuments = ({ id, investorId, documents }) => ({
  [CALL_API]: {
    types: [
      types.ALLOW_PIPELINE_DOCUMENTS_REQUEST,
      types.ALLOW_PIPELINE_DOCUMENTS_SUCCESS,
      types.ALLOW_PIPELINE_DOCUMENTS_FAILURE,
    ],
    method: 'put',
    url: `${pipelineDocumentsPath({ id, investorId })}?action=add`,
    data: {
      documents: isArray(documents) ? documents : [documents],
    },
  },
});

/* fund pipelines */
const pipelinesPath = createPath('funds/:id/pipelines');
export const createFundPipeline = ({ id, investorId, amount }) => ({
  [CALL_API]: {
    types: [
      types.CREATE_FUND_PIPELINE_REQUEST,
      types.CREATE_FUND_PIPELINE_SUCCESS,
      types.CREATE_FUND_PIPELINE_FAILURE,
    ],
    method: 'post',
    url: pipelinesPath({ id }),
    data: { userId: investorId, amount },
    extraData: { investorId },
    withProgressBar: true,
  },
});
const pipelinePath = createPath('funds/:id/pipelines/:investorId');
export const acceptFundPipeline = ({ id, investorId, amount }) => ({
  [CALL_API]: {
    types: [
      types.ACCEPT_FUND_PIPELINE_REQUEST,
      types.ACCEPT_FUND_PIPELINE_SUCCESS,
      types.ACCEPT_FUND_PIPELINE_FAILURE,
    ],
    method: 'put',
    url: `${pipelinePath({ id, investorId })}/accept`,
    data: { amount },
    extraData: { id },
    withProgressBar: true,
  },
});
export const declineFundPipeline = ({ id, investorId }) => ({
  [CALL_API]: {
    types: [
      types.DECLINE_FUND_PIPELINE_REQUEST,
      types.DECLINE_FUND_PIPELINE_SUCCESS,
      types.DECLINE_FUND_PIPELINE_FAILURE,
    ],
    method: 'put',
    url: `${pipelinePath({ id, investorId })}/decline`,
    extraData: { investorId },
    withProgressBar: true,
  },
});
export const updateFundPipeline = ({ id, amount }) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_FUND_PIPELINE_REQUEST,
      types.UPDATE_FUND_PIPELINE_SUCCESS,
      types.UPDATE_FUND_PIPELINE_FAILURE,
    ],
    method: 'put',
    url: `funds/${id}/pipelines/update`,
    data: { amount },
    withProgressBar: true,
  },
});
export const addKYSStatusPipeline = ({ id }) => ({
  [CALL_API]: {
    types: [
      types.ADD_KYS_STATUS_PIPELINE_REQUEST,
      types.ADD_KYS_STATUS_PIPELINE_SUCCESS,
      types.ADD_KYS_STATUS_PIPELINE_FAILURE,
    ],
    method: 'put',
    url: `funds/${id}/pipelines/documents-access-request`,
    withProgressBar: false,
  },
});

/* fund manage */
export const readFundActivityFeedsAm = fundId => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_ACTIVITY_FEEDS_REQUEST,
      types.READ_FUND_ACTIVITY_FEEDS_SUCCESS,
      types.READ_FUND_ACTIVITY_FEEDS_FAILURE,
    ],
    method: 'get',
    url: `funds/${fundId}/activity-feed`,
    withProgressBar: false,
  },
});
export const readFundActivityFeedsAmForInvestor = (fundId, userId) => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_ACTIVITY_FEEDS_REQUEST,
      types.READ_FUND_ACTIVITY_FEEDS_SUCCESS,
      types.READ_FUND_ACTIVITY_FEEDS_FAILURE,
    ],
    method: 'get',
    url: `funds/${fundId}/activity-feed?investor_id=${userId}`,
    withProgressBar: false,
  },
});
export const readFundActivityFeedsInv = userId => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_ACTIVITY_FEEDS_REQUEST,
      types.READ_FUND_ACTIVITY_FEEDS_SUCCESS,
      types.READ_FUND_ACTIVITY_FEEDS_FAILURE,
    ],
    method: 'get',
    url: `investors/${userId}/activity-feed`,
    withProgressBar: false,
  },
});
export const readFundActivityFeedsInvForFund = (fundId, userId) => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_ACTIVITY_FEEDS_REQUEST,
      types.READ_FUND_ACTIVITY_FEEDS_SUCCESS,
      types.READ_FUND_ACTIVITY_FEEDS_FAILURE,
    ],
    method: 'get',
    url: `investors/${userId}/activity-feed?fund_id=${fundId}`,
    withProgressBar: false,
  },
});
export const behaviorActivityFeeds = payload => ({
  type: types.UPDATE_BEHAVIOR_ACTIVITY_FEEDS,
  payload,
});
export const clearFundActivityFeeds = () => ({
  type: types.CLEAR_FUND_ACTIVITY_FEEDS,
});
export const readFundAdmins = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_ADMINS_REQUEST,
      types.READ_FUND_ADMINS_SUCCESS,
      types.READ_FUND_ADMINS_FAILURE,
    ],
    method: 'get',
    url: `funds/${id}/admins?expand=user`,
    withProgressBar: true,
  },
});
export const readFundInvestorDocuments = (id, userId) => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_INVESTOR_DOCUMENTS_REQUEST,
      types.READ_FUND_INVESTOR_DOCUMENTS_SUCCESS,
      types.READ_FUND_INVESTOR_DOCUMENTS_FAILURE,
    ],
    method: 'get',
    url: `funds/${id}/documents?investor_id=${userId}`,
    withProgressBar: true,
  },
});

// TODO: check and remove
export const readFundInvestors = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_INVESTORS_REQUEST,
      types.READ_FUND_INVESTORS_SUCCESS,
      types.READ_FUND_INVESTORS_FAILURE,
    ],
    method: 'get',
    url: `funds/${id}/investors?fields=${[
      'user_id',
      'is_pipelined',
      'is_pipeline',
      'is_pending_pipelined',
      'avatar',
      'name',
      'type_id',
      'assets_under_management_id',
      'average_investment_id',
      'is_previously_viewed',
      'firm_name',
      'preferred_asset_types',
      'preferred_hedge_fund_strategies',
      'preferred_venture_capital_stage_focuses',
      'industry_focus',
      'eligibility',
    ].join(',')}`,
    withProgressBar: true,
  },
});
export const readFundInvestor = ({ id, userId }) => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_INVESTOR_REQUEST,
      types.READ_FUND_INVESTOR_SUCCESS,
      types.READ_FUND_INVESTOR_FAILURE,
    ],
    method: 'get',
    url: `funds/${id}/investors/${userId}`,
    withProgressBar: true,
  },
});
export const readFundPipelines = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_PIPELINES_REQUEST,
      types.READ_FUND_PIPELINES_SUCCESS,
      types.READ_FUND_PIPELINES_FAILURE,
    ],
    method: 'get',
    // TODO: replace to excluded_fields: firm_name
    url: `funds/${id}/pipelines?fields=${[
      'investment_interest',
      'user_id',
      'is_pipelined',
      'is_pipeline',
      'is_pending_pipelined',
      'avatar',
      'name',
      'type_id',
      'assets_under_management_id',
      'average_investment_id',
      'is_previously_viewed',
      'stage_id',
      'preferred_asset_types',
      'preferred_hedge_fund_strategies',
      'preferred_venture_capital_stage_focuses',
      'industry_focus',
      'eligibility',
    ].join(',')}`,
    withProgressBar: true,
  },
});
export const readFundPipeline = (id, userId) => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_INVESTOR_REQUEST,
      types.READ_FUND_INVESTOR_SUCCESS,
      types.READ_FUND_INVESTOR_FAILURE,
    ],
    method: 'get',
    url: `funds/${id}/pipelines/${userId}`,
    withProgressBar: true,
  },
});
export const readFundViewCount = id => ({
  [CALL_API]: {
    types: [
      types.READ_FUND_VIEW_COUNT_REQUEST,
      types.READ_FUND_VIEW_COUNT_SUCCESS,
      types.READ_FUND_VIEW_COUNT_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${id}/view-count`,
    withProgressBar: true,
  },
});
export const createFundAdmin = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_FUND_ADMIN_REQUEST,
      types.CREATE_FUND_ADMIN_SUCCESS,
      types.CREATE_FUND_ADMIN_FAILURE,
    ],
    method: 'post',
    url: `funds/${id}/admins`,
    data,
    form: 'fundManage',
    withProgressBar: true,
  },
});
export const updateInvestorViewed = ({ id, userId, ...data }) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_INVESTOR_VIEWED_REQUEST,
      types.UPDATE_INVESTOR_VIEWED_SUCCESS,
      types.UPDATE_INVESTOR_VIEWED_FAILURE,
    ],
    method: 'put',
    url: `funds/${id}/investors/${userId}/mark`,
    data,
    withProgressBar: true,
  },
});
export const deleteFundAdmin = (id, userId) => ({
  [CALL_API]: {
    types: [
      types.DELETE_FUND_ADMIN_REQUEST,
      types.DELETE_FUND_ADMIN_SUCCESS,
      types.DELETE_FUND_ADMIN_FAILURE,
    ],
    method: 'delete',
    url: `funds/${id}/admins/${userId}`,
    withProgressBar: true,
  },
});
export const deleteFundPipeline = (id, userId) => ({
  [CALL_API]: {
    types: [
      types.DELETE_FUND_PIPELINE_REQUEST,
      types.DELETE_FUND_PIPELINE_SUCCESS,
      types.DELETE_FUND_PIPELINE_FAILURE,
    ],
    method: 'delete',
    url: `funds/${id}/pipelines/${userId}`,
    extraData: { userId },
    withProgressBar: true,
  },
});
export const changeFundOwner = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CHANGE_FUND_OWNER_REQUEST,
      types.CHANGE_FUND_OWNER_SUCCESS,
      types.CHANGE_FUND_OWNER_FAILURE,
    ],
    method: 'post',
    url: `funds/${id}/change-owner`,
    data,
    withProgressBar: true,
  },
});
export const acceptAmFundAdmin = (id, token) => ({
  [CALL_API]: {
    types: [
      types.ACCEPT_AM_FUND_ADMIN_REQUEST,
      types.ACCEPT_AM_FUND_ADMIN_SUCCESS,
      types.ACCEPT_AM_FUND_ADMIN_FAILURE,
    ],
    method: 'put',
    url: `funds/${id}/admins`,
    data: { token },
    withProgressBar: true,
  },
});
export const checkFundCollection = (name, checked) => ({
  type: types.CHECK_FUND_COLLECTION,
  payload: { name, checked },
});
export const clearFundInvestor = () => ({
  type: types.CLEAR_FUND_INVESTOR,
});
export const filterFundCollection = (name, filters) => ({
  type: types.FILTER_FUND_COLLECTION,
  payload: { name, filters },
});
export const findFundMaybeAdmin = data => ({
  [CALL_API]: {
    types: [
      types.FIND_FUND_MAYBE_ADMIN_REQUEST,
      types.FIND_FUND_MAYBE_ADMIN_SUCCESS,
      types.FIND_FUND_MAYBE_ADMIN_FAILURE,
    ],
    method: 'post',
    url: 'asset-managers/find',
    data,
    form: 'fundManage',
    withProgressBar: true,
  },
});
export const clearMaybeAdmin = () => ({
  type: types.CLEAR_MAYBE_ADMIN,
});
export const setActivityFeedReadable = ({ fundId, flag, feedId }) => ({
  [CALL_API]: {
    types: [
      types.SET_ACTIVITY_FEED_READABLE_REQUEST,
      types.SET_ACTIVITY_FEED_READABLE_SUCCESS,
      types.SET_ACTIVITY_FEED_READABLE_FAILURE,
    ],
    method: 'put',
    url: `funds/${fundId}/activity-feed/${flag}`,
    data: { feedId },
    withProgressBar: true,
  },
});
export const createNoteMessage = ({ fundId, investorId, text }) => ({
  [CALL_API]: {
    types: [
      types.CREATE_NOTE_REQUEST,
      types.CREATE_NOTE_SUCCESS,
      types.CREATE_NOTE_FAILURE,
    ],
    method: 'post',
    data: { investorId, text },
    url: `funds/published/${fundId}/notes`,
    withProgressBar: true,
  },
});
export const deleteNoteMessage = ({ fundId, deleteNotes }) => ({
  [CALL_API]: {
    types: [
      types.DELETE_NOTE_REQUEST,
      types.DELETE_NOTE_SUCCESS,
      types.DELETE_NOTE_FAILURE,
    ],
    method: 'delete',
    url: `funds/published/${fundId}/notes/${deleteNotes}`,
    extraData: deleteNotes.slice(','),
    withProgressBar: true,
  },
});

// TODO: check and remove
export const readAllNoteMessages = fundId => ({
  [CALL_API]: {
    types: [
      types.READ_ALL_NOTES_REQUEST,
      types.READ_ALL_NOTES_SUCCESS,
      types.READ_ALL_NOTES_FAILURE,
    ],
    method: 'get',
    url: `funds/published/${fundId}/notes?expand=created_at`,
    withProgressBar: true,
  },
});
export const updateNoteMessage = ({ fundId, investorId, text, id }) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_NOTES_REQUEST,
      types.UPDATE_NOTES_SUCCESS,
      types.UPDATE_NOTES_FAILURE,
    ],
    method: 'put',
    data: { investorId, text },
    url: `funds/published/${fundId}/notes/${id}`,
    withProgressBar: true,
  },
});
// export const createFundPipeline = (id, investorId) => ({
//   [CALL_API]: {
//     types: [
//       types.CREATE_FUND_PIPELINE_REQUEST,
//       types.CREATE_FUND_PIPELINE_SUCCESS,
//       types.CREATE_FUND_PIPELINE_FAILURE,
//     ],
//     method: 'post',
//     url: `funds/${id}/pipelines`,
//     data: { userId: investorId },
//     extraData: { investorId },
//     withProgressBar: true,
//   },
// });

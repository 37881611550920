// vendor
import { formValueSelector } from 'redux-form/immutable';
import { fromJS } from 'immutable';
// dm
import { fundTypes, invDashboardTypes, investmentTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'invDashboard';

/* initial state */
const initialState = fromJS({
  values: {
    dealRooms: [],
    invitationDealRooms: [],
    pendingDealRooms: [],
    pendingDocuments: [],
    dealRoom: {},
    documents: [],
    archivedDeals: [],
    totalInvestmentAmount: null,
    noLongerInterestedVisible: null,
    favoriteFunds: [],
  },
});

/* selectors */
export const selector = formValueSelector(form);

/* reducer */
export default createReducer(initialState, {
  [investmentTypes.READ_INVESTOR_INVESTMENT_INTEREST_SUCCESS]: (
    state,
    { response },
  ) =>
    state.setIn(
      'values.noLongerInterestedVisible'.p(),
      response && response.data && response.data.length > 0
        ? fromJS(false)
        : fromJS(true),
    ),
  [fundTypes.READ_DEAL_ROOM_DOCUMENTS_SUCCESS]: (state, { response }) =>
    state.setIn('values.documents'.p(), fromJS(response.data)),
  [fundTypes.READ_REQUEST_DEAL_ROOM_DOCUMENTS_SUCCESS]: (state, { response }) =>
    state.mergeDeepIn('values.dealRoom'.p(), {
      pipelinedStage: { id: response.data.statusId },
    }),
  [fundTypes.ACCEPT_FUND_PIPELINE_SUCCESS]: (state, { response: { data } }) =>
    state
      .updateIn('values.dealRooms'.p(), dealRooms =>
        dealRooms.push(fromJS(data.fund)),
      )
      .updateIn('values.invitationDealRooms'.p(), invitationDealRooms =>
        invitationDealRooms.filterNot(
          invitationDealRoom => data.fundId === invitationDealRoom.get('id'),
        ),
      ),
  [fundTypes.DECLINE_FUND_PIPELINE_SUCCESS]: (state, { response: { data } }) =>
    state.updateIn('values.invitationDealRooms'.p(), invitationDealRooms =>
      invitationDealRooms.filterNot(
        invitationDealRoom => data.fundId === invitationDealRoom.get('id'),
      ),
    ),
  [invDashboardTypes.READ_DEAL_ROOM_SUCCESS]: (state, { response }) =>
    state.setIn('values.dealRoom'.p(), fromJS(response.data)),
  [invDashboardTypes.READ_DEAL_ROOMS_SUCCESS]: (state, { response }) =>
    state.setIn('values.dealRooms'.p(), fromJS(response.data)),
  [invDashboardTypes.READ_INVITATION_DEAL_ROOMS_SUCCESS]: (
    state,
    { response },
  ) => state.setIn('values.invitationDealRooms'.p(), fromJS(response.data)),
  [invDashboardTypes.READ_PENDING_DEAL_ROOMS_SUCCESS]: (state, { response }) =>
    state.setIn('values.pendingDealRooms'.p(), fromJS(response.data)),
  [invDashboardTypes.CLEAR_DEAL_ROOM]: state =>
    state
      .setIn('values.dealRoom'.p(), fromJS({}))
      .setIn('values.documents'.p(), fromJS([])),
  [invDashboardTypes.SET_DEAL_ROOM_AF_COUNTER]: (state, payload) =>
    state.setIn(
      `values.dealRooms.${payload.indexDealRoom}.unreadFeedsAmount`.p(),
      payload.unreadCounterReal,
    ),
  [invDashboardTypes.MOVE_DEAL_ROOM_TO_ARCHIVED_SUCCESS]: (
    state,
    { requestData },
  ) =>
    state.updateIn('values.dealRooms'.p(), dealRooms =>
      dealRooms.delete(
        dealRooms.findKey(
          dealRoom => dealRoom.get('id') === +requestData.fundId,
        ),
      ),
    ),
  [invDashboardTypes.RESTORE_DEAL_ROOM_FROM_ARCHIVED_SUCCESS]: (
    state,
    { response },
  ) =>
    state.updateIn('values.archivedDeals'.p(), archivedDeals =>
      archivedDeals.delete(
        archivedDeals.findKey(
          archivedDeal => archivedDeal.get('fundId') === response.data.fundId,
        ),
      ),
    ),
  [invDashboardTypes.READ_PENDING_DOCUMENTS_SUCCESS]: (state, { response }) =>
    state.setIn('values.pendingDocuments'.p(), fromJS(response.data)),
  [invDashboardTypes.READ_ARCHIVED_DEALS_SUCCESS]: (state, { response }) =>
    state.setIn('values.archivedDeals'.p(), fromJS(response.data)),
  [invDashboardTypes.READ_INVESTMENTS_AMOUNT_SUCCESS]: (state, { response }) =>
    state.setIn('values.totalInvestmentAmount'.p(), fromJS(response.data)),
  [invDashboardTypes.UPDATE_INVESTMENT_DEAL_ROOM]: (state, payload) =>
    state.mergeIn('values.dealRoom'.p(), {
      readyToInvest: fromJS(payload.readyToInvest),
      ableToInvest: fromJS(payload.ableToInvest),
    }),
  [invDashboardTypes.READ_FAVORITE_FUNDS_SUCCESS]: (state, { response }) =>
    state.setIn('values.favoriteFunds'.p(), fromJS(response.data)),
  [fundTypes.SET_FAVORITE_FUND_SUCCESS]: (state, { response }) =>
    state.updateIn('values.favoriteFunds'.p(), favoriteFunds =>
      favoriteFunds.delete(
        favoriteFunds.findKey(fund => fund.get('id') === response.data.id),
      ),
    ),
  [fundTypes.UPDATE_FUND_PIPELINE_SUCCESS]: (state, { response }) =>
    state.mergeIn('values.dealRoom'.p(), {
      pipelinedAmount: fromJS(response.data.amount),
    }),
});

export const PUBLIC_ROUTES = {
  home: '',
  institutionalServices: 'institutional-services',
  browseInvestments: 'browse-investments',
  forInvestors: 'for-investors',
  forAssetManager: 'for-asset-managers',
  aboutUs: 'about-us',
  aboutUsAwards: 'about-us#awards_and_recognition',
  contactUs: 'contact-us',
  blog: 'blog/',
  press: 'press',
  privacy: 'privacy-policy',
  terms: 'terms-of-use',
  login: 'log-in',
  resetPassword: 'reset-password',
  confirmPassword: 'confirm-password',
  registerInvestor: 'registration?userType=investor',
  requestDemo: 'request-demo',
};

export default {
  // common routes
  ...PUBLIC_ROUTES,

  // common routes
  profile: 'profile',
  dashboard: 'dashboard',
  investments: 'investments/:id',

  // asset-manager routes
  amDashboard: 'am-dashboard',
  funds: {
    index: 'am-dashboard/funds',
    new: 'new',
    edit: ':id/edit',
    manage: {
      index: ':id/manage',
      overview: 'overview',
      investors: {
        index: 'browse',
        show: ':userId',
      },
      pipelines: {
        index: 'pipelines',
        show: {
          index: ':userId',
          activityFeeds: 'activity-feeds',
          documents: 'documents',
        },
      },
      permissions: 'permissions',
    },
  },

  // investor routes
  browse: 'browse',
  kyc: {
    index: 'know-your-customer',
    party: 'party',
    proof: 'proof-of-identity',
    verify: 'verify-your-identity',
  },
  invDashboard: {
    index: 'inv-dashboard',
    tabs: {
      index: 'tabs',
      activityFeeds: 'activity-feeds',
      dealRooms: 'deal-rooms',
      pendings: 'pendings',
    },
    dealRooms: {
      index: 'deal-rooms',
      show: {
        index: ':id',
        documents: 'documents',
        activityFeeds: 'activity-feeds',
      },
    },
    archivedDeals: 'archived-deals',
    favoriteFunds: 'favorite-funds',
  },
};

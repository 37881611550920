// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// dm
import Icon from '../../../atoms/Icons/Icon';
import AvatarImage from '../../../atoms/Images/AvatarImage';
import Dropzone from '../Dropzone';
import { IMAGE } from '../../../../constants/files';

// own
import './Avatar.scss';

export default class AvatarComponent extends Component {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
    onLoad: PropTypes.func.isRequired,
    caption: PropTypes.string,
  };

  static defaultProps = {
    caption: 'Upload photo',
  };

  constructor(props) {
    super(props);
    this.state = {
      src: props.input.value ? props.input.value.toJS().url : '',
    };
  }

  componentWillReceiveProps({ input: { value } }) {
    if (value && !value.equals(this.props.input.value)) {
      this.setState({ src: value.toJS().url });
    }
  }

  handleLoad = (onLoad, name) => avatar => {
    this.setState({ src: avatar.preview });
    return onLoad(avatar, name);
  };

  render() {
    const {
      props: {
        className,
        input: { name },
        meta,
        onLoad,
        caption,
      },
      state: { src },
    } = this;
    const { error, touched } = meta || false;
    const showFundFormErrors = this.props.showFundFormErrors || false;
    const fullClassName = classNames(className, 'avatar', {
      'avatar--error': error && (touched || showFundFormErrors),
    });

    return (
      <Dropzone
        className={fullClassName}
        activeClassName="avatar--active"
        types={IMAGE.TYPES}
        maxSize={IMAGE.SIZE_MAX}
        onLoad={this.handleLoad(onLoad, name)}
        {...{ meta }}
      >
        <AvatarImage src={src} />
        <div className="avatar__overlay">
          <Icon name="photo" />
          <div className="avatar__overlay__caption">{caption}</div>
        </div>
      </Dropzone>
    );
  }
}

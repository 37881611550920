// vendor
import React from 'react';
import classNames from 'classnames';

// own
import './ActivityFeedsSidebarRow.scss';

export default ({
  sidebarDate: { yearVisible, year, month },
  scrollForMonth,
  index,
  highlightIndex,
}) => {
  const classNameRow = classNames('activity-feeds__sidebar-row', {
    'activity-feeds__sidebar-row--custom': yearVisible,
  });

  const classNameButton = classNames('activity-feeds__sidebar-button', {
    [`activity-feeds__sidebar-button--${highlightIndex}`]: highlightIndex,
  });

  return (
    <div className={classNameRow}>
      {yearVisible && (
        <div className="activity-feeds__sidebar-year">{year}</div>
      )}
      <div
        className={classNameButton}
        onClick={scrollForMonth.bind(
          null,
          { year, month },
          index,
          highlightIndex,
        )}
      />
      <div className="activity-feeds__sidebar-month">{month}</div>
    </div>
  );
};

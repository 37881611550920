// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

// own
import './FundShowOverview.scss';

export default ({ summary, highlights }) => (
  <div className="fund-show-overview">
    <div className="fund-show-overview__title">
      <FormattedMessage id="Overview" />
    </div>
    <div className="fund-show-overview__content">
      {summary && (
        <div className="fund-show-overview__col fund-show-overview__summary">
          <div className="fund-show-overview__sub__title">
            <FormattedMessage id="Fund Summary" />
          </div>
          <span>{summary}</span>
        </div>
      )}
      {!isEmpty(highlights) && (
        <div className="fund-show-overview__col fund-show-overview__highlights">
          <div className="fund-show-overview__sub__title">
            <FormattedMessage id="Fund Highlights" />
          </div>
          <div className="fund-show-overview__list">
            {highlights
              .filter(item => item.text)
              .map((elem, index) => (
                <div className="fund-show-overview__list__item" key={index}>
                  {elem.text}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  </div>
);

// vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import IconLg from '../../../atoms/Icons/IconLg';

export default class RequestToFund extends React.Component {
  render() {
    const { className } = this.props;
    const fullClassName = classNames('am-investor-card__contacts', className);
    return (
      <div className={fullClassName}>
        <div className="am-investor-card__contacts__title">
          <FormattedMessage id="Contact Information" />:
        </div>
        <div className="am-investor-card__contacts__content">
          <IconLg name="notification" />
          <FormattedMessage id="fund.manage.investor-page.waiting-confirmation-pipeline.text" />
        </div>
      </div>
    );
  }
}

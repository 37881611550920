import React from 'react';

import Icon from '../../atoms/Icons/Icon';

import s from './HeaderNavItem.module.scss';

const HeaderNavItem = ({ icon, text, notificationsAmount, onClick }) => (
  <div className={s.header_nav_item} onClick={onClick}>
    <div className={s.icon}>
      {notificationsAmount && (
        <span className={s.amount}>{notificationsAmount}</span>
      )}
      <Icon name={icon} />
    </div>
    <span className={s.text}>{text}</span>
  </div>
);

export default HeaderNavItem;

export function openAuthWindow({ url, width, height, title = null }) {
  // Fixes dual-screen position, Most browsers, Firefox
  const dualScreenLeft = window.screenLeft || window.screen.left;
  const dualScreenTop = window.screenTop || window.screen.top;

  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  const left = (screenWidth - width) / 2 + dualScreenLeft;
  const top = (screenHeight - height) / 2 + dualScreenTop;

  const options = `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}, tolbar=no`;
  const authWindow = window.open(url, title, options);
  // Puts focus on the newWindow
  if (window.focus) {
    authWindow.focus();
  }
  return authWindow;
}

export function scrollTo(scrollTop) {
  document.body.scrollTop = scrollTop; // For Chrome, Safari and Opera
  document.documentElement.scrollTop = scrollTop; // For Firefox and IE
}

export function scrollToTop() {
  scrollTo(0);
}

export const scrollToTopOnLocationChange = (prevState, nextState) => {
  const prevLocation = prevState.location;
  const nextLocation = nextState.location;
  if (prevLocation.pathname !== nextLocation.pathname) {
    scrollToTop();
  }
};

const getOffsetTop = (node, considerHeader) => {
  const HEADER_HEIGHT = 70;

  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
  const getCSS1Compat = isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scroll;

  const offsetY = supportPageOffset ? window.pageYOffset : getCSS1Compat;

  let offsetTop;
  if (considerHeader) {
    offsetTop = node.offsetTop - (offsetY + HEADER_HEIGHT);
  } else {
    offsetTop = node.offsetTop - offsetY;
  }
  return offsetTop;
};

export const scrollIntoView = (
  node,
  { duration = 200, considerHeader = true } = {},
) => {
  const INTERVAL = 5;
  const scrollStep = getOffsetTop(node, considerHeader) / (duration / INTERVAL);
  const scrollInterval = setInterval(() => {
    const currentOffsetTop = getOffsetTop(node, considerHeader);
    if (Math.abs(currentOffsetTop) > Math.abs(scrollStep)) {
      window.scrollBy(0, scrollStep);
    } else if (
      Math.abs(currentOffsetTop) <= Math.abs(scrollStep) &&
      currentOffsetTop !== 0
    ) {
      window.scrollBy(0, currentOffsetTop);
    } else {
      clearInterval(scrollInterval);
    }
  }, INTERVAL);
};

// dm
import { CALL_API } from '../../constants/api';
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants(
  'READ_KYC_VERIFY_REQUEST',
  'READ_KYC_VERIFY_SUCCESS',
  'READ_KYC_VERIFY_FAILURE',

  'CREATE_KYC_VERIFY_REQUEST',
  'CREATE_KYC_VERIFY_SUCCESS',
  'CREATE_KYC_VERIFY_FAILURE',

  'GET_KYC_QUESTIONS_REQUEST',
  'GET_KYC_QUESTIONS_SUCCESS',
  'GET_KYC_QUESTIONS_FAILURE',

  'UPDATE_KYC_QUESTIONS_REQUEST',
  'UPDATE_KYC_QUESTIONS_SUCCESS',
  'UPDATE_KYC_QUESTIONS_FAILURE',
);

export const readKycVerify = id => ({
  [CALL_API]: {
    types: [
      types.GET_KYC_VERIFY_REQUEST,
      types.GET_KYC_VERIFY_SUCCESS,
      types.GET_KYC_VERIFY_FAILURE,
    ],
    method: 'get',
    url: `users/${id}/questions`,
    withProgressBar: true,
  },
});

export const createKycVerify = (id, data) => ({
  [CALL_API]: {
    types: [
      types.CREATE_KYC_VERIFY_REQUEST,
      types.CREATE_KYC_VERIFY_SUCCESS,
      types.CREATE_KYC_VERIFY_FAILURE,
    ],
    method: 'post',
    url: `users/${id}/questions`,
    data,
  },
});

export const getKycQuestions = id => ({
  [CALL_API]: {
    types: [
      types.GET_KYC_QUESTIONS_REQUEST,
      types.GET_KYC_QUESTIONS_SUCCESS,
      types.GET_KYC_QUESTIONS_FAILURE,
    ],
    method: 'get',
    url: `users/${id}/questions`,
    withProgressBar: true,
  },
});

export const updateKycQuestions = (id, data) => ({
  [CALL_API]: {
    types: [
      types.UPDATE_KYC_QUESTIONS_REQUEST,
      types.UPDATE_KYC_QUESTIONS_SUCCESS,
      types.UPDATE_KYC_QUESTIONS_FAILURE,
    ],
    method: 'post',
    url: `users/${id}/questions`,
    data,
  },
});
